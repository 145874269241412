export interface EmbedConfig {
  brandId: string;
  categoryId?: string;
  language?: string;
  home?: string;
  routerType: string;
  basePath?: string;
  hashType: string;
  userFirstName?: string;
  userLastName?: string;
  userEmail?: string;
  userPhone?: string;
  memberNumber?: string;
  roomNumber?: string;
  participantName?: string[];
}

export const MANDATORY_KEYS = ['brandId'];
