export default {
  reviewCompleteHeader: 'Просмотр и завершение',
  participants: 'Участники',
  enterParticipantInfoText:
    'Пожалуйста, введите информацию для каждого участника этого опыта',
  покупатель: 'Покупатель',
  firstName: 'имя',
  lastName: 'фамилия',
  email: 'электронная почта',
  optionalEmail: 'электронная почта:(необязательно)',
  'phone:': 'телефон',
  participantFieldPlaceholder:
    'Добавить нового или выбрать существующего участника',
  reservationSummary: 'Резюме бронирования',
  editParticipantsDetails: 'Редактировать данные участника',
  participantDetails: 'Сведения об участнике',
  duplicatePurchaserText: 'То же, что и покупатель',
  phone: 'Телефон',
  addParticipant: 'Дополнительный {{tierLabel}}',
  participant: 'Участник',
  removeParticipant: 'Удалить',
  experienceLimitReached:
    'Извините, количество участников этого опыта достигло максимума',
  payment: 'Оплата',
  duration: 'Продолжительность',
  discountCode: 'Код скидки',
  removeCode: 'Удалить код',
  invalidCodeValidation: 'Недействительный код купона',
  networkErrorDiscountCode: 'Что-то пошло не так на нашей стороне',
  скидка: 'Скидка',
  promo: 'Промо',
  promoCode: 'Промо-код',
  применить: 'Применить',
  total: 'Итого',
  subTotal: 'Промежуточный итог',
  includes: 'Включает',
  и: 'и',
  addHostNotes: 'Добавить заметки для вашего хоста',
  policyAggreementText:
    'Я согласен с <0> Условиями предоставления услуг</0> и <1> Уведомлением о конфиденциальности</1>',
  brandAggreementText:
    'Я согласен или иным образом подтверждаю, что уполномочен от имени этого участника согласиться с условиями и положениями соглашения',
  termsAggreementValidation: 'Пожалуйста, согласитесь с условиями',
  firstNameValidation: 'Имя обязательно',
  lastNameValidation: 'Фамилия обязательна',
  emailValidation: {
    emailRequiredText: 'требуется электронная почта',
    invalidEmail: 'Неверный адрес электронной почты',
    not_specified: 'Неверный адрес электронной почты',
  },
  phoneValidation: {
    'Неверный номер телефона': 'Неверный номер телефона',
    not_specified: 'недействительный',
    phoneRequiredText: 'Номер телефона обязателен',
  },
  participantFirstNameValidation: 'Имя участника обязательно',
  participantLastNameValidation: 'Фамилия участника обязательна',
  complete: 'Complete',
  '404Message': 'Не найдено',
  ожидание: 'Ожидание...',
  complimentary: 'бесплатный',
  confirmationHeader: 'Ваш опыт забронирован!',
  confirmationText:
    'На {{email}} было отправлено письмо с подтверждением и дополнительными подробностями',
  viewAll: 'Просмотреть все впечатления',
  paymentMethod: 'Способ оплаты',
  creditCard: 'Кредитная карта',
  roomCharge: 'Плата за номер',
  memberCharge: 'Плата за членство',
  roomOrReservationNumber: 'Номер комнаты или номер подтверждения',
  roomNumber: 'Номер комнаты',
  memberNumber: 'Номер участника',
  kicc: 'Easy Pay',
  chargeValidation: 'Номер комнаты и фамилия обязательны',
  memberNumberValidation: 'Номер участника обязателен',
  invalidRoomNumber: 'Номер комнаты недействителен',
  invalidMemberNumber: 'Номер участника недействителен',
  invalidPhoneNumber: 'Номер телефона недействителен',
  guestNameIsRequired: 'Имя гостя обязательно',
  roomNumberIsRequired: 'Номер комнаты обязателен',
  roomOrReservationNumberIsRequired:
    'Требуется номер комнаты или номер подтверждения',
  cardNumberIncomplete: 'Номер карты не заполнен',
  lastNameValidationError: 'Фамилия обязательна',
  isReqd: 'требуется',
  reservationCode: 'Номер бронирования/подтверждения',
  nonHotelGuest: 'Негость отеля',
  onPropertyGuest: 'Гость на территории отеля',
  preArrivalGuest: 'Гость до прибытия',
  cardNumber: 'Номер карты',
  pmsValidationErrorMessage:
    'Неверная фамилия, номер комнаты или номер подтверждения',
  pmsDateValidationErrorMessage:
    'Извините! Дата опыта не совпадает с датой вашего прибытия и отъезда.',
  private: 'Частный',
  browseAllExperieces: 'Просмотреть все впечатления',
  payWithCreditCard: 'Оплатить кредитной картой',
  payWithRoomCharge: 'Оплатить счет за номер',
  payWithMemberCharge: 'Оплатить счет за членство',
  payWith: 'Оплатить счет с помощью',
  bookBulkErrorByCard:
    'Произошла ошибка при подтверждении вашего бронирования. Ваша карта не была списана.',
  bookBulkErrorByRoomCharge: 'Неверная фамилия и номер комнаты.',
  bookBulkErrorByMemberCharge: 'Неверная фамилия и номер участника.',
  reviewCancellation: 'Отмена просмотра',
  selectAReservationToCancel: 'Выберите бронирование для отмены',
  confirmCancellation: 'Подтвердить отмену',
  cancellable: 'Можно отменить',
  cancelled: 'Отменено',
  deadlinePassed: 'Срок истек',
  noCancellations: 'Отмены недоступны',
  youPaid: 'Вы заплатили',
  refund: 'Возврат',
  yourRefund: 'Ваш возврат',
  yourRefundWillBeProcessedToTheOriginalPayment: 'Ваш возврат будет обработан',
  thisReservationCanNoLongerBeCancelledPleaseEmail:
    'Это бронирование больше нельзя отменить. Пожалуйста, напишите нам по адресу {{brandEmail}}',
  reservationCancelled: 'Бронирование отменено',
  thisReservationCannotBeCancelled: 'Это бронирование нельзя отменить',
  thisReservationHasAlreadyBeenCancelled: 'Это бронирование уже отменено',
  theHotel: 'oтель',
  cancelling: 'Отмена',
  somethingWentWrong: 'Что-то пошло не так',
  previouslyRefunded: 'Предыдущий возврат',
  remainingBalance: 'Оставшийся баланс',
};
