import { useAppState, useBrandId, useDispatch } from 'AppProvider';
import { IFullExperienceFields } from 'types/experience.types';
import { IWaitlistState } from 'types/waitlist';
import { IExperienceSettingsResponse } from 'types/custom-questions';
import { get } from 'utils/axios';
import { cacheRequest } from 'utils/requestCache';
import {
  addExperienceToWaitlist,
  fetchWaitlistExperience,
  fetchWaitlistExperienceError,
} from 'actions/waitlist';

interface IExperienceResponse {
  data: IFullExperienceFields;
}

const fetchEndpoint = (endpoint: string) => {
  return cacheRequest(endpoint, () => get(endpoint));
};

const useFetchWaitlist = () => {
  const dispatch = useDispatch();
  const brandId = useBrandId();

  const { item, experience, isFetchingExperience }: IWaitlistState =
    useAppState((state: Record<string, unknown>) => state.waitlist);

  const fetchExperienceList = async () => {
    const experienceId = item?.experienceId;
    if (!experienceId) return;
    dispatch(fetchWaitlistExperience(undefined));

    const experienceDetail = fetchEndpoint(
      `v1/brands/${brandId}/experiences/${experienceId}`,
    );
    const experienceSettings = fetchEndpoint(
      `v1/brands/${brandId}/experiences/${experienceId}/settings`,
    );

    try {
      const data = await Promise.all([
        experienceDetail as unknown as IExperienceResponse,
        experienceSettings as unknown as IExperienceSettingsResponse,
      ]);

      const [list, settings] = data;

      dispatch(
        addExperienceToWaitlist({
          experience: {
            ...list.data,
            ...settings.data,
          },
        }),
      );
    } catch (error) {
      dispatch(fetchWaitlistExperienceError(undefined));
    }
  };

  if (item && !experience && !isFetchingExperience) {
    fetchExperienceList();
  }
};

export default useFetchWaitlist;
