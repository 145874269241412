import React from 'react';

const PinIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24 * scale}
      height={24 * scale}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12.0047 2C10.1477 2.00246 8.36746 2.73502 7.05436 4.03704C5.74127 5.33906 5.00248 7.10427 5 8.9456C5 12.5811 11.0576 20.9493 11.7421 21.8791C11.7745 21.9168 11.8148 21.9471 11.8601 21.968C11.9054 21.9889 11.9547 21.9998 12.0047 22C12.0534 22.0004 12.1015 21.9897 12.1454 21.9687C12.1893 21.9477 12.2278 21.9171 12.2579 21.8791C12.9424 20.9493 19 12.5811 19 8.9642C19.0025 7.12125 18.2672 5.35267 16.9556 4.0469C15.644 2.74112 13.8633 2.00493 12.0047 2ZM18.3624 8.9456C18.3624 12.0418 13.2706 19.3594 12.0047 21.1353C10.7388 19.3594 5.64702 12.0418 5.64702 8.9456C5.64702 7.27367 6.31684 5.67021 7.50914 4.48797C8.70143 3.30574 10.3185 2.64156 12.0047 2.64156C13.6908 2.64156 15.3079 3.30574 16.5002 4.48797C17.6925 5.67021 18.3624 7.27367 18.3624 8.9456Z"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
      <path
        d="M12 5.03003C11.2089 5.03003 10.4355 5.26463 9.77772 5.70415C9.11992 6.14368 8.60723 6.76839 8.30448 7.49929C8.00173 8.2302 7.92252 9.03447 8.07686 9.81039C8.2312 10.5863 8.61216 11.299 9.17157 11.8585C9.73098 12.4179 10.4437 12.7988 11.2196 12.9532C11.9956 13.1075 12.7998 13.0283 13.5307 12.7255C14.2616 12.4228 14.8864 11.9101 15.3259 11.2523C15.7654 10.5945 16 9.82115 16 9.03003C15.9975 7.96994 15.5752 6.95398 14.8256 6.20438C14.076 5.45478 13.0601 5.03254 12 5.03003ZM15.3714 9.03003C15.3714 9.69683 15.1737 10.3487 14.8032 10.9031C14.4328 11.4575 13.9062 11.8896 13.2902 12.1448C12.6741 12.4 11.9963 12.4668 11.3423 12.3367C10.6883 12.2066 10.0875 11.8855 9.61604 11.414C9.14454 10.9425 8.82344 10.3418 8.69335 9.68776C8.56327 9.03377 8.63003 8.35589 8.88521 7.73984C9.14038 7.12379 9.57251 6.59725 10.1269 6.22679C10.6814 5.85633 11.3332 5.6586 12 5.6586C12.8942 5.6586 13.7517 6.0138 14.384 6.64607C15.0162 7.27833 15.3714 8.13587 15.3714 9.03003Z"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
    </svg>
  );
};

export default PinIcon;
