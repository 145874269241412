import * as Sentry from '@sentry/react';
import { confirmKICCApproval } from 'actions/booking';
import { sleep } from 'utils';

interface KICCPollingArgs {
  brandId: string;
  subjectId: string;
}

interface ApprovalResponse {
  status: ApprovalStatus;
  errorMessage: string;
}

export const kiccApprovalPolling = ({
  brandId,
  subjectId,
}: KICCPollingArgs) => {
  return fetchApproval({ brandId, subjectId, requestCount: 0 });
};

export enum ApprovalStatus {
  CONFIRMED = 'confirmed',
  FAILED = 'failed',
  PROCESSING = 'processing',
}

const MAX_POLLING_DURATION = 30; // duration should take up to 30s.
const POLLING_INTERVAL = 5; // polling every 5s.
const POLLING_LIMIT = Math.floor(MAX_POLLING_DURATION / POLLING_INTERVAL); // after 30sec. we should have a result

const fetchApproval = async ({
  brandId,
  subjectId,
  requestCount,
}: KICCPollingArgs & { requestCount: number }): Promise<ApprovalResponse> => {
  const approvalResponse = await confirmKICCApproval({
    brandId,
    subjectId,
    subjectType: 'cart',
  });

  if (
    approvalResponse.status === ApprovalStatus.PROCESSING &&
    requestCount < POLLING_LIMIT
  ) {
    await sleep(POLLING_INTERVAL);
    return fetchApproval({
      brandId,
      subjectId,
      requestCount: requestCount + 1,
    });
  }

  if (requestCount === POLLING_LIMIT) {
    Sentry.captureMessage('KICC Booking - reached polling limit', {
      extra: {
        brandId,
        subjectId,
      },
    });
    return {
      status: ApprovalStatus.FAILED,
      errorMessage: 'Payment approval timed out',
    };
  }

  return {
    status: approvalResponse.status as ApprovalStatus,
    errorMessage: approvalResponse.error,
  };
};
