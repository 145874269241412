import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'AppProvider';
import { getExperiencePrice, getSessionPrice } from 'components/helpers';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';
import { STATUS_FAILURE, STATUS_IDLE, STATUS_PENDING } from 'types/app';
import * as SC from '../styles';

interface ISessionPriceProps {
  selectedSession: Record<string, unknown>;
}

const SessionPrice: React.FC<ISessionPriceProps> = ({ selectedSession }) => {
  const { t: translate } = useTranslation();
  const {
    fetchSessionStatus,
    experience,
    sessions,
    fetchFirstAvailabilityStatus,
  } = useAppState((state: Record<string, unknown>) => state.experience);
  const isFetchingSessionTiers =
    fetchSessionStatus === STATUS_IDLE || fetchSessionStatus === STATUS_PENDING;

  if (fetchFirstAvailabilityStatus === STATUS_FAILURE) {
    return null;
  }

  if (isFetchingSessionTiers || selectedSession === null) {
    return <SkeletonLine translucent style={{ height: '23px' }} />;
  }

  if (!isFetchingSessionTiers && sessions.length === 0) {
    return <span>{getExperiencePrice(experience, translate)}</span>;
  }

  return (
    <SC.SidebarPrice>
      <span>
        {getSessionPrice(selectedSession ?? sessions[0], experience, translate)}
      </span>
    </SC.SidebarPrice>
  );
};

export default SessionPrice;
