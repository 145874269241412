import { useMemo } from 'react';
import moment from 'moment';
import { Session } from 'types/listings';

export interface SessionTimeOption {
  label: string;
  value: string;
  original: Session;
}

export const formatSessionToSessionTimeOption = (
  session: Session,
): SessionTimeOption => {
  const [, sessionTime] = session.startDateTime.split('T');
  const startTimeMoment = moment(session.startDateTime);
  const startTime = startTimeMoment.format('h:mma');
  const endTime = startTimeMoment
    .add(session.duration, 'minutes')
    .format('h:mma');

  return {
    label: `${startTime} - ${endTime}`,
    value: `${sessionTime}-${session.duration}`,
    original: session,
  };
};

export const getSessionTimeOptions = (sessions: Session[]) => {
  let res = sessions.map(formatSessionToSessionTimeOption);

  res = res.filter(
    (s1, i, all) => all.findIndex((s2) => s2.value === s1.value) === i,
  );

  return res;
};

export const useSessionTimeOptions = (sessions: Session[]) => {
  return useMemo(() => getSessionTimeOptions(sessions), [sessions]);
};
