import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';
import useQueryParams from 'hooks/useQueryParams';
import { formatUTCDate } from 'utils';

type ParamsType = {
  collectionId?: string;
  groupId?: string;
  resourceId?: string;
};

const useResourceBookingSessionPropsFromUrl = () => {
  const matchCollection = useRouteMatch<ParamsType>(
    '/collection/:collectionId',
  );
  const matchGroup = useRouteMatch<ParamsType>(
    '/collection/:collectionId/group/:groupId',
  );
  const matchResource = useRouteMatch<ParamsType>(
    '/collection/:collectionId/group/:groupId/resource/:resourceId',
  );

  const emptyParams: ParamsType = {};

  const { collectionId, groupId, resourceId } =
    matchResource?.params ??
    matchGroup?.params ??
    matchCollection?.params ??
    emptyParams;

  const { date, time, duration, latest } = useQueryParams<{
    date?: string;
    time?: string;
    duration?: string;
    latest?: string;
  }>();

  return {
    collectionId: sanitizeIdParam(collectionId),
    groupId: sanitizeIdParam(groupId),
    resourceId: sanitizeIdParam(resourceId),
    date: sanitizeDateParam(date),
    time: sanitizeTimeParam(time),
    duration: sanitizeDurationParam(duration),
    latest: sanitizeLatestParam(latest),
  };
};

const sanitizeIdParam = (value?: string) => {
  return (
    decodeURIComponent(value ?? '')
      .replace(/"/g, '')
      .split('&')[0] || undefined
  );
};

const sanitizeDateParam = (value?: string) => {
  const date = formatUTCDate(value, '00:00:00');
  if (!value || date.toLowerCase() === 'invalid date') {
    return undefined;
  }
  return date.substring(0, 10);
};

const sanitizeTimeParam = (value?: string) => {
  const time = formatUTCDate('2022-12-31', value);
  if (!value || time.toLowerCase() === 'invalid date') {
    return undefined;
  }
  return time.substring(11, 19);
};

const sanitizeDurationParam = (value?: string) => {
  const duration = moment.duration(value);
  if (duration.toISOString() === 'P0D') {
    return undefined;
  }
  return duration.asMinutes();
};

const sanitizeLatestParam = (value?: string) => {
  if (value === 'true') {
    return true;
  }
  return false;
};

export default useResourceBookingSessionPropsFromUrl;
