import {
  PostSurveyEndpoint,
  PostAnswersEndpoint,
  AVAILABLE_BRAND_FEATURES,
} from '@kouto/types';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import { useEffect, useState } from 'react';
import { post } from 'utils/axios';
import { z } from 'zod';

type SurveyType = z.infer<typeof PostSurveyEndpoint.responseSchema>;
type SurveyRequest = z.infer<typeof PostSurveyEndpoint.requestSchema>;

export type AnswersRequest = z.infer<typeof PostAnswersEndpoint.requestSchema>;
type AnswersResponse = z.infer<typeof PostAnswersEndpoint.responseSchema>;

export const useGuestSurvey = (cartId: string | null) => {
  const [survey, setSurvey] = useState<SurveyType | null>(null);
  const [surveyLoaded, setSurveyLoaded] = useState(false);
  const brandHasSurveyFeature = useBrandToggleFeature(
    AVAILABLE_BRAND_FEATURES.GUEST_MICRO_SURVEY,
  );

  const fetchSurvey = async (cartId: string) => {
    try {
      if (!brandHasSurveyFeature) {
        return;
      }

      const { data: survey } = await post<{ data: SurveyType }, SurveyRequest>(
        PostSurveyEndpoint.url({}),
        { cartId },
      );

      if (!survey) throw new Error('Survey not found');

      setSurvey(survey);
    } finally {
      setSurveyLoaded(true);
    }
  };

  const postAnswers = async (surveyId: string, answers: AnswersRequest) => {
    await post<{ data: AnswersResponse }, AnswersRequest>(
      PostAnswersEndpoint.url({ surveyId }),
      answers,
    );
  };

  useEffect(() => {
    if (cartId && !surveyLoaded) {
      fetchSurvey(cartId);
    }
  }, [cartId, surveyLoaded]);

  return { survey, surveyLoaded, postAnswers };
};
