import * as R from 'ramda';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  CartActionTypes,
  CartAction,
  ICartState,
  ICartExperience,
  CART_LS_KEY,
  ICartStorageData,
} from 'types/cart';
import initialState from 'store/initial-state';
import { getLSItem, setLSItem, clearLSItem } from 'utils/localStorage';

const persistCartExperiences = (
  brandId: string,
  cartId: string,
  cartItems: Record<string, ICartExperience>,
) => {
  const LSData: ICartStorageData = getLSItem(CART_LS_KEY);
  setLSItem(CART_LS_KEY, {
    ...LSData,
    [brandId]: {
      cartId,
      cartItems,
    },
    updatedAt: new Date().toISOString(),
  });
};

export default function cartReducer(
  state: ICartState = initialState.cart,
  action: CartAction,
): ICartState {
  switch (action.type) {
    case CartActionTypes.SET_CART_ID: {
      persistCartExperiences(
        action.payload.brandId,
        action.payload.cartId,
        state.experiences,
      );

      return {
        ...state,
        cartId: action.payload.cartId,
      };
    }

    case CartActionTypes.ADD_EXPERIENCE_TO_CART: {
      const newState = {
        ...state,
        experiences: {
          ...state.experiences,
          [action.payload.cartItemId]: action.payload.cartItemMetadata,
        },
      };
      if (action.payload.persistMeta) {
        persistCartExperiences(
          action.payload.persistMeta.brandId,
          newState.cartId,
          newState.experiences,
        );
      }
      return newState;
    }
    case CartActionTypes.REMOVE_EXPERIENCE_FROM_CART: {
      const newState = {
        ...state,
        experiences: R.omit([action.payload.cartItemId], state.experiences),
      };

      if (action.payload.persistMeta) {
        persistCartExperiences(
          action.payload.persistMeta.brandId,
          newState.cartId,
          newState.experiences,
        );
      }

      return newState;
    }
    case CartActionTypes.SET_PARTICIPANTS_INFO: {
      const newState = {
        ...state,
        experiences: {
          ...state.experiences,
          [action.payload.cartItemId]: {
            ...state.experiences[action.payload.cartItemId],
            participants: action.payload.metadata,
          },
        },
      };

      if (action.payload.persistMeta) {
        persistCartExperiences(
          action.payload.persistMeta.brandId,
          newState.cartId,
          newState.experiences,
        );
      }

      return newState;
    }
    case CartActionTypes.SET_CHECKOUT_INFORMATION:
      return {
        ...state,
        purchaser: action.payload.purchaserData,
        paymentDetails: action.payload.paymentDetails,
      };

    case CartActionTypes.LOAD_PERSISTED_CART_ITEMS: {
      try {
        const { updatedAt } = getLSItem(CART_LS_KEY);
        const lastUpdatedFromNow = moment(new Date()).diff(
          moment(new Date(updatedAt)),
          'days',
        );

        const shouldCartClear = lastUpdatedFromNow >= 2;

        if (shouldCartClear || !updatedAt) {
          clearLSItem(CART_LS_KEY);
          return {
            ...initialState.cart,
            loading: false,
          };
        }
      } catch (e) {
        clearLSItem(CART_LS_KEY);
        return {
          ...initialState.cart,
          loading: true,
        };
      }

      return {
        ...state,
        cartId: action.payload.cartId,
        experiences: action.payload.cartItems,
        loading: false,
      };
    }

    case CartActionTypes.NO_NEED_TO_LOAD_PERSISTED_CART_ITEMS: {
      return {
        ...initialState.cart,
        loading: false,
      };
    }

    case CartActionTypes.EDIT_CART_ITEM: {
      const newState = {
        ...state,
        experiences: {
          ...state.experiences,
          [action.payload.cartItemId]: {
            ...state.experiences[action.payload.cartItemId],
            ...action.payload.cartItemMetadata,
          },
        },
      };

      if (action.payload.persistMeta) {
        persistCartExperiences(
          action.payload.persistMeta.brandId,
          newState.cartId,
          newState.experiences,
        );
      }

      return newState;
    }
    case CartActionTypes.RESET_CART: {
      const newState = initialState.cart;
      newState.cartId = uuidv4();

      if (action.payload.persistMeta) {
        persistCartExperiences(
          action.payload.persistMeta.brandId,
          newState.cartId,
          newState.experiences,
        );
      }

      return newState;
    }
    default:
      return state;
  }
}
