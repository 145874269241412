export default {
  bookExperiencePanel: 'Book experience panel',
  calendarIcon: 'Calendar icon',
  coverImageForResource: 'Cover image for {{title}} experiences',
  filterBy: 'Filter by {{filter}}',
  filterByDateRange: 'Filter by date range',
  gallery: 'Gallery',
  goBack: 'Back to previous page',
  hostUserIcon: 'host user icon',
  imageOf: 'Image of {{title}}',
  interactiveMapAltText: 'Interactive map with selectable {{title}}',
  mediaSlides: 'Media slides',
  muteVideo: 'Mute video',
  nextPhoto: 'Next photo',
  noVideoPlayback: 'No video playback capacity',
  pauseVideo: 'Pause video',
  photoOf: 'photo of {{name}}',
  playVideo: 'Play video',
  previousPhoto: 'Previous photo',
  reserveSelectedSlot: 'Reserve selected slot',
  selectDate: 'Select date',
  selectParticipants: 'Select participants',
  selectSessionTime: 'Select session time',
  unmuteVideo: 'Unmute video',
  viewShoppingCart: 'View shopping cart',
  zoomIn: 'Zoom in',
  zoomOut: 'Zoom out',
  selectData: `Select {{data}}`,
  reserveName: `Reserve {{name}}`,
  deleteReservation: 'Delete {{esperienceTitle}} reservation',
  editParticipantsFor: 'Edit participants for {{esperienceTitle}}',
  enterPromoCode: 'Enter promo code',
  applyPromoCode: 'Apply promo code',
  increaseAddOnAmount: 'Increase {{addOn}} selected',
  decreaseAddOnAmount: 'Decrease {{addOn}} selected',
  addOnModal: '{{addOn}} modal',
  moreInformationAboutAddOn: 'More information about {{addOn}}',
  decreasePriceTierTickets: 'Decrease {{priceTier}} tickets',
  increasePriceTierTickets: 'Increase {{priceTier}} tickets',
  downloadFilename: 'Download {{filename}}',
  viewFullDetailsDescription: 'View full details description',
  viewFullDescription: 'View full description',
  closeModal: 'Close modal',
};
