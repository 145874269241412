import * as types from 'types/access-code';
import * as appTypes from 'types/app';
import initialState from 'store/initial-state';

export default function accessCodeReducer(
  state = initialState.accessCode,
  action,
) {
  switch (action.type) {
    case types.VALIDATE_ACCESS_CODE_REQUEST:
      return {
        ...state,
        error: '',
        responseStatus: null,
        validateAccessCodeStatus: appTypes.STATUS_PENDING,
      };
    case types.VALIDATE_ACCESS_CODE_FAILURE:
      return {
        ...state,
        accessCode: null,
        validateAccessCodeStatus: appTypes.STATUS_FAILURE,
        responseStatus: action.payload.responseStatus,
        validationMessages: action.payload.validationMessages,
        error: action.error,
      };
    case types.VALIDATE_ACCESS_CODE_SUCCESS:
      return {
        ...state,
        accessCode: action.payload.accessCode,
        validateAccessCodeStatus: appTypes.STATUS_SUCCESS,
      };
    default:
      return state;
  }
}
