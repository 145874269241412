import React from 'react';

const ClockIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24 * scale}
      height={24 * scale}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 3C7.03748 3 3 7.03748 3 12C3 16.9625 7.03748 21 12 21C16.9625 21 21 16.9625 21 12C21 7.03748 16.9625 3 12 3ZM12 20.2174C7.4687 20.2174 3.78261 16.5313 3.78261 12C3.78261 7.4687 7.4687 3.78261 12 3.78261C16.5313 3.78261 20.2174 7.4687 20.2174 12C20.2174 16.5313 16.5313 20.2174 12 20.2174Z"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
      <path
        d="M11.6087 11.6086H8.08691V12.3912H12.3913V5.73901H11.6087V11.6086Z"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
    </svg>
  );
};

export default ClockIcon;
