import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { MainTitle } from 'components/theme/Typography';
import FeaturedExperienceItem from 'components/FeaturedExperienceItem';
import { Wrapper } from 'components/theme/Wrapper';
import { SkeletonLine } from 'components/theme/Skeleton/Skeleton';
import { useFeaturedListings } from 'hooks/use-featured-listings';

const FeaturedTitle = styled(MainTitle)`
  font-size: 36px;
  font-family: ${({ theme }) => theme.font.primaryFont};
  font-weight: normal;
  text-align: center;
  margin-bottom: 48px;
  display: block;
  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

const FeaturedExperiences = () => {
  const { t: translate } = useTranslation();

  const { listings: featuredListings, isLoading } = useFeaturedListings();

  if (!isLoading && !featuredListings?.length) {
    return null;
  }

  const renderItems = () =>
    featuredListings.map((experience, index) => (
      <FeaturedExperienceItem
        key={`featured-experience-item-${experience.id}`}
        experience={experience}
        isEven={index % 2 === 0}
      />
    ));

  return (
    <section className="featured-experiences">
      <FeaturedTitle as="h1" id="featured-title">
        {!isLoading &&
          featuredListings.length > 0 &&
          translate('featuredExperiences')}
        {isLoading && (
          <SkeletonLine translucent style={{ width: 500, margin: '0 auto' }} />
        )}
      </FeaturedTitle>
      <Wrapper>{renderItems()}</Wrapper>
    </section>
  );
};

export default FeaturedExperiences;
