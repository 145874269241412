import { useAppState } from 'AppProvider';
import { useTierParticipants } from 'hooks/use-selected-participants';

const useSelectedParticipants = () => {
  const { selectedParticipants, selectedSession, currentExperience } =
    useAppState((state) => state.selectedParticipants) || {};

  const [{ selectedParticipants: selectedTierCustomers, groupTitle }] =
    useTierParticipants();

  const totalSelected = selectedParticipants
    ?.map((priceCategory) => priceCategory.selectedNumber)
    ?.reduce((current, previous) => current + previous, 0);
  const filteredSelectedCustomers = selectedTierCustomers
    ? selectedTierCustomers.filter((customer) => customer.selectedNumber > 0)
    : [];

  return {
    selectedParticipants,
    totalSelected,
    selectedSession,
    currentExperience,
    filteredSelectedCustomers,
    selectedTierCustomers,
    groupTitle,
  };
};

export default useSelectedParticipants;
