import { AnalyticsDataObserver, analyticsManager } from 'features/analytics';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useAnalyticsReadyForCartData = () => {
  const [ready, setReady] = useState(false);

  const analyticsObserver: AnalyticsDataObserver = (appData, newPayload) => {
    if ('cartExperiencesSubtotalByPriceTier' in newPayload) {
      setReady(true);
    }
  };

  const location = useLocation();

  useEffect(() => {
    setReady(false);
  }, [location]);

  useEffect(() => {
    const removeObserver = analyticsManager.addDataObserver(analyticsObserver);

    return () => {
      removeObserver();
    };
  }, []);

  return ready;
};
