export function truncateString(
  str: string | null | undefined,
  maxLength: number,
): string {
  if (!str) {
    return '';
  }
  if (str.length > maxLength) {
    return `${str.slice(0, maxLength - 3)}...`;
  }
  return str;
}
