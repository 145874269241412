export default {
  bookExperiencePanel: '체험 예약 패널',
  calendarIcon: '캘린더 아이콘',
  coverImageForResource: '{{title}} 체험의 표지 이미지',
  filterBy: '{{filter}}로 필터링',
  filterByDateRange: '날짜 범위로 필터링',
  gallery: '갤러리',
  goBack: '이전 페이지로 돌아가기',
  hostUserIcon: '호스트 사용자 아이콘',
  imageOf: '{{title}}의 이미지',
  interactiveMapAltText: '선택 가능한 {{title}}의 인터랙티브 맵',
  mediaSlides: '미디어 슬라이드',
  muteVideo: '비디오 음소거',
  nextPhoto: '다음 사진',
  noVideoPlayback: '비디오 재생 기능 없음',
  pauseVideo: '비디오 일시 정지',
  photoOf: '{{name}}의 사진',
  playVideo: '비디오 재생',
  previousPhoto: '이전 사진',
  reserveSelectedSlot: '선택한 슬롯 예약',
  selectDate: '날짜 선택',
  selectParticipants: '참가자 선택',
  selectSessionTime: '세션 시간 선택',
  unmuteVideo: '비디오 음소거 해제',
  viewShoppingCart: '장바구니 보기',
  zoomIn: '확대',
  zoomOut: '축소',
  selectData: '{{data}} 선택',
  reserveName: '{{name}} 예약',
  deleteReservation: '{{esperienceTitle}} 예약 삭제',
  editParticipantsFor: '참가자 편집 {{esperienceTitle}}',
  enterPromoCode: '프로모션 코드 입력',
  applyPromoCode: '프로모션 코드 적용',
  increaseAddOnAmount: '선택한 {{addOn}} 늘리기',
  decreaseAddOnAmount: '선택한 {{addOn}} 줄이기',
  addOnModal: '{{addOn}} 모달',
  moreInformationAboutAddOn: '{{addOn}}에 대한 자세한 정보',
  decreasePriceTierTickets: '{{priceTier}} 티켓 감소',
  increasePriceTierTickets: '{{priceTier}} 티켓 증가',
  downloadFilename: '{{filename}} 다운로드',
  viewFullDetailsDescription: '전체 세부 사항 설명 보기',
  viewFullDescription: '전체 설명 보기',
  closeModal: '모달 닫기',
};
