import React from 'react';
import styled from 'styled-components';

import { StyledInput } from 'components/theme/Form/Form';
import { DimmedText, Flex } from 'components/common/styled/common-styled';

export const BookSticky = styled.div`
  position: sticky;
  top: 150px;
  height: fit-content;
`;

export const BookSidebar = styled.div`
  border: 0.5px solid var(--way-colors-borderColor);
  border-radius: 0;
  box-shadow: var(--way-design-boxShadow-s);
  background: var(--way-palette-white-10);
  margin-bottom: 60px;
  h5 {
    margin-bottom: 8px;
  }
`;

export const ExperienceImage = styled.img`
  width: 54px;
  height: 54px;
  object-fit: cover;
`;

export const DiscountInput = styled(StyledInput)<
  React.HTMLProps<HTMLInputElement>
>`
  border: 0.5px solid var(--way-colors-borderColor);
  font-size: 14px !important;
  box-shadow: none;
  height: 35px;
  width: 60%;
  flex: 1;
`;

export const DiscountInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const CalculationRow = styled(Flex)<{
  fontSize?: number;
  isOmitted?: boolean;
}>`
  line-height: 22px;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
  margin-top: ${({ isOmitted }) => (isOmitted ? '12px' : '0')};
`;

export const DiscountSection = styled.div`
  font-size: 14px;
  padding: 20px 0;
  border-bottom: 0.5px solid var(--way-colors-borderColor);
`;

export const CartSummaryItemTitle = styled.p`
  margin: 0;
  font-size: 16px;
`;

export const CartSummaryItemSection = styled(Flex)`
  padding: 18px 0;
`;

export const SessionInfoText = styled(DimmedText)`
  font-size: 16px;
`;

export const CartSummaryTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding: 20px;
  border-bottom: 0.5px solid var(--way-colors-borderColor);
`;

export const CartItemsContainer = styled.div`
  padding: 20px;
  max-height: 500px;
  border-bottom: 0.5px solid var(--way-colors-borderColor);
  overflow-y: auto;
`;

export const CartFooter = styled.div`
  padding: 0 20px;
`;

export const PriceSectionContainer = styled.div`
  padding: 20px 0;
`;

export const PromoAmount = styled.span`
  margin-left: auto;
  margin-right: 8px;
`;

export const CartError = styled.div`
  padding: 10px 0;
  font-weight: bold;
`;

export const ApplyDiscountButton = styled.button`
  font-size: 15px;
  line-height: 20px;
  padding: 1px 6px;
  border-radius: var(--way-design-borderRadiusDefault);
  font-family: ${({ theme }) => theme.font.secondaryFont};
  min-height: 1px;
  background: var(--way-colors-buttonColorShades-80);
  color: var(--way-colors-buttonColorContrastShades-80);
  cursor: pointer;

  &:hover {
    background: var(--way-colors-buttonColorShades-100);
    color: var(--way-colors-buttonColorContrastShades-100);
  }

  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  border: none;
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
