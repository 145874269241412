import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { useIsMobile } from 'WindowDimensionProvider';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import { PriceTierWithParticipants } from 'components/Booking/PriceTier/types';
import PriceTiersSummary from 'components/Resource/PriceTiersSummary';
import { getListingCoverUrl } from 'utils/listings';
import CancellationPolicySummary from 'features/ShoppingCart/components/CancellationPolicySummary';

interface Props {
  priceTiers: PriceTierWithParticipants[];
}

const TicketSummary: FC<Props> = ({ priceTiers }) => {
  const isMobile = useIsMobile();
  const { isLoading, event, cancellationPolicy } = useEventBookingSession();

  const formatterPriceTiers = useMemo(() => {
    return priceTiers.map((tier) => {
      return {
        ...tier,
        selectedNumber: tier.participants,
      };
    });
  }, [priceTiers]);

  const coverImageUrl = useMemo(
    () => (event ? getListingCoverUrl(event, isMobile) : ''),
    [event, isMobile],
  );

  const taxes = useMemo(() => {
    return (
      event?.taxes ||
      (event?.resourceGroups && event.resourceGroups[0]?.taxes) ||
      []
    );
  }, [event]);

  if (isLoading || !event) {
    return (
      <Wrapper>
        <SkeletonLineSlim />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <PriceTiersSummary
        priceTiers={formatterPriceTiers}
        title={event.title}
        taxes={taxes}
        coverImageUrl={coverImageUrl}
        applyFlatFeeToEachTier={false}
      />
      <CancellationPolicySummary cancellationPolicy={cancellationPolicy} />
    </Wrapper>
  );
};

export default TicketSummary;

const Wrapper = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 768px) {
    display: none;
  }
`;
