import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from 'utils';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import * as ModalSC from 'components/Modal/Modal.styles';
import { Listing } from 'types/listings';

type Props = {
  addOn: Listing['addOns'][number];
  onClose: () => void;
};

const getPictureUrl = (picture: Listing['addOns'][number]['picture']) => {
  if (!picture) {
    return '';
  }

  return (
    picture.uri['360w'] ||
    picture.uri['720w'] ||
    picture.uri['1080w'] ||
    picture.uri.original
  );
};

const AddOnDetailsModal: FC<Props> = ({ addOn, onClose }) => {
  const currency = useBrandCurrency();
  const { t: translate } = useTranslation();

  const priceLabel = useMemo(
    () =>
      addOn.price
        ? currencyFormat(currency)(addOn.price)
        : translate('complimentary'),
    [currency, addOn.price, translate],
  );

  return (
    <ModalSC.Overlay
      className="modal-overlay"
      onClick={(e) => {
        const element = e.target as HTMLDivElement;
        if (element?.classList.contains('modal-overlay')) {
          onClose();
        }
      }}
    >
      <ModalWrapper className="modal-content">
        <CloseButtonWrapper className="add-on-modal-close" onClick={onClose}>
          <ModalSC.Close />
        </CloseButtonWrapper>
        {addOn.picture && (
          <Image
            className="add-on-modal-image"
            src={getPictureUrl(addOn.picture)}
            alt={addOn.label}
          />
        )}
        <Details>
          <Title>{addOn.label}</Title>
          {addOn.description && (
            <Description className="add-on-modal-description">
              {addOn.description}
            </Description>
          )}
          <Price className="add-on-card-price">{priceLabel}</Price>
        </Details>
      </ModalWrapper>
    </ModalSC.Overlay>
  );
};

const ModalWrapper = styled.div`
  position: relative;
  background: var(--way-palette-white-100);
  color: var(--way-palette-black-100);
  box-shadow: var(--way-design-boxShadow-l);
  margin: 0px;
  border-radius: 0px;
  padding: 24px;
  width: 600px;
  max-width: 95vw;
  max-height: 85vh;
  gap: 24px;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  z-index: 1;
  scale: 1.4;
  transform-origin: 100% 0px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Image = styled.img`
  height: 150px;
  aspect-ratio: 1;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 250px;
  }
`;

const Details = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
`;

const Title = styled.h2`
  margin: 0px;
  max-width: calc(100% - 20px);
`;

/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
const Description = styled.p`
  margin: 0px;
  margin-top: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.014px;
  color: var(--way-palette-black-60);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: var(--maxLines, 20);
  -webkit-box-orient: vertical;
`;
/* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */

const Price = styled.span`
  margin-top: 16px;
  height: 17px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.014px;
`;

export default AddOnDetailsModal;
