import React, { FC } from 'react';
import styled from 'styled-components';
import { ProductLine } from '@kouto/types';
import { useIsMobile, useIsTablet } from 'WindowDimensionProvider';
import ListingCarousel from '../ListingCarousel/ListingCarousel';
import { CarouselType } from '../ListingCarousel/carouselUtils';
import { LandingPageHeroProps } from './types';

type HeroWithTitleProps = LandingPageHeroProps & {
  carouselType: CarouselType;
  product: ProductLine;
};

const HeroWithTitle: FC<HeroWithTitleProps> = ({
  title,
  listings,
  isLoading,
  carouselType,
  product,
}) => {
  const isMobile = useIsMobile();
  const itTablet = useIsTablet();
  const direction =
    isMobile || itTablet || carouselType === CarouselType.WIDE_HORIZONTAL
      ? 'vertical'
      : 'horizontal';

  return (
    <Wrapper
      className="landing-page-hero-container"
      direction={direction}
      carouselType={carouselType}
    >
      <HeroTitle
        className={`landing-page-hero-title landing-page-${product}-hero-title`}
        direction={direction}
      >
        {title}
      </HeroTitle>
      <ListingCarousel
        listings={listings}
        isLoading={isLoading}
        type={carouselType}
      />
    </Wrapper>
  );
};

export default HeroWithTitle;

const HeroTitle = styled.h1<{
  direction: 'horizontal' | 'vertical';
}>`
  /* skip css namespace */
  ${({ direction }) =>
    direction === 'vertical'
      ? `
        text-align: center !important;
        margin-top: 0px;
        margin-bottom: 50px;
      `
      : `
        text-align: left;
      `}
`;

const Wrapper = styled.div<{
  direction: 'horizontal' | 'vertical';
  carouselType: CarouselType;
}>`
  /* skip css namespace */
  height: 840px;
  margin-bottom: 120px;
  display: flex;
  align-items: center;
  margin-top: 24px;

  @media (max-width: 768px) {
    width: 100%;
    height: ${({ carouselType }) =>
      carouselType === CarouselType.WIDE_HORIZONTAL ? 540 : 640}px;
    margin-bottom: 60px;
  }

  ${({ direction, carouselType }) =>
    direction === 'vertical'
      ? `
        flex-direction: column;
        & > * {
          width: 100%;
        }
      `
      : `
        flex-direction: row;
        ${
          carouselType === CarouselType.NARROW_HORIZONTAL
            ? `
            justify-content: left;
            & > * {
              width: 50%;
            }
          `
            : `
            justify-content: center;
            & > *:first-child {
              width: 35%;
            }
            & > *:last-child {
              width: 65%;
            }
          `
        }
      `}
`;
