import * as Sentry from '@sentry/react';
import { useAppState, useBrandId } from 'AppProvider';
import { useEffect } from 'react';

export const useSentryContext = () => {
  const brandId = useBrandId();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const brandSettings = useAppState((s: any) => s.brand.settings);

  useEffect(() => {
    if (!brandId && !brandSettings) return;
    Sentry.setContext('brand', {
      id: brandId,
      name: brandSettings?.name,
    });
  }, [brandId, brandSettings]);
};
