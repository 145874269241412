import { useAppState } from 'AppProvider';
import { type ICartExperience } from 'types/cart';
import type { DiscountCode } from 'types/discount-code';
import { ITax } from 'types/experience.types';
import { Listing } from 'types/listings';
import { computeCartTotalDetails } from '@kouto/cart-prices';

export type ITaxWithAmount = ITax & { taxAmount: number };

export default function useTotalCartAmount(
  cartItems: Record<string, ICartExperience>,
  listings: Record<string, Listing>,
) {
  const { discountCode }: DiscountCode = useAppState(
    (state: Record<string, unknown>) => state.discountCode,
  );

  const cartDetails = computeCartTotalDetails(cartItems, listings, {
    discountCode,
  });

  return cartDetails;
}
