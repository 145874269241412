import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  MouseEvent,
} from 'react';
import styled from 'styled-components';

interface SlidesIndicatorProps {
  slidesCount: number;
  selectedSlide: number;
  selectOneSlide: (index: number) => void;
}

const SlidesIndicator: FC<SlidesIndicatorProps> = ({
  slidesCount,
  selectedSlide,
  selectOneSlide,
}) => {
  const [width, setWidth] = useState(0);
  const [left, setLeft] = useState(0);
  const containerRef = useRef(null);

  const onResize = useCallback(() => {
    if (containerRef.current) {
      const rect = (containerRef.current as Element).getBoundingClientRect();
      setWidth(Math.round(rect.width));
      setLeft(Math.round(rect.x));
    }
  }, [containerRef]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  const handleOnClick = useCallback(
    (e: MouseEvent) => {
      const xCoord = e.clientX - left;
      const slideWidth = width / slidesCount;
      const selectedSlide = Math.floor(xCoord / slideWidth);
      selectOneSlide(selectedSlide);
    },
    [width, left, slidesCount, selectOneSlide],
  );

  const preventDefault = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <Wrapper
      ref={containerRef}
      className="listing-carousel-indicator"
      onClick={handleOnClick}
    >
      <GrayLine className="listing-carousel-indicator-gray-line" />
      <Indicator
        className="listing-carousel-indicator-cursor"
        slidesCount={slidesCount}
        selectedSlide={selectedSlide}
        onClick={preventDefault}
      >
        <BlackLine className="listing-carousel-indicator-black-line" />
      </Indicator>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 10px;
  cursor: pointer;
  position: relative;
`;

const GrayLine = styled.div`
  height: 1px;
  width: 100%;
  top: 4px;
  position: absolute;
  background-color: var(--way-colors-coherentColorShades-20);
`;

const BlackLine = styled.div`
  height: 1px;
  width: 100%;
  top: 4px;
  position: absolute;
  background-color: var(--way-colors-contrastColorShades-20);
`;

const Indicator = styled.div<{
  slidesCount: number;
  selectedSlide: number;
}>`
  height: 100%;
  top: 0px;
  position: absolute;
  cursor: auto;
  width: ${({ slidesCount }) => `${100 / slidesCount}%`};
  left: ${({ slidesCount, selectedSlide }) =>
    `calc(${(100 / slidesCount) * selectedSlide}%)`};
  transition: left 0.4s ease;
`;

export default SlidesIndicator;
