import { z } from 'zod';
import useSWR from 'swr';
import { get } from 'utils/axios';
import { PublicGetListingSessionsEndpoint, ListingVersion } from '@kouto/types';
import { Session } from 'types/listings';

export interface PublicSessionsQueryParams {
  from?: string;
  to?: string;
  eventId?: string;
  latest?: boolean;
  waitlistEntryId?: string;
}

const useEventSessions = ({
  from,
  to,
  eventId,
  latest,
  waitlistEntryId,
}: PublicSessionsQueryParams) => {
  let sessionsApiUrl: string | null = null;

  if (from && to && eventId) {
    const queryParam: z.infer<
      typeof PublicGetListingSessionsEndpoint.querySchema
    > = {
      from,
      to,
      version: latest ? ListingVersion.LATEST : ListingVersion.PUBLISHED,
      ...(waitlistEntryId && { waitlistEntryId }),
    };
    sessionsApiUrl = PublicGetListingSessionsEndpoint.url({
      listingId: eventId,
      query: queryParam,
    });
  }

  const { data: sessionsData, isLoading } = useSWR(
    sessionsApiUrl,
    get<{ data: { sessions: Session[] } }>,
  );

  const sessions = sessionsData?.data.sessions || [];
  sessions.sort((s1, s2) => (s1.startDateTime > s2.startDateTime ? 1 : -1));

  return {
    sessions,
    isLoading,
  };
};

export default useEventSessions;
