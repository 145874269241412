import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { SkeletonPulseAbsolute } from 'components/theme/Skeleton';
import ResponsiveImage from 'components/common/ResponsiveImage';

import Next from './next.svg';
import Prev from './prev.svg';

const GalleryImageContent = styled.img`
  display: ${({ loading }) => (loading ? 'none' : 'block')};
  height: 100vh;
  max-width: 100%;
  max-height: 450px;
  margin: 0 auto;
  object-fit: cover;
  object-position: center;
  border-radius: var(--way-design-borderRadiusDefault);
`;

const ModalGallery = ({ experience, closeModal }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [allPictures, setAllPictures] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLoaded = () => {
    setLoading(false);
  };

  const closeicon = () => <Close onClick={closeModal} />;

  useEffect(() => {
    setLoading(true);
    const { pictures, coverPicture } = experience;
    setAllPictures([coverPicture, ...pictures]);
  }, [experience]);

  const onPrevClick = () => {
    if (currentImageIndex === 0) {
      setCurrentImageIndex(allPictures.length - 1);
      return;
    }
    setCurrentImageIndex(currentImageIndex - 1);
  };

  const onNextClick = () => {
    if (currentImageIndex === allPictures.length - 1) {
      setCurrentImageIndex(0);
      return;
    }
    setCurrentImageIndex(currentImageIndex + 1);
  };

  return (
    <Gallery>
      <GalleryImage>
        <IconLeft onClick={onPrevClick}>
          <Prev />
        </IconLeft>
        <GalleryImageWrap>
          {loading && (
            <SkeletonPulseAbsolute
              style={{ width: '100%', height: '450px' }}
              translucent
            />
          )}
          {allPictures && (
            <ResponsiveImage
              uriObject={get(allPictures[currentImageIndex], 'uri')}
              defaultSize="480w"
              viewType="large"
              onLoad={handleLoaded}
              CustomRenderer={GalleryImageContent}
              alt={experience.title}
            />
          )}
        </GalleryImageWrap>
        {closeicon()}
        <GalleryMeta>
          {currentImageIndex + 1}/{allPictures?.length}
        </GalleryMeta>
        <IconRight onClick={onNextClick}>
          <Next />
        </IconRight>
      </GalleryImage>
    </Gallery>
  );
};

ModalGallery.propTypes = {
  experience: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalGallery;

const Gallery = styled.div``;

// card Image

const GalleryImage = styled.div`
  border-radius: var(--way-design-borderRadiusDefault);
  position: relative;
  max-width: 100%;
`;

const GalleryImageWrap = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 100%;
  max-height: 450px;
`;

const Icon = styled.div`
  position: fixed;
  width: 16px;
  top: 42%;
`;

const IconRight = styled(Icon)`
  right: 20px;
  cursor: pointer;
  @media (min-width: 768px) {
    width: 50px;
  }
`;
const IconLeft = styled(Icon)`
  left: 45px;
  cursor: pointer;
  @media (min-width: 768px) {
    width: 50px;
  }
`;

const GalleryMeta = styled.div`
  color: var(--way-palette-black-100);
  margin-top: 24px;
`;

const Close = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0.5px solid var(--way-palette-black-100);
  position: absolute;
  right: 10%;
  top: 15px;
  background: var(--way-palette-white-100);
  box-shadow: var(--way-design-boxShadow-s);
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 1px;
    left: 50%;
    top: 50%;
    background: var(--way-palette-black-100);
    border: none;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
    transform-origin: center center;
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
    transform-origin: center center;
  }
  @media (max-width: 1400px) {
    right: 6%;
  }
  @media (max-width: 1200px) {
    right: 15px;
  }
`;
