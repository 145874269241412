export default {
  unavailable: 'Non disponibile',
  person: 'persona',
  people: 'persone',
  reserveNow: 'Prenota ora',
  selectTime: "Seleziona l'orario",
  select: 'Seleziona',
  reserve: 'Prenotare',
  time: 'Orario',
  tier: 'Livello',
  questions: 'Domande',
  addToCart: 'Aggiungi al carrello',
  checkout: 'Cassa',
  total: 'Totale',
  availability: 'Disponibilità',
  whatsIncluded: 'Cosa è incluso',
  showMore: 'Mostra di più',
  showLess: 'Mostra meno',
  session: 'Sessione',
  viewDetails: 'Visualizza dettagli',
  findAvailability: 'Trova disponibilità',
  loading: 'Caricamento',
  capacity: 'Capacità',
  noAvailableSessionsOnThisDate: 'Nessuna sessione disponibile in questa data',
  noGroupAvailableOnThisDateAndTime:
    'Nessun {{groupTitle}} disponibile in questa data e orario',
  noCollectionFound: 'Nessuna collezione trovata!',
  noResourceGroupFound: 'Nessun gruppo di risorse trovato!',
  noData: 'Nessun dato!',
  continue: 'Continua',
};
