import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IWaitlistState } from 'types/waitlist';
import { Error } from 'components/theme/Form/Form';
import { useAppState } from 'AppProvider';
import { Flex } from 'components/common/styled/common-styled';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';
import useOneListing from 'hooks/useOneListing';
import { computeOneCartItemTotalDetails } from '@kouto/cart-prices';
import CartSummaryItem from '../CartSummary/CartSummaryItem';
import PriceSection from '../CartSummary/PriceSection';

import {
  BookSidebar,
  BookSticky,
  CartSummaryTitle,
  CartItemsContainer,
  CartFooter,
} from '../CartSummary/styles';

const WaitlistCartSummary = () => {
  const { t } = useTranslation();
  const {
    item: waitlistCartItem,
    isFetchingExperience,
    waitlistError,
  }: IWaitlistState = useAppState(
    (state: Record<string, unknown>) => state.waitlist,
  );

  const { listing, isLoading: isLoadingListing } = useOneListing(
    waitlistCartItem?.listingId,
  );
  const cartItemTotalDetails = useMemo(
    () =>
      !!listing &&
      !!waitlistCartItem &&
      computeOneCartItemTotalDetails(waitlistCartItem, listing),
    [waitlistCartItem, listing?.id],
  );

  if (!waitlistCartItem) {
    return (
      <BookSticky>
        <BookSidebar>
          <div>The cart is empty</div>
        </BookSidebar>
      </BookSticky>
    );
  }

  if (isFetchingExperience || isLoadingListing) {
    return (
      <BookSticky>
        <SkeletonLine style={{ height: 300 }} translucent />
      </BookSticky>
    );
  }

  if (!listing || !cartItemTotalDetails) {
    return (
      <BookSticky>
        <BookSidebar>
          <Error>There was an error while fetching the experience.</Error>
        </BookSidebar>
      </BookSticky>
    );
  }

  return (
    <BookSticky>
      <BookSidebar>
        <CartSummaryTitle>
          <Flex direction="row">
            <span>{t('reservationSummary')}</span> <span>(1)</span>
          </Flex>
          {waitlistError && (
            <div style={{ paddingTop: '10px' }}>
              <Error>
                There seem to be an error while fetching the waitlist.
              </Error>
            </div>
          )}
        </CartSummaryTitle>
        <CartItemsContainer>
          {!!cartItemTotalDetails && (
            <CartSummaryItem
              cartItem={waitlistCartItem}
              listing={listing}
              cartItemTotalDetails={cartItemTotalDetails}
              canDelete={false}
              canEditParticipants={false}
            />
          )}
        </CartItemsContainer>

        <CartFooter>
          <PriceSection
            cartTotal={cartItemTotalDetails.total}
            cartSubTotal={cartItemTotalDetails.subTotal}
            areAllListingsPricesHidden={listing.hidePrice}
            includedTaxes={cartItemTotalDetails.includedTaxes}
            nonIncludedTaxes={cartItemTotalDetails.nonIncludedTaxes}
          />
        </CartFooter>
      </BookSidebar>
    </BookSticky>
  );
};

export default WaitlistCartSummary;
