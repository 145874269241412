import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';
import { analyticsManager, IAnalyticsDataPayload } from 'features/analytics';
import { useEffect, useRef } from 'react';
import { usePageAnalyticsTracker } from './useGeneralAnalyticsTracker';

interface IProps {
  eventName: ANALYTICS_EVENT;
  payload?: IAnalyticsDataPayload;
  optionalPayload?: IAnalyticsDataPayload;
  key?: string;
  isNotReady?: boolean;
}
export const usePageViewEvent = ({
  eventName,
  payload,
  optionalPayload,
  isNotReady = false,
  key = 'key',
}: IProps) => {
  const hasSent = useRef<string>('');

  usePageAnalyticsTracker();

  function sendEvent() {
    if (hasSent.current === key || isNotReady) {
      return;
    }
    if (
      !payload ||
      (Object.keys(payload) as ANALYTICS_PROPERTY[]).every(
        (key) => !!payload[key],
      )
    ) {
      analyticsManager.sendEvent(eventName, {
        ...optionalPayload,
        ...payload,
        [ANALYTICS_PROPERTY.Url]: window.location.href,
      });
      hasSent.current = key;
    }
  }

  useEffect(() => {
    sendEvent();
  }, [payload, hasSent, key, isNotReady, optionalPayload, eventName]);

  return sendEvent;
};
