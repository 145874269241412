import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { DATE_FORMAT } from 'utils';
import { useTranslation } from 'react-i18next';
import { IPriceTier } from 'types/experience.types';
import { useResourceMapContext } from 'features/Reserve/contexts/ResourceMapContext';
import useResourceBookingSession from '../../hooks/useResourceBookingSession';
import useAvailableDates from '../../hooks/useAvailableDates';
import ResourceGroupLayout from '../../components/ResourceGroupLayout';
import { useSessionTimeOptions } from '../../hooks/useSessionTimeOptions';
import { ResourceGroupPageSkeleton } from '../Skeleton';

type PriceTier = IPriceTier & {
  id: string;
};

export const MappedResourceGroupPage: React.FC = () => {
  const {
    collectionId,
    groupId,
    sessions,
    collection,
    resourceGroup,
    date,
    time,
    duration,
    latest,
    setResourceBookingSessionParams,
  } = useResourceBookingSession();
  const { t: translate } = useTranslation();
  const allSessionTimes = useSessionTimeOptions(sessions);
  const { focusResources, selectResource, selectedResource, unselectResource } =
    useResourceMapContext();
  const [dateForCalendarAvailability, setDateForCalendarAvailability] =
    useState<moment.Moment>(date ? moment(date) : moment());

  useEffect(() => {
    focusResources([]);
  }, []);

  useEffect(() => {
    if (
      selectedResource &&
      date &&
      time &&
      duration &&
      !sessions.find(
        (session) =>
          session.experienceId === selectedResource &&
          session.startDateTime === `${date}T${time}` &&
          session.duration === duration,
      )
    ) {
      unselectResource();
    }
  }, [sessions, date, time, duration, unselectResource, selectedResource]);

  useEffect(() => {
    if (date) {
      setDateForCalendarAvailability(moment(date));
    }
  }, [date]);

  const { availableDates, isLoadingAvailableDates } = useAvailableDates({
    from: dateForCalendarAvailability
      .clone()
      .startOf('month')
      .format(DATE_FORMAT),
    to: dateForCalendarAvailability.clone().endOf('month').format(DATE_FORMAT),
    collectionId: collection?.id,
    resourceGroupId: groupId,
    latest,
  });

  const handleReserveClick = useCallback(() => {
    if (selectedResource) {
      setResourceBookingSessionParams({
        resourceId: selectedResource,
        groupId,
      });
    }
  }, [selectedResource, groupId]);

  const handleSessionChange = useCallback(
    (time: string, duration: number) => {
      setResourceBookingSessionParams({
        time,
        duration,
      });
    },
    [setResourceBookingSessionParams],
  );

  const handleDateChange = useCallback(
    (date: string) => {
      setResourceBookingSessionParams({
        date,
      });
    },
    [setResourceBookingSessionParams],
  );

  const handleResourceChange = useCallback(
    (resourceId: string) => {
      if (selectedResource === resourceId) {
        unselectResource();
      } else {
        selectResource(resourceId);
      }
    },
    [selectResource, selectedResource, unselectResource],
  );

  if (!collectionId || !groupId || !collection || !resourceGroup) {
    return <h1>{translate('noResourceGroupFound')}</h1>;
  }

  const priceTiers = sessions
    .flatMap<PriceTier>((e) => (e ? e.priceTiers ?? [] : []))
    .sort((a, b) => a.price - b.price);
  const startingPrice = priceTiers[0]?.price || 0;

  const resourcesWithAvailabilities = resourceGroup.experiences.filter(
    (exp) =>
      !!sessions.find(
        (session) =>
          session.experienceId === exp.id &&
          (!duration || session.duration === duration) &&
          (!time || session.startDateTime.split('T')[1] === time),
      ),
  );

  const resourceGroupPayload = {
    id: resourceGroup.id,
    groupListItems: resourcesWithAvailabilities.map((item) => ({
      id: item.id,
      name: item.title,
      number: item.number,
    })),
    headline: resourceGroup.description,
    headlineTags: collection.vibes.map(({ name }) => name),
    heroImage: resourceGroup.pictures?.[0],
    price: startingPrice,
    title: resourceGroup.title,
    description: resourceGroup.description,
    includedItems: resourceGroup.included,
    experiences: resourceGroup.experiences,
  };

  if (!collection && !resourceGroup) {
    return <ResourceGroupPageSkeleton />;
  }

  return (
    <ResourceGroupLayout
      canReserve={!!selectedResource}
      onReserveClick={handleReserveClick}
      resourceGroup={resourceGroupPayload}
      selectedDate={date || ''}
      selectedTime={time || ''}
      selectedDuration={duration || 0}
      sessionsTimeOptions={allSessionTimes}
      availableDates={availableDates}
      isLoadingAvailableDates={isLoadingAvailableDates}
      onMonthChange={setDateForCalendarAvailability}
      onDateChange={handleDateChange}
      onSessionChange={handleSessionChange}
      onResourceChange={handleResourceChange}
      categoryName={collection.category?.name}
      collectionName={collection.title}
      collectionId={collection.id}
    />
  );
};
