/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { isFeatureEnabled } from './helper';
import { useAppState } from '../../AppProvider';
import { BRAND_FEATURES } from '../../types/brand';

const ToggleFeature = ({ featureId, children }) => {
  const brandFeatures =
    useAppState((state) => state.brand?.settings?.features?.active) ?? [];

  if (BRAND_FEATURES.includes(featureId)) {
    return brandFeatures.includes(featureId) ? children : null;
  }

  if (isFeatureEnabled(featureId)) {
    return children;
  }
  return null;
};

ToggleFeature.propTypes = {
  featureId: PropTypes.string.isRequired,
};

export default ToggleFeature;
