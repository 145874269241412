import React, { ComponentProps, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { NavigationHeader } from '../NavigationHeader';

interface Props extends ComponentProps<typeof NavigationHeader> {}

export const CollectionLayout: React.FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  return (
    <LayoutWrapper>
      <NavigationHeader />
      <ContentWrapper>{children}</ContentWrapper>
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  > div {
    flex-shrink: 0;
    height: 50px;
    margin-bottom: 12px;
  }
`;

const ContentWrapper = styled.main`
  position: relative;
`;
