import { useEffect, useState } from 'react';
import { preloadImages } from 'utils/image-preloader';

export const useImagePreloader = (images: string[]) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [imagesFailed, setImagesFailed] = useState(false);

  useEffect(() => {
    if (images.length === 0) return;

    preloadImages(images)
      .then(() => {
        setImagesLoaded(true);
      })
      .catch(() => {
        setImagesFailed(true);
      });
  }, [images]);

  return { success: imagesLoaded, error: imagesFailed };
};
