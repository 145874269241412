import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { CSSProperties } from 'react';
import { merge } from 'lodash';

interface FlexProps {
  isContent?: boolean;
  direction?: 'row' | 'column';
  justifyContent?: CSSProperties['justifyContent'];
  alignItem?: CSSProperties['alignItems'];
  gap?: number;
  flexWrap?: CSSProperties['flexWrap'];
  width?: string;
  height?: CSSProperties['height'];
  flex?: number;
}

const flexCss = (props: Partial<FlexProps>) => {
  return css`
    display: ${props.isContent ? 'contents' : 'flex'};
    flex-direction: ${props.direction ?? 'initial'};
    align-items: ${props.alignItem ?? 'center'};
    justify-content: ${props.justifyContent ?? 'space-between'};
    gap: ${props.gap ? `${props.gap}px` : 'space-between'};
    flex-wrap: ${props.flexWrap ?? 'nowrap'};
    width: ${props.width ?? 'auto'};
    flex: ${props.flex};
    height: ${props.height ?? 'auto'};
  `;
};
export const Flex = styled.div<FlexProps>`
  ${(props) => flexCss(props)}
`;

interface ResponsiveFlexProps extends FlexProps {
  breakpoint?: string;
  mobileOverride?: Partial<FlexProps>;
}
export const ResponsiveFlex = styled.div<ResponsiveFlexProps>`
  ${(props) => flexCss(props)}
  ${({ breakpoint, mobileOverride, ...props }) =>
    !breakpoint || !props
      ? ''
      : css`
          @media all and (max-width: ${breakpoint}) {
            ${flexCss(merge(props, mobileOverride))}
          }
        `}
`;

export const ClearButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 0px;
`;

export const Divider = styled.div<{
  space: number;
  marginHorizontal?: number;
}>`
  height: 1px;
  background-color: var(--way-palette-black-10);
  margin: ${(props) =>
    `${props.space ?? 12}px ${props.marginHorizontal ?? 0}px`};
`;

export const LinkText = styled(Link)<{ size?: number }>`
  color: #404fdb;
  text-decoration: underline;
  font-size: ${(props) => (props.size ? `${props.size}px` : 'initial')};
  cursor: pointer;
`;

export const DimmedText = styled.div<{ size?: number }>`
  color: var(--way-colors-contrastColorShades-60);
  font-size: ${(props) => (props.size ? `${props.size}px` : 'initial')};
`;

export const Flex1 = styled.div`
  flex: 1;
`;

export const HideWrapper = styled.div<{
  minWidth?: number;
  maxWidth?: number;
}>`
  ${(p) =>
    p.minWidth &&
    css`
      @media screen and (min-width: ${p.minWidth}px) {
        display: none;
      }
    `}

  ${(p) =>
    p.maxWidth &&
    css`
      @media screen and (max-width: ${p.maxWidth}px) {
        display: none;
      }
    `}
`;

export const HidableResponsiveContentWrapper = styled.div<{
  minWidth?: number;
  maxWidth?: number;
}>`
  display: contents;
  ${(p) =>
    p.minWidth &&
    css`
      @media screen and (min-width: ${p.minWidth}px) {
        > * {
          display: none;
        }
      }
    `}

  ${(p) =>
    p.maxWidth &&
    css`
      @media screen and (max-width: ${p.maxWidth}px) {
        > * {
          display: none;
        }
      }
    `}
`;

export const Sep = styled.div`
  display: block;
  width: 100%;
  height: 0.5px;
  background: var(--way-colors-contrastColorShades-10);
`;

export const SrOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;
