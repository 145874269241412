export default {
  bookExperiencePanel: 'Panel rezervace zážitku',
  calendarIcon: 'Ikona kalendáře',
  coverImageForResource: 'Obrázek obálky pro {{title}} zážitky',
  filterBy: 'Filtrovat podle {{filter}}',
  filterByDateRange: 'Filtrovat podle časového rozsahu',
  gallery: 'Galerie',
  goBack: 'Zpět na předchozí stránku',
  hostUserIcon: 'Ikona hostujícího uživatele',
  imageOf: 'Obrázek {{title}}',
  interactiveMapAltText: 'Interaktivní mapa s výběrem {{title}}',
  mediaSlides: 'Mediální snímky',
  muteVideo: 'Ztlumit video',
  nextPhoto: 'Další fotografie',
  noVideoPlayback: 'Není dostupná přehrávací kapacita videa',
  pauseVideo: 'Pozastavit video',
  photoOf: 'Fotografie {{name}}',
  playVideo: 'Přehrát video',
  previousPhoto: 'Předchozí fotografie',
  reserveSelectedSlot: 'Rezervovat vybraný slot',
  selectDate: 'Vyberte datum',
  selectParticipants: 'Vyberte účastníky',
  selectSessionTime: 'Vyberte čas sezení',
  unmuteVideo: 'Zrušit ztlumení videa',
  viewShoppingCart: 'Zobrazit nákupní košík',
  zoomIn: 'Přiblížit',
  zoomOut: 'Oddálit',
  selectData: `Vyberte {{data}}`,
  reserveName: 'Rezervovat {{name}}',
  deleteReservation: 'Smazat {{esperienceTitle}} rezervaci',
  editParticipantsFor: 'Upravit účastníky pro {{esperienceTitle}}',
  enterPromoCode: 'Zadejte promo kód',
  applyPromoCode: 'Použít promo kód',
  increaseAddOnAmount: 'Zvýšit vybrané {{addOn}}',
  decreaseAddOnAmount: 'Snížit vybrané {{addOn}}',
  addOnModal: '{{addOn}} modální',
  moreInformationAboutAddOn: 'Další informace o {{addOn}}',
  decreasePriceTierTickets: 'Snížit {{priceTier}} vstupenky',
  increasePriceTierTickets: 'Zvýšit {{priceTier}} vstupenky',
  downloadFilename: 'Stáhnout {{filename}}',
  viewFullDetailsDescription: 'Zobrazit podrobný popis',
  viewFullDescription: 'Zobrazit úplný popis',
  closeModal: 'Zavřít modální okno',
};
