export default {
  // these should be unique as the values are used as keys
  profileImageOfHost: 'ホスト{{hostName}}のプロフィール画像',
  hostedBy: 'によるホスト',
  experiences: '体験',
  experience: '体験',
  clear: 'クリア',
  cancel: 'キャンセル',
  apply: '適用',
  more: 'もっと見る',
  experiencePhotoOfListing: 'リスト{{listingTitle}}の体験写真{{photoNumber}}',
  noExperiencesFound: '体験が見つかりません',
  noExperiencesFoundFor: '{{listingTitle}}の体験が見つかりません',
  discoverExperiences: '体験を発見する',
  clearFilters: 'フィルターをクリア',
  buyTickets: 'チケットを購入',
  reserve: '予約する',
  activateProductCardTitle: 'イベント',
  reserveProductCardTitle: 'アクティビティ',
  hostProductCardTitle: 'ローカル体験',
  mainLandingPageTitle: 'すべての体験',
  activateLandingPageTitle: 'イベント',
  reserveLandingPageTitle: 'アクティビティと設備',
  hostLandingPageTitle: 'ローカル体験',
  exclusive: '限定',
  filterExperiences: '体験をフィルター',
};
