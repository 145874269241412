import { useEffect, useMemo } from 'react';
import { useAppState, useDispatch } from '../AppProvider';
import {
  type ITierParticipantState,
  PRICE_TIER_ACTIONS,
} from '../types/reducer-states/priceTierState';

const key = 'price-tier';

export const useTierParticipants = (): [
  ITierParticipantState,
  (data: ITierParticipantState) => void,
] => {
  const dispatch = useDispatch();
  const getDataFromSession = () => {
    try {
      const data = sessionStorage.getItem(key);
      if (data) {
        return JSON.parse(data) as ITierParticipantState;
      }
    } catch (_e) {
      //
    }

    return {
      currentExperience: '',
      selectedParticipants: [],
    };
  };

  const priceTierData: ITierParticipantState = useAppState(
    (state: { priceTierData: ITierParticipantState }) =>
      state.priceTierData || getDataFromSession(),
  );

  const data = useMemo(() => {
    if (priceTierData.selectedParticipants.length) {
      return priceTierData;
    }

    return getDataFromSession();
  }, [priceTierData]);

  const setData = (payload: ITierParticipantState) => {
    dispatch({
      type: PRICE_TIER_ACTIONS.SET,
      payload,
    });
  };

  useEffect(() => {
    if (priceTierData.selectedParticipants.length) {
      return;
    }

    setData(getDataFromSession());
  }, []);

  const addTierParticipantData = (data: ITierParticipantState) => {
    sessionStorage.setItem(key, JSON.stringify(data));

    setData(data);
  };

  return [data, addTierParticipantData];
};
