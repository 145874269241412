import React from 'react';

const PlayIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      width={20 * scale}
      height={20 * scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 3.13281L16.4658 9.48143L5.5 16.4072V3.13281ZM6.5 4.86726V14.5929L14.5342 9.51864L6.5 4.86726Z"
        fill={color}
      />
    </svg>
  );
};

export default PlayIcon;
