import React, { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CancellationPolicy, Listing } from 'types/listings';
import { ICartExperience, CartItemAddOn, ICartParticipant } from 'types/cart';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { currencyFormat } from 'utils';
import { Sep } from 'components/common/styled/common-styled';
import { computeOneCartItemTotalDetails } from '@kouto/cart-prices';
import { ANALYTICS_PROPERTY } from '@kouto/types';
import {
  analyticsManager,
  getAnalyticsDataFromCartItem,
} from 'features/analytics';
import { getListingCoverUrl } from 'utils/listings';
import { useIsMobile } from 'WindowDimensionProvider';
import CancellationPolicySummary from 'features/ShoppingCart/components/CancellationPolicySummary';

interface Props {
  cartItem: ICartExperience;
  listing: Listing;
  overriddenParticipants?: ICartParticipant[];
  overriddenAddOns?: CartItemAddOn[];
  cancellationPolicy?: CancellationPolicy;
}

const ListingCartItemSummary: FC<Props> = ({
  cartItem,
  listing,
  overriddenParticipants,
  overriddenAddOns,
  cancellationPolicy,
}) => {
  const currency = useBrandCurrency();
  const isMobile = useIsMobile();
  const { formattedNumber } = useCurrencyFormatter();
  const { t: translate } = useTranslation();

  const currentCartItem = {
    ...cartItem,
    participants:
      overriddenParticipants && overriddenParticipants.length
        ? overriddenParticipants
        : cartItem.participants,
    addOns: overriddenAddOns || cartItem.addOns || [],
  };

  const {
    isPrivateBooking,
    selectedPriceTiers,
    includedTaxes,
    nonIncludedTaxes,
    subTotal,
    total,
  } = computeOneCartItemTotalDetails(currentCartItem, listing);

  useEffect(() => {
    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.CurrentCartAmount]: `${total}`,
    });
  }, [total]);

  useEffect(() => {
    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.TotalTaxAmount]: `${nonIncludedTaxes
        .reduce((sum, tax) => sum + tax.amountFee, 0)
        .toFixed(2)}`,
    });
  }, [nonIncludedTaxes]);

  const coverImageUrl = useMemo(() => {
    return listing ? getListingCoverUrl(listing, isMobile) : '';
  }, [listing, isMobile]);

  useEffect(() => {
    const data = getAnalyticsDataFromCartItem({
      cartData: {
        ...cartItem,
        addOns: currentCartItem.addOns,
      },
      listing,
    });

    analyticsManager.setAppData(data);
  }, [
    cartItem?.experienceId,
    cartItem.listingId,
    currentCartItem.addOns,
    listing.category?.name,
    listing?.title,
    selectedPriceTiers,
  ]);

  return (
    <Wrapper>
      <SummaryWrapper>
        <CoverImage
          className="resource-group-summary__resource-image listing-cart-summary__resource-image"
          src={coverImageUrl}
          alt={listing.title}
          data-testid="picture-component"
        />

        <Sep />

        <Prices>
          {selectedPriceTiers.map((tier) => {
            if (!tier.selectedNumber) {
              return null;
            }
            return (
              <PriceRow key={tier.name}>
                <p className="resource-group-summary__price-tier listing-cart-summary__price-tier">
                  {translate(tier.name)}
                  {!isPrivateBooking && ` (x${tier.selectedNumber})`}
                </p>
                <p className="resource-group-summary__tier-amount listing-cart-summary__tier-amount">
                  {currencyFormat(currency)(formattedNumber(tier.price))}
                </p>
              </PriceRow>
            );
          })}
          {currentCartItem.addOns.map((addOn) => {
            if (!addOn.selectedNumber) {
              return null;
            }
            return (
              <PriceRow key={addOn.id}>
                <p className="listing-cart-summary__addon-name">
                  {addOn.label} (x{addOn.selectedNumber})
                </p>
                <p className="listing-cart-summary__addon-price">
                  {currencyFormat(currency)(formattedNumber(addOn.price))}
                </p>
              </PriceRow>
            );
          })}

          <Sep style={{ margin: '8px 0px' }} />

          <PriceRow>
            <p className="resource-group-summary__total listing-cart-summary__total">
              {translate('subTotal')}: {includedTaxes.length > 0 ? '*' : ''}
            </p>
            <p className="resource-group-summary__total-amount listing-cart-summary__total-amount">
              {subTotal > 0
                ? currencyFormat(currency)(formattedNumber(subTotal))
                : '-'}
            </p>
          </PriceRow>

          {nonIncludedTaxes.map((tax) => (
            <PriceRow key={tax.name}>
              <p className="resource-group-summary__tax listing-cart-summary__tax">
                {tax.name}
              </p>
              <p className="resource-group-summary__tax-amount listing-cart-summary__tax-amount">
                {currencyFormat(currency)(formattedNumber(tax.amountFee))}
              </p>
            </PriceRow>
          ))}

          <Sep style={{ margin: '8px 0px' }} />

          <PriceRow bold>
            <p className="resource-group-summary__total listing-cart-summary__total">
              {translate('total')}:
            </p>
            <p className="resource-group-summary__total-amount listing-cart-summary__total-amount">
              {total > 0
                ? currencyFormat(currency)(formattedNumber(total))
                : '-'}
            </p>
          </PriceRow>

          {includedTaxes.length > 0 && (
            <PriceRow>
              <small>
                * {translate('includes')}{' '}
                {includedTaxes
                  .map((t) => `${t.name} (${t.percentage}%)`)
                  .join(` ${translate('and')} `)}
              </small>
            </PriceRow>
          )}
        </Prices>
      </SummaryWrapper>

      <CancellationPolicySummary cancellationPolicy={cancellationPolicy} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 350px;
  min-width: 350px;

  & > * {
    width: 100%;
    padding: 24px;
  }

  @media (max-width: 768px) {
    max-width: unset;
  }
`;

const CoverImage = styled.img`
  object-fit: cover;
  user-select: none;
  max-height: 100%;
  object-position: center;
  height: 254px;
  border-radius: var(--way-design-borderRadiusDefault);
  width: 100%;
`;

const SummaryWrapper = styled.div`
  box-shadow: var(--way-design-boxShadow-s);
  flex-shrink: 0;
  height: fit-content;
  border: 0.5px solid var(--way-colors-borderColor);
  background: var(--way-palette-white-10);
  align-self: stretch;
`;

const Prices = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 24px;
`;

const PriceRow = styled.div<{ bold?: boolean }>`
  /* skip css namespace */
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;

  &:last-child {
    margin-bottom: 0px;
  }

  & > p {
    margin: 0px;
    font-size: 15px;
    font-style: normal;
    font-weight: ${({ bold }) => (bold ? '700' : '100')};
    line-height: normal;
    letter-spacing: 0.014px;
  }
`;

export default ListingCartItemSummary;
