import * as types from 'types/app';
import initialState from 'store/initial-state';

export default function appReducer(state = initialState.app, action) {
  switch (action.type) {
    case types.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    default:
      return state;
  }
}
