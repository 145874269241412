import React, { FC, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useIsMobile } from 'WindowDimensionProvider';
import styled from 'styled-components';
import EventTicketsPage from './pages/EventTicketsPage/EventTicketsPage';
import EventDetailsPage from './pages/EventDetailsPage/EventDetailsPage';
import useEventBookingSession from './hooks/useEventBookingSession';

export const EventTicketsRoute = () => {
  const isMobile = useIsMobile();
  const { event, date, setEventBookingSessionParams } =
    useEventBookingSession();

  const deselectDate = useCallback(() => {
    setEventBookingSessionParams({
      date: undefined,
    });
  }, [setEventBookingSessionParams]);

  const dateFormatted = useMemo(() => {
    return date
      ? `${moment(date).format('dddd')}, ${
          moment(date).format('LL').split(',')[0]
        }`
      : '';
  }, [date]);

  return (
    <>
      {isMobile && <EventDetailsPage />}
      <EventTicketsPage
        open
        heading={
          <BottomDrawerHeader
            title={event?.title || ''}
            subTitle={dateFormatted}
          />
        }
        size="full"
        onClose={deselectDate}
      />
    </>
  );
};

interface HeaderProps {
  title: string;
  subTitle: string;
}

const BottomDrawerHeader: FC<HeaderProps> = ({ title, subTitle }) => {
  return (
    <HeaderWrapper>
      <BottomDrawerTitle>{title}</BottomDrawerTitle>
      <BottomDrawerSubTitle>{subTitle}</BottomDrawerSubTitle>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const BottomDrawerTitle = styled.h1`
  margin: 0px;
  color: var(--way-colors-primaryTextColor);
  font-size: 24px;
  line-height: 26px;
`;

const BottomDrawerSubTitle = styled.p`
  margin: 0px;
  color: var(--way-colors-contrastColorShades-80);
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 18px;
`;
