import React from 'react';

const PlusIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17 * scale}
      height={16 * scale}
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <path
        d="M13.1673 7.3335H9.16732V3.3335H7.83398V7.3335H3.83398V8.66683H7.83398V12.6668H9.16732V8.66683H13.1673V7.3335Z"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
    </svg>
  );
};

export default PlusIcon;
