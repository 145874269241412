import { getAppLanguage } from 'utils/language';
import { EmbedConfig } from './types';

const getBrandIdFromUrl = () => {
  const brandIdRegexp =
    /\/brands\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;
  if (brandIdRegexp.test(window.location.pathname)) {
    const idMatches = window.location.pathname.match(brandIdRegexp);
    return idMatches?.[0].split('/')[2];
  }

  return null;
};

export const EMBED_CONFIG_PROPERTIES_MAP = {
  brandId: 'data-brand-id',
  categoryId: 'data-category-id',
  language: 'data-language',
  home: 'data-home',
  routerType: 'data-router-type',
  basePath: 'data-base-path',
  hashType: 'data-hash-type',
  userLastName: 'data-user-last-name',
  userFirstName: 'data-user-first-name',
  userEmail: 'data-user-email',
  userPhone: 'data-user-phone',
  memberNumber: 'data-member-number',
  roomNumber: 'data-user-room-number',
  participantName: 'data-participant-name',
} as const satisfies Record<keyof EmbedConfig, string>;

export const FALLBACK_EMBED_CONFIG_GETTER_MAP: Partial<
  Record<keyof EmbedConfig, () => string | null | undefined>
> = {
  brandId: getBrandIdFromUrl,
  language: getAppLanguage,
  home: () => 'home',
  routerType: () => 'hash',
  hashType: () => 'hashbang',
};

export const isKey = <T extends object>(
  obj: T,
  key: PropertyKey,
): key is keyof T => key in obj;

export const EMBED_CONFIG_PROPERTY_LOADER: Partial<
  Record<
    keyof EmbedConfig,
    (
      property: keyof EmbedConfig,
      value?: string,
    ) => EmbedConfig[keyof EmbedConfig]
  >
> = {
  participantName: (_property, value) => {
    return (value || '').split(',').map((name) => name.trim());
  },
};
