import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import compose from 'lodash/fp/compose';
import get from 'lodash/get';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { useAppState } from 'AppProvider';
import {
  isExperienceMultipleTiers,
  getTimezoneOffset,
  parseTextFromHtmlString,
} from 'utils';
import { STATUS_IDLE, STATUS_PENDING } from 'types/app';

const SCHEMA_URL = 'https://schema.org';

const SchemaMarkup = ({ url }) => {
  const [excludedFields, setExcludedFields] = useState([]);
  const {
    experience,
    sessions: availableSessions,
    firstAvailableDate,
    fetchFirstAvailabilityStatus,
    searchAvailabilityStatus,
    availabilities,
  } = useAppState((state) => state.experience);
  const isMultipleTiers = isExperienceMultipleTiers(experience);
  const experienceCoverImage = get(experience, 'coverPicture.uri.original');
  const firstBookableDate = useMemo(
    () => firstAvailableDate ?? availabilities[0],
    [firstAvailableDate, availabilities],
  );

  const expFirstDateTime = `${firstBookableDate} ${availableSessions?.[0]?.startTime}`;

  const getWithTimezone = (date) => {
    const timezone = getTimezoneOffset(get(experience, 'location.timezoneId'));
    return `${date.format('YYYY-MM-DDTHH:mm:ss')}${timezone}`;
  };

  const addExperienceDurationToDate = (date) => {
    return date.add(experience.sessionDurationMinutes, 'm');
  };

  const startDateTime = useMemo(() => {
    return compose(getWithTimezone, moment)(expFirstDateTime);
  }, [expFirstDateTime]);

  const endDateTime = useMemo(
    () =>
      compose(
        getWithTimezone,
        addExperienceDurationToDate,
        moment,
      )(expFirstDateTime),
    [expFirstDateTime],
  );

  const isDateValid = useMemo(() => {
    return moment(startDateTime).isValid();
  }, [startDateTime]);

  const isLoading =
    fetchFirstAvailabilityStatus === STATUS_PENDING ||
    fetchFirstAvailabilityStatus === STATUS_IDLE ||
    searchAvailabilityStatus === STATUS_PENDING ||
    searchAvailabilityStatus === STATUS_IDLE;

  useEffect(() => {
    setExcludedFields(() => {
      const fields = [];
      if (experience?.isNoHost) {
        fields.push('performer');
      }
      if (experience?.hidePrice) {
        fields.push('offers');
      }
      if (isLoading) {
        return fields;
      }
      return isDateValid ? fields : [...fields, 'startDate', 'endDate'];
    });
  }, [experience, availableSessions, isDateValid, isLoading]);

  const schemaData = useMemo(() => {
    const offers = experience?.priceTiers?.map((tier) => {
      const doesTierHaveAvailability = availableSessions.some(
        (sess) =>
          sess.supportedParticipantsCount > 0 &&
          sess.availableQuantityPerTier[tier.name] > 0,
      );
      return {
        '@type': 'Offer',
        name: tier.name,
        price: tier.price,
        priceCurrency: experience?.currency,
        ...(isDateValid && { validFrom: startDateTime }),
        url,
        availability: `${SCHEMA_URL}/${
          doesTierHaveAvailability ? 'InStock' : 'SoldOut'
        }`,
      };
    });

    const data = {
      '@context': SCHEMA_URL,
      '@type': 'Event',
      url,
      name: experience?.title,
      description: parseTextFromHtmlString(experience?.description || ''),
      image: experienceCoverImage,
      startDate: startDateTime,
      endDate: endDateTime,
      eventStatus: `${SCHEMA_URL}/EventScheduled`,
      eventAttendanceMode: `${SCHEMA_URL}/OfflineEventAttendanceMode`,
      location: {
        '@type': 'Place',
        name: get(experience, 'location.name'),
        geo: {
          '@type': 'GeoCoordinates',
          latitude: get(experience, 'location.latitude'),
          longitude: get(experience, 'location.longitude'),
        },
      },
      performer: {
        '@type': 'Person',
        name: `${get(experience, 'hostedBy.firstName')} ${get(
          experience,
          'hostedBy.lastName',
        )}`,
      },
      offers: isMultipleTiers ? offers : offers?.[0],
    };

    return omit(data, excludedFields);
  }, [
    experience,
    availableSessions,
    expFirstDateTime,
    excludedFields,
    isLoading,
  ]);

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

SchemaMarkup.propTypes = {
  url: PropTypes.string.isRequired,
};

export default React.memo(SchemaMarkup);
