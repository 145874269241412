import React from 'react';
import { useAppState } from 'AppProvider';
import get from 'lodash/get';
import {
  StyledWrapInput,
  StyledLabel,
  ErrorContainer,
  Error,
} from 'components/theme/Form';
import PhoneNumberInput from 'components/common/PhoneNumberInput/PhoneNumberInput';
import { ITextFieldProps } from 'components/common/TextFieldInput/TextFieldInput';
import { Controller, useFormContext } from 'hook-form';

const PhoneNumberController: React.FC<ITextFieldProps> = ({
  name,
  label,
  ...props
}) => {
  const { control } = useFormContext();
  const { setting } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );

  return (
    <Controller
      control={control}
      render={(props) => {
        const errorMessage = get(props.formState, `errors.${name}.message`);
        return (
          <StyledWrapInput>
            <StyledLabel htmlFor={name}>{label}</StyledLabel>
            <PhoneNumberInput
              value={props.field.value}
              onChange={props.field.onChange}
              countryCode={setting?.countryCode}
              countryCodeEditable
              inputProps={{
                name,
                id: name,
                ref: props.field.ref,
              }}
              {...props}
            />
            {errorMessage && (
              <ErrorContainer>
                <Error>{errorMessage as string}</Error>
              </ErrorContainer>
            )}
          </StyledWrapInput>
        );
      }}
      name={name}
      {...props}
    />
  );
};

export default PhoneNumberController;
