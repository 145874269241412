/**
 *
 * Stylis is a CSS preprocessor used by Emotion, which is used
 * under the hood by styled components. Since the
 * @quickbaseoss/babel-plugin-styled-components-css-namespace
 * babel plugin is breaking with the latest version of styled
 * components, we had to find another way to add the kouto
 * css root namespace to all our classes and address the specifity
 * issues which made us inherit some parent CSS in a weird and
 * unexpected way
 *
 * the benefit of this is that it will also correctly work with
 * typescript files, which are not transpiled by babel
 */

function stylisPluginCssNamespace(options) {
  const { cssNamespace } = options;

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const stylisProcessor = (
    context,
    content,
    selectors,
    parents,
    line,
    column,
    length,
    ns,
    depth,
    at,
  ) => {
    if (context !== -1) {
      return;
    }

    const [selector] = selectors;
    if (
      !selector ||
      selector.includes(cssNamespace) ||
      selector === 'body' ||
      selector.includes('sc-') ||
      content.includes('-webkit-line-clamp') ||
      !(content.includes('font-size') || content.includes('text-align')) ||
      content.includes('-webkit-linear-gradient') ||
      content.includes('/* skip css namespace */')
    ) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    selectors[0] = `${cssNamespace} ${selector}`;
  };

  // see https://github.com/styled-components/styled-components/blob/main/packages/styled-components/src/utils/errors.md#15
  Object.defineProperty(stylisProcessor, 'name', {
    value: 'stylis-css-namespace-plugin',
  });

  return stylisProcessor;
}

export default stylisPluginCssNamespace;
