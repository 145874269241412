import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ResourceGroup, Session } from 'types/listings';
import { DateSelector } from 'components/DatePicker/DateSelector';
import InfoIcon from 'assets/bx-info-circle';
import { DATE_FORMAT } from 'utils';
import { ResourceGroupCard } from 'components/Resource/ResourceGroup/ResourceGroupCard';
import { useIsMobile } from 'WindowDimensionProvider';
import {
  CollectionHero,
  CollectionTitle,
} from 'features/Reserve/components/CollectionHero';
import MobileMappedCollectionPage from 'features/Reserve/components/MobileMappedCollectionPage';
import useResourceBookingSession from 'features/Reserve/hooks/useResourceBookingSession';
import useAvailableDates from 'features/Reserve/hooks/useAvailableDates';
import { getSessionTimeOptions } from 'features/Reserve/hooks/useSessionTimeOptions';
import { CollectionPageNonMappedGroupsSkeleton } from 'features/Reserve/pages/Skeleton';
import {
  Wrapper,
  NoSessionsMessage,
} from 'features/Reserve/components/ResourceGroupLayout';

export const MappedCollectionPage: React.FC = () => {
  const isMobile = useIsMobile();
  const { t: translate } = useTranslation();
  const {
    collection,
    sessions,
    date,
    isLoading,
    latest,
    setResourceBookingSessionParams,
  } = useResourceBookingSession();
  const [dateForCalendarAvailability, setDateForCalendarAvailability] =
    useState<moment.Moment>(date ? moment(date) : moment());
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleDateSelectorFocusChange = ({ focused }: { focused: boolean }) =>
    setShowDatePicker(focused);

  useEffect(() => {
    if (date) {
      setDateForCalendarAvailability(moment(date));
    }
  }, [date]);

  const { availableDates, isLoadingAvailableDates } = useAvailableDates({
    from: dateForCalendarAvailability.startOf('month').format(DATE_FORMAT),
    to: dateForCalendarAvailability.endOf('month').format(DATE_FORMAT),
    collectionId: collection?.id,
    latest,
  });

  const handleDateChange = useCallback(
    (newDate: string) => {
      setResourceBookingSessionParams({
        date: newDate,
      });
      setShowDatePicker(false);
    },
    [setResourceBookingSessionParams],
  );

  const handleGroupSelect = useCallback(
    (groupId: string) => {
      setResourceBookingSessionParams({
        groupId,
      });
    },
    [setResourceBookingSessionParams],
  );

  const sessionsByGroup = Object.fromEntries(
    collection?.resourceGroups?.map((group) => [
      group.id,
      filterSessionsByGroup(sessions, group),
    ]) || [],
  );

  const thereAreSomeAvailableSessions = Object.values(sessionsByGroup).some(
    (sessions) => sessions.length > 0,
  );

  if (!collection) {
    return <h1>{translate('noCollectionFound')}</h1>;
  }

  if (collection.map && isMobile) {
    return (
      <MobileMappedCollectionPage
        collection={collection}
        sessionsByGroup={sessionsByGroup}
        onReserve={(groupId) => {
          setResourceBookingSessionParams({
            groupId,
          });
        }}
      />
    );
  }

  return (
    <CollectionDetailsContainer gap="24px">
      {collection.map ? (
        <CollectionTitle as="h1" allowMultipleLines>
          {collection.title}
        </CollectionTitle>
      ) : (
        <CollectionHero isLoading={!collection} collection={collection} />
      )}
      <CollectionDetailsContainer>
        {isLoading ? (
          <CollectionPageNonMappedGroupsSkeleton />
        ) : (
          <>
            {isMobile && collection.map ? null : (
              <>
                <DateSelector
                  loading={isLoadingAvailableDates}
                  maxWidth={collection.map ? undefined : '240px'}
                  date={date || ''}
                  availableDates={availableDates}
                  onMonthChange={setDateForCalendarAvailability}
                  onChange={handleDateChange}
                  onFocusChange={handleDateSelectorFocusChange}
                  showDatePicker={showDatePicker}
                />
                {!thereAreSomeAvailableSessions && !collection.map && (
                  <Wrapper marginBottom={16}>
                    <NoSessionsMessage isMobile={isMobile}>
                      <InfoIcon />
                      <span>{translate('noAvailableSessionsOnThisDate')}</span>
                    </NoSessionsMessage>
                  </Wrapper>
                )}
              </>
            )}
            {collection?.resourceGroups?.map((group) => {
              const groupSessions = sessionsByGroup[group.id] || [];
              const allSessionTimes = getSessionTimeOptions(groupSessions);
              return (
                <ResourceGroupCard
                  key={group.id}
                  collectionName={collection.title}
                  categoryName={collection.category?.name}
                  collectionId={collection.id}
                  group={group}
                  sessions={groupSessions}
                  allSessionTimes={allSessionTimes}
                  showTruncatedView={!!collection.map}
                  latest={latest}
                  handleGroupSelect={handleGroupSelect}
                />
              );
            })}
          </>
        )}
      </CollectionDetailsContainer>
    </CollectionDetailsContainer>
  );
};

const CollectionDetailsContainer = styled.div<{ gap?: CSSProperties['gap'] }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || '24px'};
`;

const filterSessionsByGroup = (
  sessions: Session[],
  resourceGroup: ResourceGroup,
) => {
  return sessions.filter(
    (session) =>
      !!resourceGroup.experiences.find(
        (exp) => exp.id === session.experienceId,
      ),
  );
};
