import {
  ANALYTICS_EVENT,
  ANALYTICS_PROPERTY,
  type IAnalyticsIntegrationResponse,
} from '@kouto/types/src/api/integration/analytics';
import { IntegrationGroup, Integrations } from '@kouto/types';
import { shouldLog } from 'utils/debug';
import type { IFullExperienceFields } from 'types/experience.types';

export type AnalyticsConfig = Omit<IAnalyticsIntegrationResponse, 'credential'>;

export type AnalyticsProduct = {
  id: string;
  title: string;
  category?: string;
  itemListId?: string;
  itemListName?: string;
  priceTiers?: (IFullExperienceFields['priceTiers'][number] & {
    selectedNumber?: number;
  })[];
  index: number;
  coupon?: string;
  discount?: number;
};

export abstract class AnalyticsClient {
  protected ready = false;

  provider!: Readonly<
    (typeof Integrations)[IntegrationGroup.ANALYTICS][number]
  >;

  events: {
    eventName: ANALYTICS_EVENT;
    payload: Record<string, string>;
  }[] = [];

  abstract hasEvent(eventName: ANALYTICS_EVENT): boolean;

  abstract sendEvent(
    eventName: ANALYTICS_EVENT,
    payload: IAnalyticsDataPayload,
  ): unknown;

  abstract init(): void;

  abstract isAvailable(): boolean;

  runWhenAvailable(): Promise<boolean> {
    return new Promise((resolve) => {
      let tryCount = 0;
      const interval = setInterval(() => {
        if (this.isAvailable()) {
          resolve(true);
          clearInterval(interval);
        }
        tryCount += 1;

        if (tryCount > 5) {
          resolve(shouldLog());
          clearInterval(interval);
        }
      }, 500);
    });
  }
}

export type IAnalyticsDataPayload = Partial<Record<ANALYTICS_PROPERTY, string>>;
