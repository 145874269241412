import React, {
  FC,
  useCallback,
  useState,
  useMemo,
  useEffect,
  PropsWithChildren,
} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CartItemState } from 'types/cart';
import useSearchQueryParams from 'hooks/use-search-params';
import { useDispatch, useAppState } from 'AppProvider';
import { useCart } from 'hooks/useCart';
import { BookingMode } from 'types/payment';
import { IWaitlistState } from 'types/waitlist';
import { listingShouldGoThroughParticipantsPage } from 'utils/listings';
import { PrimaryButton } from 'components/theme/Button/Button';
import useFetchWaitlist from 'selectors/waitlist';
import { addItemToWaitlist } from 'actions/waitlist';
import {
  DATE_FORMAT,
  formatUTCDate,
  serializeParams,
  TIME_FORMAT,
  uuidv4,
} from 'utils';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import { useTierParticipants } from 'hooks/use-selected-participants';
import {
  integrateSessionParticipants,
  setTierParticipants,
} from 'actions/participants';
import { filterEmpty } from 'actions/helpers';
import { Session, Experience, ResourceGroup } from 'types/listings';
import {
  ResourceGroupCollectionEventType,
  ANALYTICS_EVENT,
} from '@kouto/types';
import { validateAccessCode } from 'actions/access-code';
import { VALIDATE_ACCESS_CODE_FAILURE } from 'types/access-code';
import ResourceAccessCode, {
  AccessCodeHeading,
} from 'components/Resource/ResourceAccessCode';
import { useIsMobile } from 'WindowDimensionProvider';
import { BottomDrawer, BottomDrawerProps } from 'components/BottomDrawer';
import {
  analyticsManager,
  getAnalyticsDataFromCartItem,
} from 'features/analytics';
import { getMaxQuantitiesPerTiersLists } from './utils/tickets-utils';
import {
  TicketHeader,
  TicketPricesSelector,
  TicketTimesSelector,
  TicketSummary,
} from './components';

interface PriceTiersWithParticipantsProps {
  selectedNumber: number;
  participants: number;
  id: string;
  name: string;
  price: number;
  maxQuantity?: number | undefined;
}

interface Props extends BottomDrawerProps {}

const EventTicketsPage: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { event, daySessions, latest, date, waitlistEnabled } =
    useEventBookingSession();
  const [priceTierSelection, setPriceTierSelection] = useState<
    { id: string; numberOfParticipants: number }[]
  >([]);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const isCartFeatureEnabled = useBrandToggleFeature('shoppingCart');
  const { searchParams } = useSearchQueryParams();
  const [, setSelectedTierCustomers] = useTierParticipants();
  const { onAddToCart } = useCart();
  useFetchWaitlist();

  const isMobileView = useIsMobile();

  const [accessCodeError, setAccessCodeError] = useState('');
  const [isCodeValidated, setIsAccessCodeValidated] = useState(false);

  const isEventExclusive = event?.resourceGroups?.[0].isExclusive;
  const isAccessCodeVerified = isEventExclusive ? isCodeValidated : true;

  const bookingMode =
    event?.eventType === ResourceGroupCollectionEventType.RESERVED_SEATING
      ? BookingMode.PRIVATE
      : BookingMode.SHARED;

  const allExperiences = useMemo(() => {
    return (
      event?.resourceGroups?.flatMap(
        (resourceGroup) => resourceGroup.experiences,
      ) || []
    );
  }, [event]);

  const { availableStartTimes, waitlistTimes, priceTiers } = useMemo(
    () =>
      getMaxQuantitiesPerTiersLists({
        experiences: allExperiences,
        sessions: daySessions,
        priceTiersSelection: priceTierSelection,
        waitlistEnabled,
      }),
    [allExperiences, daySessions, priceTierSelection, waitlistEnabled],
  );

  const waitlistTimesList = useMemo(() => {
    return waitlistTimes.map((time) => ({
      value: time,
      label: moment(time, 'HH:mm:ss').format('LT'),
    }));
  }, [waitlistTimes]);

  const priceTiersWithParticipants = useMemo(() => {
    return priceTierSelection.flatMap((selectedPriceTier) => {
      const priceTier = priceTiers.find(
        ({ id }) => id === selectedPriceTier.id,
      );

      return !priceTier
        ? []
        : [
            {
              ...priceTier,
              participants: selectedPriceTier.numberOfParticipants,
              maxQuantity: priceTier.maxQuantity ?? undefined,
            },
          ];
    });
  }, [priceTierSelection, priceTiers]);

  const isWaitlistSelected = useMemo(
    () => selectedTime && waitlistTimes.includes(selectedTime),
    [waitlistTimes, selectedTime],
  );

  const directCheckoutFlow = useCallback(
    async ({
      listingId,
      session,
      experience,
      resourceGroup,
      priceTiersWithParticipants,
    }: {
      listingId: string;
      session: Session;
      experience: Experience;
      resourceGroup: ResourceGroup;
      priceTiersWithParticipants: PriceTiersWithParticipantsProps[];
    }) => {
      const participants = priceTiersWithParticipants.flatMap((p) =>
        new Array(p.participants).fill(p).map(() => ({
          fullName: 'Resource Purchaser',
          firstName: 'Resource',
          lastName: 'Purchaser',
          terms: false,
          price: p.price,
          priceTierName: p.name || '',
        })),
      );

      if (waitlistEnabled && isWaitlistSelected) {
        dispatch(
          addItemToWaitlist({
            item: {
              cartItemId: uuidv4(),
              listingId,
              state: CartItemState.READY,
              sessionDateTime: session.startDateTime,
              sessionDuration: moment
                .duration(session.duration, 'minutes')
                .toISOString(),
              experienceId: experience.id,
              bookingMode: searchParams.mode || BookingMode.SHARED,
              notes: '',
              participants,
              additionalCustomQuestionResponses: {},
            },
          }),
        );
      } else {
        setSelectedTierCustomers({
          selectedParticipants: priceTiersWithParticipants,
          currentExperience: experience.id,
        });

        const cartData = {
          state: CartItemState.READY,
          participants,
          notes: '',
          maxParticipantReached: '',
          bookingMode,
          listingId: event?.id,
          experienceId: experience?.id,
          groupId: resourceGroup.id,
          additionalCustomQuestionResponses: {},
          sessionDateTime: `${date}T${
            session?.startDateTime
              ? moment(session.startDateTime).format(TIME_FORMAT)
              : undefined
          }`,
          sessionDuration: session?.duration
            ? moment.duration(session?.duration, 'minutes').toISOString()
            : '',
        };

        analyticsManager.sendEvent(
          ANALYTICS_EVENT.CLICK_PROCEED_TO_CHECKOUT_BUTTON,
          getAnalyticsDataFromCartItem({
            cartData,
            collection: {
              id: event?.id ?? '',
              category: event?.category ?? null,
              title: event?.title ?? '',
            },
            resourceGroup,
          }),
        );

        await onAddToCart(cartData, '/checkout');
      }
    },
    [
      date,
      onAddToCart,
      setSelectedTierCustomers,
      bookingMode,
      event,
      isWaitlistSelected,
      dispatch,
      searchParams,
      waitlistEnabled,
    ],
  );

  const addToCartAndRedirect = useCallback(
    ({
      experience,
      resourceGroup,
      session,
      route,
      state,
      skipParticipantsName = false,
      overrideSearch = '',
    }: {
      experience: Experience;
      resourceGroup: ResourceGroup;
      session: Session;
      route: string;
      state: CartItemState;
      skipParticipantsName?: boolean;
      overrideSearch?: string;
    }) => {
      const participants = priceTiersWithParticipants.flatMap((p) =>
        new Array(p.participants).fill(p).map(() => ({
          fullName: skipParticipantsName ? 'Resource Purchaser' : '',
          firstName: skipParticipantsName ? 'Resource' : '',
          lastName: skipParticipantsName ? 'Purchaser' : '',
          terms: false,
          price: p.price,
          priceTierName: p.name || '',
        })),
      );

      const cartData = {
        state,
        participants,
        notes: '',
        maxParticipantReached: '',
        bookingMode,
        ...(event?.id && { listingId: event.id }),
        experienceId: experience?.id,
        groupId: resourceGroup.id,
        additionalCustomQuestionResponses: {},
        sessionDateTime: `${date}T${
          session?.startDateTime
            ? moment(session.startDateTime).format(TIME_FORMAT)
            : undefined
        }`,
        sessionDuration: session?.duration
          ? moment.duration(session?.duration, 'minutes').toISOString()
          : '',
      };
      onAddToCart(cartData, route, overrideSearch);
    },
    [bookingMode, event, onAddToCart, priceTiersWithParticipants, date],
  );

  const defaultCheckoutFlow = useCallback(
    ({
      noOfGuests,
      session,
      resourceGroup,
      experience,
      priceTiersWithParticipants,
      skipParticipantsName,
    }: {
      noOfGuests: number;
      session: Session;
      resourceGroup: ResourceGroup;
      experience: Experience;
      priceTiersWithParticipants: PriceTiersWithParticipantsProps[];
      skipParticipantsName: boolean;
    }) => {
      const bookingData = {
        mode: bookingMode,
        noOfGuests,
        sessionDate: moment(session.startDateTime).format(DATE_FORMAT),
        sessionTime: moment(session.startDateTime).format(TIME_FORMAT),
        duration: moment.duration(session.duration, 'minutes').toISOString(),
        supportedParticipantsCount: resourceGroup.experiences.length,
        listingId: event?.id,
        skipParticipantsName,
        waitlistId: event?.waitlistId ?? undefined,
        groupId: resourceGroup.id,
        ...(isCartFeatureEnabled ? searchParams : {}),
      };

      setSelectedTierCustomers({
        selectedParticipants: priceTiersWithParticipants,
        currentExperience: experience.id,
        groupTitle: resourceGroup.title,
      });

      dispatch(
        integrateSessionParticipants({
          session,
        }),
      );

      dispatch(
        setTierParticipants({
          participants: priceTiersWithParticipants,
          currentExperience: experience.id,
          groupTitle: resourceGroup.title,
        }),
      );

      if (latest) {
        bookingData.latest = true;
      }
      const filteredBookingData = filterEmpty(bookingData);

      if (waitlistEnabled && isWaitlistSelected) {
        history.push({
          pathname: `/e/${experience.id}/waitlist`,
          search: serializeParams(filteredBookingData),
        });
      } else if (isCartFeatureEnabled) {
        const params = new URLSearchParams();
        params.set(
          'skipParticipantsName',
          skipParticipantsName ? 'true' : 'false',
        );
        if (date) {
          params.set('date', date);
        }
        if (latest) {
          params.set('latest', 'true');
        }

        addToCartAndRedirect({
          experience,
          resourceGroup,
          session,
          route: `/e/${experience.id}/participants/{cartItemId}`,
          state: CartItemState.PENDING,
          skipParticipantsName,
          overrideSearch: params.toString(),
        });
      } else {
        history.push({
          pathname: `/e/${experience.id}/booking`,
          search: serializeParams(filteredBookingData),
        });
      }
    },
    [
      dispatch,
      event,
      latest,
      date,
      history,
      isCartFeatureEnabled,
      searchParams,
      setSelectedTierCustomers,
      addToCartAndRedirect,
      bookingMode,
      isWaitlistSelected,
      waitlistEnabled,
    ],
  );

  const { item: waitlistItem, experience: waitlistExperience }: IWaitlistState =
    useAppState((state: Record<string, unknown>) => state.waitlist);

  useEffect(() => {
    if (waitlistItem && waitlistExperience) {
      const params = { ...searchParams };
      if (event?.waitlistId) {
        params.waitlistId = event.waitlistId;
      }
      history.push({
        pathname: '/join-waitlist',
        search: `?${new URLSearchParams(params).toString()}`,
      });
    }
  }, [event, waitlistItem, waitlistExperience, searchParams, history]);

  const selectedPriceTiersAmount = useMemo(
    () =>
      priceTierSelection.reduce((sum, tier) => {
        return sum + tier.numberOfParticipants;
      }, 0),
    [priceTierSelection],
  );

  const withAccessCodeValidation = async (
    code: string,
    onSuccess: CallableFunction,
  ) => {
    if (!isEventExclusive || isAccessCodeVerified) {
      onSuccess();
      return;
    }

    if (!code) {
      setAccessCodeError('Access code is required');
      return;
    }
    const response = await dispatch(
      validateAccessCode({
        code,
        brandId: event?.brandId,
        experienceId: event?.resourceGroups?.[0]?.experiences?.[0]?.id,
        sessionDateTime: formatUTCDate(date, selectedTime),
        isCartFeatureEnabled,
      }),
    );
    if (response.type === VALIDATE_ACCESS_CODE_FAILURE) {
      setAccessCodeError('Invalid code');
      return;
    }
    onSuccess();
  };

  const onContinueClick = useCallback(async () => {
    const session = daySessions.find(
      (s) => s.startDateTime.split('T')[1] === selectedTime,
    );
    const resourceGroup = event?.resourceGroups?.[0];
    const experience = resourceGroup?.experiences.find(
      (e) => e.id === session?.experienceId,
    );
    const noOfGuests = priceTiersWithParticipants.reduce(
      (sum, data) => sum + data.participants,
      0,
    );

    if (
      !event ||
      !session ||
      !experience ||
      !resourceGroup ||
      noOfGuests === 0
    ) {
      return;
    }

    const { hasAddOns, hasCustomQuestions, askPartiticpantsInfo } =
      listingShouldGoThroughParticipantsPage(
        event,
        resourceGroup.id,
        experience.id,
      );

    if (hasAddOns && !(waitlistEnabled && isWaitlistSelected)) {
      addToCartAndRedirect({
        experience,
        resourceGroup,
        session,
        route: '/addons/{cartItemId}',
        state: CartItemState.PENDING,
        skipParticipantsName: !askPartiticpantsInfo,
      });
    } else if (askPartiticpantsInfo || hasCustomQuestions) {
      defaultCheckoutFlow({
        noOfGuests,
        session,
        resourceGroup,
        experience,
        priceTiersWithParticipants: priceTiersWithParticipants.map((pt) => ({
          ...pt,
          selectedNumber: pt.participants,
        })),
        skipParticipantsName: !askPartiticpantsInfo,
      });
    } else {
      directCheckoutFlow({
        listingId: event.id,
        session,
        experience,
        resourceGroup,
        priceTiersWithParticipants: priceTiersWithParticipants.map((pt) => ({
          ...pt,
          selectedNumber: pt.participants,
        })),
      });
    }
  }, [
    daySessions,
    event,
    priceTiersWithParticipants,
    selectedTime,
    waitlistEnabled,
    isWaitlistSelected,
    defaultCheckoutFlow,
    directCheckoutFlow,
    addToCartAndRedirect,
  ]);

  const startTimesList = useMemo(() => {
    return availableStartTimes.map((time) => ({
      value: time,
      label: moment(time, 'HH:mm:ss').format('LT'),
    }));
  }, [availableStartTimes]);

  const allTimes = useMemo(
    () => [...startTimesList, ...waitlistTimesList],
    [startTimesList, waitlistTimesList],
  );

  const isOneTime = allTimes.length === 1;

  useEffect(() => {
    if (!isOneTime) return;
    setSelectedTime(allTimes[0].value);
  }, [allTimes, isOneTime]);

  if (isMobileView && !isAccessCodeVerified) {
    return (
      <BottomDrawer {...props} heading={<AccessCodeHeading />}>
        <ResourceAccessCode
          errorMessage={accessCodeError}
          withHeading={false}
          isExclusive={isEventExclusive}
          onContinue={(code) =>
            withAccessCodeValidation(code, () => {
              setIsAccessCodeValidated(true);
            })
          }
        />
      </BottomDrawer>
    );
  }

  return (
    <EventPageWrapper {...props}>
      <EventTicketsWrapper>
        <TicketInfo>
          <TicketHeader selectedTime={selectedTime} />
          <TicketPricesSelector
            priceTiers={priceTiers}
            onChangePriceTiers={setPriceTierSelection}
            priceTierSelection={priceTierSelection}
          />
          <TicketTimesSelector
            selectedTime={selectedTime}
            selectedPriceTiersAmount={selectedPriceTiersAmount}
            availableTimes={startTimesList}
            waitlistTimes={waitlistTimesList}
            setSelectedTime={setSelectedTime}
          />
          <CheckoutButtonWrapper>
            <ResourceAccessCode
              errorMessage={accessCodeError}
              isExclusive={
                isMobileView ? !isAccessCodeVerified : isEventExclusive
              }
              renderActionButtons={(accessCode) => (
                <PrimaryButton
                  big
                  disabled={
                    !selectedTime ||
                    (startTimesList.length === 0 &&
                      waitlistTimesList.length === 0) ||
                    selectedPriceTiersAmount === 0
                  }
                  onClick={() =>
                    withAccessCodeValidation(accessCode, onContinueClick)
                  }
                >
                  {isWaitlistSelected ? t('joinWaitlist') : t('continue')}
                </PrimaryButton>
              )}
            />
          </CheckoutButtonWrapper>
        </TicketInfo>
        <TicketSummary priceTiers={priceTiersWithParticipants} />
      </EventTicketsWrapper>
    </EventPageWrapper>
  );
};

const EventPageWrapper = ({
  children,
  ...props
}: PropsWithChildren<BottomDrawerProps>) => {
  const isMobileView = useIsMobile();
  if (isMobileView) {
    return <BottomDrawer {...props}>{children}</BottomDrawer>;
  }
  return children;
};

const EventTicketsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TicketInfo = styled.div`
  width: calc(100% - 350px);
  display: flex;
  flex-direction: column;
  padding-right: 48px;

  & > * {
    border-bottom: 0.5px solid var(--way-colors-borderColor);
    padding: 42px 0px;
  }
  & > *:first-child {
    padding: 0px 0px 42px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;

    & > * {
      border-bottom: 0.5px solid var(--way-colors-borderColor);
      padding: 24px 0px;
    }
    & > *:first-child {
      padding: 0px 0px 24px;
    }
  }
`;

const CheckoutButtonWrapper = styled.div`
  border-bottom: none;
  padding-bottom: 100px;

  @media (max-width: 768px) {
    padding-bottom: 24px;
  }
`;

export default EventTicketsPage;
