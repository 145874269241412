import moment from 'moment';
import { ProductLine } from '@kouto/types';
import { useBrandId } from 'AppProvider';
import { DATE_FORMAT } from 'utils';
import { getListingsCoverPreview } from 'utils/listings';
import useGetListings from '../../hooks/useGetListings';

const allListingsAreLoadded = (listing: ReturnType<typeof useGetListings>) =>
  !listing.isLoadingInitialData;

const useProductList = () => {
  const brandId = useBrandId();
  const products: Partial<
    Record<
      ProductLine,
      {
        bg: string;
        count: number;
      }
    >
  > = {};

  const hostListings = useGetListings(
    brandId,
    {
      productLine: ProductLine.HOST,
      limit: 1,
      bypassFirstAvailableDate: 'true',
    },
    { ignoreSearchParam: true },
  );

  const activateListings = useGetListings(
    brandId,
    {
      productLine: ProductLine.ACTIVATE,
      limit: 1,
      from: moment().format(DATE_FORMAT),
      to: moment().add(1, 'year').format(DATE_FORMAT),
      bypassFirstAvailableDate: 'true',
    },
    { ignoreSearchParam: true },
  );

  const reserveListings = useGetListings(
    brandId,
    {
      productLine: ProductLine.RESERVE,
      limit: 1,
      bypassFirstAvailableDate: 'true',
    },
    { ignoreSearchParam: true },
  );

  if (allListingsAreLoadded(hostListings)) {
    const hostListingsCover = getListingsCoverPreview(hostListings.listings);
    if (hostListingsCover) {
      products[ProductLine.HOST] = {
        bg: hostListingsCover,
        count: hostListings.meta?.totalItems || 0,
      };
    }
  }

  if (allListingsAreLoadded(activateListings)) {
    const activateListingsCover = getListingsCoverPreview(
      activateListings.listings,
    );
    if (activateListingsCover) {
      products[ProductLine.ACTIVATE] = {
        bg: activateListingsCover,
        count: activateListings.meta?.totalItems || 0,
      };
    }
  }

  if (allListingsAreLoadded(reserveListings)) {
    const reserveListingsCover = getListingsCoverPreview(
      reserveListings.listings,
    );
    if (reserveListingsCover) {
      products[ProductLine.RESERVE] = {
        bg: reserveListingsCover,
        count: reserveListings.meta?.totalItems || 0,
      };
    }
  }

  const allProductsLoaded = [
    hostListings,
    activateListings,
    reserveListings,
  ].every(allListingsAreLoadded);

  if (!allProductsLoaded) {
    return { products: {}, isLoading: true };
  }

  return { products, loading: false };
};

export default useProductList;
