import styled from 'styled-components';

export const HostedByText = styled.p`
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  letter-spacing: normal !important;
  text-transform: none !important;
  margin: 10px 0;
  font-family: ${({ theme }) => theme.font.secondaryFont};
`;

export const PrimaryColorText = styled.div`
  display: flex;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  letter-spacing: normal !important;
  text-transform: none !important;
  font-family: ${({ theme }) => theme.font.secondaryFont};
  color: ${({ isPast }) =>
    isPast
      ? 'var(--way-colors-primaryColorShades-50)'
      : 'var(--way-colors-primaryColo-100)'};
  opacity: ${({ isPast }) => (isPast ? 0.5 : 1)};
`;

export const ExperienceTitle = styled.h3`
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 17px !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  margin: 0;
  font-family: ${({ theme }) => theme.font.primaryFont};
  color: var(--way-colors-primaryTextColor);
`;

export const ExperienceHeadline = styled.p`
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  letter-spacing: normal !important;
  text-transform: none !important;
  font-family: ${({ theme }) => theme.font.secondaryFont};
  color: var(--way-colors-primaryTextColor);
  padding: 10px 0;
  margin: 0;
`;

export const ExperiencePrice = styled.span`
  font-weight: 300 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  letter-spacing: normal !important;
  text-transform: none !important;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.font.secondaryFont};
  color: var(--way-colors-primaryTextColor);
`;

export const TitleColor = styled.div`
  color: ${({ isPast }) =>
    isPast
      ? 'var(--way-patelle-black-50)'
      : 'var(--way-colors-primaryTextColor)'};
`;
