import { ResourceParagraph } from 'components/theme/Typography/ResourceTypography';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CancellationPolicy } from 'types/listings';

type Props = {
  cancellationPolicy?: CancellationPolicy;
};

const CancellationPolicySummary: FC<Props> = ({ cancellationPolicy }) => {
  const { t: translate } = useTranslation();

  if (!cancellationPolicy || !cancellationPolicy.active) {
    return null;
  }

  return (
    <CancellationPolicyWrapper>
      <ResourceParagraph
        className="summary__cancellation-policy-title"
        color="var(--way-colors-secondaryTextColor)"
        fontSize="14px"
        fontWeight="700"
      >
        {translate('cancellationPolicy')}
      </ResourceParagraph>

      <ResourceParagraph
        className="summary__cancellation-policy"
        color="var(--way-colors-secondaryTextColor)"
        fontSize="14px"
        fontWeight="400"
        style={{ marginTop: 8 }}
      >
        {cancellationPolicy.notes}
      </ResourceParagraph>
    </CancellationPolicyWrapper>
  );
};

export default CancellationPolicySummary;

const CancellationPolicyWrapper = styled.div`
  padding: 24px;
  border: 0.5px solid var(--way-colors-borderColor);
  box-shadow: var(--way-design-boxShadow-s);
  height: auto;
  background: var(--way-palette-white-10);
  align-self: stretch;
`;
