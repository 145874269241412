import React from 'react';

const InfoCircleIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20 * scale}
      height={20 * scale}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M9.99999 1.66797C5.40499 1.66797 1.66666 5.4063 1.66666 10.0013C1.66666 14.5963 5.40499 18.3346 9.99999 18.3346C14.595 18.3346 18.3333 14.5963 18.3333 10.0013C18.3333 5.4063 14.595 1.66797 9.99999 1.66797ZM9.99999 16.668C6.32416 16.668 3.33332 13.6771 3.33332 10.0013C3.33332 6.32547 6.32416 3.33464 9.99999 3.33464C13.6758 3.33464 16.6667 6.32547 16.6667 10.0013C16.6667 13.6771 13.6758 16.668 9.99999 16.668Z"
        fillOpacity="0.6"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
      <path
        d="M9.16666 9.16536H10.8333V14.1654H9.16666V9.16536ZM9.16666 5.83203H10.8333V7.4987H9.16666V5.83203Z"
        fillOpacity="0.6"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
    </svg>
  );
};

export default InfoCircleIcon;
