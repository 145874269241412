import { useAppState } from 'AppProvider';
import { useEffect, useState } from 'react';
import { setExperienceAnalyticsData } from 'features/analytics/services/analytics.helpers';
import { useParams } from 'react-router-dom';
import type { IExperienceDetailState } from 'types/reducer-states/experienceState';

export function useExperienceAnalyticsData() {
  const params = useParams() as { experienceId?: string };
  const { experience }: { experience: IExperienceDetailState } = useAppState(
    (state: { experience: Record<string, string> }) => state.experience,
  );

  const [experienceDetail, setExperienceDetail] =
    useState<IExperienceDetailState | null>(null);

  useEffect(() => {
    if (experience?.loading) {
      return;
    }

    if (
      experience.id !== params.experienceId &&
      params.experienceId !== experience.slug
    ) {
      setExperienceDetail(null);
      return;
    }

    setExperienceDetail(experience);

    setExperienceAnalyticsData(experience);
  }, [experience, params.experienceId]);

  return {
    currentExperienceDetail: experienceDetail,
  };
}
