import useCartItemId from 'features/ShoppingCart/hooks/useCartItemId';
import { isSessionMultipleTiers } from 'utils';
import {
  IParticipantConfig,
  IParticipantResult,
  ITier,
} from 'features/ShoppingCart/types';
import useSelectedParticipants from 'selectors/participants';
import useCartItems from 'hooks/useCartItems';

interface ISelectedParticipants {
  name: string;
  price: number;
  selectedNumber: number;
}

const useParticipants = (
  filteredSelectedParticipants: ISelectedParticipants[] | [],
) => {
  const cartItemId = useCartItemId();

  const { selectedTierCustomers } = useSelectedParticipants();

  const { cartItems } = useCartItems();
  const defaultPriceTier = filteredSelectedParticipants[0];

  if (cartItemId) {
    const { participants } = cartItems[cartItemId] ?? {};
    if (Array.isArray(participants)) {
      const currentParticipants = participants.map((participant) => ({
        fullName: `${participant.firstName} ${participant.lastName}`,
        terms: participant.terms,
        priceTierName: participant.priceTierName,
        price: participant.price,
        customQuestionResponses: participant.customQuestionResponses,
        emailAddress: participant.emailAddress,
        phoneNumber: participant.phoneNumber,
      }));
      return currentParticipants as IParticipantConfig['participants'];
    }
  }

  if (!isSessionMultipleTiers(selectedTierCustomers)) {
    return [
      {
        count: defaultPriceTier?.selectedNumber || 1,
        priceTier: defaultPriceTier?.name,
        price: defaultPriceTier?.price,
      },
    ] as IParticipantResult[];
  }

  if (selectedTierCustomers.length >= 1) {
    return filteredSelectedParticipants.map((tier: ITier) => ({
      count: tier.selectedNumber,
      priceTier: tier.name,
      price: tier.price,
    })) as IParticipantResult[];
  }

  return [
    {
      count: 1,
      priceTier: defaultPriceTier?.name,
      price: defaultPriceTier?.price,
    },
  ] as IParticipantResult[];
};

export default useParticipants;
