import { useIsMobile } from 'WindowDimensionProvider';
import { useLandingPageRouteParams } from 'hooks/useLandingPageRouteParams';
import {
  DEFAULT_DESKTOP_GRID,
  DEFAULT_MOBILE_GRID,
  DEFAULT_RESULTS_GRID,
} from '../consts';

export const useGridConfig = () => {
  const isMobile = useIsMobile();
  const { isResults } = useLandingPageRouteParams();

  if (isMobile) return DEFAULT_MOBILE_GRID;

  return isResults ? DEFAULT_RESULTS_GRID : DEFAULT_DESKTOP_GRID;
};
