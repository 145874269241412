import { getEmbedConfig } from 'features/EmbedConfig';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { getHistory, getRouterFromType, getRouterOptions } from './utils';

// TODO when La Reserve is moved to using brand config
// we can remove the handling of this edge case
const BROWSER_HISTORY_BRANDS = [
  // Preview brand called Browser History brand on the preview seed
  'a1107748-2534-4511-8916-b1e98f1cae1e',
  // Production brand for La Reserve
  'fe390f1e-6184-4607-a3a3-0c2fc992966e',
];

export const initializeRouter = (brandId: string) => {
  if (BROWSER_HISTORY_BRANDS.includes(brandId)) {
    const history = createBrowserHistory({ basename: '/experiences' });

    return {
      Router: BrowserRouter,
      routerProps: { basename: '/experiences', history },
    };
  }

  const embedConfig = getEmbedConfig();

  const Router = getRouterFromType(embedConfig.routerType);
  const history = getHistory(embedConfig);
  const routerProps = { ...getRouterOptions(embedConfig), history };

  return {
    Router,
    routerProps,
  };
};
