export const getLSItem = (key: string) => {
  if (!key) return null;

  const dataString = localStorage.getItem(key);
  return dataString ? JSON.parse(dataString) : {};
};

export const setLSItem = (key: string, data: object | string | number) => {
  if (!key || data === null || data === undefined) {
    return;
  }
  localStorage.setItem(key, JSON.stringify(data));
};

export const clearLSItem = (key: string) => {
  localStorage.removeItem(key);
};
