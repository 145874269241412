import React, { FC } from 'react';
import { useIsMobile } from 'WindowDimensionProvider';
import EventCoverMediaDesktop from './EventCoverMediaDesktop';
import EventCoverMediaMobile from './EventCoverMediaMobile';

interface Props {
  scrollAnimationDelay?: number;
}

const EventCoverMedia: FC<Props> = ({ scrollAnimationDelay = 0 }) => {
  const isMobile = useIsMobile();
  if (isMobile) {
    return (
      <EventCoverMediaMobile scrollAnimationDelay={scrollAnimationDelay} />
    );
  }
  return <EventCoverMediaDesktop scrollAnimationDelay={scrollAnimationDelay} />;
};

export default EventCoverMedia;
