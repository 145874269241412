import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const getSlugFromSocialUrl = () => {
  try {
    const query = new URLSearchParams(window.location.search);
    return query?.get('fbclid')?.split('#!/')?.[1] ?? null;
  } catch {
    return null;
  }
};

export const useHandleSocialUrl = () => {
  const history = useHistory();
  useEffect(() => {
    const slug = getSlugFromSocialUrl();

    if (slug) {
      history.push(`/${slug}`);
    }
  }, []);
};
