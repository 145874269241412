import { useEffect } from 'react';
import * as R from 'ramda';
import { analyticsManager } from 'features/analytics';
import { ANALYTICS_PROPERTY } from '@kouto/types';
import useSelectedParticipants from 'selectors/participants';
import useCartItems from 'hooks/useCartItems';
import useParticipants from './useParticipants';

const useTrackCartParticipants = (isCheckoutPage = false) => {
  const { cartItems } = useCartItems();
  const { filteredSelectedCustomers } = useSelectedParticipants();
  const selectedParticipants = useParticipants(filteredSelectedCustomers);

  useEffect(() => {
    if (!selectedParticipants?.length || R.isEmpty(cartItems)) {
      return;
    }
    if (selectedParticipants) {
      const currentCartParticipants = Object.values(cartItems)?.map((p) =>
        p.participants.length.toString(),
      );

      if (isCheckoutPage) {
        analyticsManager.setAppData({
          [ANALYTICS_PROPERTY.CartParticipants]:
            currentCartParticipants.join(';'),
        });
      } else {
        analyticsManager.setAppData({
          [ANALYTICS_PROPERTY.CartParticipants]: [
            ...currentCartParticipants,
            selectedParticipants
              ?.map((participant) => {
                return 'count' in participant ? participant.count : 1;
              })
              .reduce((acc, par) => acc + par, 0),
          ].join(';'),
        });
      }
    }
  }, [selectedParticipants, cartItems]);
};

export default useTrackCartParticipants;
