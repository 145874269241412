import {
  ICustomQuestionResponseAction,
  ICustomQuestionResponsesState,
  QUESTION_SCOPES,
  REMOVE_PARTICIPANT_CUSTOM_QUESTION_RESPONSE,
  UPDATE_PARTICIPANT_CUSTOM_QUESTION_RESPONSE,
  UPDATE_PURCHASER_CUSTOM_QUESTION_RESPONSE,
} from 'types/custom-questions';
import initialState from 'store/initial-state';

export default function customQuestionReducer(
  // @ts-ignore
  state: ICustomQuestionResponsesState = initialState.customQuestionResponses,
  action: ICustomQuestionResponseAction,
): ICustomQuestionResponsesState {
  switch (action.type) {
    case UPDATE_PARTICIPANT_CUSTOM_QUESTION_RESPONSE: {
      const currentResponses = state[QUESTION_SCOPES.PARTICIPANT] || [];
      const { participantIndex, questionId, response } = action.payload;
      const responseDoesExists = currentResponses.find(
        (r) =>
          r.participantIndex === participantIndex &&
          r.questionId === questionId,
      );

      const newResponses = responseDoesExists
        ? currentResponses.map((r) =>
            r.participantIndex === participantIndex &&
            r.questionId === questionId
              ? {
                  ...r,
                  answer: response,
                }
              : r,
          )
        : [
            ...currentResponses,
            {
              participantIndex,
              questionId,
              answer: response,
            },
          ];
      return {
        ...state,
        [QUESTION_SCOPES.PARTICIPANT]: newResponses,
      };
    }

    case REMOVE_PARTICIPANT_CUSTOM_QUESTION_RESPONSE: {
      const currentResponses = state[QUESTION_SCOPES.PARTICIPANT] || [];
      const { participantIndex } = action.payload;

      const newResponses = currentResponses.filter(
        (r) => r.participantIndex !== participantIndex,
      );
      return {
        ...state,
        [QUESTION_SCOPES.PARTICIPANT]: newResponses,
      };
    }

    case UPDATE_PURCHASER_CUSTOM_QUESTION_RESPONSE: {
      const currentResponses = state[QUESTION_SCOPES.PURCHASER] || [];
      const { questionId, response } = action.payload;
      const responseDoesExists = currentResponses.find(
        (r) => r.questionId === questionId,
      );

      const newResponses = responseDoesExists
        ? currentResponses.map((r) =>
            r.questionId === questionId
              ? {
                  ...r,
                  answer: response,
                }
              : r,
          )
        : [
            ...currentResponses,
            {
              questionId,
              answer: response,
            },
          ];

      return { ...state, [QUESTION_SCOPES.PURCHASER]: newResponses };
    }

    default:
      return state;
  }
}
