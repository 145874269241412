export default {
  // these should be unique as the values are used as keys
  profileImageOfHost: 'Profile image of host {{hostName}}',
  hostedBy: 'Hosted by',
  experiences: 'experiences',
  experience: 'experience',
  clear: 'Clear',
  cancel: 'Cancel',
  apply: 'Apply',
  more: 'More',
  experiencePhotoOfListing:
    'Experience photo {{photoNumber}} of listing {{listingTitle}}',
  noExperiencesFound: 'No experiences found',
  noExperiencesFoundFor: 'No experiences found for',
  discoverExperiences: 'Discover experiences',
  clearFilters: 'Clear filters',
  buyTickets: 'Buy tickets',
  reserve: 'Reserve',
  activateProductCardTitle: 'Events',
  reserveProductCardTitle: 'Activities',
  hostProductCardTitle: 'Local experiences',
  mainLandingPageTitle: 'All experiences',
  activateLandingPageTitle: 'Events',
  reserveLandingPageTitle: 'Activities & amenities',
  hostLandingPageTitle: 'Local experiences',
  exclusive: 'Exclusive',
  filterExperiences: 'Filter experiences',
};
