export default {
  chooseExpDate: 'Scegli una data',
  filterExpDate: 'Filtra per data',
  experienceType: 'Tipo',
  filterExperienceType: 'Filtra per tipo',
  experienceVibe: 'Vibe',
  filterExperienceVibe: 'Filtra per vibe',
  search: 'Cerca',
  viewCalendar: 'Calendario',
  back: 'Indietro',
  previous: 'Precedente',
  next: 'Prossimo',
  noExperiences: 'Nessuna esperienza',
  cancelChanges: 'Cancella modifiche',
  saveChanges: 'Salva modifiche',
  removeFromReservation: 'Rimuovi dalla prenotazione',
  addToCart: 'Aggiungi al carrello',
  proceedToCheckout: 'Procedi al checkout',
};
