import {
  ANALYTICS_EVENT,
  IAnalyticsConfiguration,
  type IAnalyticsIntegrationResponse,
} from '@kouto/types/src/api/integration/analytics';
import {
  AnalyticsClient,
  type IAnalyticsDataPayload,
} from 'features/analytics/types/analytics';
import { debug } from 'utils/debug';
import { IntegrationName } from '@kouto/types';
import {
  getAnalyticsEventParams,
  getResolvedPropertyMapping,
} from 'features/analytics/services/analyticsPropertyMapper';

type GtmConfig = Partial<Omit<IAnalyticsConfiguration, 'initializeSdk'>> & {
  defaultTracking?: boolean;
};

export class GTMClient extends AnalyticsClient {
  private readonly config: GtmConfig;

  readonly provider = IntegrationName.GTM_ANALYTICS;

  constructor(response: IAnalyticsIntegrationResponse) {
    super();
    this.config = response.config ?? {};
  }

  async init() {
    await this.runWhenAvailable();

    this.ready = true;

    this.events.forEach((event) =>
      this.sendAnalyticsEvent(event.eventName, event.payload),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  hasEvent(eventName: ANALYTICS_EVENT) {
    if (this.config?.defaultTracking !== false) {
      return true;
    }
    // Fallback to enabled unless the feature is disabled
    const isEnabled = !!this.config?.events?.[eventName]?.enabled;
    if (!isEnabled) {
      debug(
        'log',
        `Event "${eventName}" disabled in ${this.provider} configurations`,
      );
    }
    return isEnabled;
  }

  // eslint-disable-next-line class-methods-use-this
  isAvailable(): boolean {
    return !!window.dataLayer;
  }

  private sendAnalyticsEvent(
    eventName: ANALYTICS_EVENT,
    payload: IAnalyticsDataPayload,
  ) {
    if (!this.hasEvent(eventName)) {
      return;
    }

    const finalPayload = { ...payload };
    delete finalPayload.products;
    const isView = eventName.startsWith('view_');

    const resolvedProperties =
      this.config?.defaultTracking !== false
        ? finalPayload
        : getResolvedPropertyMapping(
            getAnalyticsEventParams(
              this.config as Required<GtmConfig>,
              eventName,
            ),
            finalPayload,
          );

    const eventProperties = {
      event: eventName,
      ...resolvedProperties,
    };

    window.dataLayer?.push(eventProperties);

    if (isView) {
      debug('log', this.provider, {
        type: 'View Event triggered',
        eventProperties,
        eventConfig: this.config,
      });
      return;
    }

    debug('log', this.provider, {
      type: 'Link Event triggered',
      eventProperties,
      eventConfig: this.config,
    });
  }

  sendEvent(eventName: ANALYTICS_EVENT, payload: IAnalyticsDataPayload): void {
    if (this.config && !this.hasEvent(eventName)) {
      return;
    }
    if (!this.ready) {
      this.events.push({
        eventName,
        payload,
      });
      return;
    }

    this.sendAnalyticsEvent(eventName, payload);
  }
}

declare global {
  interface Window {
    dataLayer?: object[];
  }
}
