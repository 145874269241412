import get from 'lodash/get';
import i18n from 'i18next';
import { isPhoneNumberValid } from 'utils';
import { getResponseJson } from 'actions/helpers';
import fetchApi from 'utils/fetch';
import { STATUS_SUCCESS, STATUS_FAILURE } from 'types/app';

export const validateEmpty = (message = 'Required') => {
  return {
    required: {
      value: true,
      message,
    },
  };
};

export const validateEmailAddress = (message = 'Invalid Email') => ({
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message,
  },
});

export const validateWhitespace = (message = 'Required') => {
  return {
    pattern: {
      value: /^.+\s.+/i,
      message,
    },
  };
};

export const validatePhoneNumber = (message = 'invalidPhoneNumber') => ({
  validate: (value: string) => {
    if (!value) return true;
    return isPhoneNumberValid(value) || (i18n.t(message) as string);
  },
});

const formatResponse = (status: string, message: string) => {
  return {
    status,
    message,
  };
};

export const validateAsyncPhoneNumber = async (value: string) => {
  try {
    await fetchApi.post(`v1/phone-number/validate`, {
      json: { phoneNumber: value },
    });
    return formatResponse(STATUS_SUCCESS, 'Success');
  } catch (e) {
    const error = getResponseJson(e);
    if (error && error.status === 422) {
      const invalidPhoneError = get(
        error,
        'validationErrors.phoneNumber.isMobilePhone',
      );
      if (invalidPhoneError) {
        return formatResponse(STATUS_FAILURE, invalidPhoneError);
      }
    }
    return formatResponse(
      STATUS_FAILURE,
      'Oops, we are having trouble validating. We are working on fixing it',
    );
  }
};
