export default {
  date: 'Fecha',
  chooseExpDate: 'Elige una fecha',
  filterExpDate: 'Filtrar por fecha',
  experienceType: 'Tipo',
  filterExperienceType: 'Filtrar por tipo',
  experienceVibe: 'Vibe',
  filterExperienceVibe: 'Filtrar por vibe',
  search: 'Buscar',
  viewCalendar: 'Ver Calendario Completo',
  back: 'Atrás',
  previous: 'Anterior',
  next: 'Siguiente',
  noExperiences: 'Sin Experiencias',
};
