import * as types from 'theme/themeType';
import initialState from 'store/initial-state';
import { THEMES } from 'types/brand';

export default function themeReducer(state = initialState.theme, action) {
  switch (action.type) {
    case types.SET_THEME_COLOR:
      return {
        ...state,
        colors: { ...state.colors, ...action.payload.colors },
      };
    case types.SET_THEME_FONT:
      return {
        ...state,
        font: { ...state.font, ...action.payload.font },
        isBaseTheme: false,
      };
    case types.SET_THEME_MODE:
      return {
        ...state,
        mode: action.payload.mode,
        design: {
          ...state.design,
          ...(action.payload.mode === THEMES.DARK
            ? state.darkDesign
            : state.design),
        },
      };
    default:
      return state;
  }
}
