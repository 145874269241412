export const RESET_EXPERIENCE = '@experience/reset-current-experience';

export const FETCH_REQUEST = '@experience/fetch-request';
export const FETCH_SUCCESS = '@experience/fetch-success';
export const FETCH_FAILURE = '@experience/fetch-failure';

export const SEARCH_REQUEST = '@experience/search-request';
export const SEARCH_SUCCESS = '@experience/search-success';
export const SEARCH_FAILURE = '@experience/search-failure';

export const FEATURED_REQUEST = '@experience/featured-request';
export const FEATURED_SUCCESS = '@experience/featured-success';
export const FEATURED_FAILURE = '@experience/featured-failure';

export const FETCH_AVAILABILITY_REQUEST =
  '@experience/fetch-availability-request';
export const FETCH_AVAILABILITY_SUCCESS =
  '@experience/fetch-availability-success';
export const FETCH_AVAILABILITY_FAILURE =
  '@experience/fetch-availability-failure';

export const FETCH_SESSIONS_REQUEST = '@experience/fetch-sessions-request';
export const FETCH_SESSIONS_SUCCESS = '@experience/fetch-sessions-success';
export const FETCH_SESSIONS_FAILURE = '@experience/fetch-sessions-failure';

export const FETCH_EXPERIENCE_SETTINGS_REQUEST =
  '@experience/fetch-experience-settings-request';
export const FETCH_EXPERIENCE_SETTINGS_SUCCESS =
  '@experience/fetch-experience-settings-success';
export const FETCH_EXPERIENCE_SETTINGS_FAILURE =
  '@experience/fetch-experience-settings-failure';

export const RESET_FIRST_AVAILABLE_DATE =
  '@experience/reset-first-available-date';
export const FETCH_FIRST_AVAILABLE_DATE_REQUEST =
  '@experience/fetch-first-available-date-request';
export const FETCH_FIRST_AVAILABLE_DATE_SUCCESS =
  '@experience/fetch-first-available-date-success';
export const FETCH_FIRST_AVAILABLE_DATE_FAILURE =
  '@experience/fetch-first-available-date-failure';

export const FETCH_SESSION_PRICE_TIERS_REQUEST =
  '@experience/fetch-session-price-tiers-request';
export const FETCH_SESSION_PRICE_TIERS_SUCCESS =
  '@experience/fetch-session-price-tiers-success';
export const FETCH_SESSION_PRICE_TIERS_FAILURE =
  '@experience/fetch-session-price-tiers-failure';

export const BOOKING_AVAILABILITY_MODE_NON_RESTRICTED = 'non-restricted';
export const BOOKING_AVAILABILITY_MODE_PRIVATE = 'private';
export const BOOKING_AVAILABILITY_MODE_GROUP = 'group';

export const TYPE_ONE_TIME = 'one-time';
export const TYPE_RECURRING = 'recurring';

export const PAYMENTS_TYPE_CREDIT_CHARGE = 'credit-card';
export const PAYMENTS_TYPE_ROOM_CHARGE = 'room-charge';
export const PAYMENTS_TYPE_MEMBER_CHARGE = 'member-number';
export const PAYMENTS_TYPE_KICC = 'kicc';

export const CREDIT_CARD_CHARGE = 'creditCard';
export const ROOM_CHARGE = 'roomCharge';
export const MEMBER_CHARGE = 'memberCharge';

export const EXTERNAL_CTA_LINK = 'url';
export const EXTERNAL_CTA_EMAIL = 'email';
export const EXTERNAL_CTA_PHONE = 'phone';
