import styled, { css } from 'styled-components';

import { Wrapper } from './Wrapper';

export const SearchAllWrapperStart = styled(Wrapper)<{
  noPadding?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px auto !important;
  margin-bottom: 56px;
  ${({ noPadding }) => !!noPadding && NoPadding}
`;

const NoPadding = css`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;
