import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Rating from 'components/Rating';
import moment from 'moment';
import ReadMoreButton from 'components/common/ReadMoreButton';
import { getShortDescription } from 'utils';

const RenderReviewItem = ({ review }) => {
  const { t: translateText } = useTranslation();
  const [isMoreShown, setIsMoreShown] = useState(false);

  return (
    <ReviewItem key={review.id}>
      <ReviewItemHead>
        <ReviewItemName>{review.reviewer.firstName}</ReviewItemName>
        <ReviewItemDate>
          {moment(review.reviewedAt).format('MMMM, YYYY')}
        </ReviewItemDate>
      </ReviewItemHead>

      <ReviewItemRating>
        <Rating originalRating={review.rating} />
      </ReviewItemRating>

      <ReviewDetail>
        {isMoreShown ? review.text : getShortDescription(review.text, 200)}
      </ReviewDetail>

      {isMoreShown && (
        <DateExperience>
          {translateText('dateOfExperience')}:{' '}
          {moment(review.experiencedDate).format('MMMM D, YYYY')}
        </DateExperience>
      )}

      <ReadMoreButton onClick={() => setIsMoreShown(!isMoreShown)}>
        {translateText(isMoreShown ? 'readLess' : 'readMore')}
      </ReadMoreButton>
    </ReviewItem>
  );
};

RenderReviewItem.propTypes = {
  review: PropTypes.shape({
    id: PropTypes.string.isRequired,
    reviewer: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
    }).isRequired,
    reviewedAt: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    experiencedDate: PropTypes.string.isRequired,
  }).isRequired,
};

const ReviewItem = styled.div`
  padding-bottom: 14px;
  border-bottom: 0.5px solid var(--way-colors-borderColor);
  margin-bottom: 32px;
`;

const ReviewItemRating = styled.div`
  margin-bottom: 10px;
`;

const ReviewItemHead = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 6px;
`;

const ReviewItemName = styled.div``;

const ReviewItemDate = styled.div``;

const ReviewDetail = styled.div`
  margin-bottom: 17px;
  font-size: 15px;
  line-height: 19px;
`;

const DateExperience = styled.div`
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 17px;
`;

export default RenderReviewItem;
