import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useFormContext } from 'hook-form';
import { AVAILABLE_BRAND_FEATURES, StripePaymentMethods } from '@kouto/types';
import {
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import {
  useElements,
  useStripe,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { PaymentError } from 'features/ShoppingCart/components/PaymentError';
import * as Styled from 'features/ShoppingCart/utils/styles';
import { CardStripeWrapper } from 'features/PaymentMethods/Styles';
import {
  ConfirmCardPaymentCallback,
  SubmitCardPaymentCallback,
} from 'features/ShoppingCart/types';
import { PaymentContext } from 'features/ShoppingCart/contexts/PaymentContext';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import { useAppState } from 'AppProvider';
import { ReducerState } from 'types/reducer';

type Props = {
  hasMultiplePaymentMethods?: boolean;
};

const CreditCardPayment: FC<Props> = ({ hasMultiplePaymentMethods }) => {
  const stripe = useStripe();
  const elements = useElements();

  const {
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();

  const email = watch('purchaser.emailAddress');

  const settings: ReducerState['brand']['settings'] = useAppState(
    (state: ReducerState) => state.brand.settings,
  );
  const { paymentMethods } = settings;

  const {
    setConfirmCardPaymentCallback,
    setSubmitCardPaymentCallback,
    setPaymentIntentInfoCallback,
  } = useContext(PaymentContext);

  const submitCardPayment = useCallback<SubmitCardPaymentCallback>(() => {
    if (!elements) {
      return null;
    }

    return elements.submit();
  }, [elements]);

  const confirmCardPayment = useCallback<ConfirmCardPaymentCallback>(
    (paymentIntentSecret: string) => {
      if (!stripe || !elements || !paymentIntentSecret) {
        return null;
      }

      const redirectUrl = new URL(window.location.href);
      redirectUrl.searchParams.set('email', email);

      return stripe.confirmPayment({
        elements,
        clientSecret: paymentIntentSecret,
        redirect: 'if_required',
        confirmParams: {
          return_url: redirectUrl.toString(),
        },
      });
    },
    [stripe, elements, email],
  );

  useEffect(() => {
    if (setConfirmCardPaymentCallback && setSubmitCardPaymentCallback) {
      // @ts-expect-error - we need to save `confirmCardPayment` as it is, without executing it here, but typescript messes up with the types
      setConfirmCardPaymentCallback(() => confirmCardPayment);
      // @ts-expect-error - same thing here
      setSubmitCardPaymentCallback(() => submitCardPayment);
    }
  }, [
    setConfirmCardPaymentCallback,
    setSubmitCardPaymentCallback,
    confirmCardPayment,
    submitCardPayment,
  ]);

  useEffect(() => {
    if (!stripe?.retrievePaymentIntent) return;
    setPaymentIntentInfoCallback?.(() => stripe.retrievePaymentIntent);
  }, [setPaymentIntentInfoCallback, stripe?.retrievePaymentIntent]);

  const onCardNumberChange = (e: StripePaymentElementChangeEvent) => {
    setValue('card', e.complete);
    setValue('stripeSelectedMethod', e.value.type);
  };

  const getValidationMessage = () => {
    return errors.card?.message || null;
  };

  const canUseWallets = useBrandToggleFeature(
    AVAILABLE_BRAND_FEATURES.ENABLE_WALLETS_PAYMENT,
  );

  const applePayEnabled =
    canUseWallets && paymentMethods?.[StripePaymentMethods.APPLE_PAY] !== false;
  const googlePayEnabled =
    canUseWallets &&
    paymentMethods?.[StripePaymentMethods.GOOGLE_PAY] !== false;

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
    },
    wallets: {
      applePay: applePayEnabled ? 'auto' : 'never',
      googlePay: googlePayEnabled ? 'auto' : 'never',
    },
  };

  return (
    <>
      <Styled.AccordionContent>
        <CardStripeWrapper withPadding={!!hasMultiplePaymentMethods}>
          <PaymentElement
            onChange={onCardNumberChange}
            options={paymentElementOptions}
          />
        </CardStripeWrapper>
      </Styled.AccordionContent>
      <PaymentError error={getValidationMessage() as string} />
    </>
  );
};

export default CreditCardPayment;
