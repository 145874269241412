import { useMemo } from 'react';

export const useSelectStyles = () => {
  const selectStyles = useMemo(
    () => ({
      control: (styles) => ({
        ...styles,
        backgroundColor: 'transparent',
        minHeight: 2,
        boxShadow: 'none',
        border: 'none',
        fontSize: '14px',
      }),
      option: (styles) => ({
        ...styles,
        color: 'black',
        fontSize: '14px',
      }),
      input: (styles) => ({ ...styles, height: 25, minHeight: 25 }),
      placeholder: (styles) => ({
        ...styles,
        height: 27,
        minHeight: 25,
        lineHeight: '27px',
        color: 'gray',
      }),
      singleValue: (styles) => ({ ...styles }),
      indicatorsContainer: (styles) => ({ ...styles, display: 'none' }),
      container: (styles) => ({ ...styles, padding: 0 }),
      multiValue: (styles) => {
        return {
          ...styles,
          padding: 0,
          '&:hover': {
            cursor: 'pointer',
          },
        };
      },
      valueContainer: (styles) => ({
        ...styles,
        minHeight: 2,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: '12px',
        paddingRight: '12px',
      }),
    }),
    [],
  );
  return selectStyles;
};
