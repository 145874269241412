import React, { FC } from 'react';
import { SessionTimeOption } from 'features/Reserve/hooks/useSessionTimeOptions';
import { ResponsiveFlex } from 'components/common/styled/common-styled';
import { SecondaryButton } from 'components/theme/Button/Button';

interface Props {
  sessions: SessionTimeOption[];
  selectedSession: SessionTimeOption | undefined;
  onChange: (session: SessionTimeOption) => void;
}

const NonMappedSessionSelector: FC<Props> = ({
  sessions,
  onChange,
  selectedSession,
}) => {
  return (
    <ResponsiveFlex
      direction="row"
      justifyContent="flex-start"
      gap={12}
      alignItem="center"
      flexWrap="wrap"
    >
      {sessions.map((session) => {
        const isActive = selectedSession?.value === session.value;
        return (
          <SecondaryButton
            key={session.value}
            data-testid={`session-${session.label}`}
            className={`session-button-active-${isActive}`}
            selected={isActive}
            onClick={() => {
              if (!isActive) {
                onChange(session);
              }
            }}
          >
            {session.label}
          </SecondaryButton>
        );
      })}
    </ResponsiveFlex>
  );
};

export default NonMappedSessionSelector;
