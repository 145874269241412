export default {
  chooseExpDate: 'Escolher uma data',
  filterExpDate: 'Filtrar por data',
  experienceType: 'Tipo',
  filterExperienceType: 'Filtrar por tipo',
  experienceVibe: 'Vibe',
  filterExperienceVibe: 'Filtrar por vibe',
  search: 'Pesquisar',
  viewCalendar: 'Calendário',
  back: 'Voltar',
  previous: 'Anterior',
  next: 'Próximo',
  noExperiences: 'Nenhuma experiência',
  cancelChanges: 'Cancelar alterações',
  saveChanges: 'Guardar alterações',
  removeFromReservation: 'Retirar da reserva',
  addToCart: 'Adicionar ao carrinho',
  proceedToCheckout: 'Proceder ao checkout',
};
