export const SET_BOOKING = '@booking/set-booking';
export const RESET_BOOKING = '@booking/reset-booking';

export const CREATE_BOOKING_REQUEST = '@booking/create-booking-request';
export const CREATE_BOOKING_SUCCESS = '@booking/create-booking-success';
export const CREATE_BOOKING_FAILURE = '@booking/create-booking-failure';

export const GET_BOOKING_CONTEXT_REQUEST =
  '@booking/get-booking-context-request';
export const GET_BOOKING_CONTEXT_SUCCESS =
  '@booking/get-booking-context-success';
export const GET_BOOKING_CONTEXT_FAILURE =
  '@booking/get-booking-context-failure';

export const CONFIRM_KICC_APPROVAL_REQUEST =
  '@booking/get-booking-context-request';
export const CONFIRM_KICC_APPROVAL_SUCCESS = 'confirmed';
export const CONFIRM_KICC_APPROVAL_FAILURE = 'failed';
export const CONFIRM_KICC_APPROVAL_PROCESSING = 'processing';

export const CREATE_BULK_BOOKING_REQUEST =
  '@booking/create-bulk-booking-request';
export const CREATE_BULK_BOOKING_SUCCESS =
  '@booking/create-bulk-booking-success';
export const CREATE_BULK_BOOKING_FAILURE =
  '@booking/create-bulk-booking-failure';
export const RESET_CART_ERROR = '@booking/reset-bulk-booking-cart-error';

export const CREATE_PMS_VALIDATION_REQUEST =
  '@booking/create-pms-validation-request';
export const CREATE_PMS_VALIDATION_SUCCESS =
  '@booking/create-pms-validation-success';
export const CREATE_PMS_VALIDATION_FAILURE =
  '@booking/create-pms-validation-failure';

export const BOOKING_MODES = {
  PRIVATE: 'private',
  SHARED: 'shared',
};

export const GUEST_TYPES = {
  NON_HOTEL_GUEST: 'nonHotelGuest',
  ON_PROPERTY_GUEST: 'onPropertyGuest',
  PRE_ARRIVAL_GUEST: 'preArrivalGuest',
};

export const INPUT_NAMES = {
  ROOM_NUMBER: 'roomNumber',
  NUMBER: 'number',
  LAST_NAME: 'lastName',
  RESERVATION_CODE: 'reservationCode',
};
