export default {
  unavailable: 'Indisponible',
  person: 'personne',
  people: 'personnes',
  reserveNow: 'Réserver maintenant',
  selectTime: "Sélectionner l'heure",
  select: 'Sélectionner',
  reserve: 'Réserver',
  time: 'Heure',
  tier: 'Niveau',
  questions: 'Questions',
  addToCart: 'Ajouter au panier',
  checkout: 'Paiement',
  total: 'Total',
  availability: 'Disponibilité',
  whatsIncluded: 'Ce qui est inclus',
  showMore: 'Voir plus',
  showLess: 'Voir moins',
  session: 'Session',
  viewDetails: 'Voir les détails',
  findAvailability: 'Trouver la disponibilité',
  loading: 'Chargement',
  capacity: 'Capacité',
  noAvailableSessionsOnThisDate: 'Aucune session disponible à cette date',
  noGroupAvailableOnThisDateAndTime:
    'Aucun(e) {{groupTitle}} disponible à cette date et heure',
  noCollectionFound: 'Aucune collection trouvée!',
  noResourceGroupFound: 'Aucun groupe de ressources trouvé!',
  noData: 'Pas de données!',
  continue: 'Continuer',
};
