import TRANSLATION_DATES_ES from './weekdays/translation';
import TRANSLATION_BOOKING_ES from './booking/translation';
import TRANSLATION_FORM_ES from './form/translation';
import TRANSLATION_EXPERIENCES_ES from './experiences/translation';
import TRANSLATION_LANDING_PAGES_ES from './landingPages/translation';
import TRANSLATION_EVENTS_ES from './events/translation';
import TRANSLATION_RESERVE_ES from './reserve/translation';
import TRANSLATION_ACCESSIBILITY_ES from './accessibility/translation';

export default {
  ...TRANSLATION_DATES_ES,
  ...TRANSLATION_BOOKING_ES,
  ...TRANSLATION_FORM_ES,
  ...TRANSLATION_EXPERIENCES_ES,
  ...TRANSLATION_LANDING_PAGES_ES,
  ...TRANSLATION_EVENTS_ES,
  ...TRANSLATION_RESERVE_ES,
  ...TRANSLATION_ACCESSIBILITY_ES,
};
