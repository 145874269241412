import React, {
  ReactNode,
  useCallback,
  useEffect,
  useState,
  MouseEvent,
} from 'react';
import * as RadixPopover from '@radix-ui/react-popover';
import * as SC from 'components/Modal/Modal.styles';
import styled from 'styled-components';

interface Props extends Omit<RadixPopover.PopoverProps, 'open'> {
  trigger: ReactNode;
  asChild?: boolean;
  toggleOnHover?: boolean;
  hoverDelay?: number;
  showCloseIcon?: boolean;
  portalled?: boolean;
  alignOffset?: number;
  sideOffset?: number;
}

export const Popover: React.FC<Props> = ({
  asChild,
  trigger,
  toggleOnHover,
  hoverDelay,
  children,
  showCloseIcon,
  sideOffset,
  alignOffset,
  portalled,
  ...props
}) => {
  const [open, setOpen] = useState(props.defaultOpen ?? false);

  let hoverTimeout: NodeJS.Timeout;

  const handleTriggerClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setOpen(!open);
    },
    [open],
  );

  // Cleanup the timeout on component unmount
  useEffect(() => {
    return () => {
      clearTimeout(hoverTimeout);
    };
  }, []);

  const mouseEventHandler = (isOver: boolean) => {
    if (!toggleOnHover) {
      return;
    }

    clearTimeout(hoverTimeout);

    hoverTimeout = setTimeout(() => {
      setOpen(isOver);
    }, hoverDelay || 500);
  };

  const content = (
    <RadixPopover.Content
      onMouseEnter={() => mouseEventHandler(true)}
      onMouseLeave={() => mouseEventHandler(false)}
      sideOffset={sideOffset}
      alignOffset={alignOffset}
    >
      <>
        {children}
        {showCloseIcon ? (
          <CloseIcon className="PopoverClose" aria-label="Close">
            <SC.Close />
          </CloseIcon>
        ) : null}
      </>
    </RadixPopover.Content>
  );

  return (
    <Container>
      <RadixPopover.Root {...props} open={open} onOpenChange={setOpen}>
        <RadixPopover.Trigger
          asChild={asChild}
          onClick={handleTriggerClick}
          onMouseEnter={() => mouseEventHandler(true)}
          onMouseLeave={() => mouseEventHandler(false)}
        >
          {asChild ? (
            <div
              role="link"
              tabIndex={0}
              onKeyUp={() => {
                //
              }}
              onClick={handleTriggerClick}
              onMouseEnter={() => mouseEventHandler(true)}
              onMouseLeave={() => mouseEventHandler(false)}
            >
              {trigger}
            </div>
          ) : (
            trigger
          )}
        </RadixPopover.Trigger>

        {portalled ? (
          <RadixPopover.Portal
            container={
              document.getElementById('kouto-embed-root') as HTMLDivElement
            }
          >
            {content}
          </RadixPopover.Portal>
        ) : (
          content
        )}
      </RadixPopover.Root>
    </Container>
  );
};

const CloseIcon = styled(RadixPopover.Close)`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const Container = styled.div`
  position: relative;
`;
