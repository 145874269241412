export enum RouterTypes {
  HASH = 'hash',
  BROWSER = 'browser',
  MEMORY = 'memory',
}

export interface RouterConfig {
  hashType?: 'slash' | 'noslash' | 'hashbang';
  basename?: string;
}
