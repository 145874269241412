export default {
  // these should be unique as the values are used as keys
  profileImageOfHost: 'Profilový obrázek hostitele {{hostName}}',
  hostedBy: 'Hostitel:',
  experiences: 'zážitky',
  experience: 'zážitek',
  clear: 'Vymazat',
  cancel: 'Zrušit',
  apply: 'Použít',
  more: 'Více',
  experiencePhotoOfListing:
    'Fotografie zážitku {{photoNumber}} u nabídky {{listingTitle}}',
  noExperiencesFound: 'Nenalezeny žádné zážitky',
  noExperiencesFoundFor: 'Nenalezeny žádné zážitky pro',
  discoverExperiences: 'Objevte zážitky',
  clearFilters: 'Vymazat filtry',
  buyTickets: 'Koupit lístky',
  reserve: 'Rezervovat',
  activateProductCardTitle: 'Události',
  reserveProductCardTitle: 'Aktivity',
  hostProductCardTitle: 'Místní zážitky',
  mainLandingPageTitle: 'Všechny zážitky',
  activateLandingPageTitle: 'Události',
  reserveLandingPageTitle: 'Aktivity a vybavení',
  hostLandingPageTitle: 'Místní zážitky',
  exclusive: 'Exkluzivní',
  filterExperiences: 'Filtrovat zážitky',
};
