import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SecondaryButton } from 'components/theme/Button';

const EventClaimTicketsError: FC = () => {
  const history = useHistory();
  const { t: translate } = useTranslation();

  const goToExperiences = () => {
    history.push({
      pathname: '/e',
    });
  };

  return (
    <Wrapper>
      <Box>
        <Title>{translate('ticketsAreNoLongerAvailable')}</Title>
        <SubTitle>{translate('checkOutOutOtherExperiences')}</SubTitle>
        <SecondaryButton onClick={goToExperiences}>
          {translate('viewExperiences')}
        </SecondaryButton>
      </Box>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 75vh;
`;

const Box = styled.div`
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  border: solid 0.5px var(--way-colors-secondaryColorShades-100);

  & > * {
    margin: 0px;
  }
`;

const Title = styled.h2`
  color: var(--way-colors-primaryTextColor);
`;

const SubTitle = styled.p`
  font-size: 18px;
  color: var(--way-colors-secondaryTextColor);
`;

export default EventClaimTicketsError;
