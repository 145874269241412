import fetchApi from 'utils/fetch';
import * as types from 'types/access-code';
import * as helpers from './helpers';

/**
 * Create
 */
const validateAccessCodeRequest = () => ({
  type: types.VALIDATE_ACCESS_CODE_REQUEST,
});

const validateAccessCodeSuccess = (data) => ({
  type: types.VALIDATE_ACCESS_CODE_SUCCESS,
  payload: {
    accessCode: data,
  },
});

export const validateAccessCode =
  ({ code, brandId, experienceId, isCartFeatureEnabled, sessionDateTime }) =>
  async (dispatch) => {
    dispatch(validateAccessCodeRequest());
    try {
      const { data } = await fetchApi
        .post(`v1/brands/${brandId}/access-codes/${code}/validate`, {
          json: { experienceId },
        })
        .json();
      return dispatch(
        validateAccessCodeSuccess({
          ...data,
          ...(isCartFeatureEnabled && {
            cartItemMetadata: {
              experienceId,
              sessionDateTime,
            },
          }),
        }),
      );
    } catch (errorResponse) {
      return dispatch(
        helpers.errorAction(types.VALIDATE_ACCESS_CODE_FAILURE, errorResponse),
      );
    }
  };
