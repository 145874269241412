import { CartStatus, CartStatusResponse } from 'types/cart';
import { PaymentMethod } from 'types/payment';
import { sleep } from 'utils';
import { axiosInstance } from 'utils/axios';
import { debug } from 'utils/debug';

interface FetchCartStatusArgs {
  cartId: string;
  tries?: number;
}

const MAX_RETRYS = 5;
const POLLING_INTERVAL = 2; // polling every 2s.

const retryIfNeeded = async ({
  cartId,
  tries,
}: {
  cartId: string;
  tries: number;
}) => {
  if (tries < MAX_RETRYS) {
    await sleep(POLLING_INTERVAL);
    return fetchCartStatus({ cartId, tries: tries + 1 });
  }
  return false;
};

export const fetchCartStatus = async ({
  cartId,
  tries = 0,
}: FetchCartStatusArgs): Promise<boolean> => {
  try {
    const { data: cartStatusResponse } =
      await axiosInstance.get<CartStatusResponse>(`v1/carts/${cartId}`);

    if (
      cartStatusResponse.data.cartStatus === CartStatus.CONFIRMED ||
      cartStatusResponse.data.paymentMethod !== PaymentMethod.ROOM_CHARGE
    ) {
      return true;
    }

    if (cartStatusResponse.data.cartStatus === CartStatus.FAILED) {
      return false;
    }

    return retryIfNeeded({ cartId, tries });
  } catch (error: unknown) {
    debug('error', error);
    return retryIfNeeded({ cartId, tries });
  }
};
