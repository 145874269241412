export const DEFAULT_DESKTOP_GRID = {
  columnGap: 36,
  rowGap: 96,
  templateRows: [
    { height: 'minmax(440px, auto)', areas: [0, 0, 1] },
    { height: 'minmax(440px, auto)', areas: [2, 3, 4] },
    { height: 'minmax(440px, auto)', areas: [5, 6, 7] },
    { height: 'minmax(440px, auto)', areas: [8, 9, 9] },
    { height: 'minmax(440px, auto)', areas: [10, 11, 12] },
    { height: 'minmax(440px, auto)', areas: [13, 14, 15] },
  ],
};

export const DEFAULT_MOBILE_GRID = {
  columnGap: 0,
  rowGap: 36,
  templateRows: [{ height: 'minmax(360px, auto)', areas: [0] }],
};

export const DEFAULT_RESULTS_GRID = {
  columnGap: 36,
  rowGap: 96,
  templateRows: [{ height: 'minmax(440px, auto)', areas: [0, 1, 2] }],
};
