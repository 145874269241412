import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

const Spinner: React.FC<{
  size?: string | number;
  width?: number;
  opacity?: number;
  className?: string;
  style?: CSSProperties;
}> = ({ size = 32, width = 4, opacity = 1, style, className }) => {
  const sizeValue = typeof size === 'string' ? size : `${size}px`;

  return (
    <Wrapper>
      <Component
        suppressHydrationWarning
        size={sizeValue}
        width={`${width}px`}
        opacity={opacity}
        style={style}
        className={className}
      >
        <div />
        <div />
        <div />
        <div />
      </Component>
    </Wrapper>
  );
};

export default Spinner;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Component = styled.div<{
  size: string;
  width: string;
  opacity: number;
}>`
  display: inline-block;
  position: relative;
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  opacity: ${(p) => p.opacity};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(p) => p.size};
    height: ${(p) => p.size};
    border: ${(p) => p.width} solid var(--way-colors-contrastColorShades-100);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--way-colors-contrastColorShades-100) transparent
      transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${(p) =>
    p.size === 'small' &&
    css`
      width: 24px;
      height: 24px;

      div {
        width: 24px;
        height: 24px;
      }
    `}
`;
