import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

export const WindowDimensionsCtx = createContext(null);

const MOBILE_BREAKPOINT = 768;
const TABLET_BREAKPOINT = 1024;
const DESKTOP_BREAKPOINT = 1280;

export const defaultBreakpoints = {
  mobile: MOBILE_BREAKPOINT,
  tablet: TABLET_BREAKPOINT,
  desktop: DESKTOP_BREAKPOINT,
};

export const ResponsiveBreakpointsCtx = createContext(defaultBreakpoints);

const windowDims = () => ({
  height: window.innerHeight,
  width: window.innerWidth,
});

export const mobileBreakpoint = `${MOBILE_BREAKPOINT}px`;
export const tabletBreakpoint = `${TABLET_BREAKPOINT}px`;
export const desktopBreakpoint = `${DESKTOP_BREAKPOINT}px`;

const WindowDimensionsProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState(windowDims());

  useEffect(() => {
    const handleResize = () => {
      setDimensions(windowDims());
    };
    window.addEventListener('resize', debounce(handleResize, 100));
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WindowDimensionsCtx.Provider value={dimensions}>
      {children}
    </WindowDimensionsCtx.Provider>
  );
};

WindowDimensionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WindowDimensionsProvider;

export const useWindowDimensions = () => useContext(WindowDimensionsCtx);

export const useIsMobile = () => {
  const { width } = useWindowDimensions();
  const breakpoints = useContext(ResponsiveBreakpointsCtx);
  return width <= breakpoints.mobile;
};

export const useIsTablet = () => {
  const { width } = useWindowDimensions();
  const breakpoints = useContext(ResponsiveBreakpointsCtx);
  return width <= breakpoints.tablet && width > breakpoints.mobile;
};

export const useIsDesktop = () => {
  const { width } = useWindowDimensions();
  const breakpoints = useContext(ResponsiveBreakpointsCtx);
  return width >= breakpoints.desktop;
};
