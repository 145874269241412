import ky from 'ky';
import { RequestCache } from './requestCache';

class HTTPError extends Error {
  constructor(response) {
    // Set the message to the status text, such as Unauthorized,
    // with some fallbacks. This message should never be undefined.
    super(response.detail || response.title);
    this.name = 'HTTPError';
    this.response = response;
  }
}

// TODO use env var here
const headers = {};

const customFetch = ky.create({
  prefixUrl: process.env.KOUTO_API_URL,
  timeout: 40000,
  retry: {
    limit: 2,
    methods: ['get'],
    statusCodes: [429, 500, 502, 503],
  },
  hooks: {
    beforeRequest: [
      (request) => {
        Object.keys(headers).forEach((headerKey) => {
          request.headers.set(headerKey, headers[headerKey]);
        });
      },
      (request) => {
        const cachedResult = RequestCache.get(request.url);

        if (cachedResult) {
          return new Response(JSON.stringify(cachedResult));
        }

        return request;
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (request.method === 'POST') return;

        const responseBody = await response?.json();
        if (!response.ok) {
          throw new HTTPError(responseBody);
        }

        if (response.status >= 200 && response.status < 300) {
          RequestCache.set(request.url, responseBody);
        }
      },
    ],
  },
});

export const setFetchHeader = (key, value) => {
  headers[key] = value;
  return headers;
};

export default customFetch;
