export interface IParticipantTierData {
  id: string;
  name: string;
  price: number;
  selectedNumber: number;
}
export interface ITierParticipantState {
  currentExperience: string;
  selectedParticipants: IParticipantTierData[];
  groupTitle?: string;
}

export enum PRICE_TIER_ACTIONS {
  SET = 'SET_TIER_DATA',
}
