import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'WindowDimensionProvider';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import InfoIcon from 'assets/bx-info-circle';
import ButtonsOptionsList from './ButtonsOptionsList';

interface Props {
  selectedTime: string | null;
  selectedPriceTiersAmount: number;
  availableTimes: { value: string; label: string }[];
  waitlistTimes: { value: string; label: string }[];
  setSelectedTime: (time: string | null) => void;
}

const TicketTimesSelector: FC<Props> = ({
  selectedTime,
  selectedPriceTiersAmount,
  availableTimes,
  waitlistTimes,
  setSelectedTime,
}) => {
  const { isLoading, event, date: selectedDate } = useEventBookingSession();
  const isMobile = useIsMobile();
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (
      !availableTimes.find((time) => time.value === selectedTime) &&
      !waitlistTimes.find((time) => time.value === selectedTime)
    ) {
      setSelectedTime(null);
    }
  }, [availableTimes, waitlistTimes, selectedTime, setSelectedTime]);

  if (selectedPriceTiersAmount === 0 || !selectedDate) {
    return null;
  }

  if (!availableTimes.length && !waitlistTimes.length) {
    return (
      <Wrapper>
        <NoSessionsMessage isMobile={isMobile}>
          <InfoIcon />
          <span>{translate('noTimesAvailableOnThisDateFotThisPartySize')}</span>
        </NoSessionsMessage>
      </Wrapper>
    );
  }

  if (isLoading || !event) {
    return (
      <Wrapper>
        <TimeButtonSkeleton />
        <TimeButtonSkeleton />
      </Wrapper>
    );
  }

  return (
    <>
      {availableTimes.length > 0 && (
        <ButtonsOptionsList
          title={translate('availableTimes')}
          options={availableTimes}
          selectedValue={selectedTime || undefined}
          onOptionClick={setSelectedTime}
        />
      )}
      {waitlistTimes.length > 0 && (
        <ButtonsOptionsList
          title={translate('waitlist')}
          options={waitlistTimes}
          selectedValue={selectedTime || undefined}
          onOptionClick={setSelectedTime}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
`;

const NoSessionsMessage = styled.div<{ isMobile: boolean }>`
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '45px' : '48px')};
  border: solid 0.5px var(--way-colors-borderColor);
  background-color: var(--way-palette-black-5);
  user-select: none;

  & * {
    display: inline-block;
  }

  & svg {
    margin: ${({ isMobile }) => (isMobile ? '12px' : '14px')};

    & path {
      stroke: none;
      fill: var(--way-colors-svgColor);
    }
  }

  & span {
    line-height: ${({ isMobile }) => (isMobile ? '45px' : '48px')};
    color: var(--way-colors-contrastColorShades-60);
    vertical-align: top;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

const TimeButtonSkeleton = styled(SkeletonLineSlim)`
  width: 128px;
  height: 45px;
`;

export default TicketTimesSelector;
