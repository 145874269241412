import React, {
  FC,
  useRef,
  InputHTMLAttributes,
  KeyboardEvent,
  ChangeEvent,
  ReactNode,
} from 'react';
import styled from 'styled-components';

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string | ReactNode;
  fontSize?: number;
};

const CheckBox: FC<CheckboxProps> = ({
  id,
  label,
  fontSize = 14,
  onChange,
  checked,
  value,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onChange && inputRef.current) {
      event.preventDefault();
      const inputElement = inputRef.current;
      inputElement.checked = !checked;
      const nativeEvent = new Event('change', {
        bubbles: true,
        cancelable: false,
      });

      const syntheticEvent = {
        ...event,
        target: inputElement,
        value,
        currentTarget: inputElement,
        nativeEvent,
        type: 'change',
      } as ChangeEvent<HTMLInputElement>;

      onChange(syntheticEvent);
    }
  };

  return (
    <LabelCheckBox fontSize={fontSize}>
      <input
        id={id}
        ref={inputRef}
        type="checkbox"
        tabIndex={0}
        checked={checked}
        onChange={onChange}
        value={value}
        onKeyDown={handleKeyDown}
        {...props}
      />
      <span className="checkmark" />
      <span style={{ marginLeft: 0, marginTop: 3 }}>{label}</span>
    </LabelCheckBox>
  );
};

export default CheckBox;

const LabelCheckBox = styled.label<{
  fontSize: number;
}>`
  display: flex;
  position: relative;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  line-height: 1.3;
  font-size: ${({ fontSize }) => fontSize}px;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;
  }

  .checkmark {
    flex-shrink: 0;
    position: relative;
    height: 15px;
    width: 15px;
    min-width: 15px;
    margin-top: 3px;
    border: 0.5px solid var(--way-colors-buttonColorShades-100);
    box-shadow: var(--way-design-boxShadow-s);
    border-radius: 4px;
    transition: border-color 0.2s;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 4.2px;
      top: 2px;
      width: 4px;
      height: 8px;
      border: solid var(--way-colors-buttonColorShades-100);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  /* Show checkmark when input is checked */
  input:checked + .checkmark:after {
    display: block;
  }

  /* Add focus styles */
  input:focus + .checkmark {
    border-color: var(--way-colors-borderFocusColor);
    box-shadow: 0 0 0 3px rgba(0, 0, 255, 0.2);
  }
`;
