import { ResourceGroup, Session } from 'types/listings';

export const filterSessionsByGroup = (
  sessions: Session[],
  resourceGroup: ResourceGroup,
) => {
  return sessions.filter(
    (session) =>
      !!resourceGroup.experiences.find(
        (exp) => exp.id === session.experienceId,
      ),
  );
};
