export default {
  weekdays: ['일', '월', '화', '수', '목', '금', '토'],
  months: [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ],
  formattedShortMonths: [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ],
  weekdays_calendar: {
    Sun: '일요일',
    Mon: '월요일',
    Tue: '화요일',
    Wed: '수요일',
    Thu: '목요일',
    Fri: '금요일',
    Sat: '토요일',
  },
  months_calendar: {
    January: '1월',
    February: '2월',
    March: '3월',
    April: '4월',
    May: '5월',
    June: '6월',
    July: '7월',
    August: '8월',
    September: '9월',
    October: '10월',
    November: '11월',
    December: '12월',
  },
  weekdays_booking_container: {
    Sunday: '일요일',
    Monday: '월요일',
    Tuesday: '화요일',
    Wednesday: '수요일',
    Thursday: '목요일',
    Friday: '금요일',
    Saturday: '토요일',
  },
};
