import * as types from 'types/brand';
import * as appTypes from 'types/app';
import initialState from 'store/initial-state';

export default function brandReducer(state = initialState.brand, action) {
  switch (action.type) {
    // fetch settings
    case types.FETCH_SETTINGS_REQUEST:
      return {
        ...state,
        fetchSettingsError: '',
        responseStatus: null,
        fetchSettingsStatus: appTypes.STATUS_PENDING,
      };
    case types.FETCH_SETTINGS_FAILURE:
      return {
        ...state,
        fetchSettingsStatus: appTypes.STATUS_FAILURE,
        responseStatus: action.payload.responseStatus,
        fetchSettingsError: action.error,
      };
    case types.FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload.settings,
        fetchSettingsStatus: appTypes.STATUS_SUCCESS,
      };
    // fetch integrations
    case types.FETCH_INTEGRATIONS_REQUEST:
      return {
        ...state,
        fetchIntegrationsError: '',
        responseStatus: null,
        fetchIntegrationsStatus: appTypes.STATUS_PENDING,
      };
    case types.FETCH_INTEGRATIONS_FAILURE:
      return {
        ...state,
        fetchIntegrationsStatus: appTypes.STATUS_FAILURE,
        responseStatus: action.payload.responseStatus,
        fetchIntegrationsError: action.error,
      };
    case types.FETCH_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        integrations: action.payload.integrations,
        fetchIntegrationsStatus: appTypes.STATUS_SUCCESS,
      };
    // fetch terms
    case types.FETCH_TERMS_REQUEST:
      return {
        ...state,
        fetchTermsError: '',
        responseStatus: null,
        fetchTermsStatus: appTypes.STATUS_PENDING,
      };
    case types.FETCH_TERMS_FAILURE:
      return {
        ...state,
        fetchTermsStatus: appTypes.STATUS_FAILURE,
        responseStatus: action.payload.responseStatus,
        fetchTermsError: action.error,
      };
    case types.FETCH_TERMS_SUCCESS:
      return {
        ...state,
        terms: action.payload.terms,
        fetchTermsStatus: appTypes.STATUS_SUCCESS,
      };
    // search category/types
    case types.SEARCH_CATEGORY_REQUEST:
      return {
        ...state,
        searchCategoryError: '',
        searchCategoryStatus: appTypes.STATUS_PENDING,
      };
    case types.SEARCH_CATEGORY_FAILURE:
      return {
        ...state,
        searchCategoryStatus: appTypes.STATUS_FAILURE,
        searchCategoryError: action.error,
      };
    case types.SEARCH_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
        searchCategoryStatus: appTypes.STATUS_SUCCESS,
      };

    // search vibes
    case types.SEARCH_VIBES_REQUEST:
      return {
        ...state,
        searchVibesError: '',
        searchVibeStatus: appTypes.STATUS_PENDING,
      };
    case types.SEARCH_VIBES_FAILURE:
      return {
        ...state,
        searchVibeStatus: appTypes.STATUS_FAILURE,
        searchVibesError: action.error,
      };
    case types.SEARCH_VIBES_SUCCESS:
      return {
        ...state,
        vibes: action.payload.vibes,
        searchVibeStatus: appTypes.STATUS_SUCCESS,
      };

    // calendar events
    case types.FETCH_CALENDAR_EVENTS_REQUEST:
      return {
        ...state,
        error: '',
        calendarSessions: [],
        calendarExperiences: [],
        calendarCollections: [],
        fetchCalendarEventsStatus: appTypes.STATUS_PENDING,
      };
    case types.FETCH_CALENDAR_EVENTS_FAILURE:
      return {
        ...state,
        fetchCalendarEventsStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.FETCH_CALENDAR_EVENTS_SUCCESS:
      return {
        ...state,
        calendarSessions: action.payload.data.sessions,
        calendarExperiences: action.payload.data.experiences,
        calendarCollections: action.payload.data.resourceGroupCollections,
        fetchCalendarEventsStatus: appTypes.STATUS_SUCCESS,
      };

    case types.FETCH_CALENDAR_FIRST_AVAILABLE_DATE_REQUEST:
      return {
        ...state,
        error: '',
        calendarFirstAvailableDate: '',
        calendarSessions: [],
        calendarExperiences: [],
        calendarCollections: [],
        fetchCalendarFirstAvailableDateStatus: appTypes.STATUS_PENDING,
      };
    case types.FETCH_CALENDAR_FIRST_AVAILABLE_DATE_FAILURE:
      return {
        ...state,
        fetchCalendarFirstAvailableDateStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.FETCH_CALENDAR_FIRST_AVAILABLE_DATE_SUCCESS:
      return {
        ...state,
        calendarFirstAvailableDate: action.payload.data.scheduledDate,
        calendarSessions: action.payload.data.sessions,
        calendarExperiences: action.payload.data.experiences,
        fetchCalendarFirstAvailableDateStatus: appTypes.STATUS_SUCCESS,
      };
    default:
      return state;
  }
}
