import React from 'react';

const ChevronRightIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={8 * scale}
      height={14 * scale}
      viewBox="0 0 8 14"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.29281 6.90393L0.550293 1.16141L1.2574 0.454308L7.35347 6.55038V7.25748L1.2574 13.3536L0.550293 12.6464L6.29281 6.90393Z"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
    </svg>
  );
};

export default ChevronRightIcon;
