import React from 'react';
import styled from 'styled-components';
import Link from 'components/Link';
import { ButtonBlock } from 'components/theme/Button/Button';

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  & button {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
  }
`;

const NotFound = () => (
  <NotFoundWrapper>
    <p>The resource you are looking for doesn&apos;t exist.</p>
    <Link to="/experiences">
      <ButtonBlock type="button">View All Experiences</ButtonBlock>
    </Link>
  </NotFoundWrapper>
);

export default NotFound;
