import { useAppState } from 'AppProvider';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import {
  isExperienceOverlapping,
  isHostOverlapping,
} from 'features/ShoppingCart/utils';
import moment from 'moment';
import { useMemo } from 'react';
import useFetchExperienceList from 'selectors/experienceList';
import useSelectedParticipants from 'selectors/participants';
import type { ISessionPayload } from 'types/experience.types';
import { ISO_DATE_FORMAT, isSessionMultipleTiers } from 'utils';
import useCartItems from 'hooks/useCartItems';

interface IStartTimesParams {
  selectedGuests: number;
  selectedDate: string;
  availablePriceTiers: ISessionPayload['priceTiers'];
  selectedDuration: string;
  filterDisabled?: boolean;
}

interface ISelectTier {
  id: string;
  name: string;
  price: number;
  maxQuantity: number;
  selectedNumber: number;
}

interface Event {
  startTime: string;
  supportedParticipantsCount: number;
  isDisabled: boolean;
  duration: string;
}

const useStartTimes = ({
  selectedGuests,
  selectedDate,
  availablePriceTiers,
  selectedDuration,
  filterDisabled,
}: IStartTimesParams): {
  experienceStartTimes: Event[];
  isUnavailable: boolean;
} => {
  const { experienceList } = useFetchExperienceList();
  const { sessions, experience } = useAppState(
    (state: Record<string, unknown>) => state.experience,
  );
  const { cartItems } = useCartItems();

  const { selectedParticipants, totalSelected: totalSelectedParticipants } =
    useSelectedParticipants();
  const isCartFeatureEnabled = useBrandToggleFeature('shoppingCart');

  const isSessionDateOverlappingCartItemsDate = (session: ISessionPayload) => {
    if (experience?.hostedBy?.allowOverlappingBookings) return false;

    const sessionDateTime = moment(
      `${selectedDate}T${session.startTime}`,
    ).format(ISO_DATE_FORMAT);

    const props = {
      cartExperienceItems: cartItems,
      addedItem: { experience, sessionDateTime, duration: session.duration },
      experienceList,
    };

    return isExperienceOverlapping(props) || isHostOverlapping(props);
  };

  const checkIsPartySizeException = (startTime: string) => {
    const cartExperience = Object.values(cartItems).find((e) => {
      return (
        e.sessionDateTime === `${selectedDate}T${startTime}Z` &&
        e.sessionDuration === selectedDuration
      );
    });
    if (!experience.partySize || !cartExperience) {
      return false;
    }
    return cartExperience?.participants?.length >= experience.partySize;
  };

  const experienceStartTimesMapped = useMemo(
    () =>
      (sessions as ISessionPayload[])?.flatMap((session) => {
        const shouldDisable = () => {
          if (session.duration !== selectedDuration) {
            return true;
          }
          if (isCartFeatureEnabled) {
            if (
              isSessionDateOverlappingCartItemsDate(session) ||
              checkIsPartySizeException(session.startTime)
            ) {
              return true;
            }
          }

          if (!isSessionMultipleTiers(availablePriceTiers)) {
            return selectedGuests > session.supportedParticipantsCount;
          }

          if (selectedGuests && session?.availableQuantityPerTier) {
            const isTierLimitCrossed = selectedParticipants.some(
              (category: ISelectTier) =>
                category.selectedNumber >
                session.availableQuantityPerTier[category.name],
            );
            return (
              isTierLimitCrossed ||
              totalSelectedParticipants > session.supportedParticipantsCount
            );
          }

          return !session?.supportedParticipantsCount;
        };

        const isDisabled = shouldDisable();

        if (filterDisabled && isDisabled) {
          return [];
        }

        return {
          startTime: session.startTime,
          supportedParticipantsCount: session?.supportedParticipantsCount ?? 0,
          isDisabled: shouldDisable(),
          duration: session.duration,
        };
      }),
    [
      selectedGuests,
      selectedDate,
      availablePriceTiers,
      sessions,
      isCartFeatureEnabled,
      experience,
      cartItems,
      selectedParticipants,
      totalSelectedParticipants,
      selectedDuration,
    ],
  );

  const uniqueStartTimes = useMemo<Map<string, Map<string, Event>>>(() => {
    const startTimeDurationMap = new Map<string, Map<string, Event>>();
    experienceStartTimesMapped.forEach((session) => {
      if (!startTimeDurationMap.has(session.startTime)) {
        startTimeDurationMap.set(session.startTime, new Map<string, Event>());
      }
      startTimeDurationMap
        .get(session.startTime)
        ?.set(session.duration, session);
    });
    return startTimeDurationMap;
  }, [experienceStartTimesMapped]);

  const filteredStartTimesForDuration = useMemo(() => {
    return Array.from(uniqueStartTimes.keys()).reduce((acc, startTime) => {
      const data = uniqueStartTimes.get(startTime);
      if (!data) {
        return acc;
      }

      const [firstStartTime] = Array.from(data.keys());

      const session = data.get(selectedDuration) || data.get(firstStartTime);
      if (session) {
        acc.push(session);
      }
      return acc;
    }, [] as Event[]);
  }, [selectedDuration, uniqueStartTimes]);

  return {
    experienceStartTimes: filteredStartTimesForDuration,
    isUnavailable: filteredStartTimesForDuration.every(
      (session) => session.isDisabled,
    ),
  };
};

export default useStartTimes;
