import { getDatesFromSessions } from 'utils/session';
import useCollectionAvailableSessions, {
  PublicSessionsQueryParams,
} from './useCollectionAvailableSessions';

const useAvailableDates = (queryParams: PublicSessionsQueryParams) => {
  const { sessions, isLoadingSessions } =
    useCollectionAvailableSessions(queryParams);

  return {
    availableDates: getDatesFromSessions(sessions),
    isLoadingAvailableDates: isLoadingSessions,
  };
};

export default useAvailableDates;
