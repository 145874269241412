import { Languages } from 'translations/i18n';

const isSupportedLanguage = (lang: string) =>
  Object.values(Languages).includes(lang.toLowerCase() as Languages);

const getLanguageCode = (lang: string | null) => {
  if (!lang) return undefined;

  if (!/^[a-zA-Z]{2}(-[a-zA-Z]{2})?$/.test(lang)) return undefined;

  const [languageCode] = lang.split('-');
  return isSupportedLanguage(languageCode) ? languageCode : undefined;
};

export const getAppLanguage = (searchParams?: Record<string, string>) => {
  const queryParams = new URLSearchParams(window.location.search);

  const { hash = '' } = window.location;

  const hashParams = new URLSearchParams(hash.split('?')?.[1] ?? '');

  const lang = getLanguageCode(
    searchParams?.lang ?? hashParams?.get('lang') ?? queryParams.get('lang'),
  );

  const path = window.location.pathname
    .split('/')
    .find((part) => !!part && getLanguageCode(part));

  const subdomain = [window.location.hostname.split('.')[0]].find(
    (part) => !!part && getLanguageCode(part),
  );

  return (
    lang?.toLowerCase() ?? // from query params
    path?.toLowerCase() ?? // from path - i.e https://host.com/fr/...
    subdomain?.toLowerCase() ?? // from subdomain - i.e https://fr.host.com...
    'en'
  );
};
