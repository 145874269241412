import React, {
  FC,
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useIsMobile } from 'WindowDimensionProvider';
import styled from 'styled-components';
import Carousel from 'react-spring-3d-carousel';
import { config } from 'react-spring';
import { Listing } from 'types/listings';
import { useLandingPageRouteParams } from 'hooks/useLandingPageRouteParams';
import { SkeletonLine } from 'components/theme/Skeleton/Skeleton';
import ListingCarouselCard from './ListingCarouselCard';
import {
  CarouselType,
  getCardWidth,
  getCardHeight,
  getCardMaxWidth,
} from './carouselUtils';
import SlidesIndicator from '../SlidesIndicator';

const MAX_ELEMENTS_TO_SHOW = 3;
const AUTO_CHANGE_INTERVAL = 4_000;

interface ListingCarouselProps {
  listings: Listing[];
  isLoading: boolean;
  type: CarouselType;
}

const getFirstListingDate = (listing: Listing) => {
  return listing.dates?.[0] || listing.firstAvailableDate?.scheduledDate || '';
};

const ListingCarousel: FC<ListingCarouselProps> = ({
  listings,
  isLoading,
  type,
}) => {
  const isMobile = useIsMobile();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const interval = useRef<NodeJS.Timeout | null>(null);
  const { product } = useLandingPageRouteParams();

  const stopAnimation = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  }, []);

  const startAnimation = useCallback(() => {
    stopAnimation();

    interval.current = setInterval(() => {
      setSelectedSlide(
        (prevSlide) =>
          (prevSlide + 1) % Math.min(listings.length, MAX_ELEMENTS_TO_SHOW),
      );
    }, AUTO_CHANGE_INTERVAL);
  }, [setSelectedSlide, listings.length, stopAnimation]);

  const selectOneSlide = useCallback(
    (index: number) => {
      setSelectedSlide(index);
      stopAnimation();
      setTimeout(startAnimation, AUTO_CHANGE_INTERVAL);
    },
    [stopAnimation, startAnimation],
  );

  const slides = useMemo(() => {
    if (isLoading) {
      return [
        {
          key: 'A0',
          content: <CardSkeleton type={type} isMobile={isMobile} />,
        },
        {
          key: 'A1',
          content: <CardSkeleton type={type} isMobile={isMobile} />,
        },
        {
          key: 'A2',
          content: <CardSkeleton type={type} isMobile={isMobile} />,
        },
      ];
    }

    return listings.slice(0, MAX_ELEMENTS_TO_SHOW).map((listing, index) => ({
      key: `${listing.id}-${getFirstListingDate(listing)}`,
      content: (
        <ListingCarouselCard
          listing={listing}
          isSelected={selectedSlide === index}
          type={type}
        />
      ),
      onClick: () => selectOneSlide(index),
    }));
  }, [listings, selectedSlide, isMobile, type, isLoading, selectOneSlide]);

  useEffect(() => {
    if (!isLoading) {
      startAnimation();
    }

    return stopAnimation;
  }, [isLoading, startAnimation, stopAnimation]);

  if (!isLoading && listings.length < 3) {
    stopAnimation();
    return null;
  }

  return (
    <Wrapper
      data-testid={`carousel-${product}`}
      className={`landing-page-carousel-container landing-page-${product}-carousel-container`}
      type={type}
    >
      <SlidesWrapper>
        <Carousel
          slides={slides}
          goToSlide={selectedSlide}
          showNavigation={false}
          animationConfig={config.default}
          offsetRadius={1}
          offsetFn={getSlidesOffsetByDirection(type, isMobile)}
        />
      </SlidesWrapper>
      {slides.length > 1 && (
        <SlidesIndicatorWrapper className="landing-page-carousel-slider-container">
          <SlidesIndicator
            slidesCount={slides.length}
            selectedSlide={selectedSlide}
            selectOneSlide={selectOneSlide}
          />
        </SlidesIndicatorWrapper>
      )}
    </Wrapper>
  );
};

const getSlidesOffsetByDirection = (type: CarouselType, isMobile: boolean) => {
  if (type === CarouselType.WIDE_HORIZONTAL) {
    return (offsetFromCenter: number) => {
      if (offsetFromCenter === 0) {
        // Center slide
        return {
          opacity: 1,
          left: '50%', // Centered
          zIndex: 1,
          top: '50%',
          pointerEvents: 'none',
        };
      }
      if (offsetFromCenter < 0) {
        // Slides to the left of center
        return {
          transform: 'translateY(-50%) translateX(-55%) scale(0.768)',
          opacity: 0.3,
          left: isMobile ? '35%' : '40%',
          zIndex: 0,
          top: '55%',
          pointerEvents: 'all',
        };
      }
      // Slides to the right of center
      return {
        transform: 'translateY(-50%) translateX(-45%) scale(0.768)',
        opacity: 0.3,
        left: isMobile ? '65%' : '60%',
        zIndex: 0,
        top: '55%',
        pointerEvents: 'all',
      };
    };
  }

  if (type === CarouselType.NARROW_HORIZONTAL) {
    return (offsetFromCenter: number) => {
      if (offsetFromCenter === 0) {
        // Center slide
        return {
          opacity: 1,
          left: '50%', // Centered
          zIndex: 1,
          top: '50%',
          pointerEvents: 'none',
        };
      }
      if (offsetFromCenter < 0) {
        // Slides to the left of center
        return {
          transform: 'translateY(-50%) translateX(-55%) scale(0.768)',
          opacity: 0.3,
          left: isMobile ? '35%' : '35%',
          zIndex: 0,
          top: '55%',
          pointerEvents: 'all',
        };
      }
      // Slides to the right of center
      return {
        transform: 'translateY(-50%) translateX(-45%) scale(0.768)',
        opacity: 0.3,
        left: isMobile ? '65%' : '65%',
        zIndex: 0,
        top: '55%',
        pointerEvents: 'all',
      };
    };
  }

  return (offsetFromCenter: number) => {
    if (offsetFromCenter === 0) {
      // Center slide
      return {
        opacity: 1,
        left: '50%',
        zIndex: 1,
        top: '50%',
        pointerEvents: 'none',
      };
    }
    if (offsetFromCenter < 0) {
      // Slides to the bottom of center
      return {
        transform: 'translateY(-50%) translateX(-50%) scale(0.85)',
        opacity: 0.3,
        left: '50%',
        zIndex: 0,
        top: '65%',
        pointerEvents: 'all',
      };
    }
    // Slides to the top of center
    return {
      transform: 'translateY(-50%) translateX(-50%) scale(0.85)',
      opacity: 0.3,
      left: '50%',
      zIndex: 0,
      top: '35%',
      pointerEvents: 'all',
    };
  };
};

export default ListingCarousel;

const Wrapper = styled.div<{ type: CarouselType }>`
  height: 700px;

  @media (max-width: 768px) {
    width: 100%;
    height: ${({ type }) =>
      type === CarouselType.WIDE_HORIZONTAL ? 400 : 550}px;
    margin-left: 0%;
  }
`;

const SlidesWrapper = styled.div`
  width: 100%;
  overflow: visible;
  height: calc(100% - 100px);

  @media (max-width: 768px) {
    height: calc(100% - 50px);
  }
`;

const SlidesIndicatorWrapper = styled.div`
  width: 70vw;
  max-width: 100%;
  margin: auto;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 95%;
    height: 50px;
  }
`;

const CardSkeleton = styled(SkeletonLine)<{
  type: CarouselType;
  isMobile: boolean;
}>`
  border-radius: var(--way-design-borderRadiusDefault);
  width: ${({ type, isMobile }) => getCardWidth(type, isMobile)};
  height: ${({ type, isMobile }) => getCardHeight(type, isMobile)};
  max-width: ${({ type, isMobile }) => getCardMaxWidth(type, isMobile)};
`;
