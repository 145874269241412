import { useState } from 'react';

export const useGuestSurveyModal = () => {
  const [surveyModalOpen, setSurveyModalOpen] = useState(false);
  const [cartId, setCartId] = useState<string | null>(null);
  const [callback, setCallback] = useState<() => void | undefined>();

  const presentSurvey = (cartId: string, cb: () => void) => {
    setCallback(() => cb);
    setCartId(cartId);
    setSurveyModalOpen(true);
  };

  const onCloseSurveyModal = () => {
    setSurveyModalOpen(false);
    callback?.();
  };

  return {
    cartId,
    surveyModalOpen,
    presentSurvey,
    onCloseSurveyModal,
  };
};
