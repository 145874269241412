import initialState from 'store/initial-state';
import {
  ITierParticipantState,
  PRICE_TIER_ACTIONS,
} from '../types/reducer-states/priceTierState';

export function setPriceTierReducer(
  state = initialState.priceTierData,
  action: { type: PRICE_TIER_ACTIONS.SET; payload: ITierParticipantState },
) {
  return {
    ...state,
    priceTierData: action.payload,
  };
}
