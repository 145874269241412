import { useAppState } from 'AppProvider';
import TextFieldInput, {
  ITextFieldProps,
} from 'components/common/TextFieldInput/TextFieldInput';
import React from 'react';
import { validateEmailAddress, validateEmpty } from 'utils/validators';

interface IEmailFieldProps extends ITextFieldProps {
  scope: string;
}

const BookingEmail: React.FC<IEmailFieldProps> = ({ scope, ...props }) => {
  const { experienceSettings: settings } = useAppState(
    (state: Record<string, unknown>) => state.experience,
  );
  const isCurrentScope = settings?.scope === scope;

  const getFieldsConfig = (config: string) => {
    if (!settings || !settings.fields) {
      return false;
    }
    return settings.fields.emailAddress[config] ?? false;
  };

  const isFieldIncluded = getFieldsConfig('isActive');

  const emailValidationPattern = () => {
    if (isCurrentScope) {
      const isRequired = getFieldsConfig('isRequired');
      return {
        ...validateEmailAddress(),
        ...(isRequired && { ...validateEmpty() }),
      };
    }
    const isPurchaserScope = scope === 'purchaser';
    return {
      ...(isPurchaserScope && { ...validateEmpty() }),
      ...validateEmailAddress(),
    };
  };

  const { required } = emailValidationPattern();

  if (!isCurrentScope && scope === 'participant') {
    return null;
  }

  if (isCurrentScope && !isFieldIncluded) {
    return null;
  }

  return (
    <TextFieldInput
      {...props}
      rules={{ ...emailValidationPattern() }}
      label={`${props.label}${required ? ':*' : ''}`}
    />
  );
};

export default BookingEmail;
