import { useState, useEffect } from 'react';
import { filterExperienceSearchParams } from 'utils';
import useSearchQueryParams from 'hooks/use-search-params';

export const useExperienceFilterParam = () => {
  const { searchParams } = useSearchQueryParams();

  const [experienceFilterParam, setExperienceFilterParam] =
    useState(searchParams);
  useEffect(() => {
    if (searchParams) {
      const filterParams = filterExperienceSearchParams(searchParams);
      setExperienceFilterParam(filterParams);
    }
  }, [searchParams]);

  return experienceFilterParam;
};
