import React, { FC } from 'react';
import styled from 'styled-components';
import { SecondaryButton } from 'components/theme/Button/Button';

export enum ButtonsOptionSizes {
  SMALL = 'small',
  BIG = 'big',
}

interface Props {
  title?: string;
  options: { value: string; label: string }[];
  selectedValue?: string;
  onOptionClick?: (value: string) => void;
  buttonSize?: ButtonsOptionSizes;
}

const ButtonsOptionsList: FC<Props> = ({
  title,
  options,
  selectedValue,
  onOptionClick = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  buttonSize = ButtonsOptionSizes.SMALL,
}) => {
  return (
    <div>
      {title && <Title>{title}</Title>}
      <ListWrapper>
        {options.map(({ value, label }) => (
          <OptionButton
            data-testid={`session-time-at-${label}`}
            key={value}
            selected={value === selectedValue}
            onClick={() => onOptionClick(value)}
            buttonSize={buttonSize}
          >
            {label}
          </OptionButton>
        ))}
      </ListWrapper>
    </div>
  );
};

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
`;

const Title = styled.p`
  margin-top: 0px;
  margin-bottom: 12px;
  user-select: none;
  color: var(--way-colors-contrastColorShades-60);
`;

const OptionButton = styled(SecondaryButton)<{
  selected: boolean;
  buttonSize: ButtonsOptionSizes;
}>`
  /* skip css namespace */

  height: 45px;
  min-width: ${({ buttonSize }) =>
    buttonSize === ButtonsOptionSizes.BIG ? '150px' : '110px'};
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};

  @media (max-width: 768px) {
    padding: 12px;
    font-size: 12px;
    width: calc(33.33% - 12px);
    min-width: 0px;
  }
`;

export default ButtonsOptionsList;
