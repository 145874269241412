import React, { useEffect } from 'react';
import Hero from 'components/Hero';
import FeaturedExperiences from 'components/FeaturedExperiences';
import Experiences from 'components/Experiences';
import { WrapperFluid } from 'components/theme/Wrapper';
import ErrorBoundary from 'components/ErrorBoundary';

import { setLoading } from 'actions/app';

import { useAppState, useDispatch } from 'AppProvider';
import { HomePageAnalyticsTracker } from 'features/analytics/components/HomePageAnalyticsTracker';

const Home = () => {
  const dispatch = useDispatch();
  const { brand } = useAppState();
  const { experiences } = useAppState((state) => state.experience);

  useEffect(() => {
    dispatch(setLoading(false));
  }, []);

  return (
    <WrapperFluid id="way-home__wrapper-fluid-container">
      <HomePageAnalyticsTracker experiences={experiences} />
      <ErrorBoundary>
        {brand.settings && <Hero settings={brand.settings} />}
      </ErrorBoundary>
      <ErrorBoundary>
        <FeaturedExperiences />
      </ErrorBoundary>
      <ErrorBoundary>
        <Experiences
          classId="home-page-experiences"
          limit={48}
          hidePagination
          showTitle
          redirectToSearch
        />
      </ErrorBoundary>
    </WrapperFluid>
  );
};

export default Home;
