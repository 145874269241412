import React, { FC } from 'react';
import { ProductLine } from '@kouto/types';
import { LandingPageHeroProps } from './types';
import HeroWithTitle from './HeroWithTitle';

import { CarouselType } from '../ListingCarousel/carouselUtils';

const LandingPageHostHero: FC<LandingPageHeroProps> = ({
  title,
  listings,
  isLoading,
}) => {
  return (
    <HeroWithTitle
      title={title}
      listings={listings}
      isLoading={isLoading}
      carouselType={CarouselType.NARROW_HORIZONTAL}
      product={ProductLine.HOST}
    />
  );
};

export default LandingPageHostHero;
