export default {
  buyTickets: 'Buy tickets',
  salesEnded: 'Sales ended',
  joinWaitlist: 'Join Waitlist',
  joining: 'Joining',
  soldOut: 'Sold out',
  noDatesAvailable: 'No dates available',
  seeMoreDates: 'See more dates',
  about: 'About',
  agenda: 'Agenda',
  menu: 'Menu',
  included: 'Included',
  viewMore: 'View more',
  viewLess: 'View less',
  location: 'Location',
  time: 'Time',
  at: 'at',
  noTimesAvailableOnThisDateFotThisPartySize:
    'No times available on this date for this party size',
  availableTimes: 'Available times',
  waitlist: 'Waitlist',
  continue: 'Continue',
  ticketsAreNoLongerAvailable: 'Tickets are no longer available.',
  checkOutOutOtherExperiences: 'Check out our other experiences!',
  viewExperiences: 'View experiences',
  from: 'from',
  cancellationPolicy: 'Cancellation policy',
  rsvp: 'RSVP',
};
