import React from 'react';
import { useFormContext } from 'hook-form';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'AppProvider';
import { FieldName } from 'features/ShoppingCart/types';
import { PaymentError } from 'features/ShoppingCart/components/PaymentError';
import * as Styled from 'features/ShoppingCart/utils/styles';
import {
  isIntegratedPaymentMethodEnabledForBrand,
  isIntegratedPreArrivalChargeEnabled,
} from 'components/helpers';

const KICC = () => {
  const { t: translateText } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { integrations, settings } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );
  const isEnabled = isIntegratedPaymentMethodEnabledForBrand(
    integrations,
    settings,
  );

  const isPreArrivalChargeEnabled =
    isIntegratedPreArrivalChargeEnabled(integrations);
  const placeholder = isPreArrivalChargeEnabled
    ? 'roomOrReservationNumber'
    : 'roomNumber';

  const getValidationMessage = () => {
    return errors.card?.message || null;
  };

  return (
    <>
      <Styled.AccordionContent>
        {isEnabled ? (
          <Styled.InputWrapper>
            <input
              type="text"
              placeholder={translateText(FieldName.LAST_NAME)}
              {...register(FieldName.PAYMENT_NAME)}
            />
            <input
              type="text"
              placeholder={translateText(placeholder)}
              {...register(FieldName.RESERVATION_NUMBER)}
            />
          </Styled.InputWrapper>
        ) : null}
      </Styled.AccordionContent>
      <PaymentError error={getValidationMessage() as string} />
    </>
  );
};

export default KICC;
