import { useState, useEffect, useMemo } from 'react';
import { useBrandId } from 'AppProvider';
import { z } from 'zod';
import { CreatePaymentIntentEndpoint } from '@kouto/types';
import { PaymentMethod } from 'types/payment';
import { PaymentOptions } from 'features/ShoppingCart/hooks/usePaymentOptions';
import fetchApi from 'utils/fetch';
import isEqual from 'lodash/isEqual';
import usePrevious from 'hooks/usePrevious';

type PaymentIntentResponse = {
  data: z.infer<typeof CreatePaymentIntentEndpoint.responseSchema>;
};

type UsePaymentIntentProps = {
  cartId: string;
  cartTotal: number;
  cartItemsData: unknown;
  paymentOptions: PaymentOptions;
};

const usePaymentIntent = ({
  cartId,
  cartTotal,
  cartItemsData,
  paymentOptions,
}: UsePaymentIntentProps) => {
  const brandId = useBrandId();
  const [paymentIntentSecret, setPaymentIntentSecret] =
    useState<PaymentIntentResponse['data']['paymentIntentSecret']>(null);
  const [isLoading, setIsLoading] = useState(false);

  const hasCreditCardPaymentOption = useMemo(
    () =>
      paymentOptions.some((item) => item.name === PaymentMethod.CREDIT_CARD),
    [paymentOptions],
  );
  // We need this previousCartItemsData computed this way, to avoid calling the api two times at first page refresh
  const previousCartItemsData = usePrevious(
    paymentOptions.length ? cartItemsData : undefined,
  );
  const previousCartId = usePrevious(cartId);
  const previousCartTotal = usePrevious(cartTotal);

  useEffect(() => {
    const doJob = async () => {
      const cartHasChanged =
        !isEqual(cartItemsData, previousCartItemsData) ||
        !isEqual(cartId, previousCartId) ||
        !isEqual(cartTotal, previousCartTotal);
      const cartNeedsPaymentIntent =
        cartTotal > 0 ||
        (typeof previousCartTotal !== 'undefined' && previousCartTotal > 0);

      if (
        hasCreditCardPaymentOption &&
        cartHasChanged &&
        cartNeedsPaymentIntent
      ) {
        setIsLoading(true);
        try {
          const apiUrl = CreatePaymentIntentEndpoint.url({
            brandId,
            cartId,
          }).substring(1);
          const response = (await fetchApi
            .post(apiUrl, {
              json: {
                data: cartItemsData,
              },
            })
            .json()) as PaymentIntentResponse;
          setPaymentIntentSecret(response.data.paymentIntentSecret);
        } catch (error) {
          console.error('Error fetching payment intent', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    doJob();
  }, [
    brandId,
    cartId,
    cartTotal,
    hasCreditCardPaymentOption,
    cartItemsData,
    previousCartItemsData,
    previousCartId,
    previousCartTotal,
  ]);

  return {
    paymentIntent: paymentIntentSecret?.slice(0, 27),
    paymentIntentSecret,
    isLoading,
  };
};

export default usePaymentIntent;
