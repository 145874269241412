import { SurveySchema } from '@kouto/types';
import { Flex } from 'components/common/styled/common-styled';
import { Button } from 'components/theme/Button/Button';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { z } from 'zod';
import { AnswerInputs } from './AnswerInputs/AnswerInputs';
import { AnswersRequest } from '../hooks/useGuestSurvey';

type SurveyType = z.infer<typeof SurveySchema>;

interface Props {
  survey: SurveyType;
  postAnswers: (surveyId: string, anwsers: AnswersRequest) => void;
}

const formatAnswers = (answers: SurveyType['questions']): AnswersRequest => {
  return {
    answers: answers
      .map(({ surveyQuestionHistoryId, answer }) => ({
        surveyQuestionHistoryId,
        answer,
      }))
      .filter(
        (answer): answer is AnswersRequest['answers'][number] =>
          answer.answer !== null,
      ),
  };
};

export const GuestSurvey: FC<Props> = ({ survey, postAnswers }) => {
  const [answers, setAnswers] = useState<SurveyType['questions']>(
    survey.questions,
  );

  const onChange = (questionId: string) => (value: string) => {
    setAnswers((prev) =>
      prev.map((question) =>
        question.surveyQuestionHistoryId === questionId
          ? {
              ...question,
              answer: value,
            }
          : question,
      ),
    );
  };

  return (
    <>
      <Flex
        direction="column"
        gap={16}
        style={{ marginBottom: '24px' }}
        className="guest-survey__wrapper"
      >
        {survey.questions.map((question) => {
          return (
            <Flex
              direction="column"
              gap={16}
              key={question.surveyQuestionHistoryId}
              className="guest-survey__header"
            >
              <QuestionLabel className="guest-survey__question-label">
                {question.label}
              </QuestionLabel>
              <AnswerInputs
                type={question.type}
                selectedValue={
                  answers.find((a) => a.type === question.type)?.answer || ''
                }
                onChange={onChange(question.surveyQuestionHistoryId)}
              />
            </Flex>
          );
        })}
      </Flex>
      <ButtonWrapper>
        <SubmitButton
          className="guest-survey__submit-button"
          disabled={answers.every((answer) => answer.answer === null)}
          onClick={() => {
            postAnswers(survey.id, formatAnswers(answers));
          }}
        >
          Proceed
        </SubmitButton>
      </ButtonWrapper>
    </>
  );
};

const QuestionLabel = styled.h4`
  margin: 0;
  padding-right: 24px;
  font-family: ${({ theme }) => theme.font.primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
`;

const ButtonWrapper = styled.div`
  padding: 12px 0;
  width: 100%;
  border-top: 0.5px solid var(--way-colors-borderColor);
`;

const SubmitButton = styled(Button)`
  padding: 12px 16px;
  border: none;
  width: 100%;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed !important;
  }
`;
