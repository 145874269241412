import React from 'react';
import styled from 'styled-components';

import CustomizableCalendarDay from 'react-dates/lib/components/CustomizableCalendarDay';
import {
  customDayStyles,
  loadingDayStyles,
} from '../AvailabilityCalendar/AvailabilityCalendar';

export const CustomizedCalendarDay = (props) => {
  return <CustomizableCalendarDay {...props} {...customDayStyles} />;
};

CustomizedCalendarDay.propTypes = CustomizableCalendarDay.propTypes;

export const LoadingCalendarDay = (props) => {
  return <CustomizableCalendarDay {...props} {...loadingDayStyles} />;
};

export const KeyFrameWrapper = styled.style`
  @keyframes load {
    50% {
      opacity: 0.2;
    }
  }
`;
