export default {
  unavailable: 'Недоступно',
  person: 'человек',
  people: 'люди',
  reserveNow: 'Забронировать сейчас',
  selectTime: 'Выбрать время',
  select: 'Выбрать',
  reserve: 'Забронировать',
  time: 'Время',
  tier: 'Уровень',
  questions: 'Вопросы',
  addToCart: 'Добавить в корзину',
  checkout: 'Оформить заказ',
  total: 'Итого',
  availability: 'Доступность',
  whatsIncluded: 'Что включено',
  showMore: 'Показать больше',
  showLess: 'Показать меньше',
  session: 'Сессия',
  viewDetails: 'Просмотреть детали',
  findAvailability: 'Найти доступность',
  loading: 'Загрузка',
  capacity: 'Вместимость',
  noAvailableSessionsOnThisDate: 'Нет доступных сессий на эту дату',
  noGroupAvailableOnThisDateAndTime:
    'Нет {{groupTitle}} доступных на эту дату и время',
  noCollectionFound: 'Коллекция не найдена!',
  noResourceGroupFound: 'Группа ресурсов не найдена!',
  noData: 'Нет данных!',
  continue: 'Продолжить',
};
