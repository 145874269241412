import React, { PropsWithChildren, useCallback } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

interface Props {
  hideError?: boolean;
}

export const ErrorBoundary: React.FC<PropsWithChildren<Props>> = ({
  children,
  hideError,
}) => {
  const handleError = useCallback((error: Error, componentStack: string) => {
    console.info(error, componentStack);
  }, []);

  return (
    <Sentry.ErrorBoundary
      onError={handleError}
      fallback={({ error }) => {
        if (!hideError) {
          return (
            <>
              <ProdErrorInfo>Something went wrong.</ProdErrorInfo>
              {process.env.NODE_ENV === 'development' && (
                <DevErrorInfo>
                  {error.toString()}
                  {error.stack}
                </DevErrorInfo>
              )}
            </>
          );
        }
        return <EmptyErrorContainer />;
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

const DevErrorInfo = styled.pre`
  color: red;
`;

const ProdErrorInfo = styled.div`
  color: var(--way-palette-black-70);
`;

const EmptyErrorContainer = styled.div`
  height: 50px;
  width: 100%;
  background-color: var(--way-palette-black-70);
`;
