export default {
  featuredExperiences: 'Наши лучшие впечатления',
  searchAllExperiences: 'Поиск всех впечатлений',
  searchAll: 'Искать все',
  groupSize: 'Размер группы',
  eventSize: 'Размер мероприятия',
  upTo: 'До',
  whatWeDo: 'Что мы будем делать',
  hostedBy: 'Организатор',
  whatsIncluded: 'Что включено',
  avail: 'Доступность',
  whereWeAre: 'Где мы будем',
  experienceStartingPrice: 'От {{price}}',
  experienceTotalPrice: 'Итого: {{price}}',
  at: 'в',
  viewPrivateSessAvailability: 'Просмотр доступности частных сеансов',
  selectSession: 'Выбрать сеанс',
  noAvailability: 'Нет доступности',
  select: 'Выбрать',
  slotsLeft: '{{remainSlots}} осталось слотов',
  slotLeft: '{{remainSlots}} остался слот',
  bookExperience: 'Забронировать впечатление',
  book: 'Забронировать',
  apply: 'Применить',
  maxPartySizes: 'Максимум {{maxGroupSize}} мест в группе',
  maxPartySize: 'Максимум {{maxGroupSize}} место в группе',
  selectSessionValidation: 'Пожалуйста, выберите сеанс',
  selectParticipantValidation: 'Пожалуйста, выберите участников',
  selectDateValidation: 'Пожалуйста, выберите дату',
  reminders: 'Напоминания',
  seeOtherDates: 'Посмотреть другие даты',
  location: 'Местоположение',
  about: 'Об этом',
  soldOut: 'Продано',
  reviews: 'Отзывы',
  reviewSingular: 'отзыв',
  reviewPlural: 'отзывов',
  dateOfExperience: 'Дата впечатления',
  readLess: 'Скрыть',
  readMore: 'Читать далее',
  seeMore: 'Смотреть больше',
  details: 'Детали',
  theFollowingVideoDoesNotContainAnyAudio: 'Следующее видео не содержит аудио',
};
