import * as types from 'theme/themeType';

export const setThemeColor = (colors) => ({
  type: types.SET_THEME_COLOR,
  payload: {
    colors,
  },
});

export const setThemeFont = (font) => ({
  type: types.SET_THEME_FONT,
  payload: {
    font,
  },
});

export const setThemeMode = (mode) => ({
  type: types.SET_THEME_MODE,
  payload: {
    mode,
  },
});
