export default {
  unavailable: '利用不可',
  person: '人',
  people: '人々',
  reserveNow: '今すぐ予約',
  selectTime: '時間を選択',
  select: '選択',
  reserve: '予約する',
  time: '時間',
  tier: '層',
  questions: '質問',
  addToCart: 'カートに追加',
  checkout: 'チェックアウト',
  total: '合計',
  availability: '可用性',
  whatsIncluded: '含まれているもの',
  showMore: 'もっと見る',
  showLess: '表示を減らす',
  session: 'セッション',
  viewDetails: '詳細を見る',
  findAvailability: '可用性を確認',
  loading: '読み込み中',
  capacity: '容量',
  noAvailableSessionsOnThisDate: 'この日に利用可能なセッションはありません',
  noGroupAvailableOnThisDateAndTime:
    'この日付と時間には{{groupTitle}}は利用できません',
  noCollectionFound: 'コレクションが見つかりませんでした！',
  noResourceGroupFound: 'リソースグループが見つかりませんでした！',
  noData: 'データがありません！',
  continue: '続ける',
};
