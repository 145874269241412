import React, {
  useState,
  InputHTMLAttributes,
  HTMLProps,
  ReactNode,
  KeyboardEvent,
  ChangeEvent,
  useRef,
} from 'react';
import styled from 'styled-components';
import SimpleRadioUI from 'components/theme/SimpleRadioUI';

type RadioProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string | ReactNode;
  fontSize?: number;
};

const RadioField = ({
  id,
  checked,
  disabled,
  label,
  value,
  onChange,
  ...props
}: RadioProps) => {
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onChange && inputRef.current) {
      event.preventDefault();
      const inputElement = inputRef.current;
      inputElement.checked = !checked;
      const nativeEvent = new Event('change', {
        bubbles: true,
        cancelable: false,
      });

      const syntheticEvent = {
        ...event,
        target: inputElement,
        value,
        currentTarget: inputElement,
        nativeEvent,
        type: 'change',
      } as ChangeEvent<HTMLInputElement>;
      onChange(syntheticEvent);
    }
  };

  return (
    <Label
      htmlFor={id}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Input
        id={id}
        ref={inputRef}
        type="radio"
        value={value}
        checked={checked}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        {...props}
      />
      <SimpleRadioUI
        checked={checked || false}
        disabled={disabled}
        hovered={hovered}
        focused={focused}
      />
      {typeof label === 'string' ? <span>{label}</span> : label}
    </Label>
  );
};

export default RadioField;

// Visually hidden but focusable input
const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  z-index: -1;
`;

const Label = styled.label<
  HTMLProps<HTMLLabelElement> & { disabled?: boolean }
>`
  display: flex;
  gap: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
