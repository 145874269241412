import { Flex } from 'components/common/styled/common-styled';
import { MainTitleH3 } from 'components/theme/Typography/Typography';
import styled, { css } from 'styled-components';

export type ModalSize = 'xsmall' | 'small' | 'medium' | 'large';

const CONTENT_MAX_WIDTH: Record<ModalSize, string> = {
  xsmall: '200px',
  small: '346px',
  medium: '500px',
  large: '739px',
};

export const Overlay = styled.div`
  position: fixed;
  display: grid;
  justify-content: center;
  align-items: center;
  place-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--way-palette-black-60);
  z-index: 9999999;
  padding: 0 15px;
`;

export const CloseButtonWrapper = styled.div<{ positionAbsolute?: boolean }>`
  border: 0.5px solid var(--way-colors-borderColor);
  padding: 10px;
  border-radius: 50% !important;
  height: 42px;
  width: 42px;
  cursor: pointer;

  ${({ positionAbsolute }) =>
    positionAbsolute
      ? `
      position: absolute;
      top: -10px;
      right: -10px;
    `
      : ''}

  &:hover {
    border: 0.5px solid var(--way-colors-borderHoverColor);
  }
`;

export const Close = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 1px;
    left: 50%;
    top: 50%;
    background: var(--way-palette-black-100);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
    transform-origin: center center;
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
    transform-origin: center center;
  }
`;

export const OverlayContent = styled.div<{
  isDarkMode: boolean;
  size: ModalSize;
}>`
  width: 100%;
  position: relative;
  background: var(--way-palette-white-100);
  color: var(--way-palette-black-100);
  border-radius: 10px;
  box-shadow: var(--way-design-boxShadow-l);
  padding: ${({ size }) => (size === 'large' ? '36px' : '24px')};
  margin: 30px 0;

  min-width: 200px;
  max-width: ${({ size }) => CONTENT_MAX_WIDTH[size]};

  ${({ isDarkMode }) =>
    isDarkMode &&
    css`
      background: var(--way-palette-black-30);
      color: var(--way-palette-white-100);
    `}
`;

export const ModalTitle = styled(MainTitleH3)`
  margin-bottom: 0px !important;
  line-height: normal !important;
  color: var(--way-palette-black-100) !important;
`;

export const ContentBody = styled.div`
  max-height: calc(90vh - 220px);
  overflow-y: auto;
  padding-right: 12px;
`;

export const HeaderWrapper = styled(Flex)`
  width: 100%;
`;
