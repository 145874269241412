export default {
  // these should be unique as the values are used as keys
  profileImageOfHost: 'Profilbild des Gastgebers {{hostName}}',
  hostedBy: 'Gastgeber:',
  experiences: 'Erlebnisse',
  experience: 'Erlebnis',
  clear: 'Löschen',
  cancel: 'Abbrechen',
  apply: 'Anwenden',
  more: 'Mehr',
  experiencePhotoOfListing:
    'Erlebnisfoto {{photoNumber}} von Angebot {{listingTitle}}',
  noExperiencesFound: 'Keine Erlebnisse gefunden',
  noExperiencesFoundFor: 'Keine Erlebnisse gefunden für',
  discoverExperiences: 'Erlebnisse entdecken',
  clearFilters: 'Filter löschen',
  buyTickets: 'Tickets kaufen',
  reserve: 'Reservieren',
  activateProductCardTitle: 'Veranstaltungen',
  reserveProductCardTitle: 'Aktivitäten',
  hostProductCardTitle: 'Lokale Erlebnisse',
  mainLandingPageTitle: 'Alle Erlebnisse',
  activateLandingPageTitle: 'Veranstaltungen',
  reserveLandingPageTitle: 'Aktivitäten und Annehmlichkeiten',
  hostLandingPageTitle: 'Lokale Erlebnisse',
  exclusive: 'Exklusiv',
  filterExperiences: 'Erlebnisse filtern',
};
