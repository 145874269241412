import React, { FC } from 'react';
import { useBrandProducts } from 'hooks/useBrandProducts';

import { useLandingPageRouteParams } from 'hooks/useLandingPageRouteParams';
import { ProductLandingPage } from './ProductLandingPage';
import { AllProductsLandingPage } from './AllProductsLandingPage';

const MainLandingPage: FC = () => {
  const brandProducts = useBrandProducts();
  const { product } = useLandingPageRouteParams();

  if (product || brandProducts.length === 1) {
    return <ProductLandingPage product={product ?? brandProducts[0]} />;
  }

  return <AllProductsLandingPage />;
};

export default MainLandingPage;
