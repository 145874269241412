import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import CustomizableCalendarDay from 'react-dates/lib/components/CustomizableCalendarDay';
import 'components/theme/ReactDate';
// import { useAppState } from 'AppProvider';
// import { defaultProps } from 'react-select/src/Select';

const START_DATE = 'startDate';
// const END_DATE = 'endDate';

const DayStyleShape = {
  width: ' 39px',
  height: '39px',
  borderRadius: '0px',
  background: 'rgba(45, 54, 87, 0.0893247)',
  border: '3px solid var(--way-palette-white-100)',
  padding: 0,
  margin: 0,
  lineHeight: '33px',
  fontWeight: 'normal',
  fontSize: '14px',
};

const hoveredStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
};

const selectedStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',

  // hover: {
  //   background: 'rgba(45, 54, 87, 0.4)',
  //   border: '1px solid #e4e7e7',
  //   color: 'var(--way-palette-white-100)',
  // },
};

const selectedSpanStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',

  hover: {
    background: 'rgba(45, 54, 87, 0.4)',
    border: '3px solid var(--way-palette-white-100)',
    color: '#051E39',
  },
};

const blockedCalendarStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
};
const blockedOutOfRangeStyles = {
  background: 'rgba(45, 54, 87, 0.0893247)',
  border: '3px solid var(--way-palette-white-100)',
  color: 'rgba(5, 30, 57,.33)',
  borderRadius: '0px',
};

const highlightedCalendarStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
  hover: {
    background: 'rgba(45, 54, 87, 0.4)',
    border: '3px solid var(--way-palette-white-100)',
    color: '#051E39',
  },
};

const outsideStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
};

const DateRangePicker = ({
  startDate,
  endDate,
  onChange,
  onOutsideClick,
  orientation,
  ...props
}) => {
  // const { colors } = useAppState((state) => state.theme);
  const [focus, setFocus] = useState(START_DATE);

  const customDayStyles = useMemo(
    () => ({
      defaultStyles: {
        ...DayStyleShape,
      },
      selectedStartStyles: {
        ...selectedStyles,
        // background: colors.buttonColor,
      },
      selectedEndStyles: {
        ...selectedStyles,
        // background: colors.buttonColor,
      },
      hoveredSpanStyles: {
        ...hoveredStyles,
        // background: colors.buttonColor,
        // color: 'var(--way-palette-white-100)',
      },
      selectedSpanStyles,
      selectedStyles: {
        ...selectedStyles,
        // background: colors.buttonColor,
        // border: '1px solid #e4e7e7',
        // color: 'var(--way-palette-white-100)',
      },
      highlightedCalendarStyles,
      outsideStyles,
      blockedCalendarStyles,
      blockedOutOfRangeStyles,
    }),
    [],
  );

  return (
    <div>
      <DayPickerRangeController
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onChange}
        enableOutsideDays={false}
        keepOpenOnDateSelect={false}
        hideKeyboardShortcutsPanel
        focusedInput={focus}
        onFocusChange={(focusedInput) =>
          setFocus(!focusedInput ? START_DATE : focusedInput)
        }
        numberOfMonths={2}
        onOutsideClick={onOutsideClick}
        orientation={orientation}
        renderCalendarDay={(dayProps) => (
          <CustomizableCalendarDay {...dayProps} {...customDayStyles} />
        )}
        {...props}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
  orientation: PropTypes.string,
};

DateRangePicker.defaultProps = {
  startDate: null,
  endDate: null,
  orientation: 'horizontal',
};

export default DateRangePicker;
