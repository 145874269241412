import styled, { css } from 'styled-components';

const measureTextWidth = (text: string, element: HTMLElement) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return 0;
  }
  context.font = element.style.font;
  return context?.measureText(text).width;
};

const getNumberOfLinesInText = (
  paragraphElement: HTMLParagraphElement | HTMLLIElement,
) => {
  const computedStyle = window.getComputedStyle(paragraphElement);
  let { lineHeight } = computedStyle;
  if (!lineHeight.endsWith('px')) {
    lineHeight = `${
      parseFloat(lineHeight) * parseFloat(computedStyle.fontSize)
    }px`;
  }
  const numberOfLines = Math.ceil(
    paragraphElement.clientHeight / parseFloat(lineHeight),
  );

  return numberOfLines;
};

const getNumberOfLinesInRichText = (parentElement: HTMLDivElement) => {
  const maxWidth = parentElement.clientWidth;

  /* <p> and ul<li> are the only two block elements that we support in our dashboard's rich text editor */
  const elements = parentElement.querySelectorAll<
    HTMLParagraphElement | HTMLLIElement
  >('p, li');

  let totalLines = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const element of Array.from(elements)) {
    const includesLineBreak = Array.from(element?.children)
      .map((c) => c.tagName)
      .includes('BR');

    // If there's a line break, increment the total lines count
    if (includesLineBreak) {
      totalLines += 1;
    } else {
      // Calculate the number of lines based on text content and styles
      totalLines += getNumberOfLinesInText(element);

      // Handle potential overflow due to width constraints
      const elementWidth = element.clientWidth;
      if (elementWidth > maxWidth) {
        const words = element?.textContent?.split(/\s+/);

        // Simulate word wrapping to count lines
        let currentLine = '';
        let linesInElement = 0;

        // eslint-disable-next-line no-restricted-syntax
        for (const word of words ?? []) {
          const testLine = `${currentLine} ${word}`;
          const testWidth = measureTextWidth(testLine, element);

          if (testWidth > maxWidth) {
            linesInElement += 1;
            currentLine = word;
          } else {
            currentLine = testLine;
          }
        }

        // Add the last line and any extra lines due to word wrapping
        totalLines += linesInElement + 1;
      }
    }
  }

  return totalLines;
};

type IParams =
  | {
      isRichText: true;
      ref: HTMLDivElement | null;
    }
  | {
      isRichText: false;
      ref: HTMLParagraphElement | null;
    };

export const calculateNumberOfLines = ({ isRichText, ref }: IParams) => {
  if (!ref) return 0;
  if (isRichText) {
    return getNumberOfLinesInRichText(ref);
  }
  return getNumberOfLinesInText(ref);
};

export const withTruncationStyles = (
  element: keyof JSX.IntrinsicElements | React.ComponentType,
) => styled(element)<{
  shouldTruncate: boolean;
  maxLines?: number;
  customStyles?: ReturnType<typeof css>;
}>`
  ${(props) => props.customStyles};
  ${({ maxLines, shouldTruncate }) =>
    maxLines &&
    shouldTruncate &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: var(--maxLines, ${maxLines});
      -webkit-box-orient: vertical;
    `}
`;
