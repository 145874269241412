import React from 'react';
import styled from 'styled-components';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import { useIsTablet, useIsMobile } from 'WindowDimensionProvider';
import { Wrapper } from 'components/theme/Wrapper';
import { NavigationHeader } from 'components/NavigationHeader';
import {
  ResponsiveFlex,
  Sep,
  Flex,
} from 'components/common/styled/common-styled';
import AddOnsList from './AddOnsList';

const AddOnsPageLoading = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return (
    <Wrapper>
      <LayoutWrapper>
        <NavigationHeader />
        <ContentWrapper>
          <ResponsiveFlex
            direction="row"
            justifyContent="space-between"
            alignItem="flex-start"
            gap={72}
          >
            <ResponsiveFlex
              flex={1}
              justifyContent="flex-start"
              alignItem="flex-start"
              direction="column"
              gap={36}
            >
              <ResponsiveFlex
                direction="column"
                alignItem="flex-start"
                gap={12}
              >
                <SkeletonLineSlim style={{ width: 300 }} />
                <SkeletonLineSlim style={{ width: 200 }} />
              </ResponsiveFlex>

              <Sep />

              <AddOnsWrapper>
                <AddOnsList
                  addOns={[]}
                  addOnsAmounts={{}}
                  isLoading
                  columns={isMobile || isTablet ? 1 : 2}
                />
              </AddOnsWrapper>

              <Sep />
              <SkeletonLineSlim
                style={{
                  width: '100%',
                  height: '48px',
                }}
              />
            </ResponsiveFlex>

            {!isMobile && (
              <SidebarWrapper
                direction="column"
                alignItem="flex-start"
                gap={24}
              >
                <ImageWrapper>
                  <SkeletonLineSlim
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </ImageWrapper>
                <Details>
                  <SkeletonLineSlim
                    style={{
                      width: '100%',
                      marginBottom: '24px',
                    }}
                  />
                  <SkeletonLineSlim
                    style={{
                      width: '100%',
                    }}
                  />
                </Details>
              </SidebarWrapper>
            )}
          </ResponsiveFlex>
        </ContentWrapper>
      </LayoutWrapper>
    </Wrapper>
  );
};

const LayoutWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  > div {
    flex-shrink: 0;
    height: 50px;
    margin-bottom: 12px;
  }
`;

const ContentWrapper = styled.main`
  position: relative;
`;

const AddOnsWrapper = styled.div`
  width: 100%;
  max-height: calc(100vh - 400px);
  min-height: 270px;
  overflow-y: auto;
`;

const SidebarWrapper = styled(Flex)`
  width: 350px;
  border: 0.5px solid var(--way-palette-borderColor);
  box-shadow: var(--way-design-boxShadow-s);
  max-width: 350px;
  height: min-content;
  gap: 0px;
  background: var(--way-palette-white-100);
  align-self: stretch;

  & > * {
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 300px;
  padding: 24px;
  overflow: hidden;
  border-bottom: 0.5px solid var(--way-colors-borderColor);
`;

const Details = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

export default AddOnsPageLoading;
