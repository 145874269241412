import { useCallback } from 'react';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import { WaitlistMode } from '@kouto/types';
import { useBrandId } from 'AppProvider';
import useEventBookingSessionPropsFromUrl from './useEventBookingSessionPropsFromUrl';
import useEventBookingSessionSelectedData from './useEventBookingSessionSelectedData';

interface BookingProps {
  eventId?: string;
  date?: string;
  preselectedDate?: string;
  latest?: boolean;
}

const formatNextBookingUrl = (props: BookingProps) => {
  let url = '';

  if (props.eventId) {
    url += `/event/${props.eventId}`;

    const searchParams = new URLSearchParams();
    if (props.date) {
      url += `/tickets/${props.date}`;
    }

    if (props.preselectedDate) {
      searchParams.set('preselectedDate', props.preselectedDate);
    }

    if (props.latest) {
      searchParams.set('latest', 'true');
    }

    if (searchParams.size) {
      url += `?${searchParams.toString()}`;
    }
  }

  return url;
};

const useEventBookingSession = () => {
  const location = useLocation();
  const history = useHistory();
  const brandId = useBrandId();

  /* eslint-disable prefer-const */
  // 1 - get props from url
  let { eventId, date, preselectedDate, latest } =
    useEventBookingSessionPropsFromUrl();

  // 2 - get data using props from url
  let {
    isLoading,
    isLoadingEvent,
    isLoadingEventSessions,
    isLoadingDaySessions,
    isLoadingWaitlist,
    isLoadingCancellationPolicy,
    event,
    eventSessions,
    daySessions,
    waitlist,
    cancellationPolicy,
  } = useEventBookingSessionSelectedData({
    brandId,
    eventId,
    date,
    latest,
  });
  /* eslint-enable prefer-const */

  // 3 - if the given ids are not found, reset the data
  if (!event) {
    eventId = undefined;
    date = undefined;
    eventSessions = [];
    daySessions = [];
  } else if (date) {
    const setFirstAvailableDate = waitlist
      ? waitlist.mode === WaitlistMode.NONE
      : true;
    if (
      setFirstAvailableDate &&
      event.firstAvailableDate?.scheduledDate &&
      moment(event.firstAvailableDate.scheduledDate).isAfter(date)
    ) {
      date = event.firstAvailableDate.scheduledDate;
    }
  }

  // 4 - format new cleaned url
  const cleanedUrl = formatNextBookingUrl({
    eventId,
    date,
    preselectedDate,
    latest,
  });

  const setEventBookingSessionParams = useCallback(
    (changes: BookingProps, forceHistoryPush = false) => {
      const nextUrl = formatNextBookingUrl({
        eventId,
        date,
        preselectedDate,
        latest,
        ...changes,
      });

      if (`${location.pathname}${location.search}` !== nextUrl) {
        const [pathname, search] = nextUrl.split('?');
        if (forceHistoryPush || location.pathname !== pathname) {
          history.push({
            pathname,
            search,
          });
        } else {
          history.replace({
            pathname,
            search,
          });
        }
      }
    },
    [location, history, eventId, date, preselectedDate, latest],
  );

  return {
    isLoading,
    isLoadingEvent,
    isLoadingEventSessions,
    isLoadingDaySessions,
    isLoadingWaitlist,
    isLoadingCancellationPolicy,
    cleanedUrl,
    event,
    eventSessions,
    daySessions,
    waitlist,
    cancellationPolicy,
    cancellationPolicyEnabled:
      !!cancellationPolicy && cancellationPolicy.active,
    waitlistEnabled: !!waitlist && waitlist.mode !== WaitlistMode.NONE,
    eventId,
    date,
    preselectedDate,
    latest,
    setEventBookingSessionParams,
  };
};

export default useEventBookingSession;
