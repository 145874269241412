import styled, { css } from 'styled-components';
import ChevronDownIcon from 'assets/chevron-down';
import { PrimaryButton, SecondaryButton } from 'components/theme/Button/Button';
import { Flex } from 'components/common/styled/common-styled';
import {
  ResourceHeading2,
  ResourceParagraph,
} from 'components/theme/Typography/ResourceTypography';
import { CSSProperties } from 'react';
import { tabletBreakpoint } from '../../WindowDimensionProvider';

export const ResourceGroupImage = styled.img<{ isSixteenNineRatio: boolean }>`
  object-fit: cover;
  max-height: 100%;
  height: 80px;
  width: 100%;
  object-position: center;
  border-radius: var(--way-design-borderRadiusDefault);
`;

export const ResourceGroupText = styled(ResourceParagraph)<{
  minWidth?: string;
}>`
  white-space: nowrap;
  min-width: ${({ minWidth }) => minWidth ?? 'auto'};
`;

export const ChevronIconWrapper = styled.div`
  border: 0.5px solid var(--way-palette-black-5);
  min-width: 46px;
  max-width: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: stretch;
  gap: 16px;
`;

export const SelectBox = styled.label`
  & .resource-group-card-sessions__indicator {
    padding: 0px;
    margin-right: -5px;
  }

  & .resource-group-card-sessions__control {
    &:hover {
      border-color: var(--way-palette-black-30);
    }
  }
`;

export const HeaderWrapper = styled.div<{
  mobileGap?: CSSProperties['gap'];
  gap?: CSSProperties['gap'];
}>`
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: ${(props) => props.gap};
  align-items: center;

  @media all and (max-width: 450px) {
    gap: 8px;
  }

  @media all and (max-width: ${tabletBreakpoint}) {
    gap: ${(props) => props.mobileGap};
  }

  picture {
    display: grid;
    max-width: 120px;
    max-height: 80px;

    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

export const ResourceChevronIcon = styled(ChevronDownIcon)<{
  isExpanded: boolean;
}>`
  transition: transform ease-in-out 300ms;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      transform: rotate(180deg);
    `}
`;

export const ReserveButton = styled(PrimaryButton)`
  height: 48px;
  min-width: 120px;

  @media all and (max-width: ${tabletBreakpoint}) {
    font-size: 12px;
    min-width: 90px;
    height: 36px;
  }
`;

export const SecondaryReserveButton = styled(SecondaryButton)`
  padding: 4px 12px !important;
  display: grid;
  place-items: center;
  font-weight: 350;
  color: var(--way-colors-buttonColorShades-100);
  border: 0.5px solid var(--way-colors-borderColor);
  &:hover {
    border: 0.5px solid var(--way-colors-borderHoverColor);
  }

  font-size: 12px;
  min-width: 90px;
  height: 36px;
`;

export const DetailsContainer = styled.div`
  padding: 0 24px;
`;

export const ResourceDetailsSection = styled(Flex)`
  padding: 24px 0;
`;

export const ResourceIncludedSection = styled(Flex)`
  padding: 24px 0;
`;

export const ResourceIncludedItem = styled.div`
  padding: 8px 12px;
  border-radius: 6px;
  border: 0.5px solid var(--way-colors-borderColor);
`;

export const MainHeading = styled(ResourceHeading2)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  @media all and (max-width: 768px) {
    width: auto;
    white-space: normal;
  }
`;
