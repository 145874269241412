import styled from 'styled-components';

export const SkeletonPulseHero = styled.div`
  display: inline-block;
  padding-bottom: 150%;
  margin-bottom: 62px;
  width: 100%;
  background: ${(props) =>
    props.translucent
      ? 'linear-gradient(-90deg, #C1C1C1 0%, #F8F8F8 50%, #C1C1C1 100%)'
      : 'linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%)'};
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
  @media (min-width: 768px) {
    padding-bottom: 40%;
  }
`;

export const SkeletonPulse = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) =>
    props.translucent
      ? 'linear-gradient(-90deg, #C1C1C1 0%, #F8F8F8 50%, #C1C1C1 100%)'
      : 'linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%)'};
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export const SkeletonPulseAbsolute = styled(SkeletonPulse)`
  position: absolute;
`;

export const SkeletonLine = styled(SkeletonPulse)`
  width: 100%;
  min-width: 200px;
  border-radius: 10px;
  margin-bottom: 8px;
  &::before {
    content: '\u00a0';
  }
  color: transparent;
`;

export const SkeletonLineSlim = styled(SkeletonLine)`
  margin-bottom: 0px;
  min-width: 100px;
`;
