import {
  AVAILABLE_BRAND_FEATURES,
  Currency,
  CurrencySymbol,
} from '@kouto/types';

export const FETCH_SETTINGS_REQUEST = '@brand/fetch-settings-request';
export const FETCH_SETTINGS_SUCCESS = '@brand/fetch-settings-success';
export const FETCH_SETTINGS_FAILURE = '@brand/fetch-settings-failure';

export const FETCH_INTEGRATIONS_REQUEST = '@brand/fetch-integrations-request';
export const FETCH_INTEGRATIONS_SUCCESS = '@brand/fetch-integrations-success';
export const FETCH_INTEGRATIONS_FAILURE = '@brand/fetch-integrations-failure';

export const FETCH_TERMS_REQUEST = '@brand/fetch-terms-request';
export const FETCH_TERMS_SUCCESS = '@brand/fetch-terms-success';
export const FETCH_TERMS_FAILURE = '@brand/fetch-terms-failure';

export const SEARCH_CATEGORY_REQUEST = '@brand/search-category-request';
export const SEARCH_CATEGORY_SUCCESS = '@brand/search-category-success';
export const SEARCH_CATEGORY_FAILURE = '@brand/search-category-failure';

export const SEARCH_VIBES_REQUEST = '@brand/search-vibes-request';
export const SEARCH_VIBES_SUCCESS = '@brand/search-vibes-success';
export const SEARCH_VIBES_FAILURE = '@brand/search-vibes-failure';

export const FETCH_CALENDAR_EVENTS_REQUEST =
  '@experience/fetch-calendar-events-request';
export const FETCH_CALENDAR_EVENTS_SUCCESS =
  '@experience/fetch-calendar-events-success';
export const FETCH_CALENDAR_EVENTS_FAILURE =
  '@experience/fetch-calendar-events-failure';

export const FETCH_CALENDAR_FIRST_AVAILABLE_DATE_REQUEST =
  '@experience/fetch-calendar-first-available-date-request';
export const FETCH_CALENDAR_FIRST_AVAILABLE_DATE_SUCCESS =
  '@experience/fetch-calendar-first-available-date-success';
export const FETCH_CALENDAR_FIRST_AVAILABLE_DATE_FAILURE =
  '@experience/fetch-calendar-first-available-date-failure';

export const THEMES = {
  DEFAULT: 'default',
  DARK: 'dark',
};

type CurrencyObject = {
  code: Currency;
  fullName: string;
  currencySymbol: CurrencySymbol;
};

export const CURRENCY_CODES: Record<Currency, CurrencyObject> = {
  [Currency.AED]: {
    code: Currency.AED,
    fullName: 'UAE Dirham',
    currencySymbol: CurrencySymbol.AED,
  },
  [Currency.AUD]: {
    code: Currency.AUD,
    fullName: 'Australian Dollar',
    currencySymbol: CurrencySymbol.AUD,
  },
  [Currency.BRL]: {
    code: Currency.BRL,
    fullName: 'Brazilian Real',
    currencySymbol: CurrencySymbol.BRL,
  },
  [Currency.CAD]: {
    code: Currency.CAD,
    fullName: 'Canadian Dollar',
    currencySymbol: CurrencySymbol.CAD,
  },
  [Currency.CHF]: {
    code: Currency.CHF,
    fullName: 'Swiss Franc',
    currencySymbol: CurrencySymbol.CHF,
  },
  [Currency.EUR]: {
    code: Currency.EUR,
    fullName: 'Euro',
    currencySymbol: CurrencySymbol.EUR,
  },
  [Currency.GBP]: {
    code: Currency.GBP,
    fullName: 'UK Pound',
    currencySymbol: CurrencySymbol.GBP,
  },
  [Currency.HUF]: {
    code: Currency.HUF,
    fullName: 'Hungarian Forint',
    currencySymbol: CurrencySymbol.HUF,
  },
  [Currency.JPY]: {
    code: Currency.JPY,
    fullName: 'Japanese Yen',
    currencySymbol: CurrencySymbol.JPY,
  },
  [Currency.KRW]: {
    code: Currency.KRW,
    fullName: 'Korean Won',
    currencySymbol: CurrencySymbol.KRW,
  },
  [Currency.MXN]: {
    code: Currency.MXN,
    fullName: 'Mexican Peso',
    currencySymbol: CurrencySymbol.MXN,
  },
  [Currency.USD]: {
    code: Currency.USD,
    fullName: 'US Dollar',
    currencySymbol: CurrencySymbol.USD,
  },
};

export const AVAILABLE_INTEGRATIONS = {
  OPERA: 'opera',
  STAYNTOUCH: 'stayntouch',
  INFOR: 'infor',
};

export const BRAND_FEATURES = Object.values(AVAILABLE_BRAND_FEATURES);

export const PAYMENT_PLATFORMS = {
  STRIPE_US: 'stripe-us',
  STRIPE_EU: 'stripe-eu',
  KICC: 'kicc',
};
