import {
  WaitlistActionTypes,
  WaitlistAction,
  IWaitlistState,
} from 'types/waitlist';
import initialState from 'store/initial-state';

export default function cartReducer(
  state: IWaitlistState = initialState.waitlist,
  action: WaitlistAction,
): IWaitlistState {
  switch (action.type) {
    case WaitlistActionTypes.FETCH_WAITLIST_EXPERIENCE: {
      return {
        ...state,
        isFetchingExperience: true,
      };
    }
    case WaitlistActionTypes.ADD_EXPERIENCE_TO_WAITLIST: {
      return {
        ...state,
        experience: action.payload.experience,
        isFetchingExperience: false,
      };
    }
    case WaitlistActionTypes.ADD_ITEM_TO_WAITLIST: {
      return {
        ...state,
        item: action.payload.item,
      };
    }
    case WaitlistActionTypes.FETCH_WAITLIST_EXPERIENCE_ERROR: {
      return {
        ...state,
        waitlistError: true,
        isFetchingExperience: false,
      };
    }
    case WaitlistActionTypes.CLEAN_WAITLIST: {
      return {
        item: null,
        experience: null,
        waitlistError: false,
        isFetchingExperience: false,
      };
    }
    default:
      return state;
  }
}
