import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'AppProvider';

const BrandTerms = () => {
  const { t: translateText } = useTranslation();
  const { terms } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );
  const termsText = translateText('brandAggreementText');

  return (
    <>
      <span>
        {termsText}
        {terms?.map((brandTerm: object) => {
          return (
            <span key={get(brandTerm, 'media.id')}>
              &nbsp;
              <ExternalLink
                target="_blank"
                rel="noopener noreferrer"
                href={get(brandTerm, 'media.uri.original')}
              >
                {get(brandTerm, 'title')}
              </ExternalLink>
            </span>
          );
        })}
      </span>
    </>
  );
};

const ExternalLink = styled.a`
  color: #0086e6;
  font-family: ${({ theme }) => theme.font.secondaryFont};
  cursor: pointer;
  text-decoration: none;
`;

export default BrandTerms;
