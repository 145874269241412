import React from 'react';
import { RadioGroup } from 'components/RadioGroup';
import { AnswerInputComponent } from './types';

const options = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

export const Binary: AnswerInputComponent = ({ selectedValue, onChange }) => {
  return (
    <RadioGroup
      selectedValue={selectedValue}
      options={options}
      onChange={onChange}
    />
  );
};
