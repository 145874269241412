import { z } from 'zod';
import useSWR from 'swr';
import { get } from 'utils/axios';
import {
  PublicGetListingSessionsEndpoint,
  SessionStatus,
  ListingVersion,
} from '@kouto/types';
import { Session } from 'types/listings';

export interface PublicSessionsQueryParams {
  from?: string;
  to?: string;
  collectionId?: string;
  resourceGroupId?: string;
  experienceId?: string;
  latest?: boolean;
  skip?: boolean;
}

const useCollectionAvailableSessions = ({
  from,
  to,
  collectionId,
  resourceGroupId,
  experienceId,
  latest,
  skip = false,
}: PublicSessionsQueryParams) => {
  let sessionsApiUrl: string | null = null;

  if (from && to && collectionId) {
    const queryParam: z.infer<
      typeof PublicGetListingSessionsEndpoint.querySchema
    > = {
      from,
      to,
      version: latest ? ListingVersion.LATEST : ListingVersion.PUBLISHED,
    };
    if (resourceGroupId) {
      queryParam.resourceGroupId = resourceGroupId;
    }
    if (experienceId) {
      queryParam.experienceId = experienceId;
    }
    sessionsApiUrl = PublicGetListingSessionsEndpoint.url({
      listingId: collectionId,
      query: queryParam,
    });
  }

  const response = useSWR(
    skip ? null : sessionsApiUrl,
    get<{ data: { sessions: Session[] } }>,
  );

  let sessions = response.data?.data.sessions || [];
  sessions = sessions.filter((session) => !session.participantCount); // booked sessions
  sessions = sessions.filter(
    (session) => session.status !== SessionStatus.OVERLAPPED,
  ); // overlapped sessions
  sessions.sort((s1, s2) => (s1.startDateTime > s2.startDateTime ? 1 : -1));

  return {
    sessions,
    isLoadingSessions: response.isLoading || response.isValidating,
  };
};

export default useCollectionAvailableSessions;
