import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Session } from 'types/listings';
import { formatDuration, getSessionStartEndTime } from 'utils/formatDuration';
import { Span } from '../theme/Typography/ResourceTypography';

interface Props {
  sessions: Session[];
  selectedSession?: Session;
  onSessionClick?: (session: Session) => void;
}

export const ResourceSessionList: React.FC<Props> = ({
  sessions,
  selectedSession,
  onSessionClick,
}) => {
  const modifiedSessions = useMemo(() => {
    return sessions.map((session) => {
      const duration = formatDuration(session.duration);

      return {
        ...getSessionStartEndTime(session, 'h:mm A'),
        duration,
        original: session,
      };
    });
  }, [sessions]);

  return (
    <Container className="session-list-container">
      {modifiedSessions.map(
        ({ startTime, endTime, duration, original: session }) => (
          <SessionItem
            className={
              selectedSession?.startDateTime === session.startDateTime &&
              selectedSession?.duration === session.duration
                ? 'selected'
                : ''
            }
            key={`${session.startDateTime}_${session.duration}`}
            onClick={() => onSessionClick?.(session)}
          >
            <Span className="session-time" fontWeight="600px" fontSize="14px">
              {startTime} - {endTime}
            </Span>
            <Span
              className="session-duration"
              fontSize="14px"
              color="var(--way-palette-black-80)"
            >
              {duration}
            </Span>
          </SessionItem>
        ),
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SessionItem = styled.div`
  cursor: pointer;
  border: 0.5px solid var(--way-colors-borderColor);
  height: 41px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &:hover {
    background: rgba(212, 212, 212, 0.6);
  }

  &.selected {
    background: rgba(212, 212, 212, 0.3);

    &:hover {
      border: 0.5px solid var(--way-colors-borderHoverColor);
    }
  }
`;
