export default {
  // these should be unique as the values are used as keys
  profileImageOfHost: 'Изображение профиля хозяина {{hostName}}',
  hostedBy: 'Организовано',
  experiences: 'впечатления',
  experience: 'впечатление',
  clear: 'Очистить',
  cancel: 'Отмена',
  apply: 'Применить',
  more: 'Больше',
  experiencePhotoOfListing:
    'Фото впечатления {{photoNumber}} из объявления {{listingTitle}}',
  noExperiencesFound: 'Впечатления не найдены',
  noExperiencesFoundFor: 'Впечатления не найдены для',
  discoverExperiences: 'Откройте для себя впечатления',
  clearFilters: 'Очистить фильтры',
  buyTickets: 'Купить билеты',
  reserve: 'Забронировать',
  activateProductCardTitle: 'События',
  reserveProductCardTitle: 'Мероприятия',
  hostProductCardTitle: 'Местные впечатления',
  mainLandingPageTitle: 'Все впечатления',
  activateLandingPageTitle: 'События',
  reserveLandingPageTitle: 'Мероприятия и удобства',
  hostLandingPageTitle: 'Местные впечатления',
  exclusive: 'Эксклюзив',
  filterExperiences: 'Фильтровать впечатления',
};
