import axios, { AxiosResponse, Method } from 'axios';
import { cacheRequest } from './requestCache';

export const axiosInstance = axios.create({
  baseURL: process.env.KOUTO_API_URL,
  timeout: 40 * 1000,
});

interface Headers {
  [key: string]: string;
}

const headers: Headers = {};

const axiosRequestWithBody =
  (method: Omit<Method, 'GET' | 'get' | 'DELETE' | 'delete'>) =>
  async <T, R>(url: string, data: R): Promise<T> => {
    const response = await axiosInstance.request<T, AxiosResponse<T>, R>({
      method: method.toString(),
      url,
      data,
    });
    return response.data;
  };

const axiosRequestWithoutBody =
  (method: 'get' | 'GET' | 'delete' | 'DELETE') =>
  async <T>(url: string): Promise<T> => {
    if (method === 'get' || method === 'GET') {
      return cacheRequest<T>(url, async () => {
        const response = await axiosInstance.request<T, AxiosResponse<T>>({
          method: method.toString(),
          url,
          headers,
        });
        return response.data;
      });
    }
    const response = await axiosInstance.request<T, AxiosResponse<T>>({
      method: method.toString(),
      url,
      headers,
    });
    return response.data;
  };

export const get = axiosRequestWithoutBody('get');

export const post = axiosRequestWithBody('post');

export const patch = axiosRequestWithBody('patch');

export const put = axiosRequestWithBody('put');

export const deleteRequest = axiosRequestWithoutBody('delete');

export const setAxiosHeader = (key: string, value: string) => {
  if (key && value) {
    headers[key] = value;
  }
};
