import moment, { Moment } from 'moment';
import { Session } from 'types/listings';
import { ISO_DATE_FORMAT, TIME_FORMAT_FOR_DISPLAY } from 'utils';

export const useDaySessionRange = (sessions: Session[]) => {
  const { earliestStartTime, latestEndTime } = sessions.reduce(
    (result, session) => {
      let earliestStartTime: Moment | null = result.earliestStartTime ?? null;
      let latestEndTime: Moment | null = result.latestEndTime ?? null;

      const startTime = moment(session.startDateTime, ISO_DATE_FORMAT);
      const endTime = startTime.clone().add(session.duration, 'minutes');

      if (!earliestStartTime || startTime.isBefore(earliestStartTime)) {
        earliestStartTime = startTime;
      }

      if (!latestEndTime || endTime.isAfter(latestEndTime)) {
        latestEndTime = endTime;
      }

      return {
        ...result,
        earliestStartTime,
        latestEndTime,
      };
    },
    {} as { earliestStartTime: Moment; latestEndTime: Moment },
  );

  return {
    from: earliestStartTime?.format(TIME_FORMAT_FOR_DISPLAY),
    to: latestEndTime?.format(TIME_FORMAT_FOR_DISPLAY),
  };
};
