import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { roundToDecimal } from 'utils';

import RateSvg from 'components/common/rating-small.svg';

export const RatingSummary = ({ rating, count }) => {
  if (!count || !rating) {
    return null;
  }
  return (
    <RateBlock className="rating-summary__rate-block">
      <YellowStar />
      {roundToDecimal(rating)} ({count})
    </RateBlock>
  );
};

RatingSummary.propTypes = {
  rating: PropTypes.number,
  count: PropTypes.number,
};

RatingSummary.defaultProps = {
  rating: 0,
  count: 0,
};

const RateBlock = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const YellowStar = styled(RateSvg)`
  margin-right: 7px;
`;
