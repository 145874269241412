import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoBoxProps } from './types';
import { HostName, HostPhoto, InfoBox } from './styles';

export const HostInfoBox: FC<InfoBoxProps> = ({ listing }) => {
  const { t: translate } = useTranslation();
  const profilePicture = listing.hostedBy?.profilePicture?.uri['48w'];
  const name = listing.hostedBy?.firstName;

  if (!name) return null;

  return (
    <InfoBox className="listing-carousel-card-details">
      {profilePicture && (
        <HostPhoto
          src={profilePicture}
          alt={translate('profileImageOfHost', { hostName: name })}
          className="listing-card-host-photo"
        />
      )}
      <HostName>
        {translate('hostedBy')} {name}
      </HostName>
    </InfoBox>
  );
};
