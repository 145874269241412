import i18n from 'i18next';

export const lastNameValidation = {
  required: {
    value: true,
    message: i18n.t('lastNameValidationError'),
  },
};

export const reservationCodeValidation = {
  required: {
    value: true,
    message: `${i18n.t('reservationCode')} ${i18n.t('isReqd')}`,
  },
};

export const roomNumberValidation = {
  required: {
    value: true,
    message: `${i18n.t('roomNumber')} ${i18n.t('isReqd')}`,
  },
};

export const roomNumberLegacyValidation = {
  required: {
    value: true,
    message: `${i18n.t('roomNumberLegacy')} ${i18n.t('isReqd')}`,
  },
};

export const memberNumberValidation = {
  required: {
    value: true,
    message: `${i18n.t('memberNumber')} ${i18n.t('isReqd')}`,
  },
};

export const creditCardValidation = {
  message: `${i18n.t('cardNumber')} ${i18n.t('isReqd')}`,
};

export const pmsAuthValidation = {
  message: i18n.t('pmsValidationErrorMessage'),
};

export const pmsDateValidation = {
  message: i18n.t('pmsDateValidationErrorMessage'),
};
