import styled from 'styled-components';
import React, { ChangeEvent, ForwardedRef, forwardRef } from 'react';

import RadioField from 'components/theme/RadioField/RadioField';
import { ExternalLink } from 'components/ExperienceView/styles';
import {
  Error,
  ErrorContainer,
  StyledInput,
  StyledLabel,
  TextArea,
} from 'components/theme/Form/Form';
import CheckBox from 'components/theme/CheckBox/CheckBox';
import {
  Question,
  CustomQuestionResponse,
  QUESTION_FIELD_TYPES,
} from 'types/custom-questions';
import { useTranslation } from 'react-i18next';

interface Props {
  question: Question;
  answer: CustomQuestionResponse[] | null;
  onAnswerChange?: (newAnswer: CustomQuestionResponse[] | null) => void;
  error?: string;
}

const Question = forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(
  ({ question, answer = [], onAnswerChange, error }, ref) => {
    const { t: translate } = useTranslation();

    const handleChange = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      optionKey?: string,
    ) => {
      if (
        question.fieldType === QUESTION_FIELD_TYPES.INPUT ||
        question.fieldType === QUESTION_FIELD_TYPES.TEXTAREA
      ) {
        const { value } = e.target;
        onAnswerChange?.(value ? [{ value, key: null }] : null);
      }

      if (question.fieldType === QUESTION_FIELD_TYPES.SINGLE_SELECT) {
        onAnswerChange?.([{ value: e.target.value, key: optionKey || null }]);
      }

      if (question.fieldType === QUESTION_FIELD_TYPES.MULTIPLE_SELECT) {
        const event = e as ChangeEvent<HTMLInputElement>;

        if (event.target.checked) {
          onAnswerChange?.([
            ...(answer || []),
            { value: e.target.value, key: optionKey || null },
          ]);
          return;
        }

        const newAnswer =
          answer && answer.length > 1
            ? answer.filter((a) => a.key !== optionKey)
            : null;
        onAnswerChange?.(newAnswer);
      }

      if (question.fieldType === QUESTION_FIELD_TYPES.TERMS_AND_CONDITIONS) {
        onAnswerChange?.(
          (e.target as HTMLInputElement).checked
            ? [{ value: true, key: null }]
            : null,
        );
      }
    };

    const renderQuestionField = (question: Question) => {
      const { fieldType, options } = question;

      if (fieldType === QUESTION_FIELD_TYPES.INPUT) {
        return (
          <StyledInput
            ref={ref as ForwardedRef<HTMLInputElement>}
            value={(answer?.[0]?.value || '') as string}
            name="color"
            aria-label={question.prompt}
            aria-required={question.config.isRequired ? 'true' : 'false'}
            onChange={handleChange}
          />
        );
      }

      if (fieldType === QUESTION_FIELD_TYPES.TEXTAREA) {
        return (
          <TextArea
            ref={ref as ForwardedRef<HTMLTextAreaElement>}
            value={(answer?.[0]?.value || '') as string}
            aria-label={question.prompt}
            aria-required={question.config.isRequired ? 'true' : 'false'}
            name="about"
            onChange={handleChange}
          />
        );
      }

      if (fieldType === QUESTION_FIELD_TYPES.SINGLE_SELECT) {
        return (
          <>
            {options?.map((option) => (
              <RadioField
                key={option.key}
                checked={option.key === answer?.[0]?.key}
                label={option.value}
                onChange={(e) => handleChange(e, option.key)}
                value={option.value}
              />
            ))}
          </>
        );
      }

      if (fieldType === QUESTION_FIELD_TYPES.MULTIPLE_SELECT) {
        return (
          <>
            {options?.map((option) => (
              <CheckBox
                key={option.key}
                id={option.key}
                checked={(answer || []).map((a) => a.key).includes(option.key)}
                label={option.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, option.key)
                }
                value={option.value}
              />
            ))}
          </>
        );
      }

      if (fieldType === QUESTION_FIELD_TYPES.TERMS_AND_CONDITIONS) {
        return (
          <CheckBox
            id="terms"
            value="terms"
            checked={!!answer?.[0]?.value}
            label={
              <div>
                {translate('brandAggreementText')}{' '}
                <ExternalLink
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={translate('downloadFilename', {
                    filename: question.termsAndConditions?.label,
                  })}
                  href={question.termsAndConditions?.mediaURL}
                >
                  {question.termsAndConditions?.label}
                </ExternalLink>
              </div>
            }
            onChange={handleChange}
          />
        );
      }

      return null;
    };

    return (
      <div>
        <StyledLabel>
          {question.prompt}{' '}
          {question.config.isRequired && (
            <RequiredMark style={{ color: 'red' }}>*</RequiredMark>
          )}
        </StyledLabel>
        <FieldWrapper>
          {renderQuestionField(question)}
          {error && (
            <ErrorContainer>
              <Error>{translate(error)}</Error>
            </ErrorContainer>
          )}
        </FieldWrapper>
      </div>
    );
  },
);

export default Question;

const RequiredMark = styled.span`
  color: red;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
