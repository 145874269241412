import React, {
  FC,
  PropsWithChildren,
  useState,
  useRef,
  useMemo,
  useCallback,
  Children,
  isValidElement,
  useEffect,
} from 'react';
import IconButton from 'components/theme/IconButton/IconButton';
import usePrevious from 'hooks/usePrevious';
import IconLeft from 'assets/chevron-left';
import IconRight from 'assets/chevron-right';
import { useTranslation } from 'react-i18next';
import { Wrapper, Slider } from './styled';

type Props = {
  showArrows?: boolean;
  selectedSlideIndex?: number;
  fit?: 'contain' | 'cover';
  onSlideChange?: (index: number) => void;
};

const MinimalCarousel: FC<PropsWithChildren<Props>> = ({
  showArrows = true,
  selectedSlideIndex = 0,
  fit = 'cover',
  onSlideChange,
  children,
}) => {
  const { t } = useTranslation();
  const sliderRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(selectedSlideIndex);
  const previousSelectedTabIndex = usePrevious(selectedSlideIndex);
  const [childrenLeftCoords, setChildrenLeftCoords] = useState<number[]>([]);
  const refreshChildrenLeftCoords = useCallback(() => {
    if (sliderRef.current) {
      const childrenWidth = Array.from(sliderRef.current.children).map(
        (child: Element) => Math.round(child.getBoundingClientRect().width),
      );
      const maxScroll =
        sliderRef.current.scrollWidth - sliderRef.current.clientWidth;
      let leftCoords = childrenWidth.reduce(
        (acc, width) => [
          ...acc,
          Math.min(maxScroll, acc[acc.length - 1] + width),
        ],
        [0],
      );
      leftCoords.pop();
      leftCoords = Array.from(new Set(leftCoords));
      setChildrenLeftCoords(leftCoords);
      return [...leftCoords];
    }
    return [];
  }, [sliderRef.current]);
  const canGoLeft = useMemo(() => currentIndex > 0, [currentIndex]);
  const canGoRight = useMemo(
    () => currentIndex < childrenLeftCoords.length - 1,
    [currentIndex, childrenLeftCoords],
  );

  useEffect(() => {
    refreshChildrenLeftCoords();
  }, [refreshChildrenLeftCoords]);

  const onScroll = useCallback(() => {
    if (sliderRef.current) {
      let newSelectedTabIndex: number;
      if (
        sliderRef.current.scrollLeft >=
        sliderRef.current.scrollWidth - sliderRef.current.clientWidth
      ) {
        newSelectedTabIndex = childrenLeftCoords.length - 1;
      } else {
        newSelectedTabIndex = childrenLeftCoords.filter(
          // @ts-expect-error because TS is stupid sometimes
          (leftCoord) => leftCoord <= sliderRef.current.scrollLeft - 10,
        ).length;
      }
      if (currentIndex !== newSelectedTabIndex) {
        setCurrentIndex(newSelectedTabIndex);
      }
    }
  }, [sliderRef.current, currentIndex, childrenLeftCoords]);

  const goToIndex = useCallback(
    (index: number) => {
      if (sliderRef.current) {
        const leftCoords = refreshChildrenLeftCoords();
        sliderRef.current.scrollTo({
          left: leftCoords[Math.min(leftCoords.length - 1, index)],
          behavior: 'smooth',
        });
      }
    },
    [sliderRef.current],
  );

  const goLeft = useCallback(() => {
    goToIndex(currentIndex - 1);
  }, [currentIndex, goToIndex]);

  const goRight = useCallback(() => {
    goToIndex(currentIndex + 1);
  }, [currentIndex, goToIndex]);

  useEffect(() => {
    if (onSlideChange) {
      onSlideChange(currentIndex);
    }
  }, [onSlideChange, currentIndex]);

  useEffect(() => {
    if (
      previousSelectedTabIndex !== selectedSlideIndex &&
      selectedSlideIndex !== currentIndex
    ) {
      goToIndex(selectedSlideIndex);
    }
  }, [previousSelectedTabIndex, selectedSlideIndex, currentIndex, goToIndex]);

  const formattedChildren = useMemo(
    () =>
      Children.map(children, (child) =>
        isValidElement(child) ? (
          <div key={child.key} className={`fit-${child.props?.fit ?? fit}`}>
            {child}
          </div>
        ) : null,
      )?.filter((e) => !!e),
    [children],
  );

  return (
    <Wrapper>
      {showArrows && childrenLeftCoords.length > 1 && (
        <>
          <IconButton
            aria-label={t('previousPhoto')}
            disabled={!canGoLeft}
            className={`carousel-arrow carousel-arrow-left ${
              !canGoLeft ? 'carousel-arrow-disabled' : ''
            }`}
            Icon={IconLeft}
            onClick={goLeft}
          />
          <IconButton
            aria-label={t('nextPhoto')}
            disabled={!canGoRight}
            className={`carousel-arrow carousel-arrow-right ${
              !canGoLeft ? 'carousel-arrow-disabled' : ''
            }`}
            Icon={IconRight}
            onClick={goRight}
          />
        </>
      )}
      <Slider
        role="region"
        aria-label={t('mediaSlides')}
        ref={sliderRef}
        onScroll={onScroll}
        className="carousel-slider"
      >
        {formattedChildren}
      </Slider>
    </Wrapper>
  );
};

export default MinimalCarousel;
