import ExperiencesCalendar from 'components/ExperiencesCalendar/ExperiencesCalendar';
import Home from 'components/Home/Home';
import MainLandingPage from 'features/LandingPage/MainLandingPage';

export const getHomeComponent = ({
  homeComponent,
  newLandingPageEnabled,
  isV2Brand,
}: {
  homeComponent?: string;
  newLandingPageEnabled?: boolean;

  isV2Brand?: boolean;
}) => {
  if (homeComponent === 'calendar') {
    return ExperiencesCalendar;
  }

  if (
    typeof newLandingPageEnabled === 'undefined' ||
    typeof isV2Brand === 'undefined'
  ) {
    return null;
  }

  if (isV2Brand && newLandingPageEnabled) {
    return MainLandingPage;
  }

  return Home;
};
