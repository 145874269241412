import moment from 'moment';
import find from 'lodash/find';
import map from 'lodash/fp/map';
import every from 'lodash/fp/every';
import compose from 'lodash/fp/compose';
import filter from 'lodash/fp/filter';
import intersection from 'lodash/fp/intersection';
import concat from 'lodash/concat';
import intersec from 'lodash/intersection';
import values from 'lodash/values';
import difference from 'lodash/difference';
import isNil from 'lodash/isNil';
import { STATUS_FAILURE } from 'types/app';
import { IExperienceFields } from 'types/experience.types';
import {
  ICartCurrentParticipant,
  ICartDiscountParams,
  IIntergrationEnabledParams,
  IOverlapCheckParams,
  IParams,
  IParticipantResult,
  ISelectOptions,
} from 'features/ShoppingCart/types';
import { isCreditCharge, isRoomCharge, uuidv4 } from 'utils';
import { isIntegratedRoomChargeEnabledForExperience } from 'components/helpers';
import { ICartExperience } from 'types/cart';
import { convertIntervalToMinutes } from 'utils/formatDuration';
import { EmbedConfig } from 'features/EmbedConfig';

export const findItemById = (
  experienceList: IExperienceFields[],
  id: string,
) => {
  return find(experienceList, (item) => item.id === id);
};

export const isPaymentMethodCompatible = ({
  cartExperienceItems,
  addedItem,
  experienceList,
}: IParams) => {
  return compose(
    every(
      (exp: string[]) =>
        intersection(exp, addedItem.experience.paymentMethods).length >= 1,
    ),
    map((experienceId) => {
      const experienceItem = findItemById(
        experienceList,
        experienceId as string,
      );
      return experienceItem?.paymentMethods || [];
    }),
    map((item) => item.experienceId),
    Object.values,
  )(cartExperienceItems);
};

interface SessionData {
  sessionDateTime: string;
  sessionDurationMinutes: number;
}

function isOverlapped(itemA: SessionData, itemB: SessionData): boolean {
  const startDateTimeA = moment(itemA.sessionDateTime);
  const endDateTimeA = startDateTimeA
    .clone()
    .add(itemA.sessionDurationMinutes, 'minutes');

  const startDateTimeB = moment(itemB.sessionDateTime);
  const endDateTimeB = startDateTimeB
    .clone()
    .add(itemB.sessionDurationMinutes, 'minutes');

  const isOverlap =
    startDateTimeB.isBetween(startDateTimeA, endDateTimeA, null, '[]') ||
    endDateTimeB.isBetween(startDateTimeA, endDateTimeA, null, '[]') ||
    startDateTimeA.isBetween(startDateTimeB, endDateTimeB, null, '[]') ||
    endDateTimeA.isBetween(startDateTimeB, endDateTimeB, null, '[]');

  const isEndEqualStart = endDateTimeB.isSame(startDateTimeA);

  const isStartEqualEnd = startDateTimeB.isSame(endDateTimeA);

  return isOverlap && !isEndEqualStart && !isStartEqualEnd;
}

const checkIsOverlapping = ({
  selectedList,
  addedItem,
}: IOverlapCheckParams) => {
  if (!selectedList.length) {
    return false;
  }

  if (addedItem.experience.isNoHost) {
    return false;
  }

  const isExperienceSessionAdded = selectedList.some(
    ({ sessionDateTime, experienceId, sessionDuration }) => {
      return (
        addedItem.experience.id === experienceId &&
        addedItem.sessionDateTime === sessionDateTime &&
        addedItem.duration === sessionDuration
      );
    },
  );

  if (isExperienceSessionAdded) {
    return false;
  }

  /* eslint-disable-next-line */
  for (const item of selectedList) {
    const { sessionDateTime, sessionDuration } = item;

    if (
      isOverlapped(
        {
          sessionDateTime,
          sessionDurationMinutes: convertIntervalToMinutes(sessionDuration),
        },
        {
          sessionDateTime: addedItem.sessionDateTime,
          sessionDurationMinutes: convertIntervalToMinutes(addedItem.duration),
        },
      )
    ) {
      return true;
    }
  }
  return false;
};

export const isExperienceOverlapping = ({
  cartExperienceItems,
  addedItem,
}: IParams) => {
  if (addedItem.experience?.hostedBy?.allowOverlappingBookings) {
    return false;
  }

  const mutualExperiences = compose(
    filter((item) => !!item && item.experienceId === addedItem.experience.id),
    Object.values,
  )(cartExperienceItems);

  return checkIsOverlapping({
    selectedList: mutualExperiences,
    addedItem,
  });
};

export const isHostOverlapping = ({
  cartExperienceItems,
  addedItem,
  experienceList,
}: IParams) => {
  if (
    addedItem.experience?.hostedBy?.allowOverlappingBookings ||
    addedItem.experience?.loading
  ) {
    return false;
  }

  const sameHostExperiences = Object.values(cartExperienceItems).filter(
    (item) => {
      const currentExperience = findItemById(experienceList, item.experienceId);
      if (!currentExperience) {
        return false;
      }
      return (
        currentExperience?.hostedBy?.id ===
          addedItem?.experience?.hostedBy?.id && !addedItem.experience.isNoHost
      );
    },
  );
  return checkIsOverlapping({
    selectedList: sameHostExperiences,
    addedItem,
  });
};

export const getPaymentMethods = ({
  cartExperienceItems,
  experienceList,
}: Omit<IParams, 'addedItem'>) => {
  const experiencePaymentMethods = Object.values(cartExperienceItems).map(
    (item) => {
      const experience = experienceList.find((e) => e.id === item.experienceId);
      return experience?.paymentMethods;
    },
  );
  return intersec(...experiencePaymentMethods).sort();
};

export const getPaymentFields = (guestType: string) => {
  const fields = {
    preArrivalGuest: ['lastName', 'reservationCode'],
    onPropertyGuest: ['lastName', 'methodNumber'],
    nonHotelGuest: [],
  };
  return fields[guestType as keyof typeof fields];
};

export const getSelectOptions = ({
  cartExperienceItems,
}: Pick<IParams, 'cartExperienceItems'>) => {
  return Object.values(cartExperienceItems).reduce<Array<ISelectOptions>>(
    (cart, item) => {
      const currentCartPariticipants = item?.participants?.map((i) => {
        const name = `${i.firstName} ${i.lastName}`;
        return {
          label: name,
          value: `${uuidv4()}T${name}`,
        };
      }) as ISelectOptions[];
      return concat(currentCartPariticipants, cart);
    },
    [],
  );
};

export const initializeFormFields = (
  selectedParticipants: IParticipantResult[],
  embedConfig?: EmbedConfig,
) => {
  return selectedParticipants.reduce(
    (participants, tier) =>
      concat(
        participants,
        new Array(tier.count).fill(null).map((_, index) => ({
          fullName: embedConfig?.participantName?.[index] || '',
          terms: false,
          priceTierName: tier.priceTier,
          id: uuidv4(),
          price: tier.price,
        })),
      ),
    [] as Array<ICartCurrentParticipant>,
  );
};

export const isPMSEnabledForCartExperience = ({
  activeOption,
  cartItems,
  listings,
}: IIntergrationEnabledParams) => {
  const isPMSEnabledForCart = Object.values(cartItems).every((cartItem) => {
    const listing = listings[cartItem.listingId || cartItem.experienceId];
    return isIntegratedRoomChargeEnabledForExperience(listing);
  });
  if (isRoomCharge(activeOption) && !isPMSEnabledForCart) {
    return false;
  }
  if (isCreditCharge(activeOption) && isPMSEnabledForCart) {
    return false;
  }
  return true;
};

export const isFullCartDiscounted = ({
  cartExperienceItems,
  discountCode,
}: ICartDiscountParams) => {
  if (!discountCode?.experienceIds || !cartExperienceItems) {
    return false;
  }
  const cartExpIds = compose(
    map((item) => item.experienceId),
    values,
  )(cartExperienceItems);
  const cartItemDifferences = difference(
    cartExpIds,
    discountCode.experienceIds as string[],
  );
  return cartItemDifferences.length === 0 && discountCode.value === 100;
};

export const getCartIneligibleError = (
  props: IParams,
  fetchExperienceStatus: string,
) => {
  if (!isPaymentMethodCompatible(props)) {
    return 'Error adding to cart. Payment method is not compatible';
  }
  if (!props.addedItem.sessionDateTime) {
    return 'Error. Invalid session date and time.';
  }
  if (fetchExperienceStatus === STATUS_FAILURE) {
    return 'Error adding to cart. Please try again later!';
  }
  if (isExperienceOverlapping(props) || isHostOverlapping(props)) {
    return 'Error adding to cart. Experience session is unavailable';
  }
  return null;
};

export const isComplimentary = (
  cartExperienceItems: IParams['cartExperienceItems'],
) => {
  const cartItems = Object.values(cartExperienceItems);

  if (!cartItems || cartItems.some((i) => isNil(i))) {
    return false;
  }

  return cartItems.every((item: ICartExperience) =>
    item.participants.every((tier) => tier.price === 0),
  );
};
