import React, {
  FC,
  useState,
  useMemo,
  useCallback,
  HTMLAttributes,
} from 'react';
import styled from 'styled-components';

type Props = HTMLAttributes<HTMLButtonElement> & {
  disabled?: boolean;
  disabledArrowsOpacity?: number;
  Icon: FC<{ color: string }>;
};

const IconButton: FC<Props> = ({
  Icon,
  disabled = false,
  disabledArrowsOpacity = 50,
  ...props
}) => {
  const [hovered, setHovered] = useState(false);

  const backgroundColor = useMemo(() => {
    let opacity = 50;
    if (hovered && !disabled) {
      opacity = 70;
    }
    return `var(--way-colors-contrastColorShades-${opacity})`;
  }, [hovered, disabled]);

  const iconColor = useMemo(() => {
    let opacity = 80;
    if (hovered && !disabled) {
      opacity = 100;
    }
    return `var(--way-colors-coherentColorShades-${opacity})`;
  }, [hovered, disabled]);

  const onMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  if (disabled && disabledArrowsOpacity === 0) {
    return null;
  }

  return (
    <Button
      role="button"
      backgroundColor={backgroundColor}
      disabled={disabled}
      disabledArrowsOpacity={disabledArrowsOpacity}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      <Icon color={iconColor} />
    </Button>
  );
};

export default IconButton;

const Button = styled.button<{
  backgroundColor: string;
  disabledArrowsOpacity: number;
  disabled?: boolean;
}>`
  position: absolute;
  border: none;
  width: 32px;
  height: 32px;
  z-index: 20;
  border-radius: 50%;
  transition: background-color 0.2s;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ disabled, disabledArrowsOpacity }) =>
    disabled ? disabledArrowsOpacity / 100 : 1};
  display: grid;
  place-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  @media (max-width: 768px) {
    width: 42px;
    height: 42px;
  }
`;
