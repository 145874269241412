import React from 'react';

export default function CashAppIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0Z"
        fill="url(#paint0_linear_867_1617)"
      />
      <path
        d="M8.40681 5.29776C9.17683 5.30047 9.92125 5.57452 10.5092 6.07176C10.5702 6.12709 10.65 6.15704 10.7323 6.15548C10.8146 6.15391 10.8932 6.12096 10.952 6.06336L11.552 5.45376C11.5824 5.42361 11.6063 5.38748 11.6221 5.34766C11.6379 5.30784 11.6453 5.26517 11.6439 5.22235C11.6424 5.17953 11.6321 5.13748 11.6136 5.09883C11.5951 5.06018 11.5688 5.02577 11.5364 4.99776C11.0614 4.59384 10.5124 4.28613 9.92001 4.09176L10.1096 3.19536C10.1191 3.14944 10.1182 3.102 10.107 3.05647C10.0958 3.01094 10.0746 2.96848 10.045 2.93216C10.0153 2.89585 9.97793 2.8666 9.93556 2.84653C9.8932 2.82647 9.84689 2.81609 9.80001 2.81616H8.62881C8.55631 2.81611 8.48597 2.84082 8.42945 2.88622C8.37292 2.93161 8.3336 2.99496 8.31801 3.06576L8.14761 3.86256C6.58761 3.93936 5.26761 4.70976 5.26761 6.28896C5.26761 7.65576 6.35841 8.24136 7.51161 8.64696C8.60241 9.05256 9.17841 9.20256 9.17841 9.77376C9.17841 10.345 8.60241 10.705 7.75401 10.705C6.93031 10.718 6.13457 10.4063 5.53881 9.83736C5.47942 9.77946 5.39976 9.74705 5.31681 9.74705C5.23386 9.74705 5.1542 9.77946 5.09481 9.83736L4.44321 10.4734C4.41283 10.5027 4.38867 10.5378 4.37217 10.5767C4.35567 10.6156 4.34717 10.6573 4.34717 10.6996C4.34717 10.7418 4.35567 10.7836 4.37217 10.8224C4.38867 10.8613 4.41283 10.8964 4.44321 10.9258C4.97338 11.4259 5.62149 11.7839 6.32721 11.9662L6.15201 12.8002C6.14253 12.8465 6.14331 12.8943 6.1543 12.9403C6.16528 12.9863 6.18621 13.0294 6.2156 13.0664C6.24499 13.1035 6.28213 13.1337 6.32442 13.1548C6.36672 13.176 6.41313 13.1876 6.46041 13.189H7.64001C7.71297 13.1898 7.78399 13.1654 7.84105 13.1199C7.89812 13.0745 7.93773 13.0107 7.95321 12.9394L8.12241 12.1402C9.98601 12.0202 11.1224 11.023 11.1224 9.55296C11.1224 8.20176 9.98481 7.63296 8.60241 7.16496C7.81401 6.87936 7.13241 6.68496 7.13241 6.09816C7.13241 5.51136 7.76961 5.29776 8.40681 5.29776Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_867_1617"
          x1="0"
          y1="8"
          x2="16"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00C244" />
          <stop offset="1" stopColor="#00D64B" />
        </linearGradient>
      </defs>
    </svg>
  );
}
