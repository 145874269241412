import styled from 'styled-components';

export const NavigationHeaderContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  min-height: 50px;
  width: 100%;
`;

export const LinksBack = styled.a`
  cursor: pointer;
  font-size: 14px;
  display: grid;
  align-items: center;
  width: 18px;
  height: 40px;
  place-items: center;
`;

export const IconBack = styled.div`
  height: 20px;
  width: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const BackLabel = styled.span<{ hovered: boolean }>`
  /* skip css namespace */
  cursor: pointer;
  display: inline-block;
  font-weight: 350;
  font-size: 16px;
  line-height: 40px;
  white-space: nowrap;
  text-transform: capitalize;
  color: ${({ hovered }) =>
    `var(--way-colors-contrastColorShades-${hovered ? 100 : 70})`};
`;
