import React, {
  type ComponentProps,
  type FC,
  type HTMLAttributes,
  type MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import ResponsiveImage from 'components/common/ResponsiveImage';
import { isImageRatioGreaterOne } from 'utils';
import { ExperienceImageSizes } from '@kouto/types';
import * as SC from './ExperienceDescImage.styled';
import { FullScreenExperienceDescImage } from './FullScreenExperienceDescImage';

interface IProps
  extends Pick<ComponentProps<typeof ResponsiveImage>, 'uriObject'> {
  altText: string;
}
export const ExperienceDescImage: FC<IProps> = ({ uriObject, altText }) => {
  const [isPortrait, setIsPortrait] = useState(false);
  const onLoad: HTMLAttributes<HTMLImageElement>['onLoad'] = ({ target }) => {
    const img = target as HTMLImageElement;
    setIsPortrait(isImageRatioGreaterOne(img.naturalWidth, img.naturalHeight));
  };

  const [shouldShowFullScreen, showFullScreen] = useState(false);

  useEffect(() => {
    if (!shouldShowFullScreen) {
      document.body.classList.remove('way-modal-open');
      return;
    }
    document.body.classList.add('way-modal-open');
  }, [shouldShowFullScreen]);

  const onClick: MouseEventHandler<HTMLDivElement> = () => {
    showFullScreen(!shouldShowFullScreen);
  };

  return (
    <>
      <SC.ExperienceDescriptionImageWrapper>
        <ResponsiveImage
          onClick={onClick}
          uriObject={uriObject}
          onLoad={onLoad}
          defaultSize={ExperienceImageSizes.W480}
          isPortrait={isPortrait}
          viewType={shouldShowFullScreen ? 'large' : 'small'}
          alt={altText}
          CustomRenderer={SC.ExperienceDescriptionImage}
        />
      </SC.ExperienceDescriptionImageWrapper>
      {shouldShowFullScreen && (
        <FullScreenExperienceDescImage
          uriObject={uriObject}
          onClose={() => showFullScreen(false)}
          altText={altText}
        />
      )}
    </>
  );
};
