import React, { FC, HTMLProps, useState } from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import { noop } from 'lodash';
import { useBrandId } from 'AppProvider';
import { useIsMobile } from 'WindowDimensionProvider';
import DateRangePicker from 'components/DateRangePicker';
import useBrandCategories, {
  Category,
} from 'features/LandingPage/hooks/useBrandCategories';
import { SecondaryButton } from 'components/theme/Button';
import PrevArrowIcon from 'assets/chevron-left';
import NextArrowIcon from 'assets/chevron-right';
import CalendarIcon from 'assets/calendar';
import useCustomHistory from 'hooks/use-custom-history';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSearchQueryParams from 'hooks/use-search-params';
import { ProductLine } from '@kouto/types';
import { useLandingPageRouteParams } from 'hooks/useLandingPageRouteParams';
import BottomSheetCategorySelector from './BottomSheetCategorySelector';

const CategoryButton: FC<{
  category: ReturnType<typeof useBrandCategories>['categories'][number];
}> = ({ category }) => {
  const history = useCustomHistory();
  const location = useLocation();
  const { searchParams, setParam } = useSearchQueryParams();
  const { t } = useTranslation();

  const onClick = () => {
    const currentCategoryIds = Array.isArray(searchParams.categoryIds)
      ? searchParams.categoryIds
      : [searchParams.categoryIds];

    const categoryIds = isActive
      ? currentCategoryIds.filter((id: string) => id !== category.id)
      : [...currentCategoryIds, category.id];

    if (categoryIds.length >= 1) {
      setParam('categoryIds', categoryIds.filter(Boolean));
    } else {
      setParam('categoryIds', null);
    }

    if (
      location.pathname.includes('search') ||
      location.pathname.includes('/e')
    )
      return;

    history.push({
      pathname: `${location.pathname}/search`,
      search: searchParams,
    });
  };

  const isActive = (searchParams.categoryIds ?? []).includes(category.id);

  return (
    <FilterButton
      className="listing-filter-category-button"
      onClick={onClick}
      selected={isActive}
      tabIndex={0}
      aria-label={`${t('filterBy', { filter: category.name })}`}
    >
      {category.name}
    </FilterButton>
  );
};

const DatePickerButton: FC<
  Pick<HTMLProps<HTMLButtonElement>, 'aria-label'> & {
    position: 'left' | 'right';
    showClearButton: boolean;
  }
> = ({ position, showClearButton, ...props }) => {
  const isMobile = useIsMobile();
  const { t: translate } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDateCalendar, setStartDateCalendar] = useState<Moment | null>(
    null,
  );
  const [endDateCalendar, setEndDateCalendar] = useState<Moment | null>(null);
  const { searchParams, setParam } = useSearchQueryParams();
  const location = useLocation();
  const history = useCustomHistory();

  const handleOnDateChange = ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    if (startDate) {
      setStartDateCalendar(startDate);
    } else {
      setStartDateCalendar(null);
    }
    if (endDate) {
      setEndDateCalendar(endDate);
    } else {
      setEndDateCalendar(null);
    }
  };

  const applyDateFilter = () => {
    if (startDateCalendar) {
      setParam('from', startDateCalendar.format('YYYY-MM-DD'));
    }
    if (endDateCalendar) {
      setParam('to', endDateCalendar.format('YYYY-MM-DD'));
    }
    if (
      location.pathname.includes('search') ||
      location.pathname.includes('/e')
    )
      return;

    history.push({
      pathname: `${location.pathname}/search`,
      search: searchParams,
    });
  };

  const openPickerIfNeeded = () => {
    if (!showDatePicker) {
      if (searchParams.from) {
        setStartDateCalendar(moment(searchParams.from));
      }
      if (searchParams.to) {
        setEndDateCalendar(moment(searchParams.to));
      }
      setShowDatePicker(true);
    }
  };

  const closePicker = () => {
    setTimeout(() => {
      setShowDatePicker(false);
    }, 10);
  };

  const clearPicker = () => {
    setStartDateCalendar(null);
    setEndDateCalendar(null);
    setParam('from', undefined);
    setParam('to', undefined);
    setShowDatePicker(false);
  };

  const cancelPicker = () => {
    setStartDateCalendar(null);
    setEndDateCalendar(null);
    setShowDatePicker(false);
  };

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  const isActive = searchParams.from && searchParams.to;
  const iconColor = isActive
    ? `var(--way-colors-primaryColorContrastShades-${hovered ? 100 : 80})`
    : `var(--way-colors-contrastColorShades-${hovered ? 100 : 80})`;

  return (
    <DateButton
      className="listing-filter-date-button"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={openPickerIfNeeded}
      selected={isActive}
      {...props}
    >
      <CalendarIcon color={iconColor} />
      {isActive ? (
        <span>
          {` ${moment(searchParams.from).format('MMM DD')} - ${moment(
            searchParams.to,
          ).format('MMM DD')}`}
        </span>
      ) : (
        translate('date')
      )}
      {showDatePicker && (
        <InputDatesWrapper position={position}>
          <DateRangePicker
            startDate={startDateCalendar}
            endDate={endDateCalendar}
            onChange={handleOnDateChange}
            onOutsideClick={closePicker}
            orientation={isMobile ? 'vertical' : 'horizontal'}
            noBorder
            navPrev={
              <PrevIconCalendar hidden>
                <PrevArrowIcon color="var(--way-palette-black-100)" />
              </PrevIconCalendar>
            }
            navNext={
              <NextIconCalendar>
                <NextArrowIcon color="var(--way-palette-black-100)" />
              </NextIconCalendar>
            }
          />
          <Footer>
            {showClearButton && (
              <CancelButton
                onClick={clearPicker}
                disabled={!startDateCalendar && !endDateCalendar}
              >
                {translate('clear')}
              </CancelButton>
            )}
            <FooterSpacer />
            <CancelButton type="button" onClick={cancelPicker}>
              {translate('cancel')}
            </CancelButton>
            <ConfirmButton
              type="button"
              onClick={
                startDateCalendar && endDateCalendar ? applyDateFilter : noop
              }
              disabled={!startDateCalendar || !endDateCalendar}
            >
              {translate('apply')}
            </ConfirmButton>
          </Footer>
        </InputDatesWrapper>
      )}
    </DateButton>
  );
};

const CalendarButton = () => {
  const location = useLocation();
  const history = useCustomHistory();
  const { t: translate } = useTranslation();

  const goToResultsPage = () => {
    history.push({
      pathname: `${location.pathname}/search`,
    });
  };

  const [hovered, setHovered] = useState(false);

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  const { searchParams } = useSearchQueryParams();

  const isActive = searchParams.from && searchParams.to;
  const iconColor = isActive
    ? `var(--way-colors-primaryColorContrastShades-${hovered ? 100 : 80})`
    : `var(--way-colors-contrastColorShades-${hovered ? 100 : 80})`;

  return (
    <DateButton
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="listing-filter-calendar-button"
      onClick={goToResultsPage}
      selected={false}
      aria-label={translate('filterByDateRange')}
    >
      <CalendarIcon color={iconColor} />
      {translate('viewCalendar')}
    </DateButton>
  );
};

const DateOrCalendarButton: FC<{
  product?: ProductLine;
  position: 'left' | 'right';
  showClearButton: boolean;
}> = ({ product, position, showClearButton }) => {
  const params = useLandingPageRouteParams();
  const { t } = useTranslation();

  if (product === ProductLine.ACTIVATE) {
    if (!params.isResults) {
      return <CalendarButton aria-label={t('filterByDateRange')} />;
    }
  }
  return (
    <DatePickerButton
      aria-label={t('filterByDateRange')}
      position={position}
      showClearButton={showClearButton}
    />
  );
};

const getCategoriesToShow = (
  categories: Category[],
  isMobile: boolean,
  isResults: boolean,
) => {
  let categoriesToShow = categories.length;

  if (isMobile) {
    if (isResults) {
      categoriesToShow = 6;
    } else {
      categoriesToShow = 2;
    }
  } else if (!isResults) {
    categoriesToShow = 3;
  }

  return categoriesToShow;
};

const LandingPageFilters: FC = () => {
  const brandId = useBrandId();
  const isMobile = useIsMobile();
  const history = useCustomHistory();
  const [categoriesBottomDrawerOpen, setCategoriesBottomDrawerOpen] =
    useState(false);
  const { t: translate } = useTranslation();
  const location = useLocation();
  const params = useLandingPageRouteParams();
  const { searchParams } = useSearchQueryParams();
  const { categories } = useBrandCategories(brandId, params.product);

  const categoriesToShow = getCategoriesToShow(
    categories,
    isMobile,
    params.isResults || false,
  );

  const displayedCategories = categories.slice(0, categoriesToShow);

  const selectedCategoryIds = Array.isArray(searchParams.categoryIds)
    ? searchParams.categoryIds
    : [searchParams.categoryIds];
  const moreButtonIsActive = categories.some(
    (category) =>
      selectedCategoryIds.includes(category.id) &&
      categories.indexOf(category) > categoriesToShow - 1,
  );

  const goToResultsPage = () => {
    history.push({
      pathname: `${location.pathname}/search`,
      search: searchParams,
    });
  };

  const onMoreClick = () => {
    if (isMobile && params.isResults) {
      setCategoriesBottomDrawerOpen(true);
    } else {
      goToResultsPage();
    }
  };

  return (
    <Wrapper className="listing-filters-container">
      {(params.isResults || isMobile) && (
        <DateOrCalendarButton
          product={params.product}
          position="left"
          showClearButton
        />
      )}

      {displayedCategories.map((category) => (
        <CategoryButton category={category} key={category.id} />
      ))}

      {(!params.isResults || isMobile) &&
        categories.length > categoriesToShow && (
          <FilterButton selected={moreButtonIsActive} onClick={onMoreClick}>
            {translate('more')}
          </FilterButton>
        )}

      {!params.isResults && !isMobile && (
        <DateOrCalendarButton
          product={params.product}
          position="right"
          showClearButton={false}
        />
      )}

      {categoriesBottomDrawerOpen && (
        <BottomSheetCategorySelector
          onClose={() => setCategoriesBottomDrawerOpen(false)}
        >
          {categories.map((category) => (
            <CategoryButton category={category} key={category.id} />
          ))}
        </BottomSheetCategorySelector>
      )}
    </Wrapper>
  );
};

export default LandingPageFilters;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

const FilterButton = styled(SecondaryButton)<{ selected?: boolean }>``;

const DateButton = styled(FilterButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const InputDatesWrapper = styled.div<{
  position: 'left' | 'right';
}>`
  position: absolute;
  background-color: var(--way-palette-white-100);
  box-shadow: var(--way-design-boxShadow-l);
  ${({ position }) => position}: 0;
  top: 115%;
  z-index: 999999;

  strong {
    font-weight: normal;
  }
  td {
    color: var(--way-palette-black-100);
  }
`;

const Footer = styled.div`
  display: flex;
  padding: 14px;
  padding-top: 0px;
  gap: 12px;
`;

const FooterSpacer = styled.div`
  flex: 1;
`;

const CancelButton = styled.button`
  height: 40px;
  padding: 10px 18px;
  border: none;
  background-color: var(--way-palette-white-100);
  color: var(--way-colors-primaryColorShades-100);
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ConfirmButton = styled.button`
  height: 40px;
  padding: 10px 14px;
  border: none;
  background-color: var(--way-colors-buttonColorShades-100);
  color: var(--way-palette-white-100);
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PrevIconCalendar = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(45, 54, 87, 0.25);
  color: var(--way-palette-black-30);
  font-size: 24px;
  position: absolute;
  margin-top: -2px;
  top: 19px;
  left: 13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding-right: 2px;
  & svg {
    path {
      stroke: none;
    }
  }
`;

const NextIconCalendar = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(45, 54, 87, 0.25);
  color: var(--way-palette-black-30);
  font-size: 24px;
  position: absolute;
  margin-top: -2px;
  top: 19px;
  right: 13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding-left: 2px;
  & svg {
    path {
      stroke: none;
    }
  }
`;
