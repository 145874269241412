import { ResourceParagraph } from 'components/theme/Typography/ResourceTypography';
import React, { FC, useState, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { sortResourcesTitlesfn } from 'utils/resource';
import { SecondaryButton } from 'components/theme/Button/Button';
import { tabletBreakpoint, useIsMobile } from '../../WindowDimensionProvider';
import { useResourceMapContext } from '../../features/Reserve/contexts/ResourceMapContext';

interface Props {
  resourceList: Array<{ id: string; name: string; number: string }>;
  truncateLimit?: number;
  onResourceListItemClick: (id: string) => void;
}

const ResourceGroupList: FC<Props> = ({
  resourceList,
  truncateLimit = 0,
  onResourceListItemClick,
}) => {
  const isMobile = useIsMobile();
  const { t: translate } = useTranslation();

  const [maxLimit, setMaxLimit] = useState(
    Math.min(resourceList.length, Math.max(0, truncateLimit)),
  );

  useEffect(() => {
    if (truncateLimit > 0) {
      setMaxLimit(Math.min(resourceList.length, truncateLimit));
    }
  }, [resourceList.length, truncateLimit]);

  const sortedList = resourceList.sort((a, b) =>
    sortResourcesTitlesfn(a.number, b.number),
  );

  const truncatedResourceList = sortedList.slice(0, maxLimit);

  const shouldShowMoreLess = useMemo(
    () => resourceList.length > truncateLimit,
    [resourceList.length, truncateLimit],
  );

  const { focusResources, unfocusResources, selectedResource } =
    useResourceMapContext();

  useEffect(() => {
    if (selectedResource) {
      const index = sortedList.findIndex(
        (item) => item.id === selectedResource,
      );

      if (index > -1 && index >= truncateLimit) {
        setMaxLimit(sortedList.length);
      }

      setTimeout(() => {
        if (isMobile) {
          const item = document.getElementById(selectedResource);

          item?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'center',
          });
        }
      }, 1);
    }
  }, [selectedResource, sortedList, truncateLimit, isMobile]);

  const resourceName = useMemo(
    () => truncatedResourceList[0]?.name?.split('#')[0]?.trim(),
    [truncatedResourceList],
  );

  return (
    <ResourceListWrapper className="resource-group-list__list-wrapper">
      {truncatedResourceList.map((list, index) => (
        <SecondaryButton
          aria-label={`${translate('select')} ${list.name} ${index + 1}`}
          className="resource-group-list__item"
          id={list.id}
          key={list.id}
          onClick={() => onResourceListItemClick(list.id)}
          onMouseEnter={() => focusResources([list.id])}
          onMouseLeave={() => unfocusResources([list.id])}
          selected={selectedResource === list.id}
        >
          {list.number?.replace('#', '') ?? list.name}
        </SecondaryButton>
      ))}
      {shouldShowMoreLess && (
        <ResourceListTruncateToggle
          as="a"
          aria-label={`${translate(
            maxLimit >= resourceList.length ? 'showLess' : 'showMore',
          )} ${resourceName}`}
          fontWeight="350"
          lineHeight="normal"
          fontSize="12px"
          className="resource-group-list__toggle-truncate"
          onClick={() => {
            if (maxLimit === resourceList.length) {
              setMaxLimit(truncateLimit);
              return;
            }
            setMaxLimit(resourceList.length);
          }}
        >
          {translate(
            maxLimit === resourceList.length ? 'showLess' : 'showMore',
          )}
        </ResourceListTruncateToggle>
      )}
    </ResourceListWrapper>
  );
};

const ResourceListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-row-gap: 16px;
  grid-column-gap: 12px;
  width: 100%;

  @media all and (max-width: ${tabletBreakpoint}) {
    > * {
      min-width: 23vw !important;
    }
  }
`;

const applyCommonStyles = () => css`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ResourceListTruncateToggle = styled(ResourceParagraph)`
  #kouto-embed-root && {
    ${applyCommonStyles()};
    color: var(--way-colors-primaryTextColor);
    text-decoration: underline;
    user-select: none;
  }
`;

export default ResourceGroupList;
