import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Flex } from 'components/common/styled/common-styled';
import { Collection, Session } from 'types/listings';
import { Button } from 'components/theme/Button/Button';
import ResponsiveImage from 'components/common/ResponsiveImage/ResponsiveImage';
import { currencyFormat, isImageRatioGreaterOne } from 'utils';
import { useTranslation } from 'react-i18next';
import { ResourceParagraph } from 'components/theme/Typography/ResourceTypography';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { IPriceTier } from 'types/experience.types';
import { CollectionTitle } from './CollectionHero';

type PriceTier = IPriceTier & {
  id: string;
};

interface Props {
  collection: Collection;
  sessionsByGroup: Record<string, Session[]>;
  onReserve: (groupId: string) => void;
}

const MobileMappedCollectionPage: FC<Props> = ({
  collection,
  sessionsByGroup,
  onReserve,
}) => {
  const [isSixteenNineRatio, setIsSixteenNineRatio] = useState(false);
  const currency = useBrandCurrency();
  const { t: translate } = useTranslation();

  const onImgLoad: HTMLImageElement['onload'] = (e) => {
    if (e.target instanceof HTMLImageElement) {
      setIsSixteenNineRatio(
        isImageRatioGreaterOne(e.target.naturalWidth, e.target.naturalHeight),
      );
    }
  };

  const getLabel = (
    resourceGroup: NonNullable<Collection['resourceGroups']>[number],
  ) => {
    const groupSessions = sessionsByGroup[resourceGroup.id];
    const priceTiers = groupSessions
      .flatMap<PriceTier>((e) => (e ? e.priceTiers ?? [] : []))
      .sort((a, b) => a.price - b.price);
    const startingPrice = priceTiers[0]?.price || 0;
    const displayedCapacity = Number(
      resourceGroup.experiences?.[0].displayedCapacity,
    );

    let label = '';
    if (!groupSessions.length) {
      label = translate('unavailable');
    } else {
      if (displayedCapacity > 0) {
        label = `${displayedCapacity} ${
          displayedCapacity === 1 ? translate('person') : translate('people')
        }`;
      }
      if (displayedCapacity > 0 && currency) {
        label += ' • ';
      }
      if (currency) {
        label += `${translate('from')} ${currencyFormat(currency)(
          startingPrice,
        )}`;
      }
    }
    return label;
  };

  return (
    <>
      <Container
        direction="column"
        alignItem="flex-start"
        justifyContent="flex-start"
        gap={16}
      >
        <Flex
          direction="column"
          gap={24}
          alignItem="flex-start"
          justifyContent="flex-start"
          style={{ width: '100%' }}
        >
          {collection.resourceGroups?.map((resourceGroup) => (
            <GroupRow key={resourceGroup.id} disabled={false}>
              <ResponsiveImage
                className="resource-group-card__resource-image"
                uriObject={resourceGroup?.pictures?.[0]?.uri}
                defaultSize="384w"
                viewType="small"
                isSixteenNineRatio={isSixteenNineRatio}
                onLoad={onImgLoad}
                CustomRenderer={ResourceGroupImage}
                alt={resourceGroup.title}
                pictureStyle={`
                  display: grid;
                  width: 100%;
                  align-items: center;
                `}
              />
              <Flex
                direction="column"
                gap={3}
                alignItem="flex-start"
                justifyContent="flex-start"
                style={{
                  maxWidth: 'calc(100% - 164px)',
                }}
              >
                <ResourceParagraphHeading
                  className="mapped-collection-page__resource-title"
                  lineHeight="normal"
                  fontWeight="500"
                >
                  {resourceGroup.title}
                </ResourceParagraphHeading>

                <ResourceParagraphDetails
                  className="mapped-collection-page__resource-details"
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="normal"
                >
                  {getLabel(resourceGroup)}
                </ResourceParagraphDetails>
                <StyledReserveButton
                  onClick={() => onReserve(resourceGroup.id)}
                >
                  {translate('reserveNow')}
                </StyledReserveButton>
              </Flex>
            </GroupRow>
          ))}
        </Flex>
      </Container>
      <CollectionTitle allowMultipleLines>{collection.title}</CollectionTitle>
    </>
  );
};

const Container = styled(Flex)`
  padding: 4px;
`;

const GroupRow = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  place-items: center flex-start;

  & > *:first-child {
    width: 140px;
    margin-right: 24px;
    flex-shrink: 0;
  }

  ${({ disabled }) =>
    disabled
      ? `
    &:before {
      content: ' ';
      cursor: not-allowed;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--way-palette-black-20);
      pointer-events: all;
    }
  `
      : ''}
`;

const ResourceGroupImage = styled.img<{ isSixteenNineRatio: boolean }>`
  width: 100%;
  height: 93px;
  object-fit: cover;
  object-position: center;
`;

const ResourceParagraphHeading = styled(ResourceParagraph)`
  #kouto-embed-root && {
    color: var(--way-colors-primaryTextColor);
  }
`;

const ResourceParagraphDetails = styled(ResourceParagraph)`
  #kouto-embed-root && {
    margin-bottom: 3px;
  }
`;

const StyledReserveButton = styled(Button)`
  padding: 12px 20px;
  font-size: 12px;
  border: 0;
`;

export default MobileMappedCollectionPage;
