import React, { useState, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useAppState } from 'AppProvider';
import { parseTextFromHtmlString } from 'utils';
import SchemaMarkup from './SchemaMarkup';

const TITLE_MAX_CHARLENGTH = 71;
const DESCRIPTION_MAX_CHARLENGTH = 160;

const EventMeta = ({ title, description, slug, ...experience }) => {
  const [isDuplicateRemoved, setIsDuplicateRemoved] = useState(false);
  const supportedLanguages = ['en-us', 'fr', 'ko'];
  const brandDomainUrl = document.location.origin + document.location.pathname;
  const experienceDetailUri = `${brandDomainUrl}#!/e/${slug}/`;
  const { settings } = useAppState((state) => state.brand);
  const brandName = get(settings, 'name');
  const experienceImage = get(experience, 'coverPicture.uri.original');

  const experiencePageTitle = useMemo(() => {
    const availableCharLength = TITLE_MAX_CHARLENGTH - brandName?.length;
    if (availableCharLength <= 0) {
      return brandName.substring(0, TITLE_MAX_CHARLENGTH);
    }
    return `${title.substring(0, availableCharLength - 1)} | ${brandName}`;
  }, [brandName, title]);

  const parsedMetaDescription = useMemo(() => {
    return parseTextFromHtmlString(description || '').substring(
      0,
      DESCRIPTION_MAX_CHARLENGTH,
    );
  }, [description]);

  const getQueryParam = (lang) => {
    return lang !== 'en-us' ? `?lang=${lang}` : '';
  };

  useEffect(() => {
    const canonicalElement = document.querySelector("link[rel='canonical']");
    if (canonicalElement) {
      canonicalElement.remove();
    }
    setIsDuplicateRemoved(true);
  }, []);

  return (
    <>
      <Helmet>
        {title && <title>{experiencePageTitle}</title>}
        <meta property="og:title" content={experiencePageTitle} />
        <meta name="twitter:title" content={experiencePageTitle} />
        <meta name="description" content={parsedMetaDescription} />
        <meta name="og:description" content={parsedMetaDescription} />
        <meta name="twitter:description" content={parsedMetaDescription} />
        <meta name="og:url" content={experienceDetailUri} />
        <meta property="og:image" content={experienceImage} />
        <meta name="twitter:image" content={experienceImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="robots" content="index, follow" />
        {supportedLanguages.map((lang) => (
          <link
            key={lang}
            rel="alternate"
            hrefLang={lang}
            href={`${experienceDetailUri}${getQueryParam(lang)}`}
          />
        ))}
        {isDuplicateRemoved && (
          <link rel="canonical" href={experienceDetailUri} />
        )}
      </Helmet>
      <SchemaMarkup url={experienceDetailUri} />
    </>
  );
};

EventMeta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string.isRequired,
  coverPicture: PropTypes.shape({
    uri: PropTypes.shape({
      original: PropTypes.string,
    }),
  }),
};

EventMeta.defaultProps = {
  title: '',
  description: '',
  coverPicture: {},
};

export default EventMeta;
