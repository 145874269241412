import { useMemo } from 'react';
import { STATUS_IDLE, STATUS_PENDING } from 'types/app';
import { useAppState } from 'AppProvider';
import { IBrandState } from 'types/reducer-states/brandState';
import useSearchParams from './use-search-params';

export const useAnalyticsReadyForQueryParams = (waitForExperience = false) => {
  const { searchParams } = useSearchParams();

  const { categoryIds, vibeIds } = searchParams;

  const state: { searchExperienceStatus: string } = useAppState(
    (state: { experience: unknown }) => state.experience,
  );

  const { vibes, categories }: IBrandState = useAppState(
    (state: Record<'brand', IBrandState>) => state.brand,
  );

  const isNotReady = useMemo(() => {
    return (
      (waitForExperience &&
        (state.searchExperienceStatus === STATUS_PENDING ||
          state.searchExperienceStatus === STATUS_IDLE)) ||
      (!!vibeIds?.length && !Object.keys(vibes).length) ||
      (!!categoryIds?.length && !Object.keys(categories).length)
    );
  }, [
    vibeIds,
    categories,
    Object.keys(vibes)?.length,
    Object.keys(categories)?.length,
    state,
  ]);

  return isNotReady;
};
