import * as R from 'ramda';
import styled from 'styled-components';
import React, { FC } from 'react';
import { Controller } from 'hook-form';

import Question from 'components/CustomQuestion/Question';
import useCustomQuestions from 'hooks/useCustomQuestions';
import {
  CustomQuestionResponse,
  QuestionScope,
  QUESTION_SCOPES,
} from 'types/custom-questions';
import { get } from 'lodash';
import { validateEmpty } from 'utils/validators';

type Props = {
  title?: string;
  scope: QuestionScope;
  formStatePath: string;
};

const CustomQuestions: FC<Props> = ({ title, scope, formStatePath }) => {
  const {
    loading,
    participantParentQuestions,
    participantConditionalQuestions,
    purchaserParentQuestions,
    purchaserConditionalQuestions,
  } = useCustomQuestions();
  if (loading) {
    return null;
  }

  const parentQuestions =
    scope === QUESTION_SCOPES.PURCHASER
      ? purchaserParentQuestions
      : participantParentQuestions;
  const conditionalQuestions =
    scope === QUESTION_SCOPES.PURCHASER
      ? purchaserConditionalQuestions
      : participantConditionalQuestions;

  const renderConditionalQuestions = (
    parentQuestionId: string,
    parentQuestionResponse?: CustomQuestionResponse[],
  ) => {
    const ownConditionalQuestions = conditionalQuestions.filter((cq) =>
      cq.config.conditions
        ?.map((c) => c.conditionKey)
        .includes(parentQuestionId),
    );

    if (!ownConditionalQuestions.length) {
      return null;
    }

    return ownConditionalQuestions.map((cq) => {
      const conditionQuestionId = cq.id;
      const conditionValues = cq.config.conditions?.map(
        (c) => c.conditionValue,
      );

      const matchesCondition = R.intersection(
        conditionValues || [],
        (parentQuestionResponse || []).map((r) => r.key),
      ).length;

      if (matchesCondition) {
        return (
          <Controller
            key={conditionQuestionId}
            name={`${formStatePath}.${conditionQuestionId}`}
            rules={
              cq.config.isRequired
                ? { ...validateEmpty(`This field is required`) }
                : {}
            }
            render={({ field: { onChange, value, ref }, formState }) => {
              const errorMessage = get(
                formState,
                `errors.${formStatePath}.${conditionQuestionId}.message`,
              );

              return (
                <Question
                  ref={ref}
                  question={cq}
                  onAnswerChange={onChange}
                  answer={value || []}
                  error={errorMessage}
                />
              );
            }}
          />
        );
      }

      return null;
    });
  };

  return (
    <QuestionsWrapper>
      {scope === QUESTION_SCOPES.PURCHASER &&
        !!parentQuestions.length &&
        title && <Title>{title}</Title>}
      {parentQuestions.map((q) => {
        const parentQuestionId = q.id;
        return (
          <Controller
            key={parentQuestionId}
            name={`${formStatePath}.${parentQuestionId}`}
            rules={
              q.config.isRequired
                ? { ...validateEmpty(`This field is required`) }
                : {}
            }
            render={({ field: { onChange, value, ref }, formState }) => {
              const errorMessage = get(
                formState,
                `errors.${formStatePath}.${parentQuestionId}.message`,
              );
              return (
                <>
                  <Question
                    ref={ref}
                    question={q}
                    onAnswerChange={onChange}
                    answer={value || []}
                    error={errorMessage}
                  />
                  {renderConditionalQuestions(q.id, value)}
                </>
              );
            }}
          />
        );
      })}
    </QuestionsWrapper>
  );
};

export default CustomQuestions;

const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-size: 16px;
  border-bottom: 1px solid gray;
  padding-bottom: 5px;
`;
