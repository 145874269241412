import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { queryParamsToObject } from 'utils';

const useQueryParams = <T>(): T => {
  const { search } = useLocation();

  const queryParams = useMemo(() => queryParamsToObject(search), [search]);

  return queryParams;
};

export default useQueryParams;
