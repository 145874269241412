import React, {
  type ComponentProps,
  type FC,
  type HTMLAttributes,
  type MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ResponsiveImage from 'components/common/ResponsiveImage';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import * as SC from './ExperienceDescImage.styled';
import { FullScreenImage } from './ExperienceDescImage.styled';

interface IProps
  extends Pick<ComponentProps<typeof ResponsiveImage>, 'uriObject'> {
  onClose?: CallableFunction;
  altText: string;
}
export const FullScreenExperienceDescImage: FC<IProps> = ({
  uriObject,
  onClose,
  altText,
}) => {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  const imageContainer = useRef<HTMLDivElement>(null);

  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    setContainerWidth(imageContainer.current?.clientWidth || 0);

    const resizeEventListener: EventListener = () => {
      setContainerWidth(imageContainer.current?.clientWidth || 0);
    };

    window.addEventListener('resize', resizeEventListener);

    return () => {
      window.removeEventListener('resize', resizeEventListener);
    };
  }, [imageContainer.current]);

  const onFullScreenImageLoad: HTMLAttributes<HTMLImageElement>['onLoad'] = ({
    target,
  }) => {
    const img = target as HTMLImageElement;

    setContainerWidth(imageContainer.current?.clientWidth || containerWidth);
    setDimensions({
      width: img.naturalWidth,
      height: img.naturalHeight,
    });
  };

  const scale = useMemo(() => {
    if (!dimensions.width || !containerWidth) {
      return 1;
    }

    const scale = dimensions.width / containerWidth;

    if (scale < 1) {
      return 1;
    }

    return Math.min(scale, 5);
  }, [containerWidth, dimensions.width]);

  const onClick: MouseEventHandler<HTMLDivElement> = () => {
    onClose?.();
  };

  useEffect(() => {
    const eventListener: EventListener = (e) => {
      if ((e as KeyboardEvent).key === 'Escape') {
        onClose?.();
      }
    };

    document.addEventListener('keyup', eventListener);

    return () => {
      document.removeEventListener('keyup', eventListener);
    };
  }, []);

  const handleFullScreenImageClick: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <FullScreenImage onClick={onClick}>
      <SC.CloseIcon onClick={onClick} />

      <div
        ref={imageContainer}
        role="presentation"
        onClick={handleFullScreenImageClick}
      >
        <TransformWrapper
          minScale={1}
          maxScale={scale}
          limitToBounds
          centerZoomedOut
          smooth
          disablePadding
          panning={{
            disabled: scale === 1,
          }}
        >
          <TransformComponent>
            <img
              src={uriObject.original}
              onLoad={onFullScreenImageLoad}
              alt={altText}
            />
          </TransformComponent>
        </TransformWrapper>
      </div>
    </FullScreenImage>
  );
};
