import useSearchQueryParams from 'hooks/use-search-params';
import React from 'react';
import styled from 'styled-components';
import { WarningIcon } from './WarningIcon';

export const PreviewBanner = () => {
  const { searchParams } = useSearchQueryParams();

  if (searchParams.latest !== 'true') return null;
  return (
    <BannerWrapper>
      <WarningIcon />
      <p>
        Experience Preview - Guests will not be able to book through this link
      </p>
    </BannerWrapper>
  );
};

const BannerWrapper = styled.div`
  z-index: ${Number.MAX_SAFE_INTEGER};
  position: fixed;
  top: 0;
  right: 0;
  height: 30px;
  margin: 48px;
  padding: 25px 16px;
  background-color: #fffbe6;
  border: 0.5px solid #ffe58f;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;

  & p {
    margin: 0;
  }
`;
