import React from 'react';
import styled from 'styled-components';
import {
  SkeletonLine,
  SkeletonPulse,
} from 'components/theme/Skeleton/Skeleton';

export const CollectionItemSkeleton = () => {
  return (
    <SkeletonCard>
      <div>
        <SkeletonPulse />
      </div>

      <SkeletonLine />
      <SkeletonLine />
    </SkeletonCard>
  );
};

const SkeletonCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  max-width: 424px;
  max-height: 292px;
  position: relative;

  > *:first-child {
    width: 100%;
    flex: 1;
    position: relative;
    min-height: 210px;
  }
`;
