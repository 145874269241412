import styled from 'styled-components';
import { Heading4 } from 'components/theme/Typography/Typography';
import { IFormItemProps } from 'features/ShoppingCart/types';

export const FieldTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 5px;

  h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: ${({ theme }) => theme.font.primaryFont};
    font-weight: normal;
    margin: 0;
  }
  p {
    font-family: ${({ theme }) => theme.font.secondaryFont};
    font-size: 12px;
    line-height: 15px;
    margin: 0;
  }
`;

export const FormWrapper = styled.div`
  display: grid;
  @media (min-width: 768px) {
    justify-content: space-between;
    grid-template-columns: minmax(200px, 720px) minmax(200px, 317px);
    align-items: flex-start;
    gap: 80px;
  }
`;

export const FormSection = styled.form``;

export const HeadTitle = styled(Heading4)`
  font-weight: bold !important;
`;

export const Title4 = styled.h5`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 6px;
  font-weight: normal;
  text-transform: none;
`;

export const ButtonLink = styled.button`
  border: none;
  background: none;
  padding: 0;
  color: var(--way-colors-primaryColorShades-100);
  font-family: ${({ theme }) => theme.font.secondaryFont};
  cursor: pointer;
  font-size: 15px;
`;

export const NotesSection = styled.div<{ isMultipleTier?: boolean }>`
  margin: ${({ isMultipleTier }) =>
    isMultipleTier ? '64px 0 24px 0' : '0 0 24px'};
`;

export const FormItem = styled.div<IFormItemProps>``;

export const FooterButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TabGroup = styled.div`
  display: flex;
  align-items: center;
  border: 0.5px solid var(--way-colors-borderColor);
  margin-bottom: 10px;
`;

export const Tab = styled.div<{ active: boolean }>`
  flex: 1;
  height: 32px;
  padding: 4px 11px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: start;
  background: ${({ active }) =>
    active ? 'var(--way-palette-black-80)' : 'var(--way-palette-white-100)'};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};

  &:not(:last-child) {
    border-right: 0.5px solid var(--way-colors-borderColor);
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  margin-top: 12px;
  gap: 24px;

  & > input {
    flex: 1;
    width: 50%;
    border-radius: var(--way-design-borderRadiusDefault);
    border: 0.5px solid var(--way-colors-borderColor);
    color: var(--way-colors-contrastColorShades-100);
    background-color: transparent;
    padding: 5px 12px;
    font-size: 14px;
    line-height: 22px;

    &:hover,
    &:focus {
      outline: none;
      border: 0.5px solid var(--way-colors-borderHoverColor);
    }
  }
`;

export const ChargeAccordionContent = styled.div`
  padding-top: 10px;
  display: block;

  & > * {
    max-height: none;
  }
`;

export const StyleInput = styled.input`
  width: '100%';
  border-radius: var(--way-design-borderRadiusDefault);
  flex: 1;
  border: 0.5px solid var(--way-colors-borderColor);
  padding: 5px 12px;
  font-size: 14px;
  height: 42px;

  &:focus {
    outline: none;
  }
  &:nth-child(2) {
    margin-left: 15px;
  }
`;

export const PaymentErrorContainer = styled.div`
  min-height: 20px;
`;

export const AccordionContent = styled.div`
  padding-top: 10px;

  & > * {
    max-height: none;
  }
`;
