const DEFAULT_PALETTE_SHADES = [
  '100',
  '90',
  '80',
  '70',
  '60',
  '50',
  '40',
  '30',
  '20',
  '10',
  '5',
] as const;

type Shades = (typeof DEFAULT_PALETTE_SHADES)[number];
export type PaletteColorShades = Record<Shades, string>;

export const generateShades = (color: string) => {
  if (!color) {
    return '';
  }

  const colors: Partial<PaletteColorShades> = {};

  DEFAULT_PALETTE_SHADES.forEach((shade) => {
    colors[shade] = `${color}${Math.round((parseInt(shade, 10) * 255) / 100)
      .toString(16)
      .padStart(2, '0')
      .toUpperCase()}`;
  });

  return colors as PaletteColorShades;
};

const themePalette = {
  black: generateShades('#000000'),
  charcoal: generateShades('#333333'),
  gray: generateShades('#666666'),
  silver: generateShades('#999999'),
  white: generateShades('#FFFFFF'),
  gold: generateShades('#957A46'),
  oxblood: generateShades('#7C0321'),
  brown: generateShades('#5F3523'),
  purple: generateShades('#563170'),
  blue: generateShades('#2D4D7E'),
  red: generateShades('#BA001E'),
} as const;

export default themePalette;
