export default {
  // these should be unique as the values are used as keys
  profileImageOfHost: '호스트 {{hostName}}의 프로필 이미지',
  hostedBy: '가 호스팅',
  experiences: '경험',
  experience: '경험',
  clear: '지우기',
  cancel: '취소',
  apply: '적용',
  more: '더보기',
  experiencePhotoOfListing:
    '리스트 {{listingTitle}}의 경험 사진 {{photoNumber}}',
  noExperiencesFound: '경험을 찾을 수 없습니다',
  noExperiencesFoundFor: '해당하는 경험을 찾을 수 없습니다',
  discoverExperiences: '경험 발견하기',
  clearFilters: '필터 지우기',
  buyTickets: '티켓 구매',
  reserve: '예약하기',
  activateProductCardTitle: '이벤트',
  reserveProductCardTitle: '활동',
  hostProductCardTitle: '현지 경험',
  mainLandingPageTitle: '모든 경험',
  activateLandingPageTitle: '이벤트',
  reserveLandingPageTitle: '활동 및 편의시설',
  hostLandingPageTitle: '현지 경험',
  exclusive: '독점',
  filterExperiences: '경험 필터',
};
