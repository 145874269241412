import * as types from 'types/review';
import * as appTypes from 'types/app';
import initialState from 'store/initial-state';

export default function reviewReducer(state = initialState.review, action) {
  switch (action.type) {
    // read
    case types.FETCH_REQUEST:
      return {
        ...state,
        status: appTypes.STATUS_PENDING,
        responseStatus: null,
        error: '',
      };

    case types.FETCH_FAILURE:
      return {
        ...state,
        status: appTypes.STATUS_FAILURE,
        responseStatus: action.payload.responseStatus,
        error: action.error,
      };

    case types.FETCH_SUCCESS:
      return {
        ...state,
        status: appTypes.STATUS_SUCCESS,
        responseStatus: 200,
        error: '',
        reviews: action.payload.shouldAppend
          ? [...state.reviews, ...action.payload.data.items]
          : action.payload.data.items,
        pagination: {
          meta: action.payload.data.meta,
          links: action.payload.data.links,
        },
      };

    default:
      return state;
  }
}
