import { useMemo } from 'react';
import * as R from 'ramda';
import { CartItemState, ICartState } from 'types/cart';
import { useAppState } from 'AppProvider';

const useCartItems = (includePendingItems = false) => {
  const {
    cartId,
    experiences: cartItems,
    loading,
  }: ICartState = useAppState((state: Record<string, unknown>) => state.cart);

  const cartItemsReady = useMemo(
    () =>
      R.filter(
        (cartItem) =>
          includePendingItems || cartItem.state === CartItemState.READY,
        cartItems,
      ),
    [cartItems, includePendingItems],
  );

  return {
    cartId,
    cartItems: cartItemsReady || {},
    isLoading: Object.values(cartItems).length === 0 && !!loading,
  };
};

export default useCartItems;
