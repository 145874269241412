import { WayVersion } from '@kouto/types';
import { useAppState } from 'AppProvider';

export const useIsBrandV2 = (): boolean => {
  const { settings } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );

  return settings?.wayVersion === WayVersion.V2;
};
