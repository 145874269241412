export default {
  date: 'Дата',
  chooseExpDate: 'Выберите дату для впечатления',
  filterExpDate: 'Фильтр по дате',
  experienceType: 'Тип',
  filterExperienceType: 'Фильтр по типу впечатления',
  experienceVibe: 'Обстановка',
  filterExperienceVibe: 'Фильтр по обстановке впечатления',
  search: 'Поиск',
  viewCalendar: 'Посмотреть календарь',
  back: 'Назад',
  previous: 'Предыдущий',
  next: 'Следующий',
  noExperiences: 'Нет впечатлений',
  cancelChanges: 'Отменить изменения',
  saveChanges: 'Сохранить изменения',
  removeFromReservation: 'Удалить из бронирования',
  addToCart: 'Добавить в корзину',
  proceedToCheckout: 'Перейти к оформлению заказа',
};
