import React, { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { getDatesFromSessions, filterAvailableSessions } from 'utils/session';
import { formatDateShort } from 'utils/dateTime';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import ButtonsOptionsList, { ButtonsOptionSizes } from './ButtonsOptionsList';

const TicketDateSelector: FC = () => {
  const {
    isLoading,
    event,
    eventSessions,
    date: selectedDate,
    setEventBookingSessionParams,
  } = useEventBookingSession();

  const availableDates = useMemo(() => {
    const availableSessions = filterAvailableSessions(eventSessions);
    return getDatesFromSessions(availableSessions).map((date) => ({
      value: date,
      label: formatDateShort(date),
    }));
  }, [eventSessions]);

  const handleClick = useCallback(
    (date: string) => {
      if (date !== selectedDate) {
        setEventBookingSessionParams({ date });
      }
    },
    [selectedDate, setEventBookingSessionParams],
  );

  if (isLoading || !event || !selectedDate) {
    return (
      <Wrapper>
        <DateButtonSkeleton />
        <DateButtonSkeleton />
      </Wrapper>
    );
  }

  return (
    <ButtonsOptionsList
      options={availableDates}
      selectedValue={selectedDate}
      onOptionClick={handleClick}
      buttonSize={ButtonsOptionSizes.BIG}
    />
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
`;

const DateButtonSkeleton = styled(SkeletonLineSlim)`
  width: 128px;
  height: 45px;
`;

export default TicketDateSelector;
