import React, { FC } from 'react';
import { CalendarProps } from 'react-calendar';
import moment from 'moment';
import BottomSheetDateSelector from 'components/Resource/BottomSheetDateSelector';
import { Modal } from 'components/Modal';
import { useIsMobile } from 'WindowDimensionProvider';
import { BottomDrawer } from 'components/BottomDrawer';

interface Props extends Omit<CalendarProps, 'value'> {
  availableDates: string[];
  onMonthChange: (date: moment.Moment) => void;
  value: string;
  onModalClose?: () => void;
  open?: boolean;
}

export const ModalDatePicker: FC<Props> = ({
  availableDates,
  onChange,
  onMonthChange,
  onModalClose = () => undefined,
  open = false,
  ...props
}) => {
  const isMobile = useIsMobile();

  const handleChange: CalendarProps['onChange'] = (option, event) => {
    onModalClose?.();
    onChange?.(option, event);
  };

  return (
    <>
      {isMobile ? (
        <BottomDrawer
          size="full"
          open={open}
          heading="Date"
          hideCloseIcon
          onClose={onModalClose}
        >
          <BottomSheetDateSelector
            availableDates={availableDates}
            onMonthChange={onMonthChange}
            {...props}
            onChange={handleChange}
          />
        </BottomDrawer>
      ) : (
        <Modal
          content={
            <BottomSheetDateSelector
              availableDates={availableDates}
              onMonthChange={onMonthChange}
              {...props}
              onChange={handleChange}
            />
          }
          isClosable
          onClose={onModalClose}
          isVisible={open}
        />
      )}
    </>
  );
};

export default BottomSheetDateSelector;
