import React, { useRef } from 'react';
import get from 'lodash/get';
import { Controller, ControllerProps, useFormContext } from 'hook-form';
import { useTranslation } from 'react-i18next';
import {
  StyledWrapInput,
  StyledLabel,
  StyledInput,
  ErrorContainer,
  Error,
} from 'components/theme/Form';
import { validateEmpty } from 'utils/validators';

export interface ITextFieldProps extends Partial<ControllerProps> {
  name: string;
  label?: string;
  required?: boolean;
}

const TextFieldInput = ({
  name,
  label,
  required,
  ...props
}: ITextFieldProps) => {
  const { control } = useFormContext();
  const { t: translate } = useTranslation();
  const inputId = `input-${name.replace('.', '-')}`;
  const errorRef = useRef<HTMLDivElement>(null);

  return (
    <Controller
      name={name}
      control={control}
      rules={required ? validateEmpty() : props.rules ?? {}}
      {...props}
      render={({ field, formState }) => {
        const errorMessage = get(formState, `errors.${name}.message`) as string;
        const errorId = `error-${inputId}`;

        return (
          <StyledWrapInput>
            <StyledLabel htmlFor={inputId}>
              {label}
              {required && <span aria-hidden="true">*</span>}
            </StyledLabel>
            <StyledInput
              type="text"
              id={inputId}
              aria-required={required ? 'true' : 'false'}
              aria-invalid={!!errorMessage}
              aria-describedby={errorMessage ? errorId : undefined}
              {...field}
            />
            {errorMessage && (
              <ErrorContainer
                ref={errorRef}
                role="alert"
                aria-live="assertive"
                tabIndex={-1}
              >
                <Error id={errorId}>{translate(errorMessage)}</Error>
              </ErrorContainer>
            )}
          </StyledWrapInput>
        );
      }}
    />
  );
};

export default TextFieldInput;
