export default {
  staging: {
    scriptVersion: true,
    discountCode: true,
    weatherWidget: true,
    calendarView: true,
    calendarFilters: false,
  },
  live: {
    scriptVersion: false,
    discountCode: true,
    weatherWidget: false,
    calendarView: true,
    calendarFilters: false,
  },
  development: {
    scriptVersion: true,
    discountCode: true,
    weatherWidget: true,
    calendarView: true,
    calendarFilters: true,
  },
};
