import {
  CartActionTypes,
  ICartSetCardIdAction,
  ICartAddExperienceAction,
  ICartEditItemAction,
  ICartPersistExperiencesAction,
  ICartRemoveExperienceAction,
  ICartResetAction,
  ICartSetCheckoutInfoAction,
  ICartSetParticipantsInfoAction,
  ICartNoNeedToLoadPersistedCartItemsAction,
} from 'types/cart';

export const setCartId = (
  payload: ICartSetCardIdAction['payload'],
): ICartSetCardIdAction => ({
  type: CartActionTypes.SET_CART_ID,
  payload,
});

export const addExperienceToCart = (
  payload: ICartAddExperienceAction['payload'],
): ICartAddExperienceAction => ({
  type: CartActionTypes.ADD_EXPERIENCE_TO_CART,
  payload,
});

export const editCartExperience = (
  payload: ICartEditItemAction['payload'],
): ICartEditItemAction => ({
  type: CartActionTypes.EDIT_CART_ITEM,
  payload,
});

export const loadPersistedCartItems = (
  payload: ICartPersistExperiencesAction['payload'],
): ICartPersistExperiencesAction => ({
  type: CartActionTypes.LOAD_PERSISTED_CART_ITEMS,
  payload,
});

export const noNeedToLoadPersistedCartItems =
  (): ICartNoNeedToLoadPersistedCartItemsAction => ({
    type: CartActionTypes.NO_NEED_TO_LOAD_PERSISTED_CART_ITEMS,
    payload: undefined,
  });

export const removeExperienceFromCart = (
  payload: ICartRemoveExperienceAction['payload'],
): ICartRemoveExperienceAction => ({
  type: CartActionTypes.REMOVE_EXPERIENCE_FROM_CART,
  payload,
});

export const setParticipantsInfoAction = (
  payload: ICartSetParticipantsInfoAction['payload'],
): ICartSetParticipantsInfoAction => ({
  type: CartActionTypes.SET_PARTICIPANTS_INFO,
  payload,
});

export const setCheckoutInformation = (
  payload: ICartSetCheckoutInfoAction['payload'],
): ICartSetCheckoutInfoAction => ({
  type: CartActionTypes.SET_CHECKOUT_INFORMATION,
  payload,
});

export const resetCart = (brandId: string): ICartResetAction => ({
  type: CartActionTypes.RESET_CART,
  payload: {
    persistMeta: {
      brandId,
    },
  },
});
