export default {
  bookExperiencePanel: 'Panel de reserva de experiencia',
  calendarIcon: 'Ícono de calendario',
  coverImageForResource: 'Imagen de portada para experiencias {{title}}',
  filterBy: 'Filtrar por {{filter}}',
  filterByDateRange: 'Filtrar por rango de fechas',
  gallery: 'Galería',
  goBack: 'Volver a la página anterior',
  hostUserIcon: 'Ícono de usuario anfitrión',
  imageOf: 'Imagen de {{title}}',
  interactiveMapAltText: 'Mapa interactivo con selección de {{title}}',
  mediaSlides: 'Diapositivas de medios',
  muteVideo: 'Silenciar video',
  nextPhoto: 'Foto siguiente',
  noVideoPlayback: 'Sin capacidad de reproducción de video',
  pauseVideo: 'Pausar video',
  photoOf: 'Foto de {{name}}',
  playVideo: 'Reproducir video',
  previousPhoto: 'Foto anterior',
  reserveSelectedSlot: 'Reservar franja horaria seleccionada',
  selectDate: 'Seleccionar fecha',
  selectParticipants: 'Seleccionar participantes',
  selectSessionTime: 'Seleccionar hora de la sesión',
  unmuteVideo: 'Activar sonido del video',
  viewShoppingCart: 'Ver carrito',
  zoomIn: 'Acercar',
  zoomOut: 'Alejar',
  selectData: 'Seleccionar {{data}}',
  reserveName: 'Reservar {{name}',
  deleteReservation: 'Eliminar la reserva de {{esperienceTitle}}',
  editParticipantsFor: 'Editar participantes para {{esperienceTitle}}',
  increaseAddOnAmount: 'Aumentar {{addOn}} seleccionado',
  decreaseAddOnAmount: 'Disminuir {{addOn}} seleccionado',
  addOnModal: '{{addOn}} modal',
  moreInformationAboutAddOn: 'Más información sobre {{addOn}}',
  viewFullDetailsDescription: 'Ver descripción completa de los detalles',
  viewFullDescription: 'Ver descripción completa',
  closeModal: 'Cerrar modal',
};
