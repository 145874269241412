import React, { ChangeEvent, ReactElement } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { Controller, ControllerProps, useFormContext } from 'hook-form';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/theme/CheckBox/CheckBox';
import { ErrorContainer, Error } from 'components/theme/Form';

interface ITextFieldProps extends Partial<ControllerProps> {
  name: string;
  label?: string | ReactElement;
  id?: string;
}

const CheckboxField = ({ name, label, id, ...props }: ITextFieldProps) => {
  const { control } = useFormContext();
  const { t: translate } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={props.defaultValue || false}
      render={({ field, formState }) => {
        const errorMessage = get(formState, `errors.${name}.message`) as string;
        return (
          <LabelSpacing>
            <CheckBox
              id={id}
              label={label}
              {...field}
              checked={field.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                field.onChange(e.target.checked)
              }
            />
            {errorMessage && (
              <ErrorContainer>
                <Error>{translate(errorMessage)}</Error>
              </ErrorContainer>
            )}
          </LabelSpacing>
        );
      }}
      {...props}
    />
  );
};

const LabelSpacing = styled.div`
  display: block;
`;

export default CheckboxField;
