import { useEffect, useState } from 'react';
import { useBrandId } from 'AppProvider';
import { IFullExperienceFields } from 'types/experience.types';
import { CartItemState } from 'types/cart';
import { IExperienceSettingsResponse } from 'types/custom-questions';
import { IExperiencePayload } from 'features/ShoppingCart/types';
import { get } from 'utils/axios';
import { cacheRequest } from 'utils/requestCache';
import useCartItems from 'hooks/useCartItems';

interface IExperienceResponse {
  data: IFullExperienceFields;
}

const fetchEndpoint = (endpoint: string) => {
  return cacheRequest(endpoint, () => get(endpoint));
};

// TODO: THIS IS WHERE WE FETCH MANUALLY EXPERIENCE DETAILS FROM CART ITEMS

const useFetchExperienceList = () => {
  const brandId = useBrandId();
  const [isError, setIsError] = useState(false);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [experienceList, setExperienceList] = useState<IExperiencePayload>([]);
  const { cartItems } = useCartItems();

  useEffect(() => {
    const fetchExperienceList = async () => {
      const experienceIds = Array.from(
        new Set(
          Object.values(cartItems).flatMap(({ experienceId, state }) =>
            state === CartItemState.READY && experienceId ? experienceId : [],
          ),
        ),
      );

      if (!experienceIds || !experienceIds.length) return;

      setIsFetchingList(true);

      try {
        const data = await Promise.all(
          experienceIds.map((experienceId) => {
            const experienceDetail = fetchEndpoint(
              `v1/brands/${brandId}/experiences/${experienceId}`,
            );
            const experienceSettings = fetchEndpoint(
              `v1/brands/${brandId}/experiences/${experienceId}/settings`,
            );
            return Promise.all([
              experienceDetail as unknown as IExperienceResponse,
              experienceSettings as unknown as IExperienceSettingsResponse,
            ]);
          }),
        );
        setExperienceList(
          data.map((e) => {
            const [details, settings] = e;
            return {
              ...details.data,
              ...settings.data,
            };
          }),
        );
      } catch (error) {
        setIsError(true);
      } finally {
        setIsFetchingList(false);
      }
    };
    const cartExperienceItemIds = Object.values(cartItems).map(
      ({ experienceId }) => experienceId,
    );
    const addedToCartExperienceItems =
      experienceList.length < cartExperienceItemIds.length;
    if (addedToCartExperienceItems) {
      fetchExperienceList();
    }
  }, [cartItems]);

  return {
    isError,
    isFetchingList,
    experienceList,
  };
};

export default useFetchExperienceList;
