import React, { FC, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/common/styled/common-styled';
import { ResourceParagraph } from 'components/theme/Typography/ResourceTypography';
import { ITier } from 'features/ShoppingCart/types';
import { ResourceGroup } from 'types/listings';
import { ResourceGroupDetail } from 'features/Reserve/components/ResourceGroupDetail';
import { ResourceGroupFullDetail } from 'features/Reserve/components/ResourceGroupFullDetail';
import useResourceBookingSession from 'features/Reserve/hooks/useResourceBookingSession';
import { useIsMobile } from 'WindowDimensionProvider';
import { getListingCoverUrl } from 'utils/listings';
import CancellationPolicySummary from 'features/ShoppingCart/components/CancellationPolicySummary';
import PriceTiersSummary from './PriceTiersSummary';

interface Props {
  priceTiers: Array<ITier>;
  resourceGroup: ResourceGroup;
}

const ResourceGroupSummary: FC<Props> = ({ priceTiers, resourceGroup }) => {
  const isMobile = useIsMobile();
  const [showDetails, setShowDetails] = useState(false);
  const { collection, cancellationPolicy } = useResourceBookingSession();
  const { t: translate } = useTranslation();

  const coverImageUrl = useMemo(
    () => (collection ? getListingCoverUrl(collection, isMobile) : ''),
    [collection, isMobile],
  );

  if (!collection) {
    return null;
  }

  return (
    <>
      <SidebarWrapper direction="column" alignItem="flex-start" gap={24}>
        <PriceTiersSummary
          priceTiers={priceTiers}
          title={resourceGroup.title}
          taxes={resourceGroup.taxes}
          coverImageUrl={coverImageUrl}
          applyFlatFeeToEachTier={!collection.map}
        />
        <DetailsWrapper>
          <Flex direction="column" gap={12} alignItem="flex-start">
            <ResourceParagraph
              className="resource-group-summary__total"
              color="var(--way-palette-black-100)"
              fontSize="14px"
              fontWeight="700"
            >
              {translate('details')}
            </ResourceParagraph>

            <ResourceGroupDetail
              group={resourceGroup}
              maxLines={2}
              customStyles={textStyles}
              onReadMore={() => setShowDetails(true)}
              hasMoreToShow={
                (!!cancellationPolicy && cancellationPolicy.active) ||
                !!resourceGroup.included.length
              }
            />
          </Flex>
        </DetailsWrapper>
        <CancellationPolicySummary cancellationPolicy={cancellationPolicy} />
      </SidebarWrapper>
      <ResourceGroupFullDetail
        group={resourceGroup}
        showContent={showDetails}
        onClose={() => setShowDetails(false)}
        maxLines={5}
        cancellationPolicy={cancellationPolicy}
      />
    </>
  );
};

const SidebarWrapper = styled(Flex)`
  width: 350px;

  & > * {
    width: 100%;
  }
`;

const WrapperDiv = styled.div`
  padding: 24px;
`;

const DetailsWrapper = styled(WrapperDiv)`
  border: solid 0.5px var(--way-colors-borderColor);
  max-width: 350px;
`;

const textStyles = css`
  margin-bottom: 0;
  & > p {
    font-family: ${({ theme }) => theme.font.primaryFont};
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    font-size: 14px;
    color: var(--way-colors-primaryTextColor);
  }
`;

export default ResourceGroupSummary;
