import { useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { ANALYTICS_PROPERTY } from '@kouto/types';
import { analyticsManager, AnalyticsProduct } from 'features/analytics';
import { useAppState } from 'AppProvider';
import {
  formatQueryParams,
  formatQueryString,
  setBrowserUserData,
} from 'features/analytics/services/analytics.helpers';
import { getAppLanguage } from 'utils/language';
import type { DiscountCode } from 'types/discount-code';
import { IBrandState } from 'types/reducer-states/brandState';
import {
  getAnalyticsDataForExperience,
  setTierBasedData,
} from 'features/analytics/hooks/usePriceTierAnalyticsDataCollection';
import { ITier } from 'features/ShoppingCart/types';
import { computeOneCartItemTotalDetails } from '@kouto/cart-prices';
import useSearchParams from './use-search-params';
import useCartItemsListings from './useCartItemsListings';

interface ITierBasedData {
  title: string[];
  category: string[];
  count: number;
  tiers: string[];
  participantsCount: number[];
}

function useCartAnalyticsTracker() {
  const { discountCode }: DiscountCode = useAppState(
    (state: Record<string, unknown>) => state.discountCode,
  );

  useEffect(() => {
    if (discountCode?.code) {
      analyticsManager.setAppData({
        [ANALYTICS_PROPERTY.DiscountCode]: discountCode.code,
      });

      return;
    }

    analyticsManager.removeAppData(ANALYTICS_PROPERTY.DiscountCode);
  }, [discountCode]);

  const { cartItems, listings } = useCartItemsListings();

  useEffect(() => {
    if (
      Object.values(listings).length === 0 ||
      Object.values(cartItems).length === 0
    ) {
      return;
    }

    const { titles, categories, prices, avgPrice, tierBasedData, products } =
      Object.values(cartItems)?.reduce(
        (values, cartExp, idx, items) => {
          const rawListing =
            listings[cartExp?.listingId || cartExp.experienceId];
          const listing: typeof rawListing | undefined = rawListing;
          const valueSeparator = idx === items.length - 1 ? '' : ';';

          let totalPriceTiersAmount = 0;
          let totalPriceTiersAmountBeforeDiscount = 0;
          let discountAmount = 0;
          if (listing) {
            const discountableExperienceIds: string[] =
              discountCode?.experienceIds || [];
            let percentageDiscount = 0;
            if (
              discountableExperienceIds.includes(cartExp.experienceId) &&
              discountCode
            ) {
              percentageDiscount = discountCode.value;
            }

            const cartItemsTotalDetails = computeOneCartItemTotalDetails(
              cartExp,
              listing,
              percentageDiscount,
            );
            totalPriceTiersAmount = cartItemsTotalDetails.totalPriceTiersAmount;
            discountAmount = cartItemsTotalDetails.priceTiersDiscountAmount;
            totalPriceTiersAmountBeforeDiscount =
              cartItemsTotalDetails.totalPriceTiersAmount +
              cartItemsTotalDetails.priceTiersDiscountAmount;
          }

          const tierData = cartExp.participants.reduce(
            (acc, p) => {
              if (p.priceTierName) {
                if (!acc[p.priceTierName]) {
                  acc[p.priceTierName] = {
                    count: 0,
                    price: 0,
                  };
                }

                acc[p.priceTierName].count += 1;
                acc[p.priceTierName].price += p.price;
              }
              return acc;
            },
            {} as Record<
              string,
              {
                count: number;
                price: number;
              }
            >,
          );

          const totalTierPrice = Object.values(tierData).reduce(
            (sum, p) => sum + p.price,
            0,
          );
          const priceRatio = totalPriceTiersAmount / totalTierPrice;

          const tiers = Object.keys(tierData).map((key) => {
            return {
              name: key,
              selectedNumber: tierData[key].count,
              price:
                tierData[key].price &&
                (tierData[key].price / tierData[key].count) * priceRatio,
            } as ITier;
          });

          const itemTierData = getAnalyticsDataForExperience({
            title: listing?.title || '',
            category: listing?.category?.name || '',
            tiers,
          });

          const categoryName =
            typeof listing?.category === 'string'
              ? listing.category
              : listing?.category?.name ?? '';

          const resourceGroup = listing?.resourceGroups?.find((group) =>
            group?.experiences?.find(
              (experience) => experience.id === cartExp.experienceId,
            ),
          );

          const itemId =
            resourceGroup?.id ?? cartExp.experienceId ?? cartExp.listingId;

          return {
            products: [
              ...values.products,
              {
                id: itemId,
                category: categoryName,
                index: 0,
                title:
                  resourceGroup?.title ??
                  cartExp?.groupTitle ??
                  listing?.title ??
                  '',
                priceTiers: tiers,
                coupon: discountAmount ? discountCode?.code : '',
                discount: discountAmount,
              },
              ...(cartExp.addOns?.map((addon) => {
                return {
                  id: addon.id,
                  category: categoryName,
                  index: 0,
                  title: addon.label,
                  itemListId: itemId,
                  itemListName: 'Addons',
                  priceTiers: [
                    {
                      name: '',
                      price: addon.price,
                      selectedNumber: addon.selectedNumber,
                    },
                  ],
                };
              }) || []),
            ],
            titles:
              values.titles +
              (listing?.title ? `${listing.title}${valueSeparator}` : ''),
            categories:
              values.categories +
              (listing?.category ? `${listing.category}${valueSeparator}` : ''),
            prices: `${
              values.prices
            }${totalPriceTiersAmountBeforeDiscount.toFixed(
              2,
            )}${valueSeparator}`,
            avgPrice: `${values.avgPrice}${(
              totalPriceTiersAmountBeforeDiscount / cartExp.participants.length
            ).toFixed(2)}${valueSeparator}`,
            tierBasedData: {
              title: values.tierBasedData.title.concat(itemTierData.title),
              category: values.tierBasedData.category.concat(
                itemTierData.category,
              ),
              count: values.tierBasedData.count + itemTierData.count,
              tiers: values.tierBasedData.tiers.concat(itemTierData.tiers),
              participantsCount: values.tierBasedData.participantsCount.concat(
                itemTierData.participantsCount,
              ),
              perParticipantTierPrice:
                values.tierBasedData.perParticipantTierPrice.concat(
                  itemTierData.perParticipantTierPrice,
                ),
              priceTierPrices: values.tierBasedData.priceTierPrices.concat(
                itemTierData.priceTierPrices,
              ),
            },
          };
        },
        {
          products: [] as AnalyticsProduct[],
          titles: '',
          categories: '',
          prices: '',
          avgPrice: '',
          tierBasedData: {
            title: [],
            category: [],
            count: 0,
            tiers: [],
            participantsCount: [],
            perParticipantTierPrice: [],
            priceTierPrices: [],
          } as ITierBasedData & {
            perParticipantTierPrice: number[];
            priceTierPrices: number[];
          },
        },
      );

    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.Products]: JSON.stringify(products),
    });

    setTierBasedData(tierBasedData);

    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.CartExperienceCount]:
        Object.keys(cartItems).length?.toString() ?? '0',
      [ANALYTICS_PROPERTY.CartExperiences]: titles,
      [ANALYTICS_PROPERTY.CartCategories]: categories,
      [ANALYTICS_PROPERTY.CartExperienceAmount]: prices,
      [ANALYTICS_PROPERTY.CartExperienceAvgAmount]: avgPrice,
    });
  }, [JSON.stringify(cartItems), Object.keys(listings).length, discountCode]);
}

export function usePageAnalyticsTracker() {
  useCartAnalyticsTracker();
  const location = useLocation();
  const searchParams = useSearchParams();

  const { vibes, categories, settings }: IBrandState = useAppState(
    (state: Record<'brand', IBrandState>) => state.brand,
  );

  useEffect(() => {
    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.BrandName]: settings?.name ?? '',
    });
  }, [settings?.name]);

  const queryParams = useMemo(() => {
    return formatQueryParams(searchParams.searchParams || {});
  }, [location.search]);

  const queryString = useMemo(() => {
    return formatQueryString({
      queryParams,
      vibes,
      categories,
    });
  }, [queryParams, vibes, categories]);

  useEffect(() => {
    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.QueryParams]: queryString,
    });
  }, [queryString]);

  useEffect(() => {
    const existingUrl = analyticsManager.getAppData()[ANALYTICS_PROPERTY.Url];
    if (existingUrl && existingUrl !== window.location.href) {
      analyticsManager.setAppData({
        [ANALYTICS_PROPERTY.PreviousUrl]: existingUrl,
      });
    }

    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.Url]: window.location.href,
    });
  }, [location]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { search } = searchParams.searchParams || {};
    if (search) {
      analyticsManager.setAppData({ [ANALYTICS_PROPERTY.SearchQuery]: search });
    } else {
      analyticsManager.removeAppData(ANALYTICS_PROPERTY.SearchQuery);
    }
  }, [location.search]);

  useEffect(() => {
    if (queryString) {
      analyticsManager.setAppData({
        [ANALYTICS_PROPERTY.QueryParams]: queryString,
      });
    } else {
      analyticsManager.removeAppData(ANALYTICS_PROPERTY.QueryParams);
    }
  }, [queryString]);

  useEffect(() => {
    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.AppLanguage]: getAppLanguage(),
    });
  }, [location.hash, location.pathname, location.search]);
}

export function useGeneralAnalyticsTracker() {
  usePageAnalyticsTracker();

  useEffect(() => {
    setBrowserUserData();
  }, []);
}
