import React, { FC } from 'react';
import styled from 'styled-components';
import { Flex } from 'components/common/styled/common-styled';
import { ResourceParagraph } from 'components/theme/Typography/ResourceTypography';
import Add from 'assets/plus-circle';
import Minus from 'assets/minus-circle';
import { currencyFormat } from 'utils';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { getMaxQuantitiesPerTiersLists } from 'features/Events/pages/EventTicketsPage/utils/tickets-utils';
import { useTranslation } from 'react-i18next';

interface SelectorProps {
  priceTier: ReturnType<
    typeof getMaxQuantitiesPerTiersLists
  >['priceTiers'][number];
  value: number;
  onChange: (increment: number) => void;
}

export const EventPriceTierSelector: FC<SelectorProps> = ({
  priceTier,
  value,
  onChange,
}) => {
  const currency = useBrandCurrency();
  const { t } = useTranslation();

  const isSoldOut = value >= priceTier.maxParticipants;

  const onIncreaseParticipantNumber = (
    e: React.MouseEvent | React.KeyboardEvent,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (isSoldOut) return;

    onChange(1);
  };

  const onDecreaseParticipantsNumber = (
    e: React.MouseEvent | React.KeyboardEvent,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (!value) return;

    onChange(-1);
  };

  return (
    <Flex direction="row" alignItem="center" width="100%">
      <Flex direction="column" alignItem="flex-start">
        <ResourceParagraph
          className="resource-group__price-tier-name"
          fontSize="16px"
          fontWeight="700"
          style={{ userSelect: 'none' }}
        >
          {priceTier.name}
        </ResourceParagraph>
        {currency && (
          <ResourceParagraph
            className="resource-group__price-tier-amount"
            fontSize="14px"
            fontWeight="400"
            style={{ userSelect: 'none' }}
          >
            {priceTier.price === 0
              ? 'Complimentary'
              : currencyFormat(currency)(priceTier.price)}
          </ResourceParagraph>
        )}
      </Flex>
      <Flex direction="row" alignItem="center" gap={12}>
        <MinusIcon
          data-testid={`remove-${priceTier.name}`}
          onClick={onDecreaseParticipantsNumber}
          disabled={!value}
          color={
            !value
              ? 'var(--way-colors-contrastColorShades-50)'
              : 'var(--way-colors-contrastColorShades-100)'
          }
          role="button"
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent) =>
            e.key === 'Enter' && onDecreaseParticipantsNumber(e)
          }
          aria-label={t('decreasePriceTierTickets', {
            priceTier: priceTier.name,
          })}
        />
        <ResourceParagraph
          data-testid={`participants-value-${priceTier.name}`}
          className="resource-group__price-tier-number"
          fontSize="16px"
          fontWeight="700"
          style={{ userSelect: 'none', minWidth: 20, textAlign: 'center' }}
        >
          {value}
        </ResourceParagraph>
        <PlusIcon
          data-testid={`add-${priceTier.name}`}
          onClick={onIncreaseParticipantNumber}
          disabled={isSoldOut}
          color={
            isSoldOut
              ? 'var(--way-colors-contrastColorShades-50)'
              : 'var(--way-colors-contrastColorShades-100)'
          }
          role="button"
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent) =>
            e.key === 'Enter' && onIncreaseParticipantNumber(e)
          }
          aria-label={t('increasePriceTierTickets', {
            priceTier: priceTier.name,
          })}
        />
      </Flex>
    </Flex>
  );
};

const PlusIcon = styled(Add)<{ disabled: boolean }>`
  pointer-events: auto;
  cursor: ${({ disabled }) => (disabled && 'not-allowed') || 'pointer'};
  &:hover {
    opacity: 0.71;
  }
`;

const MinusIcon = styled(Minus)<{ disabled: boolean }>`
  pointer-events: auto;
  cursor: ${({ disabled }) => (disabled && 'not-allowed') || 'pointer'};
  &:hover {
    opacity: 0.71;
  }
`;

export default EventPriceTierSelector;
