import initialState from 'store/initial-state';
import * as types from 'types/user';
import * as appTypes from 'types/app';

export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
      };
    case types.LOGIN_REQUEST:
      return {
        ...state,
        authenticated: false,
        loginStatus: appTypes.STATUS_PENDING,
        error: '',
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        authenticated: false,
        loginStatus: appTypes.STATUS_FAILURE,
        error: action.error,
        validationMessages: action.payload.validationMessages,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        user: action.payload.user,
        loginStatus: appTypes.STATUS_SUCCESS,
      };
    case types.REGISTER_REQUEST:
      return {
        ...state,
        authenticated: false,
        loginStatus: appTypes.STATUS_PENDING,
        error: '',
      };
    case types.REGISTER_FAILURE:
      return {
        ...state,
        authenticated: false,
        loginStatus: appTypes.STATUS_FAILURE,
        error: action.error,
        validationMessages: action.payload.validationMessages,
      };
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        authenticated: true,
        user: action.payload.user,
        loginStatus: appTypes.STATUS_SUCCESS,
      };
    case types.CURRENT_USER_REQUEST:
      return {
        ...state,
        authenticated: false,
        currentUserStatus: appTypes.STATUS_PENDING,
        responseStatus: null,
        error: '',
      };
    case types.CURRENT_USER_FAILURE:
      return {
        ...state,
        authenticated: false,
        currentUserStatus: appTypes.STATUS_FAILURE,
        responseStatus: action.payload.responseStatus,
        error: action.error,
      };
    case types.CURRENT_USER_SUCCESS:
      return {
        ...state,
        authenticated: true,
        user: action.payload.user,
        currentUserStatus: appTypes.STATUS_SUCCESS,
      };
    // Logout
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        logoutStatus: 'pending',
        error: '',
      };
    case types.LOGOUT_FAILURE:
      return {
        ...state,
        logoutStatus: 'failure',
        error: action.error,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        authenticated: false,
        user: null,
        logoutStatus: 'success',
      };
    default:
      return state;
  }
}
