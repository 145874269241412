import { FC, useEffect, useMemo } from 'react';
import { analyticsManager } from 'features/analytics';
import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import type { IFullExperienceFields } from 'types/experience.types';
import { useAppState } from 'AppProvider';
import useSearchParams from 'hooks/use-search-params';
import { useLocation } from 'react-router-dom';
import type { IBrandState } from 'types/reducer-states/brandState';
import { useAnalyticsReadyForQueryParams } from 'hooks/use-analytics-ready-for-query-params';
import {
  getExperiencesForAnalytics,
  formatQueryParams,
  formatQueryString,
} from '../services/analytics.helpers';

interface IProps {
  experiences: IFullExperienceFields[];
}

export const HomePageAnalyticsTracker: FC<IProps> = ({ experiences }) => {
  const exp = getExperiencesForAnalytics(experiences);

  const location = useLocation();

  const searchParams = useSearchParams();

  const { vibes, categories }: IBrandState = useAppState(
    (state: Record<'brand', IBrandState>) => state.brand,
  );

  useEffect(() => {
    if (!exp) return;
    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.Products]: JSON.stringify(exp ?? []),
      [ANALYTICS_PROPERTY.AllExperiences]: exp?.map((e) => e.title).join(';'),
      [ANALYTICS_PROPERTY.AllCategories]: exp?.map((e) => e.category).join(';'),
      [ANALYTICS_PROPERTY.ExperienceCount]: exp?.length.toString(),
    });
  }, [exp]);

  const queryParams = useMemo(() => {
    return formatQueryParams(searchParams.searchParams || {});
  }, [location.search]);

  const queryString = useMemo(() => {
    return formatQueryString({
      queryParams,
      vibes,
      categories,
    });
  }, [queryParams, vibes, categories]);

  useEffect(() => {
    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.QueryParams]: queryString,
    });
  }, [queryString]);

  const isNotReady = useAnalyticsReadyForQueryParams(true);

  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_HOME,
    key: queryString,
    optionalPayload: {
      [ANALYTICS_PROPERTY.QueryParams]: queryString,
      [ANALYTICS_PROPERTY.AllExperiences]:
        exp?.map((e) => e.title).join(';') || '',
      [ANALYTICS_PROPERTY.AllCategories]:
        exp?.map((e) => e.category).join(';') || '',
      [ANALYTICS_PROPERTY.ExperienceCount]: exp?.length.toString(),
    },
    isNotReady,
  });

  return null;
};
