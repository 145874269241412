import { useAppState } from 'AppProvider';
import { useMemo } from 'react';
import useFetchExperienceList from 'selectors/experienceList';
import useCartItems from 'hooks/useCartItems';
import { PaymentMethod } from 'types/payment';
import { IExperiencePayload } from '../types';
import { getPaymentMethods } from '../utils';

export type PaymentOptions = Array<{
  name: PaymentMethod;
  shouldValidateComplimentaryBooking: boolean;
}>;

export const usePaymentOptions = () => {
  const { integrations, settings } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );
  const { cartItems } = useCartItems();
  const { experienceList } = useFetchExperienceList();

  const getExperienceValidationConfigForFreeBooking = (
    experienceList: IExperiencePayload,
    type: keyof IExperiencePayload[number]['shouldValidateComplimentaryBooking'],
  ) => {
    const validationConfig = experienceList.find((e) => {
      return !!e.shouldValidateComplimentaryBooking;
    });
    if (validationConfig) {
      return (
        validationConfig.shouldValidateComplimentaryBooking?.[type] ?? null
      );
    }
    return null;
  };

  const shouldValidateComplimentaryBookings = (
    method: PaymentMethod,
  ): boolean => {
    if (method === PaymentMethod.CREDIT_CARD || method === PaymentMethod.KICC) {
      return false;
    }

    if (method === PaymentMethod.ROOM_CHARGE) {
      const shouldValidate = getExperienceValidationConfigForFreeBooking(
        experienceList,
        'roomCharge',
      );
      if (shouldValidate !== null) {
        return shouldValidate;
      }

      if (integrations.length) {
        const integration = integrations[0];

        if (
          integration.config &&
          'shouldValidateComplimentaryBooking' in integration.config
        ) {
          return !!integration.config.shouldValidateComplimentaryBooking;
        }
      }
      return settings?.shouldValidateComplimentaryBooking?.roomCharge;
    }

    if (method === PaymentMethod.MEMBER_NUMBER) {
      const shouldValidate = getExperienceValidationConfigForFreeBooking(
        experienceList,
        'memberCharge',
      );
      if (shouldValidate !== null) {
        return shouldValidate;
      }
      return settings?.shouldValidateComplimentaryBooking?.memberCharge;
    }

    return false;
  };

  const paymentOptions = useMemo<PaymentOptions>(() => {
    const paymentMethods = getPaymentMethods({
      cartExperienceItems: cartItems,
      experienceList,
    });

    return paymentMethods.map((p) => ({
      name: p,
      shouldValidateComplimentaryBooking:
        shouldValidateComplimentaryBookings(p),
    }));
  }, [experienceList, cartItems]);

  return paymentOptions;
};
