import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'AppProvider';
import { fetchReviews } from 'actions/review';
import * as appTypes from 'types/app';

import Rating from 'components/Rating';
import { Button } from 'components/theme/Button';
import { Heading4 } from 'components/theme/Typography';
import { SkeletonLine } from 'components/theme/Skeleton';
import LoadingIndicator from 'components/theme/LoadingIndicator';
import useFetchReviews from 'selectors/review';
import RenderReviewItem from './ReviewItem';

const ReviewsList = ({ id, ratings, loading: isLoading }) => {
  const { t: translateText } = useTranslation();
  const dispatch = useDispatch();
  const reviewState = useFetchReviews(id, isLoading);

  const areReviewsLoading = reviewState.status === appTypes.STATUS_PENDING;

  const currentPage = reviewState.pagination?.meta.currentPage;
  const totalPages = reviewState.pagination?.meta.totalPages;
  const totalItems = reviewState.pagination?.meta.totalItems;

  const { reviews } = reviewState;

  const onLoadMoreReviews = useCallback(() => {
    if (areReviewsLoading) {
      return;
    }

    dispatch(
      fetchReviews(id, {
        shouldAppend: true,
        page: currentPage + 1,
      }),
    );
  }, [areReviewsLoading, id, currentPage]);

  if (!reviews || reviews.length === 0 || isNil(ratings?.rating)) return null;

  return (
    <ReviewBlock className="reviews__review-block">
      <ReviewHeading>{translateText('reviews')}</ReviewHeading>

      <MainRatingWrap>
        {!isLoading ? (
          <Rating
            originalRating={ratings?.rating || 0}
            totalReviews={totalItems}
            isAggregateMode
          />
        ) : (
          <SkeletonLine translucent />
        )}
      </MainRatingWrap>

      {isLoading ? (
        <SkeletonLine translucent />
      ) : (
        <ReviewsListWrap>
          {reviews.length === 0
            ? 'No reviews yet.'
            : reviews.map((review) => (
                <RenderReviewItem key={review.id} review={review} />
              ))}
        </ReviewsListWrap>
      )}

      {!isLoading && areReviewsLoading && (
        <div>
          <LoadingIndicator />
        </div>
      )}

      {currentPage < totalPages && (
        <ButtonBlock>
          <ButtonTransparent onClick={onLoadMoreReviews}>
            {areReviewsLoading ? 'Loading...' : 'View more reviews'}
          </ButtonTransparent>
        </ButtonBlock>
      )}
    </ReviewBlock>
  );
};

ReviewsList.propTypes = {
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  ratings: PropTypes.shape({
    rating: PropTypes.number.isRequired,
  }),
};

ReviewsList.defaultProps = {
  ratings: {
    rating: 0,
  },
};

const ReviewsListWrap = styled.div`
  font-family: ${({ theme }) => theme.font.primaryFont};
`;

const ReviewBlock = styled.div`
  display: block;
  margin-bottom: 32px;
`;

const ReviewHeading = styled(Heading4)`
  margin-bottom: 22px;
`;

const ButtonBlock = styled.div`
  text-align: center;
`;

const ButtonTransparent = styled(Button)`
  background: none;
  border: 0.5px solid var(--way-colors-primaryColorShades-100);
  color: var(--way-colors-primaryColorShades-100);
  &:hover {
    background-color: var(--way-colors-primaryColorShades-100);
    color: var(--way-colors-primaryColorContrastShades-100);
  }
`;

const MainRatingWrap = styled.div`
  margin-bottom: 26px;
  border-bottom: 0.5px solid var(--way-colors-borderColor);
  padding-bottom: 26px;
`;

export default ReviewsList;
