import React from 'react';
import { components, ContainerProps } from 'react-select';
import styled from 'styled-components';
import { FilterOption } from '../types';

export const SelectContainer: React.ComponentType<
  ContainerProps<FilterOption, boolean>
> = (props) => {
  return (
    <Wrapper>
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
      <components.SelectContainer {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 256px;
  min-width: 156px;
  display: flex;
`;

const IconWrapper = styled.div`
  padding: 4px 8px;
  position: absolute;
  top: 12px;
  left: 6px;
`;

const SearchIcon = () => (
  <div style={{ color: 'var(--way-palette-black-50)', height: 24, width: 32 }}>
    <svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </div>
);
