export default {
  featuredExperiences: 'Esperienze in primo piano',
  searchAllExperiences: 'Cerca tutte le esperienze',
  searchAll: 'Cerca tutto',
  groupSize: 'Grandezza del gruppo',
  eventSize: "Grandezza dell'evento",
  upTo: 'Fino a',
  whatWeDo: 'Cosa si farà',
  hostedBy: 'Ospitato da',
  whatsIncluded: 'Cosa è incluso',
  avail: 'Disponibilità',
  whereWeAre: 'Dove sarà',
  experienceStartingPrice: 'Da',
  experienceTotalPrice: 'Totale',
  at: 'a',
  viewPrivateSessAvailability: 'Vedi disponibilità di sessioni private',
  selectSession: 'Seleziona sessione',
  noAvailability: 'Nessuna disponibilità',
  select: 'Seleziona',
  slotsLeft: 'slot rimasti',
  slotLeft: 'slot rimasto',
  bookExperience: 'Prenota esperienza',
  book: 'Prenota',
  maxPartySizes: 'Massimo numero di slot per gruppo',
  maxPartySize: 'Massimo numero di slot per gruppo',
  selectSessionValidation: 'Seleziona una sessione',
  selectParticipantValidation: 'Seleziona i partecipanti',
  selectDateValidation: 'Seleziona una data',
  reminders: 'Promemoria',
  seeOtherDates: 'Vedi altre date',
  location: 'Luogo',
  about: 'Su',
  soldOut: 'Esaurito',
  reviews: 'Recensioni',
  reviewSingular: 'recensione',
  reviewPlural: 'recensioni',
  dateOfExperience: "Data dell'esperienza",
  readLess: 'Leggi meno',
  readMore: 'Leggi di più',
  seeMore: 'Vedi di più',
  details: 'Dettagli',
  theFollowingVideoDoesNotContainAnyAudio: 'Questo video non contiene audio',
};
