import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import CustomizableCalendarDay from 'react-dates/lib/components/CustomizableCalendarDay';
import 'components/theme/ReactDate';
import { useTranslation } from 'react-i18next';

const DayStyleShape = {
  width: ' 39px',
  height: '39px',
  borderRadius: '0px',
  background: 'rgba(45, 54, 87, 0.0893247)',
  border: '3px solid var(--way-palette-white-100)',
  padding: 0,
  margin: 0,
  lineHeight: '33px',
  fontWeight: 'normal',
};

const hoveredStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
};

const selectedStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',

  // hover: {
  //   background: 'rgba(45, 54, 87, 0.4)',
  //   border: '1px solid #e4e7e7',
  //   color: 'var(--way-palette-white-100);',
  // },
};

const selectedSpanStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',

  hover: {
    background: 'rgba(45, 54, 87, 0.4)',
    border: '3px solid var(--way-palette-white-100)',
    color: '#051E39',
  },
};

const blockedCalendarStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
};
const blockedOutOfRangeStyles = {
  background: 'rgba(45, 54, 87, 0.0893247)',
  border: '3px solid var(--way-palette-white-100)',
  color: 'rgba(5, 30, 57,.33)',
  borderRadius: '0px',
};

const highlightedCalendarStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
  hover: {
    background: 'rgba(45, 54, 87, 0.4)',
    border: '3px solid var(--way-palette-white-100)',
    color: '#051E39',
  },
};

const outsideStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
};

const DatePicker = ({
  date,
  onChange,
  orientation,
  numberOfMonths,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();
  const customDayStyles = {
    defaultStyles: {
      ...DayStyleShape,
    },
    selectedStartStyles: {
      ...selectedStyles,
      // background: colors.buttonColor,
    },
    selectedEndStyles: {
      ...selectedStyles,
      // background: colors.buttonColor,
    },
    hoveredSpanStyles: {
      ...hoveredStyles,
      // background: colors.buttonColor,
      // color: 'var(--way-palette-white-100)',
    },
    selectedSpanStyles,
    selectedStyles: {
      ...selectedStyles,
      // background: colors.buttonColor,
      // border: '1px solid #e4e7e7',
      // color: 'var(--way-palette-white-100)',
    },
    highlightedCalendarStyles,
    outsideStyles,
    blockedCalendarStyles,
    blockedOutOfRangeStyles,
  };
  return (
    <div aria-label={t('selectDate')}>
      <SingleDatePicker
        ariaLabel={t('selectDate')}
        date={date}
        onDateChange={onChange}
        enableOutsideDays={false}
        keepOpenOnDateSelect={false}
        focused={isFocused}
        showClearDate
        onFocusChange={({ focused }) => setIsFocused(focused)}
        hideKeyboardShortcutsPanel
        numberOfMonths={numberOfMonths}
        orientation={orientation}
        renderCalendarDay={(dayProps) => (
          <CustomizableCalendarDay {...dayProps} {...customDayStyles} />
        )}
        {...props}
      />
    </div>
  );
};

DatePicker.propTypes = {
  date: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  orientation: PropTypes.string,
  numberOfMonths: PropTypes.number,
};

DatePicker.defaultProps = {
  numberOfMonths: 1,
  date: null,
  orientation: 'horizontal',
};

export default DatePicker;
