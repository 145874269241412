import { useEffect, useState } from 'react';
import { IExperienceSettingsResponse } from 'types/custom-questions';
import { IExperienceFields } from 'types/experience.types';

type IExperienceList = Array<
  IExperienceFields & IExperienceSettingsResponse['data']
>;

const useCartExperienceSettings = (experienceList: IExperienceList) => {
  const [isEmailIncluded, setIsEmailIncluded] = useState(false);
  const [isEmailRequired, setIsEmailRequired] = useState(false);

  const [isPhoneRequired, setIsPhoneRequired] = useState(false);
  const [isPhoneIncluded, setIsPhoneIncluded] = useState(false);

  const checkCartSettingsForEmail = (configKey: 'isRequired' | 'isActive') => {
    // eslint-disable-next-line no-restricted-syntax
    for (const experience of experienceList) {
      const [settings] = experience.bookingForm;
      const isPurchaserScope = settings.scope === 'purchaser';
      if (isPurchaserScope && settings.fields.emailAddress[configKey]) {
        if (configKey === 'isRequired') {
          setIsEmailRequired(true);
          break;
        }
        setIsEmailIncluded(true);
        break;
      }
    }

    const isEveryExperienceScopeParticipant = experienceList?.every(
      (expList) => {
        const [settings] = expList.bookingForm;
        return settings.scope === 'participant';
      },
    );

    if (isEveryExperienceScopeParticipant) {
      setIsEmailIncluded(true);
      setIsEmailRequired(true);
      setIsPhoneIncluded(true);
    }
  };

  const checkCartSettingsForPhoneNumber = (
    configKey: 'isRequired' | 'isActive',
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const experience of experienceList) {
      const [settings] = experience.bookingForm;
      const isPurchaserScope = settings.scope === 'purchaser';
      if (isPurchaserScope && settings.fields.phoneNumber[configKey]) {
        if (configKey === 'isRequired') {
          setIsPhoneRequired(true);
          break;
        }
        setIsPhoneIncluded(true);
        break;
      }
    }
  };
  useEffect(() => {
    if (!experienceList?.length) {
      return;
    }
    const settingsKeys = ['isRequired', 'isActive'] as const;
    settingsKeys.forEach((key) => {
      checkCartSettingsForEmail(key);
      checkCartSettingsForPhoneNumber(key);
    });
  }, [experienceList]);

  return {
    isPhoneRequired,
    isPhoneIncluded,
    isEmailIncluded,
    isEmailRequired,
  };
};

export default useCartExperienceSettings;
