import { useAppState } from 'AppProvider';
import { z } from 'zod';
import { getCurrencyFromCode } from 'utils';
import { STATUS_SUCCESS } from 'types/app';
import { Currency, CurrencySymbol } from '@kouto/types';

export const CurrencyObjectSchema = z.object({
  code: z.nativeEnum(Currency),
  fullName: z.string(),
  currencySymbol: z.nativeEnum(CurrencySymbol),
});

export const useBrandCurrency = () => {
  const { fetchSettingsStatus, ...brand } = useAppState(
    (app: Record<string, unknown>) => app.brand,
  );
  if (fetchSettingsStatus === STATUS_SUCCESS) {
    const data = getCurrencyFromCode(brand.settings.currency);
    const parsedCurrency = CurrencyObjectSchema.safeParse(data);
    if (parsedCurrency.success) {
      return parsedCurrency.data;
    }
  }
  return undefined;
};
