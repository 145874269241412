import moment from 'moment';

export const formatDateShort = (date: string) => {
  const momentDate = moment(date);
  return `${momentDate.format('llll').split(',')[0]}, ${momentDate
    .format('MMM Do YY')
    .split(' ')
    .slice(0, 2)
    .join(' ')}`;
};

export const formatTimeShort = (time: string) => {
  return moment(time, 'HH:mm:ss').format('LT');
};

export const findFirstDateInThefuture = (
  dates: string[] = [],
  startTimes: string[] = [],
) => {
  const nowDateTime = moment().format().substring(0, 19);
  const lastStartTime = startTimes.sort().reverse()[0] || '23:59:59';

  return dates
    .filter((date) => {
      const dateTime = `${date}T${lastStartTime}`;
      return dateTime > nowDateTime;
    })
    .sort()[0];
};
