import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import RadioField from 'components/theme/RadioField/RadioField';

interface Props {
  options: {
    value: string;
    label: ReactNode;
  }[];
  onChange: (value: string) => void;
  selectedValue?: string;
}

export const RadioGroup: FC<Props> = ({ options, onChange, selectedValue }) => {
  return (
    <GroupWrapper className="radio-buttons__radio-group">
      {options?.map((option) => (
        <RadioField
          key={`${option.value}-${option.label}`}
          checked={selectedValue === option.value}
          label={option.label}
          onChange={() => onChange(option.value)}
          value={option.value}
        />
      ))}
    </GroupWrapper>
  );
};

const GroupWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
