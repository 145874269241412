export default {
  unavailable: 'Nicht verfügbar',
  person: 'Person',
  people: 'Personen',
  reserveNow: 'Jetzt reservieren',
  selectTime: 'Zeit auswählen',
  select: 'Auswählen',
  reserve: 'Reservieren',
  time: 'Zeit',
  tier: 'Stufe',
  questions: 'Fragen',
  addToCart: 'In den Warenkorb',
  checkout: 'Kasse',
  total: 'Gesamt',
  availability: 'Verfügbarkeit',
  whatsIncluded: 'Was ist enthalten',
  showMore: 'Mehr anzeigen',
  showLess: 'Weniger anzeigen',
  session: 'Sitzung',
  viewDetails: 'Details anzeigen',
  findAvailability: 'Verfügbarkeit finden',
  loading: 'Laden',
  capacity: 'Kapazität',
  noAvailableSessionsOnThisDate: 'Keine verfügbaren Sitzungen an diesem Datum',
  noGroupAvailableOnThisDateAndTime:
    'Keine {{groupTitle}} verfügbar an diesem Datum und zu dieser Zeit',
  noCollectionFound: 'Keine Sammlung gefunden!',
  noResourceGroupFound: 'Keine Ressourcengruppe gefunden!',
  noData: 'Keine Daten!',
  continue: 'Weiter',
};
