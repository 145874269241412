import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';

const ContainerDimensionContext = createContext(null);

const ContainerDimensionProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState([0, 0]);
  const containerRef = useRef(null);

  const onResize = useCallback((entries) => {
    entries.forEach((entry) => {
      setDimensions([entry.contentRect.width, entry.contentRect.height]);
    });
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <ContainerDimensionContext.Provider value={dimensions}>
      <div ref={containerRef}>{children}</div>
    </ContainerDimensionContext.Provider>
  );
};

ContainerDimensionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContainerDimensionProvider;

export const useContainerDimension = () =>
  useContext(ContainerDimensionContext);

export const useIsNarrowView = () => {
  const [width] = useContainerDimension();
  return width <= 1080;
};
