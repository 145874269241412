import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { uuidv4 } from 'utils';

type PartialIncludes<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export interface IToastConfig {
  type: string;
  title: string;
  message?: string;
  duration: number;
  position: string;
  id: string;
}

interface IToastContextProps {
  toastList: IToastConfig[];
  showToast: (props: PartialIncludes<IToastConfig, 'title'>) => void;
  deleteToast: (id: string) => void;
}

const ToastContext = React.createContext<IToastContextProps | null>(null);

export const ToastProvider = ({ children }: PropsWithChildren<null>) => {
  const [toastList, setToastList] = useState<Array<IToastConfig>>([]);

  const showToast = ({
    duration = 5,
    type = 'success',
    position = 'topRight',
    ...config
  }: PartialIncludes<IToastConfig, 'title'>) => {
    setToastList((list) => [
      ...list,
      {
        ...config,
        id: uuidv4(),
        type,
        position,
        duration: duration * 1000,
      },
    ]);
  };

  const deleteToast = (id: string) => {
    setToastList((toastList) => {
      return toastList.filter((toast) => toast.id !== id);
    });
  };

  const providerProps = useMemo(() => {
    return {
      toastList,
      showToast,
      deleteToast,
    };
  }, [toastList]);

  return (
    <ToastContext.Provider value={providerProps}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  const contextValue = useContext(ToastContext);
  return contextValue as IToastContextProps;
};
