type variablesGroup = Record<string, string | Record<string, string>>;

export const generateVariableCss = (
  variablesGroups: Record<string, variablesGroup>,
) => {
  let cssString = '';

  Object.keys(variablesGroups).forEach((prefix) => {
    const group = variablesGroups[prefix];
    Object.keys(group).forEach((colorVarName) => {
      if (group[colorVarName]) {
        if (typeof group[colorVarName] === 'string') {
          cssString += `--${prefix}-${colorVarName}: ${group[colorVarName]};\n`;
        } else if (
          typeof group[colorVarName] === 'object' &&
          !Array.isArray(group[colorVarName])
        ) {
          Object.keys(group[colorVarName]).forEach((shade) => {
            if (
              typeof group[colorVarName] === 'object' &&
              group[colorVarName]
            ) {
              cssString += `--${prefix}-${colorVarName}-${shade}: ${group[colorVarName][shade]};\n`;
            }
          });
        }
      }
    });
  });

  return cssString;
};
