import React, { FC } from 'react';
import { ProductLine } from '@kouto/types';
import { LandingPageHeroProps } from './types';
import HeroWithTitle from './HeroWithTitle';
import { CarouselType } from '../ListingCarousel/carouselUtils';

const LandingPageActivateHero: FC<LandingPageHeroProps> = ({
  title,
  listings,
  isLoading,
}) => {
  return (
    <HeroWithTitle
      title={title}
      listings={listings}
      isLoading={isLoading}
      carouselType={CarouselType.WIDE_HORIZONTAL}
      product={ProductLine.ACTIVATE}
    />
  );
};

export default LandingPageActivateHero;
