import { Question, QUESTION_SCOPES } from 'types/custom-questions';
import React, { useEffect, useMemo } from 'react';
import CustomQuestions from 'components/CustomQuestion/CustomQuestionsReactHookFormV5';
import { Resource } from 'types/listings';
import { useFormContext } from 'react-hook-form';
import { IParticipantConfig } from 'features/ShoppingCart/types';
import * as R from 'ramda';

export interface ResourceCustomQuestionProps {
  experienceId: string;
  defaultValues: Record<string, unknown>;
  loading?: boolean;
  questions: Resource['customQuestions'];
  onChange: (
    data: IParticipantConfig['additionalCustomQuestionResponses'],
  ) => void;
}

export const ResourceCustomQuestions: React.FC<ResourceCustomQuestionProps> = ({
  questions,
  loading = false,
  defaultValues,
  onChange,
  experienceId,
}) => {
  const methods = useFormContext();

  const data = methods.watch();

  useEffect(() => {
    if (methods.formState.dirty) {
      onChange(data);
    }
  }, [data, methods.formState.dirty, onChange]);

  const { conditional: conditionals, parent: parentQuestions } =
    groupByHierarchy(questions);

  const purchaserQuestions = useMemo<Question[]>(() => {
    return (parentQuestions ?? []).flatMap((question) => {
      if (question.config.isDefault) {
        return [];
      }
      return {
        id: question.id,
        prompt: question.prompt,
        fieldType: question.fieldType,
        options: question.options ?? undefined,
        config: {
          id: question.id,
          experienceId,
          isRequired: question.config.isRequired,
          isActive: question.config.isActive,
          order: question.config.order,
          scope: question.config.scope,
          conditionsMatchType: question.config.conditionsMatchType ?? undefined,
          conditions: question.config.conditions ?? undefined,
        },
      };
    });
  }, [questions, experienceId]);

  const conditionalQuestions = useMemo<Question[]>(() => {
    return (conditionals ?? []).flatMap((question) => {
      if (question.config.isDefault) {
        return [];
      }

      return {
        id: question.id,
        prompt: question.prompt,
        fieldType: question.fieldType,
        options: question.options ?? undefined,
        config: {
          id: question.id,
          experienceId,
          isRequired: question.config.isRequired,
          isActive: question.config.isActive,
          order: question.config.order,
          scope: question.config.scope,
          conditionsMatchType: question.config.conditionsMatchType ?? undefined,
          conditions: question.config.conditions ?? undefined,
        },
      };
    });
  }, [conditionals]);

  if (!purchaserQuestions.length) {
    return null;
  }

  return (
    <form
      className="booking-form__form-container"
      onSubmit={(e) => e.preventDefault()}
    >
      <CustomQuestions
        defaultValues={defaultValues}
        loading={loading}
        participantParentQuestions={[]}
        participantConditionalQuestions={[]}
        scope={QUESTION_SCOPES.PURCHASER}
        formStatePath="additionalCustomQuestionResponses"
        purchaserParentQuestions={purchaserQuestions}
        purchaserConditionalQuestions={conditionalQuestions}
      />
    </form>
  );
};

const groupByHierarchy = R.groupBy<Resource['customQuestions'][number]>(
  (question: Resource['customQuestions'][number]) =>
    question.config.conditions?.length ? 'conditional' : 'parent',
);
