import { useCallback } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import useResourceBookingSession from 'features/Reserve/hooks/useResourceBookingSession';
import { getPreviousRoute } from './routes';

export const useNavigationBack = () => {
  const match = useRouteMatch();

  const onCollectionBack = useResourceCollectionBackNavigation();
  const onLandingPageBack = useLandingPageBackNavigation(match);
  const onStandardPageBack = useStandardPageBackNavigation();

  if (match?.path?.includes('/collection') || match?.path.includes('/event')) {
    return onCollectionBack;
  }

  if (
    match?.path?.includes('/addons') ||
    match?.path?.includes('/participant') ||
    match?.path?.includes('/checkout') ||
    match?.path?.includes('/booking') ||
    match?.path?.includes('/waitlist')
  ) {
    return onStandardPageBack;
  }

  return onLandingPageBack;
};

const useLandingPageBackNavigation = (
  match: ReturnType<typeof useRouteMatch>,
) => {
  const history = useHistory();
  const newPath = getPreviousRoute(match);

  const onBack = useCallback(() => {
    history.replace({
      pathname: newPath ?? '/',
    });
  }, [newPath]);

  return onBack;
};

const useResourceCollectionBackNavigation = () => {
  const history = useHistory();
  const location = useLocation();
  const { collection } = useResourceBookingSession();

  const handleUniqueGroupRedirect =
    collection?.resourceGroups?.length === 1 && collection?.map;

  const parts = location.pathname.split('/');
  const newParts = parts.slice(0, parts.length - 2);
  const newPath =
    (newParts.length <= 3 && handleUniqueGroupRedirect
      ? '/'
      : newParts.join('/')) || '/';

  const onBack = useCallback(() => {
    history.replace({
      pathname: newPath,
      ...(newPath !== '/' && { search: location.search }),
    });
  }, [newPath, history, location.search]);

  return onBack;
};

const useStandardPageBackNavigation = () => {
  const history = useHistory();

  const onBack = useCallback(() => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push('/');
    }
  }, [history]);

  return onBack;
};
