import TRANSLATION_DATES_PT from './weekdays/translation';
import TRANSLATION_BOOKING_PT from './booking/translation';
import TRANSLATION_FORM_PT from './form/translation';
import TRANSLATION_EXPERIENCES_PT from './experiences/translation';
import TRANSLATION_LANDING_PAGES_PT from './landingPages/translation';
import TRANSLATION_EVENTS_PT from './events/translation';
import TRANSLATION_RESERVE_PT from './reserve/translation';
import TRANSLATION_ACCESSIBILITY_PT from './accessibility/translation';

export default {
  ...TRANSLATION_DATES_PT,
  ...TRANSLATION_BOOKING_PT,
  ...TRANSLATION_FORM_PT,
  ...TRANSLATION_EXPERIENCES_PT,
  ...TRANSLATION_LANDING_PAGES_PT,
  ...TRANSLATION_EVENTS_PT,
  ...TRANSLATION_RESERVE_PT,
  ...TRANSLATION_ACCESSIBILITY_PT,
};
