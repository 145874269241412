import React, { FC } from 'react';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/common/styled/common-styled';
import { ResourceHeading2 } from 'components/theme/Typography/ResourceTypography';
import { CancellationPolicy } from 'types/listings';
import { TruncatedText, TruncationMode } from 'components/TruncatedText';

interface Props {
  cancellationPolicy: CancellationPolicy;
}

const ResourceGroupCancellationPolicy: FC<Props> = ({ cancellationPolicy }) => {
  const { t: translate } = useTranslation();

  if (!cancellationPolicy || !cancellationPolicy.active) {
    return null;
  }

  return (
    <Flex
      direction="column"
      gap={20}
      alignItem="flex-start"
      justifyContent="flex-start"
    >
      <ResourceHeading2
        className="resource-group-cancellation-policy__heading"
        fontWeight="350"
      >
        {translate('cancellationPolicy')}
      </ResourceHeading2>
      <Flex
        direction="row"
        gap={8}
        alignItem="center"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        <TruncatedText
          customStyles={headlineTextStyles}
          isRichText
          text={cancellationPolicy.notes}
          maxLines={4}
          mode={TruncationMode.INLINE}
        />
      </Flex>
    </Flex>
  );
};

const headlineTextStyles = css`
  & > p {
    color: var(--way-colors-contrastColorShades-80);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }
`;

export default ResourceGroupCancellationPolicy;
