import React, { useState } from 'react';
import * as R from 'ramda';
import { useFormContext } from 'hook-form';
import { useTranslation } from 'react-i18next';
import TextFieldInput from 'components/common/TextFieldInput/TextFieldInput';
import CheckboxField from 'components/common/CheckboxField/CheckboxField';
import BrandTerms from 'components/common/BrandTerms/BrandTerms';
import { validateEmpty, validateWhitespace } from 'utils/validators';
import { getSelectOptions } from 'features/ShoppingCart/utils';
import * as Styled from 'features/ShoppingCart/utils/styles';
import { SelectableInput } from 'components/common/SelectableInput';
import { useCartItemId } from 'features/ShoppingCart/hooks';
import { useAppState } from 'AppProvider';
import { QUESTION_SCOPES } from 'types/custom-questions';
import {
  BookingEmailField,
  BookingPhoneField,
} from 'features/ShoppingCart/components/BookingField';
import CustomQuestions from 'components/CustomQuestion/CustomQuestions';
import useCartItems from 'hooks/useCartItems';

interface IParticipantFieldProps {
  priceTierName: string;
  fieldsLength: number;
  idx: number;
  onRemove?: () => void;
}

const ParticipantField: React.FC<IParticipantFieldProps> = ({
  priceTierName,
  fieldsLength,
  idx,
  onRemove,
}) => {
  const { setError } = useFormContext();
  const cartItemId = useCartItemId();
  const { t: translateText } = useTranslation();
  const { cartItems } = useCartItems();
  const selectOptions = getSelectOptions({
    cartExperienceItems: cartItems,
  });
  const [options, setOptions] = useState(selectOptions);
  const { terms } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );

  return (
    <Styled.FormItem isLastItem={idx === fieldsLength - 1}>
      <Styled.FieldTitle>
        {priceTierName} {idx + 1}
        {fieldsLength > 1 && !!onRemove && (
          <Styled.ButtonLink type="button" onClick={onRemove}>
            {translateText('removeParticipant')}
          </Styled.ButtonLink>
        )}
      </Styled.FieldTitle>
      {selectOptions.length >= 1 && !cartItemId ? (
        <SelectableInput
          name={`participants.${idx}.fullName`}
          options={R.uniqBy(R.prop('label'), options)}
          setOptions={setOptions}
          label={translateText('fullName')}
          onValidate={(value) => {
            const isValid = value.includes(' ');
            setError(`participants.${idx}.fullName`, {
              message: 'Must be a valid full name',
            });
            return isValid;
          }}
          rules={{ ...validateEmpty() }}
        />
      ) : (
        <TextFieldInput
          name={`participants.${idx}.fullName`}
          label={translateText('fullName')}
          rules={{
            ...validateEmpty(),
            ...validateWhitespace('Must be a full name'),
          }}
        />
      )}
      <BookingEmailField
        scope="participant"
        name={`participants.${idx}.emailAddress`}
        label={translateText('email')}
      />
      <BookingPhoneField
        scope="participant"
        name={`participants.${idx}.phoneNumber`}
        label={translateText('phone')}
      />
      <CustomQuestions
        scope={QUESTION_SCOPES.PARTICIPANT}
        formStatePath={`participants.${idx}.customQuestionResponses`}
      />
      {terms?.length >= 1 && (
        <CheckboxField
          id={`${idx}-terms`}
          name={`participants.${idx}.terms`}
          label={<BrandTerms />}
          rules={{
            ...validateEmpty('termsAggreementValidation'),
          }}
        />
      )}
    </Styled.FormItem>
  );
};

export default ParticipantField;
