import React, { ComponentProps, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/common/styled/common-styled';
import {
  ResourceHeading2,
  ResourceParagraph,
} from 'components/theme/Typography/ResourceTypography';

interface Props {
  title?: string;
  includedItems: string[];
  headingProps?: Partial<ComponentProps<typeof ResourceHeading2>>;
}

const ResourceGroupIncludedItems: FC<Props> = ({
  includedItems,
  title = '',
  headingProps,
}) => {
  const { t: translate } = useTranslation();

  if (!includedItems.length) {
    return null;
  }

  return (
    <Flex
      direction="column"
      gap={20}
      alignItem="flex-start"
      justifyContent="flex-start"
    >
      <ResourceHeading2
        className="resource-group-included__heading"
        fontWeight="350"
        {...headingProps}
      >
        {title || translate('whatsIncluded')}
      </ResourceHeading2>
      <Flex
        direction="row"
        gap={8}
        alignItem="center"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        {includedItems.map((item) => (
          <ResourceIncludedItem
            className="resource-group-included__item-wrapper"
            key={item}
          >
            <ResourceParagraph
              fontSize="14px"
              className="resource-group-included__item"
            >
              {item}
            </ResourceParagraph>
          </ResourceIncludedItem>
        ))}
      </Flex>
    </Flex>
  );
};

const ResourceIncludedItem = styled.div`
  padding: 8px 12px;
  border: solid 0.5px var(--way-colors-contrastColorShades-20);
  color: var(--way-palette-white-20);
`;

export default ResourceGroupIncludedItems;
