import styled from 'styled-components';

const ReadMoreButton = styled.a`
  cursor: pointer;
  color: var(way-colors-contrastColorShades-80);
  font-size: 14px;
  line-height: 19px;
  text-decoration: underline;
`;

export default ReadMoreButton;
