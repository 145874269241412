import { useAppState } from 'AppProvider';
import { useEffect, useState } from 'react';
import { ISessionPayload } from 'types/experience.types';
import useCartExperienceSession from './useCartExperienceSession';

const useAvailableSeats = (
  selectedEventSession: ISessionPayload,
  currentDate: string,
  experienceId: string,
) => {
  const [bookableParticipants, setBookableParticipants] = useState(0);

  const { sessions: availableSessions, experience } = useAppState(
    (state: Record<string, unknown>) => state.experience,
  );

  const cartExperience = useCartExperienceSession(
    selectedEventSession,
    currentDate,
    experienceId,
  );

  useEffect(() => {
    if (selectedEventSession?.startTime && availableSessions) {
      const session = availableSessions?.find(
        (session: ISessionPayload) =>
          session.startTime === selectedEventSession?.startTime,
      );
      setBookableParticipants(session?.supportedParticipantsCount ?? 0);
      return;
    }
    setBookableParticipants(
      (availableSessions.length
        ? Math.max(
            ...availableSessions?.map(
              (session: ISessionPayload) => session?.supportedParticipantsCount,
            ),
          )
        : experience?.maxParticipantsCount) ?? 0,
    );
  }, [experience, selectedEventSession, availableSessions]);

  const shouldFilterOut = (i: number) => {
    if (experience?.partySize > 0) {
      return !cartExperience
        ? i > experience?.partySize
        : i >
            experience?.partySize - (cartExperience?.participants?.length ?? 0);
    }

    return (
      i >
      (selectedEventSession?.supportedParticipantsCount ??
        experience.maxParticipantsCount)
    );
  };

  return new Array(bookableParticipants ?? 0)
    .fill(null)
    .map((_par, i) => i + 1)
    .filter((p) => !shouldFilterOut(p))
    .map((p) => ({
      label: p,
      value: p,
    }));
};

export default useAvailableSeats;
