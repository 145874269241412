import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useToastContext } from 'ToastProvider';
import { Wrapper } from 'components/theme/Wrapper';
import {
  ResourceHeading2,
  ResourceParagraph,
} from 'components/theme/Typography/ResourceTypography';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'components/theme/Button/Button';
import { useHistory } from 'react-router-dom';
import { GuestCancellationBookingStatus } from '@kouto/types';
import { NavigationHeader } from 'components/NavigationHeader';
import useGuestCancellation from './useGuestCancellation';
import SelectReservationStep from './components/SelectReservationStep';
import ReviewCancellationStep from './components/ReviewCancellationStep';

const GuestCancellationPage: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { showToast } = useToastContext();
  const [preSelectedBookingId, setPreSelectedBookingId] = useState<
    string | undefined
  >();
  const {
    cartId,
    token,
    cleanedUrl,
    bookings,
    isLoading,
    isCancelling,
    selectedBookingId,
    selectBookingId,
    cancelOneBooking,
  } = useGuestCancellation();

  useEffect(() => {
    const [pathname, search] = cleanedUrl.split('?');
    history.push({
      pathname,
      search,
    });
  }, []);

  const handleContinue = async () => {
    if (selectedBookingId) {
      const result = await cancelOneBooking(selectedBookingId);
      if (!result) {
        showToast({
          type: 'error',
          title: t('somethingWentWrong'),
        });
        return;
      }
      showToast({
        type: 'success',
        title: t('reservationCancelled'),
      });
    } else if (preSelectedBookingId) {
      setPreSelectedBookingId(undefined);
      selectBookingId(preSelectedBookingId);
    }
  };

  if (!cartId || !token) {
    return null;
  }

  if (isLoading) {
    // TODO: skeleton
    return null;
  }

  const pageTitle = selectedBookingId
    ? t('reviewCancellation')
    : t('selectAReservationToCancel');

  const continueButtonDisabled =
    (!selectedBookingId && !preSelectedBookingId) ||
    (!!selectedBookingId &&
      bookings[selectedBookingId].status !==
        GuestCancellationBookingStatus.CANCELLABLE) ||
    isCancelling;

  const continueButtonVisible =
    (!selectedBookingId ||
      bookings[selectedBookingId].status ===
        GuestCancellationBookingStatus.CANCELLABLE) &&
    Object.values(bookings).some(
      (b) => b.status === GuestCancellationBookingStatus.CANCELLABLE,
    );

  let continueButtonLabel = t('continue');
  if (isCancelling) {
    continueButtonLabel = `${t('cancelling')}...`;
  } else if (selectedBookingId) {
    continueButtonLabel = t('confirmCancellation');
  }

  const bookingsArray = Object.values(bookings).sort((b1, b2) => {
    return (
      new Date(b1.sessionDateTime).getTime() -
      new Date(b2.sessionDateTime).getTime()
    );
  });

  return (
    <Wrapper>
      <LayoutWrapper>
        <NavigationHeaderWrapper>
          {selectedBookingId && Object.values(bookings).length > 1 && (
            <NavigationHeader
              preventDefaultBack
              onGoBack={() => selectBookingId(undefined)}
              showCart={false}
              style={{
                marginBottom: '0px',
                height: '40px',
              }}
            />
          )}
        </NavigationHeaderWrapper>
        <Header>
          <ResourceHeading2 fontSize="28px" className="title">
            {pageTitle}
          </ResourceHeading2>
          {selectedBookingId && (
            <ResourceParagraph
              className="subtitle"
              fontSize="16px"
              opacity="0.7"
              fontWeight="400"
              lineHeight="20px"
            >
              {bookings[selectedBookingId].notes}
            </ResourceParagraph>
          )}
        </Header>

        {!selectedBookingId && (
          <SelectReservationStep
            bookings={bookingsArray}
            selectedBookingId={preSelectedBookingId}
            selectBookingId={setPreSelectedBookingId}
          />
        )}

        {selectedBookingId && (
          <ReviewCancellationStep
            selectedBooking={bookings[selectedBookingId]}
          />
        )}

        {continueButtonVisible && (
          <PrimaryButton
            big
            disabled={continueButtonDisabled}
            onClick={handleContinue}
          >
            {continueButtonLabel}
          </PrimaryButton>
        )}

        {selectedBookingId &&
          bookings[selectedBookingId].status ===
            GuestCancellationBookingStatus.CANCELLABLE && (
            <Label>{t('yourRefundWillBeProcessedToTheOriginalPayment')}</Label>
          )}
      </LayoutWrapper>
    </Wrapper>
  );
};

const LayoutWrapper = styled.div`
  margin: auto;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  max-width: 600px;

  > div {
    flex-shrink: 0;
    flex-grow: 0;
  }
`;

const NavigationHeaderWrapper = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 8px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  gap: 18px;
`;

const Label = styled.span<{ bold?: boolean; refundAmount?: boolean }>`
  font-size: 16px;
  line-height: 20px;
  color: var(--way-colors-contrastColorShades-60);
  margin-top: 16px;
`;

export default GuestCancellationPage;
