import {
  ANALYTICS_EVENT,
  type IAnalyticsIntegrationResponse,
  type ITealiumCredentials,
} from '@kouto/types/src/api/integration/analytics';
import {
  AnalyticsClient,
  type AnalyticsConfig,
  IAnalyticsDataPayload,
} from 'features/analytics/types/analytics';
import {
  getAnalyticsEventParams,
  getResolvedPropertyMapping,
} from 'features/analytics/services/analyticsPropertyMapper';
import { debug } from 'utils/debug';
import { IntegrationName } from '@kouto/types';

interface ITealiumConfig extends AnalyticsConfig {
  credential: ITealiumCredentials;
}

export class TealiumClient extends AnalyticsClient {
  private readonly integration!: ITealiumConfig;

  readonly provider = IntegrationName.TEALIUM_ANALYTICS;

  constructor(config: IAnalyticsIntegrationResponse) {
    super();
    this.integration = config as ITealiumConfig;
  }

  init() {
    return this.initialize();
  }

  hasEvent(eventName: ANALYTICS_EVENT) {
    const isEnabled = !!this.integration?.config?.events?.[eventName]?.enabled;
    if (!isEnabled) {
      debug(
        'log',
        `Event "${eventName}" disabled in ${IntegrationName.TEALIUM_ANALYTICS} configurations`,
      );
    }
    return isEnabled;
  }

  // eslint-disable-next-line class-methods-use-this
  isAvailable() {
    return !!window.utag;
  }

  private async initialize() {
    const res = this.integration.config?.initializeSdk
      ? await this.addTealiumScript()
      : await this.runWhenAvailable();

    if (!res) {
      return;
    }

    this.ready = true;

    this.events.forEach((event) =>
      this.sendEventToTealium(event.eventName, event.payload),
    );
  }

  addTealiumScript(): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this.integration.credential) {
        resolve(false);
      }
      const url = `//tags.tiqcdn.com/utag/${this.integration.credential.account}/${this.integration.credential.profile}/${this.integration.credential.environment}/utag.js`;
      const el = document.createElement('script');
      el.src = url;
      el.type = 'text/javascript';
      el.async = true;

      const lastScript = document.getElementsByTagName('script')[0];
      el.onload = async () => {
        const res = await this.runWhenAvailable();
        resolve(res);
      };

      lastScript.parentNode?.insertBefore(el, lastScript);
    });
  }

  private sendEventToTealium(
    eventName: ANALYTICS_EVENT,
    payload: IAnalyticsDataPayload,
  ) {
    if (!this.integration.config || !this.hasEvent(eventName)) {
      return;
    }

    const isView = eventName.startsWith('view_');

    const propertyMappings = getAnalyticsEventParams(
      this.integration.config,
      eventName,
    );

    const resolvedProperties = getResolvedPropertyMapping(
      propertyMappings,
      payload,
    );

    const eventProperties = {
      tealium_event: eventName,
      ...resolvedProperties,
    };

    if (isView) {
      debug('log', IntegrationName.TEALIUM_ANALYTICS, {
        type: 'View Event triggered',
        eventProperties,
        eventConfig: this.integration.config,
      });

      if (window.utag) window.utag.view(eventProperties);
      return;
    }

    debug('log', IntegrationName.TEALIUM_ANALYTICS, {
      type: 'Link Event triggered',
      eventProperties,
      eventConfig: this.integration.config,
    });

    if (window.utag) window.utag.link(eventProperties);
  }

  sendEvent(eventName: ANALYTICS_EVENT, payload: IAnalyticsDataPayload): void {
    if (this.integration && !this.hasEvent(eventName)) {
      return;
    }
    if (!this.ready) {
      this.events.push({
        eventName,
        payload,
      });
      return;
    }

    this.sendEventToTealium(eventName, payload);
  }
}

declare global {
  interface Window {
    utag: {
      view: (params: Record<string, unknown>) => void;
      link: (params: Record<string, unknown>) => void;
    };
  }
}
