export enum CarouselType {
  WIDE_HORIZONTAL = 'WIDE_HORIZONTAL',
  NARROW_HORIZONTAL = 'NARROW_HORIZONTAL',
  WIDE_VERTICAL = 'WIDE_VERTICAL',
}

export const getCardWidth = (type: CarouselType, isMobile: boolean) => {
  if (type === CarouselType.WIDE_HORIZONTAL) {
    return isMobile ? '80vw' : '70vw';
  }
  if (type === CarouselType.NARROW_HORIZONTAL) {
    return isMobile ? '326px' : '500px';
  }
  return isMobile ? '382px' : '820px';
};

export const getCardMaxWidth = (type: CarouselType, isMobile: boolean) => {
  if (type === CarouselType.WIDE_HORIZONTAL) {
    return isMobile ? '85vw' : '1000px';
  }
  if (type === CarouselType.NARROW_HORIZONTAL) {
    return isMobile ? '85vw' : '800px';
  }
  return '800px';
};

export const getCardHeight = (type: CarouselType, isMobile: boolean) => {
  if (type === CarouselType.WIDE_HORIZONTAL) {
    return '95%';
  }
  if (type === CarouselType.NARROW_HORIZONTAL) {
    return isMobile ? '430px' : '650px';
  }
  return isMobile ? '416px' : '470px';
};
