import React, { FC } from 'react';
import styled from 'styled-components';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLine } from 'components/theme/Skeleton/Skeleton';

const maxHeadlineLength = 90;

const EventHeadline: FC = () => {
  const { isLoading, event } = useEventBookingSession();

  if (isLoading || !event) {
    return (
      <Wrapper>
        <HeadlineSkeleton />
      </Wrapper>
    );
  }

  let { headline } = event;
  if (headline.length > maxHeadlineLength) {
    headline = `${headline.slice(0, maxHeadlineLength)}...`;
  }

  return (
    <Wrapper>
      <Headline>{headline}</Headline>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 24px;
  border-bottom: solid 0.5px var(--way-colors-borderColor);
`;

const Headline = styled.h1`
  margin-top: 0px;
  font-size: 32px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const HeadlineSkeleton = styled(SkeletonLine)`
  height: 37px;
  margin-bottom: 21px;
`;

export default EventHeadline;
