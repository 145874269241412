import fetchApi from 'utils/fetch';
import * as types from 'types/review';
import * as helpers from './helpers';

/**
 * Fetch one review
 */
const fetchReviewRequest = ({ experienceId }) => ({
  type: types.FETCH_REQUEST,
  payload: {
    experienceId,
  },
});

const fetchReviewSuccess = (data, shouldAppend) => ({
  type: types.FETCH_SUCCESS,
  payload: {
    data,
    shouldAppend,
  },
});

export const fetchReviews =
  (experienceId, { shouldAppend, page } = { shouldAppend: false, page: 1 }) =>
  async (dispatch) => {
    if (!experienceId) {
      throw new Error('experienceId not set or invalid');
    }

    dispatch(fetchReviewRequest({ experienceId }));

    try {
      const data = await fetchApi
        .get(`v1/experiences/${experienceId}/reviews?limit=5&page=${page}`)
        .json();

      return dispatch(fetchReviewSuccess(data, shouldAppend));
    } catch (errorResponse) {
      return dispatch(helpers.errorAction(types.FETCH_FAILURE, errorResponse));
    }
  };
