import React, { FC } from 'react';
import styled from 'styled-components';
import { ResourceParagraph } from 'components/theme/Typography/ResourceTypography';
import { Flex } from 'components/common/styled/common-styled';

interface Props {
  metaLabel: string;
  value: string;
}

const ResourceMetaMobileView: FC<Props> = ({ metaLabel, value }) => {
  return (
    <Flex
      direction="row"
      alignItem="flex-end"
      justifyContent="space-between"
      width="100%"
    >
      <Flex
        direction="column"
        gap={4}
        alignItem="flex-start"
        justifyContent="flex-start"
      >
        <Alpha80Paragraph
          className="resource-page-bottom-sheet__date-title"
          fontSize="12px"
          fontWeight="600"
          lineHeight="110%"
        >
          {metaLabel}
        </Alpha80Paragraph>
        <ResourceParagraph
          className="resource-page-bottom-sheet__date-time"
          fontSize="12px"
          fontWeight="400"
          lineHeight="normal"
        >
          {value}
        </ResourceParagraph>
      </Flex>
    </Flex>
  );
};

const Alpha80Paragraph = styled(ResourceParagraph)`
  ${({ className }) => `
    &.${className} {
      color: var(--way-colors-contrastColorShades-80);
    }
  `}
`;

export default ResourceMetaMobileView;
