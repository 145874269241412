import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PriceTierWithParticipants } from 'components/Booking/PriceTier/types';
import { Flex } from 'components/common/styled/common-styled';
import { ResourceParagraph } from 'components/theme/Typography/ResourceTypography';
import Add from 'assets/plus-circle';
import Minus from 'assets/minus-circle';
import { currencyFormat } from 'utils';
import { useBrandCurrency } from 'hooks/useBrandCurrency';

interface Props {
  priceTiers: PriceTierWithParticipants[];
  maxParticipants: number;
  minParticipants?: number;
  onChange: (
    tierId: PriceTierWithParticipants['id'],
    increment: number,
  ) => void;
}

const NonMappedPriceTierSelector: FC<Props> = ({
  priceTiers,
  maxParticipants,
  minParticipants = 0,
  onChange,
}) => {
  const selectedParticipants = useMemo(() => {
    return priceTiers.reduce((sum, tier) => {
      return sum + tier.participants;
    }, 0);
  }, [priceTiers]);

  return (
    <Flex direction="column" gap={20} alignItem="flex-start" width="100%">
      {priceTiers.map((tier) => {
        return (
          <PriceTierSelector
            key={tier.id}
            priceTier={tier}
            minParticipants={minParticipants}
            maxParticipants={
              maxParticipants - selectedParticipants + tier.participants
            }
            onChange={(increment) => onChange(tier.id, increment)}
          />
        );
      })}
    </Flex>
  );
};

interface SelectorProps {
  priceTier: PriceTierWithParticipants;
  minParticipants?: number;
  maxParticipants: number;
  onChange: (increment: number) => void;
}

export const PriceTierSelector: FC<SelectorProps> = ({
  priceTier,
  minParticipants = 0,
  maxParticipants,
  onChange,
}) => {
  const currency = useBrandCurrency();
  const { t: translate } = useTranslation();

  const isSoldOut =
    maxParticipants === 0 || maxParticipants <= priceTier.participants;
  const isAtMinimum = priceTier.participants === minParticipants;

  const onIncreaseParticipantNumber = (
    e: React.MouseEvent | React.KeyboardEvent,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (isSoldOut) return;

    onChange(1);
  };

  const onDecreaseParticipantsNumber = (
    e: React.MouseEvent | React.KeyboardEvent,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (isAtMinimum) return;

    onChange(-1);
  };

  const { t } = useTranslation();

  return (
    <Flex direction="row" alignItem="center" width="100%">
      <Flex direction="column" alignItem="flex-start">
        <ResourceParagraph
          className="resource-group__price-tier-name"
          fontSize="16px"
          fontWeight="700"
          style={{ userSelect: 'none' }}
        >
          {priceTier.name}
        </ResourceParagraph>
        {currency && (
          <ResourceParagraph
            className="resource-group__price-tier-amount"
            fontSize="14px"
            fontWeight="400"
            style={{ userSelect: 'none' }}
          >
            {priceTier.price === 0
              ? translate('complimentary')
              : currencyFormat(currency)(priceTier.price)}
          </ResourceParagraph>
        )}
      </Flex>
      <Flex direction="row" alignItem="center" gap={12}>
        <MinusIcon
          role="button"
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent) =>
            e.key === 'Enter' && onDecreaseParticipantsNumber(e)
          }
          aria-label={t('decreasePriceTierTickets', {
            priceTier: priceTier.name,
          })}
          data-testid={`remove-${priceTier.name}`}
          onClick={onDecreaseParticipantsNumber}
          disabled={isAtMinimum}
        />
        <ResourceParagraph
          data-testid={`resource-value-${priceTier.name}`}
          className="resource-group__price-tier-number"
          fontSize="16px"
          fontWeight="700"
          style={{ userSelect: 'none', minWidth: 20, textAlign: 'center' }}
        >
          {priceTier.participants}
        </ResourceParagraph>
        <PlusIcon
          role="button"
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent) =>
            e.key === 'Enter' && onIncreaseParticipantNumber(e)
          }
          aria-label={t('increasePriceTierTickets', {
            priceTier: priceTier.name,
          })}
          data-testid={`add-${priceTier.name}`}
          onClick={onIncreaseParticipantNumber}
          disabled={isSoldOut}
        />
      </Flex>
    </Flex>
  );
};

const PlusIcon = styled(Add)<{ disabled: boolean }>`
  pointer-events: auto;
  color: ${({ disabled }) =>
    disabled
      ? 'var(--way-colors-contrastColorShades-70)'
      : 'var(--way-colors-buttonColorShades-100)'};
  cursor: ${({ disabled }) => (disabled && 'not-allowed') || 'pointer'};
  &:hover {
    opacity: 0.71;
  }
`;

const MinusIcon = styled(Minus)<{ disabled: boolean }>`
  pointer-events: auto;
  color: ${({ disabled }) =>
    disabled
      ? 'var(--way-colors-contrastColorShades-70)'
      : 'var(--way-colors-buttonColorShades-100)'};
  cursor: ${({ disabled }) => (disabled && 'not-allowed') || 'pointer'};
  &:hover {
    opacity: 0.71;
  }
`;

export default NonMappedPriceTierSelector;
