import { CSSProperties } from 'react';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TypeWrapper = styled(Row)``;

export const selectStyles = {
  control: (styles: CSSProperties) => ({
    ...styles,
    minWidth: 240,
    height: 16,
    maxHeight: 16,
    margin: 8,
    border: 'none',
    boxShadow: 'none',
    paddingLeft: 32,
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  placeholder: (styles: CSSProperties) => ({
    ...styles,
    height: 16,
    lineHeight: '16px',
    color: 'gray',
    '&:hover': { cursor: 'auto' },
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginTop: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  multiValue: (styles: CSSProperties) => ({
    ...styles,
    padding: 0,
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
};

export const ControlLabel = styled.div<{ hasSeparator?: boolean }>`
  padding: 8px;
  font-size: 13px;
  border-right: 0.5px solid var(--way-colors-borderColor);
`;
