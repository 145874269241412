import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from 'utils';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { Divider } from 'components/common/styled/common-styled';
import { IncludedTax, NonIncludedTax } from '@kouto/cart-prices';
import { CalculationRow, PriceSectionContainer } from './styles';

interface Props {
  areAllListingsPricesHidden: boolean;
  cartTotal: number;
  cartSubTotal: number;
  includedTaxes: IncludedTax[];
  nonIncludedTaxes: NonIncludedTax[];
}

const PriceSection: FC<Props> = ({
  cartTotal,
  cartSubTotal,
  areAllListingsPricesHidden,
  includedTaxes,
  nonIncludedTaxes,
}) => {
  const currency = useBrandCurrency();
  const { t } = useTranslation();

  if (areAllListingsPricesHidden) {
    return <PriceSectionContainer />;
  }

  if (cartTotal === 0) {
    return (
      <PriceSectionContainer>
        <CalculationRow fontSize={18}>
          <span>{t('complimentary')}</span>
        </CalculationRow>
      </PriceSectionContainer>
    );
  }

  return (
    <PriceSectionContainer>
      <CalculationRow>
        <span>
          {t('subTotal')}:{includedTaxes.length > 0 && ' *'}
        </span>
        <span>{currencyFormat(currency)(cartSubTotal)}</span>
      </CalculationRow>

      {nonIncludedTaxes.map((t) => (
        <CalculationRow key={t.name}>
          <span>{t.name}</span>
          <span>{currencyFormat(currency)(t.amountFee)}</span>
        </CalculationRow>
      ))}

      <Divider space={20} />
      <CalculationRow fontSize={18}>
        <span>{t('total')}:</span>
        <span>{currencyFormat(currency)(cartTotal)}</span>
      </CalculationRow>

      {includedTaxes.length > 0 && (
        <CalculationRow isOmitted>
          *{t('includes')}{' '}
          {includedTaxes
            .map((t) => `${t.name} (${t.percentage}%)`)
            .join(` ${t('and')} `)}
        </CalculationRow>
      )}
    </PriceSectionContainer>
  );
};

export default PriceSection;
