import {
  PaymentDetail,
  PaymentMethod,
  PaymentMethodDetail,
  PaymentProvider,
  PaymentStatus,
  BookingMode,
} from 'types/payment';
import { IReducerAction } from 'types/reducer';
import { Listing } from './listings';
import { CustomQuestionResponse } from './custom-questions';

export enum CartActionTypes {
  SET_CART_ID = '@cart/set-cart-id',
  ADD_EXPERIENCE_TO_CART = '@cart/add-to-cart',
  EDIT_CART_ITEM = '@cart/edit-cart-item',
  SET_PARTICIPANTS_COUNT = '@cart/set-pariticpants',
  REMOVE_EXPERIENCE_FROM_CART = '@cart/remove-from-cart',
  ADD_EXPERIENCE_METADATA = '@cart/add-experience-metadata',
  SET_PARTICIPANTS_INFO = '@cart/set-participants-info',
  SET_CHECKOUT_INFORMATION = '@cart/set-checkout-info',
  LOAD_PERSISTED_CART_ITEMS = '@cart/load-persisted-cart-items',
  RESET_CART = '@cart/reset-cart',
  UPDATE_PARTICIPANT_CUSTOM_QUESTION_RESPONSE = '@cart/update-participant-CQ-response',
  REMOVE_PARTICIPANT_CUSTOM_QUESTION_RESPONSE = '@cart/remove-participant-CQ-response',
  UPDATE_PURCHASER_CUSTOM_QUESTION_RESPONSE = '@cart/update-purchaser-CQ-response',
  NO_NEED_TO_LOAD_PERSISTED_CART_ITEMS = '@cart/no-need-to-load-persisted-cart-items',
}

export interface ICartStorageData {
  [brandId: string]: {
    [id: string]: ICartExperience;
  };
}

export const CART_LS_KEY = 'cart' as const;

export interface ICartParticipant {
  firstName: string;
  lastName: string;
  priceTier?: string;
  priceTierName: string;
  price: number;
  terms: boolean;
  id?: string;
  fullName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  customQuestionResponses?: {
    [questionId: string]: CustomQuestionResponse[];
  };
}

export enum CartItemState {
  PENDING = 'PENDING',
  READY = 'READY',
}

export interface CartItemAddOn {
  id: string;
  label: string;
  selectedNumber: number;
  price: number;
  taxes: Listing['taxes'];
}

export interface ICartExperience {
  cartItemId: string;
  bookingMode: BookingMode;
  state: CartItemState;
  groupId?: string;
  groupTitle?: string;
  experienceId: string;
  listingId?: string;
  addOns?: CartItemAddOn[];
  sessionDateTime: string;
  sessionDuration: string;
  participants: ICartParticipant[];
  additionalCustomQuestionResponses: {
    [questionId: string]: CustomQuestionResponse[];
  };
  notes?: string;
  validationMessages?: string[];
  waitlistEntryId?: string;
  maxParticipantCountForPrivateBooking?: number;
}

export interface ICartPurchaser {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber?: string;
}

/* TODO: fix with actual payment method type */
export interface ICartPaymentDetails {
  paymentMethod?: PaymentMethod;
  paymentDetails?: PaymentDetail;
}

export interface ICartState {
  cartId: string;
  experiences: Record<string, ICartExperience>;
  purchaser: ICartPurchaser;
  paymentDetails: ICartPaymentDetails;
  loading?: boolean;
}

export interface ICartSetCardIdAction
  extends IReducerAction<
    CartActionTypes.SET_CART_ID,
    {
      brandId: string;
      cartId: string;
    }
  > {}

export interface ICartAddExperienceAction
  extends IReducerAction<
    CartActionTypes.ADD_EXPERIENCE_TO_CART,
    {
      cartItemId: string;
      waitlistEntryId?: string;
      cartItemMetadata: ICartExperience;
      persistMeta?: {
        brandId: string;
      };
    }
  > {}

export interface ICartEditItemAction
  extends IReducerAction<
    CartActionTypes.EDIT_CART_ITEM,
    {
      cartItemId: string;
      cartItemMetadata: Partial<ICartExperience>;
      persistMeta?: {
        brandId: string;
      };
    }
  > {}

export interface ICartPersistExperiencesAction
  extends IReducerAction<
    CartActionTypes.LOAD_PERSISTED_CART_ITEMS,
    {
      cartId: string;
      cartItems: {
        [uid: string]: ICartExperience;
      };
    }
  > {}

export interface ICartNoNeedToLoadPersistedCartItemsAction
  extends IReducerAction<
    CartActionTypes.NO_NEED_TO_LOAD_PERSISTED_CART_ITEMS,
    undefined
  > {}

export interface ICartRemoveExperienceAction
  extends IReducerAction<
    CartActionTypes.REMOVE_EXPERIENCE_FROM_CART,
    {
      cartItemId: string;
      persistMeta?: {
        brandId: string;
      };
    }
  > {}

export interface ICartSetParticipantsInfoAction
  extends IReducerAction<
    CartActionTypes.SET_PARTICIPANTS_INFO,
    {
      cartItemId: string;
      metadata: ICartParticipant[];
      persistMeta?: {
        brandId: string;
      };
    }
  > {}

export interface ICartSetCheckoutInfoAction
  extends IReducerAction<
    CartActionTypes.SET_CHECKOUT_INFORMATION,
    {
      purchaserData: ICartPurchaser;
      paymentDetails: ICartPaymentDetails;
    }
  > {}

export interface ICartResetAction
  extends IReducerAction<
    CartActionTypes.RESET_CART,
    {
      persistMeta?: {
        brandId: string;
      };
    }
  > {}

export type CartAction =
  | ICartSetCardIdAction
  | ICartAddExperienceAction
  | ICartRemoveExperienceAction
  | ICartSetParticipantsInfoAction
  | ICartSetCheckoutInfoAction
  | ICartPersistExperiencesAction
  | ICartNoNeedToLoadPersistedCartItemsAction
  | ICartEditItemAction
  | ICartResetAction;

export enum CartStatus {
  CONFIRMED = 'confirmed',
  FAILED = 'failed',
  PROCESSING = 'processing',
  cancelled = 'cancelled',
  refunded = 'refunded',
}

interface CartMeta {
  readonly paymentStatus?: PaymentStatus;
  readonly paymentDetail: PaymentDetail | null;
  readonly paymentMethodDetail: PaymentMethodDetail | null;
}

export interface CartStatusResponse {
  data: {
    cartId: string;
    paymentProvider: PaymentProvider;
    paymentReferenceCode: string | null;
    paymentMethod: PaymentMethod;
    meta: CartMeta;
    couponCode: string | null;
    cartStatus: CartStatus;
  };
}
