import React, { FC } from 'react';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';
import { useTranslation } from 'react-i18next';
import { CancellationPolicy } from 'types/listings';
import { ExperiencesBodyMap, Title4, Paragraph } from './styles';

type ExperienceCancellationPolicyProps = {
  cancellationPolicy?: CancellationPolicy;
  isLoading: boolean;
};

const ExperienceCancellationPolicy: FC<ExperienceCancellationPolicyProps> = ({
  cancellationPolicy,
  isLoading,
}) => {
  const { t: translate } = useTranslation();

  if (isLoading) {
    return (
      <ExperiencesBodyMap>
        <Title4>
          <SkeletonLine translucent />
        </Title4>
        <Paragraph>
          <SkeletonLine translucent />
        </Paragraph>
      </ExperiencesBodyMap>
    );
  }

  if (!cancellationPolicy || cancellationPolicy.active === false) return null;

  return (
    <ExperiencesBodyMap>
      <Title4>{translate('cancellationPolicy')}</Title4>
      <Paragraph>{cancellationPolicy.notes}</Paragraph>
    </ExperiencesBodyMap>
  );
};

export default ExperienceCancellationPolicy;
