import React from 'react';
import styled from 'styled-components';

interface Props {
  checked: boolean;
  className?: string;
  disabled?: boolean;
  hovered?: boolean;
  focused?: boolean;
}

const SimpleRadioUI = ({
  checked,
  className,
  disabled,
  hovered,
  focused,
}: Props) => {
  return (
    <Wrapper
      className={`way__radio-ui__wrapper ${className}`}
      disabled={disabled || false}
    >
      <Circle
        className="way__radio-ui__radio"
        checked={checked}
        disabled={disabled || false}
        hovered={hovered || false}
        focused={focused || false}
      >
        <Mark className="way__radio-ui__mark" checked={checked} />
      </Circle>
    </Wrapper>
  );
};

export default SimpleRadioUI;

const Wrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  position: relative;
  ${({ disabled }) =>
    disabled
      ? `
    cursor: not-allowed !important;
    opacity: 0.5 !important;
  `
      : ''}
`;

const Circle = styled.span<{
  checked: boolean;
  disabled: boolean;
  hovered: boolean;
  focused: boolean;
}>`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 15px;
  position: relative;
  background-color: transparent;
  display: inline-grid;
  place-items: center;
  transition: all 0.2s;
  cursor: ${({ checked, disabled }) => {
    if (disabled) {
      return 'not-allowed';
    }
    return checked ? 'default' : 'pointer';
  }};
  border: 1px solid
    ${({ checked, hovered, focused }) => {
      if (checked) {
        return 'var(--way-colors-buttonColorShades-100)';
      }
      if (focused) {
        return 'var(--way-colors-borderFocusColor)';
      }
      if (hovered) {
        return 'var(--way-colors-borderHoverColor)';
      }
      return 'var(--way-colors-borderColor)';
    }};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  box-shadow: ${({ focused }) =>
    focused ? '0 0 0 3px rgba(0, 0, 255, 0.2)' : 'none'};

  &:hover {
    border: 1px solid
      ${({ checked }) =>
        checked
          ? 'var(--way-colors-buttonColorShades-100)'
          : 'var(--way-colors-borderHoverColor)'};
  }
`;

const Mark = styled.span<{ checked: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--way-colors-buttonColorShades-100);
  opacity: ${({ checked }) => (checked ? 1 : 0)};
  transition: all 0.2s;
`;
