import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { FilterOption } from './types';
import { ChevronDown } from './CustomComponents/ChevronDown';

interface Props {
  label: string;
  value: FilterOption | FilterOption[];
  onClick: () => void;
}

const getValueLabel = (value: FilterOption | FilterOption[]) => {
  const valueArray = Array.isArray(value) ? value : [value];

  if (valueArray.length === 0) return 'All';

  if (valueArray.length > 1) {
    return `${valueArray[0].label} + ${valueArray.length - 1}`;
  }

  return valueArray[0].label;
};

export const SelectButton: React.FC<PropsWithChildren<Props>> = ({
  label,
  value,
  onClick,
}) => {
  const valueLabel = getValueLabel(value);

  return (
    <Wrapper
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => e.key === 'Enter' && onClick()}
    >
      <FilterLabel>{label}</FilterLabel>
      <FilterValue>{valueLabel}</FilterValue>
      <IconWrapper>
        <ChevronDown />
      </IconWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 256px;
  height: 32px;
  margin: 0 6px;
  background-color: var(--way-palette-black-5);
  border: 0.5px solid var(--way-colors-borderColor);
  display: flex;
  flex-direction: row;
  align-items: 'center';
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const FilterLabel = styled.div`
  padding: 8px;
  font-size: 13px;
  border-right: 0.5px solid var(--way-colors-borderColor);
`;

const FilterValue = styled.div`
  padding: 8px;
  font-size: 13px;
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  padding: 4px 8px;
`;
