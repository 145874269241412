import React from 'react';

export const WarningIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 11.25C16.25 11.9404 15.6904 12.5 15 12.5C14.3096 12.5 13.75 11.9404 13.75 11.25C13.75 10.5596 14.3096 10 15 10C15.6904 10 16.25 10.5596 16.25 11.25Z"
        fill="#FAAD14"
      />
      <path
        d="M15 14.6875C15.5178 14.6875 15.9375 15.1072 15.9375 15.625V21.875C15.9375 22.3927 15.5178 22.8125 15 22.8125C14.4822 22.8125 14.0625 22.3927 14.0625 21.875V15.625C14.0625 15.1072 14.4822 14.6875 15 14.6875Z"
        fill="#FAAD14"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8375 4.99098C16.4726 2.9939 13.5266 2.99389 12.1617 4.99098L11.6218 5.78091C8.23921 10.7299 5.33301 15.9882 2.9421 21.4853L2.82932 21.7446C1.98676 23.6818 3.25971 25.8815 5.35915 26.1161C11.7662 26.8322 18.233 26.8322 24.6402 26.1161C26.7395 25.8815 28.0125 23.6818 27.1699 21.7446L27.0571 21.4853C24.6663 15.9882 21.76 10.7299 18.3775 5.78091L17.8375 4.99098ZM13.7097 6.04899C14.33 5.14122 15.6691 5.14122 16.2896 6.04899L16.8295 6.83893C20.1453 11.6902 22.994 16.8446 25.3378 22.2331L25.4505 22.4924C25.7898 23.2724 25.2771 24.1582 24.4319 24.2527C18.1633 24.9533 11.836 24.9533 5.5674 24.2527C4.72204 24.1582 4.20946 23.2724 4.54874 22.4924L4.6615 22.2331C7.00519 16.8446 9.854 11.6902 13.1698 6.83893L13.7097 6.04899Z"
        fill="#FAAD14"
      />
    </svg>
  );
};
