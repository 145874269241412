export default {
  unavailable: 'Nedostupné',
  person: 'osoba',
  people: 'lidé',
  reserveNow: 'Rezervovat nyní',
  selectTime: 'Vybrat čas',
  select: 'Vybrat',
  reserve: 'Rezervovat',
  time: 'Čas',
  tier: 'Úroveň',
  questions: 'Otázky',
  addToCart: 'Přidat do košíku',
  checkout: 'Pokladna',
  total: 'Celkem',
  availability: 'Dostupnost',
  whatsIncluded: 'Co je zahrnuto',
  showMore: 'Zobrazit více',
  showLess: 'Zobrazit méně',
  session: 'Sezení',
  viewDetails: 'Zobrazit podrobnosti',
  findAvailability: 'Najít dostupnost',
  loading: 'Načítání',
  capacity: 'Kapacita',
  noAvailableSessionsOnThisDate: 'Žádná dostupná sezení na tento datum',
  noGroupAvailableOnThisDateAndTime:
    'Žádná {{groupTitle}} dostupná v tento datum a čas',
  noCollectionFound: 'Žádná sbírka nenalezena!',
  noResourceGroupFound: 'Žádná skupina zdrojů nenalezena!',
  noData: 'Žádná data!',
  continue: 'Pokračovat',
};
