import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TextFieldInput from 'components/common/TextFieldInput/TextFieldInput';
import { validateEmpty, validateEmailAddress } from 'utils/validators';
import { IExperiencePayload } from 'features/ShoppingCart/types';
import useCartExperienceSettings from 'features/ShoppingCart/hooks/useCartExperienceSettings';
import { Sep } from 'components/common/styled/common-styled';
import PhoneNumberController from '../PhoneNumber/PhoneNumberController';

interface IPurchaserFieldProps {
  experiences: IExperiencePayload;
}

const PurchaserSection: React.FC<IPurchaserFieldProps> = ({ experiences }) => {
  const { t: translateText } = useTranslation();
  const { isPhoneRequired, isPhoneIncluded, isEmailIncluded, isEmailRequired } =
    useCartExperienceSettings(experiences);

  return (
    <Purchaser>
      <StyledRow>
        <TextFieldInput
          name="purchaser.firstName"
          label={`${translateText('firstName')} *`}
          rules={{
            ...validateEmpty(),
          }}
        />
        <TextFieldInput
          name="purchaser.lastName"
          label={`${translateText('lastName')} *`}
          rules={{
            ...validateEmpty(),
          }}
        />
      </StyledRow>
      <StyledRow>
        {isEmailIncluded && (
          <TextFieldInput
            name="purchaser.emailAddress"
            label={`${translateText('email')} ${isEmailRequired ? `*` : ''}`}
            rules={{
              ...(isEmailRequired && { ...validateEmpty() }),
              ...validateEmailAddress(),
            }}
          />
        )}
        {isPhoneIncluded && (
          <PhoneNumberController
            name="purchaser.phoneNumber"
            label={`${translateText('phone')} ${isPhoneRequired ? '*' : ''}`}
            rules={{
              ...(isPhoneRequired && { ...validateEmpty() }),
            }}
          />
        )}
      </StyledRow>

      <Sep />
    </Purchaser>
  );
};

const StyledRow = styled.div<{ singleField?: boolean }>`
  display: flex;
  margin: 0 -12px;

  > div {
    padding: 0 12px;
    width: 100%;
    width: ${({ singleField }) => (singleField ? '50%' : '100%')};
    margin-bottom: 20px;
  }
`;

const Purchaser = styled.div`
  margin-bottom: 42px;

  & > div:last-child {
    margin-top: 32px;
  }
`;

export default PurchaserSection;
