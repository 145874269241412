import React from 'react';

const TimeIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16 * scale}
      height={16 * scale}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.2812 15.75 15.75 12.2812 15.75 8C15.75 3.71875 12.2812 0.25 8 0.25ZM8 14.25C4.54688 14.25 1.75 11.4531 1.75 8C1.75 4.54688 4.54688 1.75 8 1.75C11.4531 1.75 14.25 4.54688 14.25 8C14.25 11.4531 11.4531 14.25 8 14.25ZM9.93125 10.9875L7.27812 9.05937C7.18125 8.9875 7.125 8.875 7.125 8.75625V3.625C7.125 3.41875 7.29375 3.25 7.5 3.25H8.5C8.70625 3.25 8.875 3.41875 8.875 3.625V8.05313L10.9625 9.57187C11.1312 9.69375 11.1656 9.92813 11.0437 10.0969L10.4563 10.9062C10.3344 11.0719 10.1 11.1094 9.93125 10.9875Z"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
    </svg>
  );
};

export default TimeIcon;
