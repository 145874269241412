import React, { ChangeEvent, FC, useMemo } from 'react';
import styled from 'styled-components';
import { formatListingMedia, listingMediaIsVideo } from 'utils/listings';
import { GuestCancellationBookingStatus } from '@kouto/types';
import { useTranslation } from 'react-i18next';
import { CancellationBooking } from '../useGuestCancellation';

type Props = {
  booking: CancellationBooking;
  selected: boolean;
  selectBookingId?: (bookingId: string) => void;
};

const BookingCancellationCard: FC<Props> = ({
  booking,
  selected,
  selectBookingId,
}) => {
  const { t } = useTranslation();

  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && selectBookingId) {
      selectBookingId(booking.bookingId);
    }
  };

  const photoUrl = useMemo(() => {
    const formattedMedia = formatListingMedia([booking.coverMedia])[0];
    if (listingMediaIsVideo(formattedMedia)) {
      return formattedMedia.previewMobile;
    }
    return formattedMedia.urlMobile;
  }, [booking]);

  return (
    <Wrapper
      data-testid={booking.bookingId}
      selectable={
        !!selectBookingId &&
        booking.status === GuestCancellationBookingStatus.CANCELLABLE
      }
      onClick={() => selectBookingId?.(booking.bookingId)}
    >
      <Image src={photoUrl} />
      <Content>
        <Title>{booking.title}</Title>
        <Details>{booking.sessionLabel}</Details>
      </Content>
      <StatusWrapper>
        {booking.status === GuestCancellationBookingStatus.CANCELLABLE ? (
          <input type="radio" checked={selected} onChange={handleSelect} />
        ) : (
          <BookingStatusPill>
            {getStatusLabel(booking.status, t)}
          </BookingStatusPill>
        )}
      </StatusWrapper>
    </Wrapper>
  );
};

export default BookingCancellationCard;

const getStatusLabel = (
  status: GuestCancellationBookingStatus,
  t: (s: string) => string,
) => {
  if (status === GuestCancellationBookingStatus.CANCELLABLE) {
    return t('cancellable');
  }
  if (status === GuestCancellationBookingStatus.CANCELLED) {
    return t('cancelled');
  }
  if (status === GuestCancellationBookingStatus.DEADLINE_PASSED) {
    return t('deadlinePassed');
  }
  if (status === GuestCancellationBookingStatus.UNCANCELLABLE) {
    return t('noCancellations');
  }
  return '';
};

const Wrapper = styled.div<{ selectable: boolean }>`
  position: relative;
  width: 100%;
  height: 92px;
  cursor: default;
  user-select: none;
  border: 0.5px solid var(--way-colors-borderColor);
  transition-duration: 0.2s;
  display: flex;
  align-items: center;
  flex-direction: row;

  ${({ selectable }) =>
    selectable
      ? `
        cursor: pointer;
        &:hover {
          border: solid 0.5px var(--way-colors-borderHoverColor);
        }
    `
      : ''}
`;

const Image = styled.div<{ src: string }>`
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 10;
  aspect-ratio: 1;
  background: url('${({ src }) => src}') lightgray 50% / cover no-repeat;
`;

const Content = styled.div`
  padding: 20px;
  height: 100%;
  width: 100%;
`;

const Title = styled.h3`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  overflow: hidden;
  letter-spacing: 0.016px;
  text-overflow: ellipsis;
  margin: 0px;
  color: var(--way-colors-primaryTextColor);
`;

/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
const Details = styled.p`
  margin: 0px;
  margin-top: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.014px;
  color: var(--way-colors-contrastColorShades-60);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
/* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */

const StatusWrapper = styled.div`
  position: relative;
  display: grid;
  place-content: center;
  padding-right: 20px;
  flex-shrink: 0;

  & > input[type='radio'] {
    width: 18px;
    height: 18px;
    accent-color: var(--way-colors-contrastColorShades-80);
  }
`;

const BookingStatusPill = styled.div`
  border: 0.5px solid var(--way-colors-borderColor);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  color: var(--way-colors-contrastColorShades-100);
  padding: 6px 12px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
`;
