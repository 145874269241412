import React from 'react';
import { useLocation } from 'react-router-dom';

const KOUTO_ROOT_ID = 'kouto-embed-root';
const ROOT_PATHNAMES = ['/', '/e', '/experiences'];

const getScrollToValue = () => {
  const koutoRoot = document.getElementById(KOUTO_ROOT_ID);

  if (!koutoRoot) return 0;

  return koutoRoot.offsetTop;
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (ROOT_PATHNAMES.includes(pathname)) return;

    setTimeout(() => {
      const scrollTopY = getScrollToValue();
      window.scrollTo(0, scrollTopY);
    }, 50);
  }, [pathname]);

  return null;
};
