import useListingsByIds from 'hooks/useListingsByIds';
import useCartItems from './useCartItems';

const useCartItemsListings = (includePendingItems = false) => {
  const {
    cartId,
    cartItems,
    isLoading: isLoadingCart,
  } = useCartItems(includePendingItems);

  const { listings, isLoading: isLoadingListings } = useListingsByIds(
    Object.values(cartItems).map((item) => item.listingId || item.experienceId),
  );

  return {
    cartId,
    cartItems,
    listings,
    isLoading: isLoadingCart || isLoadingListings,
    isLoadingCart,
    isLoadingListings,
  };
};

export default useCartItemsListings;
