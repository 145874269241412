import React, { CSSProperties, isValidElement, useEffect } from 'react';
import { Flex } from 'components/common/styled/common-styled';
import { P } from 'components/theme/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { type ModalSize } from './Modal.styles';
import * as SC from './Modal.styles';

interface IModalProps {
  onClose?: () => void;
  content: React.ReactNode;
  isClosable: boolean;
  title?: string;
  isVisible: boolean;
  isDarkMode?: boolean;
  style?: CSSProperties;
  disableDismissOnClickOutside?: boolean;
  size?: ModalSize;
  gap?: number;
}

const Modal: React.FC<IModalProps> = ({
  onClose,
  content,
  isClosable,
  title,
  isVisible,
  isDarkMode = false,
  style = {},
  disableDismissOnClickOutside = false,
  size = 'small',
  gap = 36,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        const closeButton = document.getElementById('modal-close-button');
        if (closeButton) {
          closeButton.focus();
        }
      }, 100);
    }
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <SC.Overlay
      onClick={(e) => {
        const element = e.target as HTMLDivElement;
        if (
          element?.classList.contains('modal-overlay') &&
          isClosable &&
          !disableDismissOnClickOutside
        ) {
          onClose?.();
        }
      }}
      className="modal-overlay"
    >
      <SC.OverlayContent
        className="modal-content"
        size={size}
        isDarkMode={isDarkMode}
        style={style}
      >
        <Flex
          direction="column"
          alignItem="flex-start"
          gap={gap}
          style={{ position: 'relative' }}
        >
          {!title && isClosable && (
            <SC.CloseButtonWrapper
              id="modal-close-button"
              role="button"
              onClick={onClose}
              onKeyDown={(e) => {
                if (e.key === 'Enter') onClose?.();
              }}
              tabIndex={0}
              positionAbsolute
            >
              <SC.Close role="button" aria-label={t('closeModal')} />
            </SC.CloseButtonWrapper>
          )}

          {title && (
            <SC.HeaderWrapper direction="row" alignItem="flex-start">
              {title ? (
                <SC.ModalTitle className="modal-title">{title}</SC.ModalTitle>
              ) : null}
              {isClosable && (
                <SC.CloseButtonWrapper
                  id="modal-close-button"
                  role="button"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') onClose?.();
                  }}
                  tabIndex={0}
                  onClick={onClose}
                >
                  <SC.Close role="button" aria-label={t('closeModal')} />
                </SC.CloseButtonWrapper>
              )}
            </SC.HeaderWrapper>
          )}

          <SC.ContentBody>
            {isValidElement(content) ? content : <P>{content}</P>}
          </SC.ContentBody>
        </Flex>
      </SC.OverlayContent>
    </SC.Overlay>
  );
};

export default Modal;
