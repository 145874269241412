export default {
  featuredExperiences: 'Our Featured Experiences',
  searchAllExperiences: 'Search all Experiences',
  searchAll: 'Search All',
  groupSize: 'Group Size',
  eventSize: 'Event Size',
  upTo: 'Up to',
  whatWeDo: "What We'll Do",
  hostedBy: 'Hosted By',
  whatsIncluded: "What's Included",
  avail: 'Availability',
  whereWeAre: "Where We'll Be",
  experienceStartingPrice: 'From {{price}}',
  experienceTotalPrice: 'Total {{price}}',
  at: 'at',
  viewPrivateSessAvailability: 'View private session availability',
  selectDuration: 'Duration',
  selectStartTime: 'Start time',
  selectSession: 'Select Session',
  noAvailability: 'No Availability',
  select: 'Select',
  slotsLeft: '{{remainSlots}} slots left',
  slotLeft: '{{remainSlots}} slot left',
  bookExperience: 'Book Experience',
  book: 'Book',
  apply: 'Apply',
  maxPartySizes: 'Maximum of {{maxGroupSize}} slots per party',
  maxPartySize: 'Maximum of {{maxGroupSize}} slot per party',
  selectSessionValidation: 'Please select session',
  selectParticipantValidation: 'Please select participants',
  selectDateValidation: 'Please select date',
  reminders: 'Reminders',
  seeOtherDates: 'See other dates',
  location: 'Location',
  about: 'About',
  soldOut: 'Sold Out',
  reviews: 'Reviews',
  reviewSingular: 'review',
  reviewPlural: 'reviews',
  dateOfExperience: 'Date of experience',
  readLess: 'Read Less',
  readMore: 'Read More',
  seeMore: 'See More',
  details: 'Details',
  theFollowingVideoDoesNotContainAnyAudio:
    'The following video does not contain any audio',
};
