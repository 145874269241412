import React, {
  FC,
  useMemo,
  MouseEventHandler,
  useState,
  useCallback,
  useEffect,
} from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/common/styled/common-styled';
import { useIsMobile } from 'WindowDimensionProvider';
import ResponsiveImage from 'components/common/ResponsiveImage/ResponsiveImage';
import {
  ResourceHeading2,
  ResourceLinkButton,
} from 'components/theme/Typography/ResourceTypography';
import { CancellationPolicy, ResourceGroup, Session } from 'types/listings';
import { ReserveButton } from 'components/CollapsibleResource/styles';
import useSearchQueryParams from 'hooks/use-search-params';
import { formatSessionToSessionTimeOption } from 'features/Reserve/hooks/useSessionTimeOptions';
import { IPriceTier } from 'types/experience.types';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { DATE_FORMAT, currencyFormat } from 'utils';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { ModalDatePicker } from 'components/DatePicker/ModalDatePicker';
import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';
import { getProductFromResourceGroups } from 'features/analytics/services/analytics.helpers';
import { analyticsManager } from 'features/analytics';
import { NonMappedGroupSessionLoading } from '../pages/Skeleton';
import { useDaySessionRange } from '../hooks/useDaySessionRange';
import { ResourceGroupDetail } from './ResourceGroupDetail';
import { ResourceGroupFullDetail } from './ResourceGroupFullDetail';
import useAvailableDates from '../hooks/useAvailableDates';
import useResourceBookingSession from '../hooks/useResourceBookingSession';

type PriceTier = IPriceTier & {
  id: string;
};

interface Props {
  group: ResourceGroup;
  sessions: Session[];
  onReserveClick: (groupId: string) => void;
  isLoading: boolean;
  cancellationPolicy?: CancellationPolicy;
}

export const NonMappedResourceGroupCard: FC<Props> = ({
  sessions,
  onReserveClick,
  group,
  isLoading,
  cancellationPolicy,
}) => {
  const { date, latest, collection, setResourceBookingSessionParams } =
    useResourceBookingSession();
  const isMobile = useIsMobile();
  const { t: translate } = useTranslation();
  const currency = useBrandCurrency();
  const { formattedNumber } = useCurrencyFormatter();
  const { searchParams } = useSearchQueryParams();
  const currentSession = sessions[0]
    ? formatSessionToSessionTimeOption(sessions[0])
    : undefined;

  const [reserveDatePickerOpen, setReserveDatePickerOpen] = useState(false);
  const handleReserveButtonClick = () => setReserveDatePickerOpen(true);
  const handleModalClose = () => setReserveDatePickerOpen(false);

  const trackAnalyticsEvent = () => {
    if (collection) {
      analyticsManager.sendEvent(ANALYTICS_EVENT.CLICK_EXPERIENCE, {
        [ANALYTICS_PROPERTY.ExperienceId]: group?.id,
        [ANALYTICS_PROPERTY.ExperienceTitle]: group?.title,
        [ANALYTICS_PROPERTY.ExperienceCategory]: collection.category?.name,
        [ANALYTICS_PROPERTY.ListId]: collection.id,
        [ANALYTICS_PROPERTY.ListName]: collection.title,
        [ANALYTICS_PROPERTY.Products]: JSON.stringify(
          getProductFromResourceGroups({
            groups: [group],
            categoryName: collection.category?.name,
            session: sessions[0],
          }),
        ),
      });
    }
  };

  const handleOnReserveClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    trackAnalyticsEvent();
    onReserveClick(group.id);
  };

  const [dateForCalendarAvailability, setDateForCalendarAvailability] =
    useState<moment.Moment>(date ? moment(date) : moment());

  useEffect(() => {
    if (date) {
      setDateForCalendarAvailability(moment(date));
    }
  }, [date]);

  const { availableDates } = useAvailableDates({
    from: dateForCalendarAvailability.startOf('month').format(DATE_FORMAT),
    to: dateForCalendarAvailability.endOf('month').format(DATE_FORMAT),
    collectionId: collection?.id,
    resourceGroupId: group.id,
    latest,
    skip: !reserveDatePickerOpen,
  });

  const { from, to } = useDaySessionRange(sessions);

  const { capacityLabel, initialPrice, resourceImage } = useMemo(() => {
    const priceTiers = sessions
      .flatMap<PriceTier>((e) => (e ? e.priceTiers ?? [] : []))
      .sort((a, b) => a.price - b.price);
    const startingPrice = priceTiers[0]?.price || 0;
    const displayedCapacity = Number(group.experiences?.[0]?.displayedCapacity);

    return {
      resourceImage: group.pictures[0],
      initialPrice: startingPrice,
      capacityLabel:
        displayedCapacity > 0
          ? `${displayedCapacity} ${
              displayedCapacity === 1
                ? translate('person')
                : translate('people')
            }`
          : '',
    };
  }, [sessions, group, translate]);

  const [showDetails, setShowDetails] = useState(false);

  const onReadMore = useCallback(() => {
    setShowDetails(true);
  }, []);

  const onClose = useCallback(() => {
    setShowDetails(false);
  }, []);

  const handleDateChange = useCallback(
    (newDate: string) => {
      trackAnalyticsEvent();
      setResourceBookingSessionParams({
        date: newDate,
        groupId: group.id,
      });
    },
    [setResourceBookingSessionParams, group.id],
  );

  return (
    <>
      <Flex
        direction={isMobile ? 'column' : 'row'}
        gap={isMobile ? 20 : 0}
        alignItem={isMobile ? 'flex-start' : 'stretch'}
        justifyContent="flex-start"
        width="100%"
      >
        <ResponsiveImage
          className="resource-group-card__resource-image"
          uriObject={resourceImage?.uri}
          defaultSize="384w"
          viewType="small"
          style={{
            borderRadius: 0,
          }}
          CustomRenderer={ResourceGroupImage}
          alt={group.title}
          pictureStyle={`
          display: grid;
          place-items: center;
          align-self: ${isMobile ? 'stretch' : 'auto'};
        `}
        />
        {isMobile ? (
          <>
            <Flex
              direction="column"
              gap={6}
              alignItem="flex-start"
              justifyContent="space-between"
              width="100%"
            >
              <Flex
                direction="row"
                gap={6}
                alignItem="center"
                justifyContent="space-between"
                width="100%"
              >
                <ResourceHeading2
                  className="nonmapped-resource-group-card__mobile-heading"
                  fontSize="21px"
                >
                  {group.title}
                </ResourceHeading2>
                <ResourceLinkButton
                  className="nonmapped-resource-group-card__details-text"
                  opacity="0.6"
                  fontSize="12px"
                  fontWeight="400"
                  onClick={onReadMore}
                >
                  {translate('viewDetails')}
                </ResourceLinkButton>
              </Flex>
              <Flex
                direction="row"
                gap={6}
                alignItem="center"
                justifyContent="space-between"
                width="100%"
              >
                <ResourceHeading2
                  className="nonmapped-resource-group-card__mobile-date"
                  fontSize="14px"
                  color="var(--way-palette-black-100)"
                  fontWeight="400"
                >
                  {moment(
                    currentSession
                      ? currentSession?.original?.startDateTime
                      : searchParams.date,
                  ).format('dddd, MMMM D')}
                </ResourceHeading2>
                {sessions.length > 0 && (
                  <ResourceGroupText className="nonmapped-resource-group-card__details-text">
                    {translate('from')}{' '}
                    <strong>
                      {currencyFormat(currency)(formattedNumber(initialPrice))}
                    </strong>
                  </ResourceGroupText>
                )}
              </Flex>
            </Flex>
            {sessions.length || isLoading ? (
              <ReserveButton
                aria-label={translate('reserveName', { name: group.title })}
                onClick={handleOnReserveClick}
                disabled={isLoading}
              >
                {translate(isLoading ? 'loading' : 'reserve')}
              </ReserveButton>
            ) : (
              <>
                <ReserveButton onClick={handleReserveButtonClick}>
                  {translate('findAvailability')}
                </ReserveButton>
                <ModalDatePicker
                  availableDates={availableDates}
                  onChange={(option) => {
                    handleDateChange(
                      moment(option as Date).format(DATE_FORMAT),
                    );
                  }}
                  onModalClose={handleModalClose}
                  onMonthChange={setDateForCalendarAvailability}
                  open={reserveDatePickerOpen}
                  value={searchParams.date}
                />
              </>
            )}
          </>
        ) : (
          <CardContentWrapper>
            <Flex
              direction="column"
              alignItem="flex-start"
              justifyContent="space-between"
              height="100%"
            >
              <Flex direction="column" gap={12} alignItem="flex-start">
                <ResourceHeading2
                  className="nonmapped-resource-group-card__heading"
                  fontSize="28px"
                >
                  {group.title}
                </ResourceHeading2>
                <ResourceGroupDetail
                  group={group}
                  maxLines={11}
                  customStyles={resourceGroupTextStyles}
                  onReadMore={onReadMore}
                  hasMoreToShow={
                    (!!cancellationPolicy && cancellationPolicy.active) ||
                    !!group.included.length
                  }
                />
              </Flex>

              <Flex direction="column" alignItem="flex-start" width="100%">
                <Flex direction="row" width="100%">
                  {isLoading ? (
                    <NonMappedGroupSessionLoading />
                  ) : (
                    <Flex direction="column" gap={4} alignItem="flex-start">
                      <ResourceGroupText className="non-mapped-session-date">
                        {moment(
                          currentSession
                            ? currentSession?.original?.startDateTime
                            : searchParams.date,
                        ).format('dddd, MMMM D')}
                      </ResourceGroupText>
                      {sessions?.length > 0 ? (
                        <ResourceGroupText className="non-mapped-session-time">
                          {from} - {to}
                        </ResourceGroupText>
                      ) : (
                        <ResourceGroupText className="non-mapped-session-time">
                          {translate('unavailable')}
                        </ResourceGroupText>
                      )}
                    </Flex>
                  )}
                  <Flex direction="row" alignItem="center" gap={16}>
                    <Flex direction="column" gap={4} alignItem="flex-end">
                      {isLoading ? (
                        <NonMappedGroupSessionLoading />
                      ) : (
                        <>
                          {sessions.length > 0 && (
                            <ResourceGroupText className="non-mapped-session-pricing">
                              {translate('from')}{' '}
                              <strong>
                                {currencyFormat(currency)(
                                  formattedNumber(initialPrice),
                                )}
                              </strong>
                            </ResourceGroupText>
                          )}
                        </>
                      )}
                      {!!capacityLabel && (
                        <ResourceGroupText className="non-mapped-session-capacity">
                          {capacityLabel}
                        </ResourceGroupText>
                      )}
                    </Flex>

                    {sessions.length || isLoading ? (
                      <ReserveButton
                        aria-label={translate('reserveName', {
                          name: group.title,
                        })}
                        data-testid={`reserve-${group.title}`}
                        onClick={handleOnReserveClick}
                        disabled={isLoading}
                      >
                        {translate(isLoading ? 'loading' : 'reserve')}
                      </ReserveButton>
                    ) : (
                      <>
                        <ReserveButton
                          data-testid={`find-${group.title}`}
                          onClick={handleReserveButtonClick}
                        >
                          {translate('findAvailability')}
                        </ReserveButton>
                        <ModalDatePicker
                          availableDates={availableDates}
                          onChange={(option) => {
                            handleDateChange(
                              moment(option as Date).format(DATE_FORMAT),
                            );
                          }}
                          onModalClose={handleModalClose}
                          onMonthChange={setDateForCalendarAvailability}
                          open={reserveDatePickerOpen}
                          value={searchParams.date}
                        />
                      </>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </CardContentWrapper>
        )}
      </Flex>
      <ResourceGroupFullDetail
        group={group}
        showContent={showDetails}
        onClose={onClose}
        maxLines={5}
        customStyles={resourceGroupTextStyles}
        cancellationPolicy={cancellationPolicy}
      />
    </>
  );
};

const CardContentWrapper = styled.div`
  @media screen and (min-width: 768px) {
    flex: 1;
    padding: 32px 36px 32px 48px;
    border-left: 0px;
    border: 0.5px solid var(--way-colors-borderColor);
  }
`;

export const ResourceGroupImage = styled.img`
  object-fit: cover;
  max-height: 100%;
  height: 382px;
  width: 574px;
  object-position: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 240px;
  }
`;

const resourceGroupTextStyles = css`
  margin-bottom: 0;
  & > p {
    font-family: ${({ theme }) => theme.font.primaryFont};
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: var(--way-colors-contrastColorShades-80);
  }
`;

export const ResourceGroupText = styled.div`
  color: var(--way-colors-contrastColorShades-80);
  white-space: nowrap;
  font-size: 16px;
`;
