import moment from 'moment';

const checkSessionsOverlap = (
  startDateTime1: string,
  durationMinutes1: number,
  startDateTime2: string,
  durationMinutes2: number,
) => {
  let start1 = startDateTime1;
  if (!startDateTime1.endsWith('.000Z')) {
    start1 += '.000Z';
  }
  let start2 = startDateTime2;
  if (!startDateTime2.endsWith('.000Z')) {
    start2 += '.000Z';
  }

  const end1 = moment(start1).add(durationMinutes1, 'minutes').toISOString();
  const end2 = moment(start2).add(durationMinutes2, 'minutes').toISOString();

  return (end1 > start2 && start1 < end2) || (start2 < end1 && end2 > start1);
};

export default checkSessionsOverlap;
