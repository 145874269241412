import styled, { css } from 'styled-components';
import SuccessIcon from 'assets/toast-success.svg';
import FailIcon from 'assets/toast-fail.svg';

const MAX_Z_INDEX = 2147483647;
const TOAST_HEIGHT = 72;
const TOAST_GAP = 12;

const getToastPosition = (current: number) => {
  return `${TOAST_HEIGHT * (current - 1) + TOAST_GAP * current}px`;
};

export const NotificationContainer = styled.div<{ current: number }>`
  ${({ className, current }) => {
    const [classname] = className?.split(' ') ?? [];
    return css`
      &.${classname} {
        font-size: 14px;
        top: ${getToastPosition(current)};
        transition: transform 0.6s ease-in-out;
        animation: toast-in-right 0.7s;
        box-sizing: border-box;
        position: fixed;
        top: ${getToastPosition(current)};
        right: 12px;
        transition: transform 0.6s ease-in-out;
        animation: toast-in-right 0.7s;
        z-index: ${() => MAX_Z_INDEX};
        font-style: normal;
        font-weight: normal;
        text-transform: none;

        @media screen and (max-width: 768px) {
          left: 12px;
          right: 12px;
        }

        @keyframes toast-in-right {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(0);
          }
        }

        @keyframes toast-in-left {
          from {
            transform: translateX(-100%);
          }
          to {
            transform: translateX(0);
          }
        }
      }

      &.topRight {
        right: 12px;
      }

      &.topLeft {
        left: 12px;
      }
    `;
  }}
`;

export const Notification = styled.div`
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: var(--way-design-boxShadow-l);
  background-position: 15px;
  background-repeat: no-repeat;
  background-color: var(--way-palette-white-100);
  font-family: ${({ theme }) => theme.font.primaryFont};
  color: var(--way-palette-black-100);
  height: auto;
  width: 365px;
  padding: 16px;
  z-index: 9999;

  & h6 {
    margin: 0px !important;
    text-align: left;
    margin-left: -1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media screen and (max-width: 768px) {
    width: auto;
  }
`;

export const NotificationButton = styled.button`
  position: relative;
  right: -0.3em;
  top: -5px;
  float: right;
  font-weight: 700;
  color: var(--way-colors-primaryColorContrastShades-100);
  outline: none;
  text-shadow: 0 1px 0 var(--way-palette-white-100);
  opacity: 0.8;
  line-height: 1;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  width: 25px;
  height: 25px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--way-palette-black-10);
  }
`;

export const NotificationText = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 15px;
`;

export const NotificationImage = styled.div<{ isSuccess: boolean }>`
  display: grid;
  place-items: center;
  background-color: ${({ isSuccess }) => (isSuccess ? '#66bc53' : '#D03F3F')};
  border-radius: 50%;
`;

export const StyledSuccessIcon = styled(SuccessIcon)``;

export const StyledFailIcon = styled(FailIcon)``;

export const NotificationTextHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 6px;

  & h6 {
    margin: 0px !important;
    font-weight: bold !important;
  }
`;

export const NotificationContent = styled.div`
  & h6.message {
    color: var(--way-palette-black-70) !important;
  }
`;
