import useSearchQueryParams from 'hooks/use-search-params';

/* eslint-disable no-console */
export const shouldLog = (searchParams?: Record<string, unknown>) => {
  const queryParams = new URLSearchParams(window.location.search);

  const hasDebugParamInFragment = searchParams
    ? searchParams.debug === 'true'
    : window.location.hash.includes('?debug=true') ||
      window.location.hash.includes('&debug=true');

  return (
    queryParams.get('debug') === 'true' || hasDebugParamInFragment || false
  );
};

export const debug = (level: 'log' | 'warn' | 'error', ...args: unknown[]) => {
  try {
    if (shouldLog()) {
      console[level](...args);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    console.log('initial log failed', { level, args });
  }
};

export const useDebugLog = () => {
  const { searchParams } = useSearchQueryParams();

  return (level: 'log' | 'warn' | 'error', ...args: unknown[]) => {
    try {
      if (shouldLog(searchParams)) {
        console[level](...args);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      console.log('initial log failed', { level, args });
    }
  };
};
