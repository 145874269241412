/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import moment from 'moment';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'AppProvider';
import { Wrapper } from 'components/theme/Wrapper';
import NotFound from 'components/NotFound';
import { resetCurrentExperience } from 'actions/experience';
import useExperienceId from 'hooks/use-experience-id';
import useCustomHistory from 'hooks/use-custom-history';
import useSearchQueryParams from 'hooks/use-search-params';
import { STATUS_FAILURE } from 'types/app';
import useFetchExperience from 'selectors/experience';
import { serializeParams as serialize } from 'utils';

const ExperienceViewWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const { replace } = useCustomHistory();
  const { searchParams } = useSearchQueryParams();
  const { experience, fetchExperienceStatus, responseStatus } =
    useFetchExperience();
  const experienceFetchError = fetchExperienceStatus === STATUS_FAILURE;
  const experienceId = useExperienceId();
  const shouldRewriteURL =
    !experience.loading && experienceId !== experience.slug;

  const translateLang = (translateKey) => {
    return translate(translateKey, { returnObjects: true });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => dispatch(resetCurrentExperience());
  }, []);

  useEffect(() => {
    if (experienceFetchError || !shouldRewriteURL) return;
    const redirectUri = `/e/${experience?.slug}`;
    replace({
      pathname: redirectUri,
      search: serialize(searchParams),
    });
  }, [experience?.slug]);

  useEffect(() => {
    moment.updateLocale('en', {
      weekdaysMin: translateLang('weekdays'),
      months: translateLang('months'),
      monthsShort: translateLang('formattedShortMonths'),
    });
  }, [i18n.language]);

  if (experienceFetchError && responseStatus === 404) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    );
  }

  return children;
};

export default ExperienceViewWrapper;
