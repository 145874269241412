import { useRouteMatch } from 'react-router-dom';
import useQueryParams from 'hooks/useQueryParams';
import { formatUTCDate } from 'utils';

type ParamsType = {
  eventId?: string;
  date?: string;
};

const useEventBookingSessionPropsFromUrl = () => {
  const matchEvent = useRouteMatch<ParamsType>('/event/:eventId');
  const matchEventTickets = useRouteMatch<ParamsType>(
    '/event/:eventId/tickets/:date',
  );

  const emptyParams: ParamsType = {};
  const { eventId } = matchEvent?.params ?? emptyParams;
  const { date } = matchEventTickets?.params ?? emptyParams;
  const { latest, preselectedDate } = useQueryParams<{
    latest?: string;
    preselectedDate?: string;
  }>();

  return {
    eventId: sanitizeIdParam(eventId),
    date: sanitizeDateParam(date),
    preselectedDate: sanitizeDateParam(preselectedDate),
    latest: sanitizeLatestParam(latest),
  };
};

const sanitizeIdParam = (value?: string) => {
  return (
    decodeURIComponent(value ?? '')
      .replace(/"/g, '')
      .split('&')[0] || undefined
  );
};

const sanitizeDateParam = (value?: string) => {
  const date = formatUTCDate(value, '00:00:00');
  if (!value || date.toLowerCase() === 'invalid date') {
    return undefined;
  }
  return date.substring(0, 10);
};

const sanitizeLatestParam = (value?: string) => {
  if (value === 'true') {
    return true;
  }
  return false;
};

export default useEventBookingSessionPropsFromUrl;
