export default {
  featuredExperiences: 'Experências sugeridas',
  searchAllExperiences: 'Procure todas as experiências',
  searchAll: 'Procurar tudo',
  groupSize: 'Dimensão do grupo',
  eventSize: 'Dimensão do evento',
  upTo: 'Até',
  whatWeDo: 'Coisas a fazer',
  hostedBy: 'Oferecido por',
  whatsIncluded: 'O que está incluído',
  avail: 'Disponibilidade',
  whereWeAre: 'Onde estaremos',
  experienceStartingPrice: 'Desde',
  experienceTotalPrice: 'Total',
  at: 'em',
  viewPrivateSessAvailability: 'Ver disponibilidade de sessões privadas',
  selectSession: 'Selecionar sessão',
  noAvailability: 'Sem disponibilidade',
  select: 'Selecionar',
  slotsLeft: 'vagas disponíveis',
  slotLeft: 'vaga disponível',
  bookExperience: 'Reservar experiência',
  book: 'Reservar',
  maxPartySizes: 'Número máximo de vagas por grupo',
  maxPartySize: 'Número máximo de vagas por grupo',
  selectSessionValidation: 'Selecionar a sessão',
  selectParticipantValidation: 'Selecionar participantes',
  selectDateValidation: 'Selecionar data',
  reminders: 'Lembretes',
  seeOtherDates: 'Ver outras datas',
  location: 'Localização',
  about: 'Sobre',
  soldOut: 'Esgotado',
  reviews: 'Avaliações',
  reviewSingular: 'Avaliar',
  reviewPlural: 'Avaliações',
  dateOfExperience: 'Data da experiência',
  readLess: 'Ler menos',
  readMore: 'Ler mais',
  seeMore: 'Ver mais',
  details: 'Detalhes',
  theFollowingVideoDoesNotContainAnyAudio: 'Este vídeo não contém áudio',
};
