export default {
  date: '日付',
  weekdays: ['日', '月', '火', '水', '木', '金', '土'],
  months: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  formattedShortMonths: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  weekdays_calendar: {
    Sun: '日曜日',
    Mon: '月曜日',
    Tue: '火曜日',
    Wed: '水曜日',
    Thu: '木曜日',
    Fri: '金曜日',
    Sat: '土曜日',
  },
  weekdays_booking_container: {
    Sunday: '日曜日',
    Monday: '月曜日',
    Tuesday: '火曜日',
    Wednesday: '水曜日',
    Thursday: '木曜日',
    Friday: '金曜日',
    Saturday: '土曜日',
  },
};
