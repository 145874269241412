import React, { FC, useEffect } from 'react';
import { useFormContext } from 'hook-form';
import { useTranslation } from 'react-i18next';
import * as Styled from 'features/ShoppingCart/utils/styles';
import { PaymentError } from 'features/ShoppingCart/components/PaymentError';
import {
  lastNameValidation,
  memberNumberValidation,
} from 'features/ShoppingCart/utils/validate';
import { FieldName } from 'features/ShoppingCart/types';

const MemberChargePayment: FC = () => {
  const {
    register,
    unregister,
    formState: { errors },
  } = useFormContext();
  const { t: translateText } = useTranslation();

  useEffect(() => {
    return () => {
      // we need to unregister these fields when the payment method
      // unmounts, otherwise the validations specific to this payment
      // method will be applied to other payment methods
      unregister([FieldName.PAYMENT_NAME, FieldName.RESERVATION_NUMBER]);
    };
  }, []);

  return (
    <>
      <Styled.AccordionContent>
        <Styled.InputWrapper>
          <input
            type="text"
            placeholder={translateText(FieldName.LAST_NAME)}
            {...register(FieldName.PAYMENT_NAME, { ...lastNameValidation })}
          />
          <input
            type="text"
            placeholder={translateText('memberNumber')}
            {...register(FieldName.RESERVATION_NUMBER, {
              ...memberNumberValidation,
            })}
          />
        </Styled.InputWrapper>
      </Styled.AccordionContent>
      <PaymentError
        error={
          (errors.paymentName?.message ||
            errors.reservationNumber?.message) as string
        }
      />
    </>
  );
};

export default MemberChargePayment;
