import React from 'react';

const MinusIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17 * scale}
      height={16 * scale}
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <path
        d="M3.83398 7.3335H13.1673V8.66683H3.83398V7.3335Z"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
    </svg>
  );
};

export default MinusIcon;
