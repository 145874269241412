import React, { FC, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'utils';
import { PrimaryButton } from 'components/theme/Button/Button';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { filterAvailableSessions, filterSessionsByDate } from 'utils/session';
import { Session } from 'types/listings';
import { useHistory } from 'react-router-dom';

export enum BuyTicketButtonState {
  AVAILABLE = 'available',
  SOLD_OUT = 'sold_out',
  PASSED = 'passed',
  WAITLIST = 'waitlist',
}

interface Props {
  date: string;
  state?: BuyTicketButtonState;
  dataTestId?: string;
  isFree?: boolean;
}

const BuyTicketButton: FC<Props> = ({
  date,
  state = BuyTicketButtonState.AVAILABLE,
  dataTestId,
  isFree = false,
}) => {
  const history = useHistory();
  const { t: translate } = useTranslation();
  const { event, setEventBookingSessionParams } = useEventBookingSession();

  const label = useMemo(() => {
    if (state === BuyTicketButtonState.AVAILABLE) {
      if (isFree) {
        return translate('rsvp');
      }
      return translate('buyTickets');
    }
    if (state === BuyTicketButtonState.PASSED) {
      return translate('salesEnded');
    }
    if (state === BuyTicketButtonState.WAITLIST) {
      return translate('joinWaitlist');
    }
    return translate('soldOut');
  }, [state]);

  const handleClick = useCallback(() => {
    if (
      event &&
      (state === BuyTicketButtonState.AVAILABLE ||
        state === BuyTicketButtonState.WAITLIST)
    ) {
      history.push(`/event/${event.id}/tickets/${date}`);
      setEventBookingSessionParams(
        {
          date,
        },
        true,
      );
    }
  }, [event, date, state, setEventBookingSessionParams]);

  return (
    <PrimaryButton
      data-testid={dataTestId}
      disabled={
        state !== BuyTicketButtonState.AVAILABLE &&
        state !== BuyTicketButtonState.WAITLIST
      }
      onClick={handleClick}
    >
      {label}
    </PrimaryButton>
  );
};

export const getDateStateFromSessionsAvailability = (
  sessions: Session[],
  date: string,
  waitlistActive: boolean,
) => {
  const sessionsOnDate = filterSessionsByDate(sessions, date);

  const availableSessionsOnDate = filterAvailableSessions(sessionsOnDate);

  if (availableSessionsOnDate.length > 0) {
    return BuyTicketButtonState.AVAILABLE;
  }

  if (sessionsOnDate.length === 0) {
    return BuyTicketButtonState.PASSED;
  }

  if (waitlistActive && moment().format(DATE_FORMAT) <= date) {
    return BuyTicketButtonState.WAITLIST;
  }

  return BuyTicketButtonState.SOLD_OUT;
};

export default BuyTicketButton;
