import React, { CSSProperties } from 'react';
import { useIsMobile } from 'WindowDimensionProvider';
import styled from 'styled-components';
import { BottomDrawer } from 'components/BottomDrawer/BottomDrawer';
import {
  SkeletonLine,
  SkeletonPulse,
} from 'components/theme/Skeleton/Skeleton';

const CollectionPageMappedSkeleton = () => {
  return (
    <SkeletonContainer>
      <StyledPulseWrapper width="236px" height="51px">
        <SkeletonPulse />
      </StyledPulseWrapper>

      <StyledPulseWrapper height="109px">
        <SkeletonPulse />
      </StyledPulseWrapper>
      <StyledPulseWrapper height="109px">
        <SkeletonPulse />
      </StyledPulseWrapper>
    </SkeletonContainer>
  );
};

const CollectionPageMobileSkeleton = () => {
  return (
    <SkeletonContainer>
      <StyledPulseWrapper height="92px">
        <SkeletonPulse />
      </StyledPulseWrapper>

      <StyledPulseWrapper height="92px">
        <SkeletonPulse />
      </StyledPulseWrapper>
    </SkeletonContainer>
  );
};

export const CollectionPageNonMappedHeaderSkeleton = () => {
  return (
    <SkeletonContainer gap="36px">
      <StyledPulseWrapper width="336px" height="59px">
        <SkeletonPulse />
      </StyledPulseWrapper>

      <StyledPulseWrapper>
        <SkeletonLine />
      </StyledPulseWrapper>
    </SkeletonContainer>
  );
};

export const CollectionPageNonMappedGroupsSkeleton = () => {
  return (
    <SkeletonContainer gap="36px">
      <StyledPulseWrapper width="233px" height="55px">
        <SkeletonPulse />
      </StyledPulseWrapper>

      <StyledPulseWrapper height="127px">
        <SkeletonPulse />
      </StyledPulseWrapper>
      <StyledPulseWrapper height="127px">
        <SkeletonPulse />
      </StyledPulseWrapper>
    </SkeletonContainer>
  );
};

const CollectionPageNonMappedSkeleton = () => {
  return (
    <SkeletonContainer gap="36px">
      <CollectionPageNonMappedHeaderSkeleton />
      <CollectionPageNonMappedGroupsSkeleton />
    </SkeletonContainer>
  );
};

const CollectionPageNonMappedMobileSkeleton = () => {
  return (
    <SkeletonContainer>
      <StyledPulseWrapper height="92px">
        <SkeletonPulse />
      </StyledPulseWrapper>
      <StyledPulseWrapper height="92px">
        <SkeletonPulse />
      </StyledPulseWrapper>
    </SkeletonContainer>
  );
};

export const CollectionPageSkeleton: React.FC<{
  isMapped?: boolean;
}> = ({ isMapped = true }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return isMapped ? (
      <CollectionPageMobileSkeleton />
    ) : (
      <CollectionPageNonMappedMobileSkeleton />
    );
  }

  return isMapped ? (
    <CollectionPageMappedSkeleton />
  ) : (
    <CollectionPageNonMappedSkeleton />
  );
};

export const ResourceGroupSessionSelectorSkeleton = () => {
  return (
    <SkeletonContainer>
      <StyledPulseWrapper height="48px">
        <SkeletonPulse />
      </StyledPulseWrapper>
    </SkeletonContainer>
  );
};

export const ResourceGroupResourceListSkeleton = () => {
  return (
    <SkeletonContainer>
      <StyledPulseWrapper height="116px">
        <SkeletonPulse />
      </StyledPulseWrapper>
    </SkeletonContainer>
  );
};

const ResourceGroupPageDesktopSkeleton = () => {
  return (
    <SkeletonContainer>
      <StyledPulseWrapper height="272px">
        <SkeletonPulse />
      </StyledPulseWrapper>
      <StyledPulseWrapper height="92px">
        <SkeletonPulse />
      </StyledPulseWrapper>
      <StyledPulseWrapper height="221px">
        <SkeletonPulse />
      </StyledPulseWrapper>
      <StyledPulseWrapper height="309px">
        <SkeletonPulse />
      </StyledPulseWrapper>
      <StyledPulseWrapper height="110px">
        <SkeletonPulse />
      </StyledPulseWrapper>
      <StyledPulseWrapper height="228px">
        <SkeletonPulse />
      </StyledPulseWrapper>
    </SkeletonContainer>
  );
};

const ResourceGroupPageMobileSkeleton = () => {
  return (
    <SkeletonContainer gap="16px">
      <StyledPulseWrapper height="53px">
        <SkeletonPulse />
      </StyledPulseWrapper>
      <StyledPulseWrapper height="53px">
        <SkeletonPulse />
      </StyledPulseWrapper>
      <StyledPulseWrapper height="127px">
        <SkeletonPulse />
      </StyledPulseWrapper>
    </SkeletonContainer>
  );
};

export const ResourceGroupPageSkeleton = () => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <ResourceGroupPageMobileSkeleton />
  ) : (
    <ResourceGroupPageDesktopSkeleton />
  );
};

const ResourcePageDesktopSkeleton = () => {
  return (
    <SkeletonContainer gap="29px">
      <StyledPulseWrapper height="43px">
        <SkeletonPulse />
      </StyledPulseWrapper>
      <StyledPulseWrapper height="89px">
        <SkeletonPulse />
      </StyledPulseWrapper>
      <StyledPulseWrapper height="43px">
        <SkeletonPulse />
      </StyledPulseWrapper>

      <StyledPulseWrapper height="54px">
        <SkeletonPulse />
      </StyledPulseWrapper>
    </SkeletonContainer>
  );
};

const ResourcePageMobileSkeleton = () => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <BottomDrawer open heading="" onClose={() => {}}>
      <SkeletonContainer gap="16px">
        <StyledPulseWrapper height="43px">
          <SkeletonPulse />
        </StyledPulseWrapper>
        <StyledPulseWrapper height="89px">
          <SkeletonPulse />
        </StyledPulseWrapper>
        <StyledPulseWrapper height="43px">
          <SkeletonPulse />
        </StyledPulseWrapper>
        <StyledPulseWrapper height="54px">
          <SkeletonPulse />
        </StyledPulseWrapper>
      </SkeletonContainer>
    </BottomDrawer>
  );
};

export const NonMappedGroupSessionLoading = () => {
  return (
    <SkeletonContainer gap="12px">
      <StyledPulseWrapper width="132px" height="25px">
        <SkeletonPulse />
      </StyledPulseWrapper>
    </SkeletonContainer>
  );
};

export const ResourcePageSkeleton = () => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <ResourcePageMobileSkeleton />
  ) : (
    <ResourcePageDesktopSkeleton />
  );
};

const SkeletonContainer = styled.div<{ gap?: CSSProperties['gap'] }>`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${(props) => props.gap ?? '24px'};

  * {
    border-radius: 0 !important;
  }
`;

const StyledPulseWrapper = styled.div<{
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  minHeight?: CSSProperties['height'];
}>`
  position: relative;
  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
`;
