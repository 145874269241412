import { KOUTO_EMBED_ROOT } from 'consts';
import { useEffect, useMemo, useState } from 'react';
import { EmbedConfig, MANDATORY_KEYS } from './types';
import {
  EMBED_CONFIG_PROPERTIES_MAP,
  isKey,
  FALLBACK_EMBED_CONFIG_GETTER_MAP,
  EMBED_CONFIG_PROPERTY_LOADER,
} from './helpers';

export const getEmbedConfig = (root?: HTMLElement): EmbedConfig => {
  const embedRoot = root ?? document.getElementById(KOUTO_EMBED_ROOT);

  if (!embedRoot) {
    throw new Error('Embed root not found');
  }

  const embedConfig = Object.keys(EMBED_CONFIG_PROPERTIES_MAP).reduce(
    (config, property) => {
      if (!isKey(EMBED_CONFIG_PROPERTIES_MAP, property)) return config;

      const propertyLoader =
        EMBED_CONFIG_PROPERTY_LOADER[property] ?? ((_, x) => x);

      const value =
        propertyLoader(property, embedRoot.dataset[property]) ||
        FALLBACK_EMBED_CONFIG_GETTER_MAP[property]?.();

      if (!value && MANDATORY_KEYS.includes(property)) {
        throw new Error(`Embed root attribute ${property} not found`);
      }

      return {
        ...config,
        [property]: value,
      };
    },
    {} as EmbedConfig,
  );

  return embedConfig;
};

export const useEmbedConfig = (root?: HTMLElement) => {
  const embedRoot = root ?? document.getElementById(KOUTO_EMBED_ROOT);
  const [embedConfig, setConfig] = useState(
    getEmbedConfig(embedRoot ?? undefined),
  );

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'attributes') {
        setConfig(getEmbedConfig());
      }
    });
  });

  useEffect(() => {
    if (embedRoot) {
      observer.observe(embedRoot, { attributes: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [embedRoot]);

  return embedConfig;
};

const USER_PROPERTIES = [
  'userFirstName',
  'userLastName',
  'userEmail',
  'userPhone',
  'memberNumber',
  'roomNumber',
] as const;

export const getPurchaserFromEmbedConfig = (embedConfig: EmbedConfig) => {
  if (USER_PROPERTIES.every((key) => !embedConfig[key])) {
    return undefined;
  }

  return {
    firstName: embedConfig.userFirstName?.trim(),
    lastName: embedConfig.userLastName?.trim(),
    emailAddress: embedConfig.userEmail?.trim(),
    phoneNumber: embedConfig.userPhone?.trim(),
    reservationNumber:
      embedConfig.roomNumber?.trim() || embedConfig.memberNumber?.trim(),
  };
};

export const usePurchaserFromEmbedConfig = () => {
  const embedConfig = useEmbedConfig();

  const embedUserData = useMemo(
    () => getPurchaserFromEmbedConfig(embedConfig),
    [embedConfig],
  );

  return embedUserData;
};
