export default {
  unavailable: 'No disponible',
  person: 'persona',
  people: 'personas',
  reserveNow: 'Reservar ahora',
  selectTime: 'Seleccionar hora',
  select: 'Seleccionar',
  reserve: 'Reservar',
  time: 'Hora',
  tier: 'Nivel',
  questions: 'Preguntas',
  addToCart: 'Añadir al carrito',
  checkout: 'Pagar',
  total: 'Total',
  availability: 'Disponibilidad',
  whatsIncluded: 'Qué está incluido',
  showMore: 'Mostrar más',
  showLess: 'Mostrar menos',
  session: 'Sesión',
  viewDetails: 'Ver detalles',
  findAvailability: 'Encontrar disponibilidad',
  loading: 'Cargando',
  capacity: 'Capacidad',
  noAvailableSessionsOnThisDate: 'No hay sesiones disponibles en esta fecha',
  noGroupAvailableOnThisDateAndTime:
    'No hay {{groupTitle}} disponible en esta fecha y hora',
  noCollectionFound: 'No se encontró ninguna colección!',
  noResourceGroupFound: 'No se encontró ningún grupo de recursos!',
  noData: 'Sin datos!',
  continue: 'Continuar',
};
