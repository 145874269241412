import { SecondaryButton } from 'components/theme/Button/Button';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { ChevronDown } from '../ExperiencesCalendar/Filters/CustomComponents/ChevronDown';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  disabled?: boolean;
  leftIcon?: React.ReactNode;
  onClick?: () => void;
}

export const SelectTriggerButton: React.FC<Props> = ({
  leftIcon,
  disabled = false,
  children,
  onClick,
  ...props
}) => {
  return (
    <TriggerButton disabled={disabled} onClick={onClick} {...props}>
      {leftIcon ?? null}

      <span className="trigger-button-value-content">{children}</span>

      <IconWrapper className="chevron-down-icon-wrapper">
        <ChevronDown />
      </IconWrapper>
    </TriggerButton>
  );
};

const TriggerButton = styled(SecondaryButton)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  min-width: 150px;
  width: 100%;
  height: 45px !important;
  padding: 12px !important;
  gap: 6px;
  border: 0.5px solid var(--way-colors-borderColor);

  &:hover {
    background: var(--way-palette-black-5) !important;
  }

  ${({ disabled }) =>
    disabled
      ? `
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  `
      : ''}

  > * {
    &.trigger-button-value-content {
      flex-grow: 1;
      display: grid;
      place-items: center left;
    }
  }
`;

const IconWrapper = styled.div`
  display: grid;
  place-items: center right;
`;
