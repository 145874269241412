import * as R from 'ramda';
import styled from 'styled-components';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Question from 'components/CustomQuestion/Question';
import {
  Question as QuestionType,
  CustomQuestionResponse,
  QuestionScope,
  QUESTION_SCOPES,
} from 'types/custom-questions';
import { get } from 'lodash';
import { validateEmpty } from 'utils/validators';

type Props = {
  defaultValues?: Record<string, unknown>;
  scope: QuestionScope;
  formStatePath: string;
  loading: boolean;
  participantParentQuestions: QuestionType[];
  participantConditionalQuestions: QuestionType[];
  purchaserParentQuestions: QuestionType[];
  purchaserConditionalQuestions: QuestionType[];
};

const CustomQuestionsReactHookFormV5: FC<Props> = ({
  defaultValues,
  scope,
  formStatePath,
  loading,
  participantParentQuestions,
  participantConditionalQuestions,
  purchaserParentQuestions,
  purchaserConditionalQuestions,
}) => {
  if (loading) {
    return null;
  }

  const parentQuestions =
    scope === QUESTION_SCOPES.PURCHASER
      ? purchaserParentQuestions
      : participantParentQuestions;
  const conditionalQuestions =
    scope === QUESTION_SCOPES.PURCHASER
      ? purchaserConditionalQuestions
      : participantConditionalQuestions;

  return (
    <QuestionsWrapper>
      {parentQuestions.map((q) => {
        return (
          <Controller
            key={q.id}
            name={`${formStatePath}.${q.id}`}
            defaultValue={
              defaultValues && defaultValues[`${formStatePath}.${q.id}`]
            }
            rules={
              q.config.isRequired
                ? { ...validateEmpty(`This field is required`) }
                : {}
            }
            as={
              <EnhancedQuestion
                formStatePath={formStatePath}
                question={q}
                conditionalQuestions={conditionalQuestions}
                defaultValues={defaultValues}
              />
            }
          />
        );
      })}
    </QuestionsWrapper>
  );
};

export default CustomQuestionsReactHookFormV5;

interface EnhancedQuestionProps {
  defaultValues?: Record<string, unknown>;
  formStatePath: string;
  question: QuestionType;
  conditionalQuestions?: QuestionType[];
  value?: CustomQuestionResponse[]; // injected by react hook form Controller
  onChange?: (newAnswer: CustomQuestionResponse[] | null) => void; // injected by react hook form Controller
}

const EnhancedQuestion: FC<EnhancedQuestionProps> = ({
  defaultValues,
  formStatePath,
  question,
  conditionalQuestions,
  value,
  onChange,
}) => {
  const { errors } = useFormContext();

  const renderConditionalQuestions = (
    parentQuestionId: string,
    parentQuestionResponse?: CustomQuestionResponse[],
  ) => {
    const ownConditionalQuestions = (conditionalQuestions || []).filter((cq) =>
      cq.config.conditions
        ?.map((c) => c.conditionKey)
        .includes(parentQuestionId),
    );

    if (!ownConditionalQuestions.length) {
      return null;
    }

    return ownConditionalQuestions.map((cq) => {
      const conditionQuestionId = cq.id;
      const conditionValues = cq.config.conditions?.map(
        (c) => c.conditionValue,
      );

      const matchesCondition = R.intersection(
        conditionValues || [],
        (parentQuestionResponse || []).map((r) => r.key),
      ).length;

      if (matchesCondition) {
        const conditionalQuestionErrorMessage = get(
          errors,
          `${formStatePath}.${conditionQuestionId}.message`,
        );

        return (
          <Controller
            key={cq.id}
            name={`${formStatePath}.${conditionQuestionId}`}
            defaultValue={
              defaultValues &&
              defaultValues[`${formStatePath}.${conditionQuestionId}`]
            }
            rules={
              cq.config.isRequired
                ? { ...validateEmpty(`This field is required`) }
                : {}
            }
            as={
              <EnhancedConditionalQuestion
                question={cq}
                error={conditionalQuestionErrorMessage}
              />
            }
          />
        );
      }

      return null;
    });
  };

  const errorMessage = get(errors, `${formStatePath}.${question.id}.message`);

  return (
    <>
      <Question
        question={question}
        answer={value || []}
        onAnswerChange={onChange}
        error={errorMessage}
      />
      {renderConditionalQuestions(question.id, value || [])}
    </>
  );
};

interface EnhancedConditionalQuestionProps {
  question: QuestionType;
  error?: string;
  value?: CustomQuestionResponse[]; // injected by react hook form Controller
  onChange?: (newAnswer: CustomQuestionResponse[] | null) => void; // injected by react hook form Controller
}

const EnhancedConditionalQuestion: FC<EnhancedConditionalQuestionProps> = ({
  question,
  value,
  onChange,
  error,
}) => {
  return (
    <Question
      question={question}
      answer={value || []}
      onAnswerChange={onChange}
      error={error}
    />
  );
};

const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;
