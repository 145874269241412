export default {
  chooseExpDate: '日付を選択',
  filterExpDate: '日付でフィルター',
  experienceType: 'タイプ',
  filterExperienceType: 'タイプでフィルター',
  experienceVibe: '雰囲気',
  filterExperienceVibe: '雰囲気でフィルター',
  search: '検索',
  viewCalendar: 'カレンダー',
  back: '戻る',
  previous: '前へ',
  next: '次へ',
  noExperiences: '体験がありません',
  cancelChanges: '変更をキャンセル',
  saveChanges: '変更を保存',
  removeFromReservation: '予約から削除',
  addToCart: 'カートに追加',
  proceedToCheckout: 'チェックアウトに進む',
};
