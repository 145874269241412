import React from 'react';
import styled from 'styled-components';
import Link from 'components/Link';
import { ButtonBlock } from 'components/theme/Button/Button';

const NotFoundWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  & button {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
  }
`;

const EmptyCartError = () => (
  <NotFoundWrapper>
    <p>The cart is empty.</p>
    <Link to="/experiences">
      <ButtonBlock type="button">View All Experiences</ButtonBlock>
    </Link>
  </NotFoundWrapper>
);

export default EmptyCartError;
