export enum PMSType {
  OPERA = 'opera',
  STAYNTOUCH = 'stayntouch',
}

export enum BookingMode {
  PRIVATE = 'private',
  SHARED = 'shared',
}

export interface PaymentDetail {
  readonly lastName: string;
  readonly methodNumber?: string;
  readonly pmsType?: PMSType;
  readonly reservationCode?: string;
  readonly reservationId?: string;
  readonly confirmationId?: string;
}

export enum PaymentMethod {
  CREDIT_CARD = 'credit-card',
  ROOM_CHARGE = 'room-charge',
  MEMBER_NUMBER = 'member-number',
  KICC = 'kicc',
}

export enum PaymentProvider {
  KICC = 'kicc',
  STRIPE_DESTINATION_CHARGE = 'stripe_destination_charge',
  STRIPE_SEPARATE_CHARGE_TRANSFER = 'stripe_separate_charge_transfer',
  SELF = 'self',
}

export enum PaymentPlatform {
  US = 'us',
  EU = 'eu',
}

export interface PaymentStatus {
  readonly code: string;
  readonly message: string;
}

export interface PaymentMethodDetail {
  readonly fixedChargeId?: string;
  readonly commentId?: string;
  readonly transactionCode?: {
    readonly code: string;
    readonly description: string;
  };
  readonly pgCno?: string;
  readonly msgAuthValue?: string;
  readonly shopTransactionId?: string;
}

export interface Purchaser {}
