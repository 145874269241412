import React from 'react';
import { Error, ErrorContainer } from 'components/theme/Form/Form';
import * as Styled from 'features/ShoppingCart/utils/styles';

interface IPaymentError {
  error: string | null;
}

const PaymentError: React.FC<IPaymentError> = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <ErrorContainer>
      <Styled.PaymentErrorContainer>
        <Error>{error}</Error>
      </Styled.PaymentErrorContainer>
    </ErrorContainer>
  );
};

export default PaymentError;
