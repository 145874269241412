import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'WindowDimensionProvider';
import { ProductLine } from '@kouto/types';
import { getProductPageTitle } from 'utils/listings';
import { useLandingPageRouteParams } from 'hooks/useLandingPageRouteParams';
import LandingPageFilters from './LandingPageFilters';

interface LandingPageHeaderProps {
  product?: ProductLine;
}

const LandingPageHeader: FC<LandingPageHeaderProps> = ({ product }) => {
  const isMobile = useIsMobile();
  const { t: translate } = useTranslation();
  const { isResults } = useLandingPageRouteParams();
  const title = getProductPageTitle({
    product,
    translate,
  });

  return (
    <Header className="landing-page-header">
      {!isResults && !isMobile && (
        <Title className="landing-page-header-title">{title}</Title>
      )}
      <LandingPageFilters />
    </Header>
  );
};

export default LandingPageHeader;
const Header = styled.div`
  width: 100%;
  min-height: 40px;
  margin-top: 24px;
  margin-bottom: 44px;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  margin: 0px;
  font-size: 32px;
  line-height: 38px;
  color: var(--way-colors-primaryTextColor);
`;
