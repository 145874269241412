export default {
  weekdays: ['DO', 'L', 'M', 'X', 'J', 'V', 'S'],
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  formattedShortMonths: [
    'En',
    'Febr',
    'Mzo',
    'Abr',
    'My',
    'Jun',
    'Jul',
    'Agt',
    'Sept',
    'Oct',
    'Nov',
    'Dic',
  ],
  weekdays_calendar: {
    Sun: 'Do',
    Mon: 'Lu',
    Tue: 'Ma',
    Wed: 'Mi',
    Thu: 'Ju',
    Fri: 'Vi',
    Sat: 'Sá',
  },
  months_calendar: {
    January: 'Enero',
    February: 'Febrero',
    March: 'Marzo',
    April: 'Abril',
    May: 'Mayo',
    June: 'Junio',
    July: 'Julio',
    August: 'Agosto',
    September: 'Septiembre',
    October: 'Octubre',
    November: 'Noviembre',
    December: 'Diciembre',
  },
  weekdays_booking_container: {
    Sunday: 'Domingo',
    Monday: 'Lunes',
    Tuesday: 'Martes',
    Wednesday: 'Miércoles',
    Thursday: 'Jueves',
    Friday: 'Viernes',
    Saturday: 'Sábado',
  },
};
