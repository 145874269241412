import React, { FC } from 'react';
import { SurveyQuestionType } from '@kouto/types';
import { AnswerInputProps } from './types';
import { Binary } from './Binary';
import { Scale3 } from './Scale3';

export const AnswerInputs: FC<AnswerInputProps> = ({
  type,
  selectedValue,
  onChange,
}) => {
  switch (type) {
    case SurveyQuestionType.BINARY:
      return <Binary selectedValue={selectedValue} onChange={onChange} />;
    case SurveyQuestionType.SCALE_3:
      return <Scale3 selectedValue={selectedValue} onChange={onChange} />;
    default:
      return null;
  }
};
