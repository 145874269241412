export const isFirefox = () => {
  return (
    navigator?.userAgent?.toLowerCase().includes('firefox') ||
    (navigator?.appCodeName === 'Mozilla' && navigator?.appName === 'Netscape')
  );
};

export const isSafari = () => {
  return (
    (navigator?.userAgent?.toLowerCase().includes('safari') &&
      !navigator?.userAgent?.toLowerCase().includes('chrome')) ||
    'safari' in window
  );
};

export const isSafariDesktop = () => {
  return (
    isSafari() && navigator?.userAgent?.toLowerCase().includes('macintosh')
  );
};
