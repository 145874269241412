import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';

import { analyticsManager } from 'features/analytics';
import { Heading3, Heading4 } from 'components/theme/Typography';
import { VibeButton, TypeButton } from 'components/theme/Button';
import { SkeletonPulseAbsolute, SkeletonLine } from 'components/theme/Skeleton';
import { RatingSummary } from 'components/common/RatingSummary';
import ToggleFeature from 'components/ToggleFeature';
import ResponsiveImage from 'components/common/ResponsiveImage';
import { getExperiencePrice } from 'components/helpers';
import { useAppState } from 'AppProvider';
import useCustomHistory from 'hooks/use-custom-history';

import {
  getShortDescription,
  isImageRatioGreaterOne,
  isImageRatioGreaterSixteenNine,
  serializeParams,
  getUserFullName,
} from 'utils';

import PeopleIcon from 'assets/icon-user';
import useSearchQueryParams from 'hooks/use-search-params';
import { setExperienceAnalyticsData } from 'features/analytics/services/analytics.helpers';
import { ExclusiveTagOverlay } from 'components/common/ExclusiveTagOverlay';
import StarIcon from './star-icon.svg';

const ExperienceImage = styled.img`
  width: 100%;
  height: ${({ isSixteenNineRatio }) => (isSixteenNineRatio ? 'auto' : '100%')};
  object-fit: cover;
  top: 50%;
  left: 50%;
  position: absolute;
  border-radius: var(--way-design-borderRadiusDefault);
  object-position: center;
  transform: translate(-50%, -50%);
`;

const HostedByImage = styled.img`
  width: 48px;
  object-fit: cover;
  height: ${({ isSquare }) => (isSquare ? 'auto' : '48px')};
  top: 50%;
  left: 50%;
  position: absolute;
  object-position: center;
  transform: translate(-50%, -50%);
`;

export default function ExperienceItem({ experience, order }) {
  const { t: translate } = useTranslation();
  const { searchParams } = useSearchQueryParams();
  const [isSquare, setIsSquare] = useState(false);
  const [isSixteenNineRatio, setIsSixteenNineRatio] = useState(false);
  const { settings } = useAppState((state) => state.brand);
  const [isHovered, setIsHovered] = useState(false);
  const { push: customPush } = useCustomHistory();
  const isExclusive = get(experience, 'isExclusive');
  const isHostLed = !experience?.isNoHost;

  const exclusiveTagName =
    get(settings, 'promotion.exclusiveTag') ?? 'Exclusive';
  const descriptionHost = get(experience, 'hostedBy.description');

  const onImgLoad = ({ target: img }) => {
    setIsSquare(isImageRatioGreaterOne(img.naturalWidth, img.naturalHeight));
  };
  const onImgLoadSixteenNineRatio = ({ target: img }) => {
    setIsSixteenNineRatio(
      isImageRatioGreaterSixteenNine(img.naturalWidth, img.naturalHeight),
    );
  };

  const vibeItemMapper = (vibe) => (
    <ButtonGray
      className="experience-item__vibe-button"
      key={`experience-${experience.id}-vibe-${vibe.id}`}
      id={`experience-${experience.id}-vibe-${vibe.id}`}
    >
      {vibe.name}
    </ButtonGray>
  );

  const renderVibes = () => {
    const filteredVibes = experience.vibes?.filter((vibe) => vibe);

    if (filteredVibes?.length === 3) {
      return filteredVibes.reverse().map(vibeItemMapper);
    }
    return filteredVibes?.map(vibeItemMapper);
  };

  const userProfileImage = get(experience, 'hostedBy.profilePicture.uri');

  const onExperienceViewClick = () => {
    setExperienceAnalyticsData(experience);

    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.ExperienceOrder]: `${order}`,
    });

    analyticsManager.sendEvent(ANALYTICS_EVENT.CLICK_EXPERIENCE);
    customPush({
      pathname: `/e/${experience.slug}/`,
      search: serializeParams(searchParams),
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onExperienceViewClick();
    }
  };

  return (
    <Card
      onMouseOver={() => setIsHovered(true)}
      onFocus={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onBlur={() => setIsHovered(false)}
    >
      <ExperienceViewItem
        onClick={experience.loading ? () => {} : onExperienceViewClick}
        tabIndex={0}
        onKeyDown={experience.loading ? () => {} : handleKeyDown}
        role="button"
      >
        <CardGroup className="experience-item__experience-card-group">
          <CardImage
            className="experience-item__experience-card-image"
            loading={experience.loading?.toString()}
          >
            {isExclusive && isHovered ? (
              <ExclusiveTagOverlay loading={experience.loading?.toString()}>
                {!experience.loading ? (
                  exclusiveTagName
                ) : (
                  <SkeletonPulseAbsolute translucent />
                )}
              </ExclusiveTagOverlay>
            ) : null}
            {experience?.category?.name && (
              <TagButtonType
                id="experience-item__category-button-container"
                loading={experience.loading?.toString()}
              >
                {!experience.loading ? (
                  <Button className="experience-item__category-button">
                    {get(experience, 'category.name')}
                  </Button>
                ) : (
                  <SkeletonPulseAbsolute translucent />
                )}
              </TagButtonType>
            )}
            {isExclusive ? (
              <ExclusiveStar loading={experience.loading?.toString()}>
                {!experience.loading ? (
                  <StarIcon />
                ) : (
                  <SkeletonPulseAbsolute translucent />
                )}
              </ExclusiveStar>
            ) : null}
            <TagButtonVibes
              id="experience-item__vibes-buttons-container"
              loading={experience.loading?.toString()}
            >
              {renderVibes()}
            </TagButtonVibes>
            <CardImageRatio className="experience-item__experience-card-image-ratio">
              {!experience.loading ? (
                <ResponsiveImage
                  className="experience-item__experience-image"
                  uriObject={get(experience, 'coverPicture.uri')}
                  defaultSize="384w"
                  isSixteenNineRatio={isSixteenNineRatio}
                  onLoad={onImgLoadSixteenNineRatio}
                  CustomRenderer={ExperienceImage}
                  alt={experience.title}
                />
              ) : (
                <SkeletonPulseAbsolute translucent />
              )}
            </CardImageRatio>
          </CardImage>

          {!experience.loading ? (
            <Title className="experience-item__experience-title">
              {experience.title}
            </Title>
          ) : (
            <SkeletonLine translucent />
          )}

          {!experience.loading ? (
            <PriceTag className="experience-item__price-tag">
              <span>{getExperiencePrice(experience, translate)}</span>
              <ToggleFeature featureId="reviews">
                <>
                  {!isNil(experience?.ratings?.total) && (
                    <span>
                      {' '}
                      <RatingSummary
                        rating={experience?.ratings?.rating}
                        count={experience?.ratings?.total}
                      />
                    </span>
                  )}
                </>
              </ToggleFeature>
            </PriceTag>
          ) : (
            <SkeletonLine translucent />
          )}
          {isHostLed ? (
            <Footer className="experience-item__experience-footer">
              {!experience.loading ? (
                <Title4 className="experience-item__hosted-by-title">
                  {translate('hostedBy')}{' '}
                  {getUserFullName(experience?.hostedBy)}
                </Title4>
              ) : (
                <SkeletonLine translucent />
              )}
              <HostedBy className="experience-item__hosted-by-section">
                {userProfileImage ? (
                  <HostedByImageWrap>
                    {!experience.loading ? (
                      <ResponsiveImage
                        uriObject={userProfileImage}
                        defaultSize="48w"
                        isSquare={isSquare}
                        onLoad={onImgLoad}
                        CustomRenderer={HostedByImage}
                        alt={descriptionHost}
                      />
                    ) : (
                      <SkeletonLine translucent />
                    )}
                  </HostedByImageWrap>
                ) : (
                  (!experience.loading && (
                    <DefaultUserAvatar>
                      <PeopleIcon />
                    </DefaultUserAvatar>
                  )) || (
                    <HostedByImageWrap>
                      <SkeletonLine translucent />
                    </HostedByImageWrap>
                  )
                )}
                <HostedByContent className="experience-item__hosted-by-content">
                  {!experience.loading ? (
                    getShortDescription(descriptionHost, 150)
                  ) : (
                    <SkeletonLine translucent />
                  )}
                </HostedByContent>
              </HostedBy>
            </Footer>
          ) : null}
        </CardGroup>
      </ExperienceViewItem>
    </Card>
  );
}

ExperienceItem.propTypes = {
  experience: PropTypes.object,
  order: PropTypes.number,
};

ExperienceItem.defaultProps = {
  experience: {
    loading: true,
  },
};

const ExperienceViewItem = styled.div`
  cursor: pointer;
`;

const Card = styled.div`
  width: 100%;
`;

const CardGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CardImage = styled.div`
  margin-bottom: 20px;
  border-radius: var(--way-design-borderRadiusDefault);
  box-shadow: var(--way-design-boxShadow-s);
  background: ${({ loading }) =>
    loading ? 'var(--way-palette-black-20)' : 'inherit'};
  order: 1;
  position: relative;
`;

const CardImageRatio = styled.div`
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
`;

const Title = styled(Heading3)`
  font-weight: normal;
  order: 2;
  @media (min-width: 768px) {
    height: 100%;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 8px;
  }
`;

const Title4 = styled(Heading4)`
  font-weight: normal;
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 18px;
  }
`;

const HostedBy = styled.div`
  display: flex;
  align-items: flex-start;
`;
const HostedByImageWrap = styled.div`
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  margin-right: 8px;
`;

const HostedByContent = styled.div`
  font-size: 12px;
  line-height: 15px;
  width: calc(100% - 55px);
`;

const Footer = styled.div`
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-bottom-color: var(--way-colors-borderColor);
  padding-bottom: 24px;
  order: 4;
`;

const Button = styled(TypeButton)`
  margin-right: 8px;
  margin-bottom: 8px;
  background: var(--way-colors-primaryColorShades-100);
`;
const ButtonGray = styled(VibeButton)`
  margin-right: 8px;
  margin-bottom: 8px;
  color: var(--way-colors-secondaryColorContrastShades-100);
`;

const PriceTag = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 17px;
  border-bottom: 0.5px solid var(--way-colors-borderColor);
  padding-bottom: 8px;
  order: 3;
`;

const TagButtonVibes = styled.div`
  position: absolute;
  z-index: 2;
  left: 8px;
  bottom: 0px;
  pointer-events: none;
  display: flex;
  flex-wrap: wrap-reverse;
`;

// const TagButtonType = styled.div``;
const TagButtonType = styled.div`
  position: absolute;
  z-index: 4;
  left: 8px;
  top: 8px;
  pointer-events: none;
`;

const ExclusiveStar = styled.div`
  position: absolute;
  float: right;
  right: 17px;
  z-index: 4;
  top: 8px;
  pointer-events: none;
`;

const DefaultUserAvatar = styled.div`
  background-color: #dedcdc6e;
  position: relative;
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;

  & > * {
    position: absolute;
    top: 20%;
    left: 25%;
  }
`;
