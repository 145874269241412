export default {
  date: 'Datum',
  chooseExpDate: 'Wählen Sie ein Datum',
  filterExpDate: 'Nach Datum filtern',
  experienceType: 'Typ',
  filterExperienceType: 'Nach Typ filtern',
  experienceVibe: 'Stimmung',
  filterExperienceVibe: 'Nach Stimmung filtern',
  search: 'Suche',
  viewCalendar: 'Kalender',
  back: 'Zurück',
  previous: 'Vorherige',
  next: 'Nächste',
  noExperiences: 'Keine Erlebnisse',
  cancelChanges: 'Änderungen abbrechen',
  saveChanges: 'Änderungen speichern',
  removeFromReservation: 'Von der Reservierung entfernen',
  addToCart: 'In den Warenkorb legen',
  proceedToCheckout: 'Zur Kasse gehen',
};
