import React, { CSSProperties, PropsWithChildren, useRef } from 'react';
import styled from 'styled-components';
import {
  ResponsiveBreakpointsCtx,
  tabletBreakpoint,
  defaultBreakpoints,
} from 'WindowDimensionProvider';
import { ResourceMap } from 'components/Resource';
import useResourceBookingSession from 'features/Reserve/hooks/useResourceBookingSession';
import { ResourceMapContextProvider } from '../contexts/ResourceMapContext';

interface Props extends ContainerOptions {}

export const TwoColumnMapContainer: React.FC<PropsWithChildren<Props>> = ({
  children,
  ...containerOptions
}) => {
  const breakpoints = useRef({
    ...defaultBreakpoints,
    mobile: 1024,
  }).current;
  const {
    collection,
    resourceGroup,
    resourceId: experienceId,
    sessions,
    time,
    duration,
  } = useResourceBookingSession();

  if (!collection) {
    return null;
  }

  if (collection.map === null) {
    return (
      <ResponsiveBreakpointsCtx.Provider value={breakpoints}>
        {children}
      </ResponsiveBreakpointsCtx.Provider>
    );
  }

  let resourcesGroupsToShowInMap = resourceGroup
    ? [resourceGroup]
    : collection.resourceGroups ?? [];
  resourcesGroupsToShowInMap = resourcesGroupsToShowInMap.map((group) => ({
    ...group,
    experiences: experienceId
      ? group.experiences.filter(
          (exp) =>
            !!sessions.find(
              (session) =>
                session.experienceId === exp.id &&
                (!duration || session.duration === duration) &&
                (!time || session.startDateTime.split('T')[1] === time),
            ),
        )
      : group.experiences.map((exp) => ({
          ...exp,
          unavailable: !sessions.find(
            (session) =>
              session.experienceId === exp.id &&
              (!duration || session.duration === duration) &&
              (!time || session.startDateTime.split('T')[1] === time),
          ),
        })),
  }));

  return (
    <ResponsiveBreakpointsCtx.Provider value={breakpoints}>
      <ResourceMapContextProvider>
        <Container {...containerOptions}>
          {children}
          <div className="map-container">
            <ResourceMap
              assetUrl={collection.map.uri.original}
              resourceGroups={resourcesGroupsToShowInMap}
              collectionName={collection.title}
            />
          </div>
        </Container>
      </ResourceMapContextProvider>
    </ResponsiveBreakpointsCtx.Provider>
  );
};

interface ContainerOptions {
  gap?: CSSProperties['gap'];
  width?: CSSProperties['width'];
}

const Container = styled.div<ContainerOptions>`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  gap: ${(props) => props.gap ?? '24px'};

  --custom-mobile-breakpoint: ${tabletBreakpoint};

  > * {
    width: 100% !important;
  }

  .map-container {
    height: 281px;
    user-select: none;
  }

  @media all and (min-width: ${tabletBreakpoint}) {
    display: grid;
    grid-template-columns: ${(props) => props.width || 'minmax(300px, 400px)'} 1fr;

    .map-container {
      position: sticky;
      height: 650px;
      top: 20px;
    }
  }
`;
