import { IReducerAction } from 'types/reducer';
import { IWaitlistExperience } from 'features/ShoppingCart/types';
import { ICartExperience } from 'types/cart';

export enum WaitlistActionTypes {
  ADD_EXPERIENCE_TO_WAITLIST = '@waitlist/addExperience',
  ADD_ITEM_TO_WAITLIST = '@waitlist/addItem',
  CLEAN_WAITLIST = '@waitlist/clean',
  FETCH_WAITLIST_EXPERIENCE = '@waitlist/fetchExperience',
  FETCH_WAITLIST_EXPERIENCE_ERROR = '@waitlist/fetchExperienceError',
}

export interface IWaitlistAddExperienceAction
  extends IReducerAction<
    WaitlistActionTypes.ADD_EXPERIENCE_TO_WAITLIST,
    {
      experience: IWaitlistExperience | null;
    }
  > {}

export interface IWaitlistAddItemAction
  extends IReducerAction<
    WaitlistActionTypes.ADD_ITEM_TO_WAITLIST,
    {
      item: ICartExperience | null;
    }
  > {}

export interface IWaitlistCleanAction
  extends IReducerAction<WaitlistActionTypes.CLEAN_WAITLIST, undefined> {}

export interface IWaitlistFetchExperienceAction
  extends IReducerAction<
    WaitlistActionTypes.FETCH_WAITLIST_EXPERIENCE,
    undefined
  > {}

export interface IWaitlistFetchExperienceErrorAction
  extends IReducerAction<
    WaitlistActionTypes.FETCH_WAITLIST_EXPERIENCE_ERROR,
    undefined
  > {}

export type WaitlistAction =
  | IWaitlistAddExperienceAction
  | IWaitlistAddItemAction
  | IWaitlistFetchExperienceAction
  | IWaitlistFetchExperienceErrorAction
  | IWaitlistCleanAction;

export interface IWaitlistState {
  item: ICartExperience | null;
  experience: IWaitlistExperience | null;
  isFetchingExperience: boolean;
  waitlistError: boolean;
}
