export const ACCESS_TOKEN_KEY = 'kouto-embed-access-token';

export const SET_ACCESS_TOKEN = `@user/set-access-token`;

export const LOGIN_REQUEST = '@user/login-request';
export const LOGIN_SUCCESS = '@user/login-success';
export const LOGIN_FAILURE = '@user/login-failure';

export const REGISTER_REQUEST = '@user/register-request';
export const REGISTER_SUCCESS = '@user/register-success';
export const REGISTER_FAILURE = '@user/register-failure';

// export const SET_PROFILE = '@user/set-profile';

export const CURRENT_USER_REQUEST = '@user/current-user-request';
export const CURRENT_USER_SUCCESS = '@user/current-user-success';
export const CURRENT_USER_FAILURE = '@user/current-user-failure';

export const UPDATE_USER_REQUEST = '@user/update-user-request';
export const UPDATE_USER_SUCCESS = '@user/update-user-success';
export const UPDATE_USER_FAILURE = '@user/update-user-failure';

export const LOGOUT_REQUEST = '@user/logout-request';
export const LOGOUT_SUCCESS = '@user/logout-success';
export const LOGOUT_FAILURE = '@user/logout-failure';

// export const RESET_PASSWORD_REQUEST = '@user/reset-password-request';
// export const RESET_PASSWORD_SUCCESS = '@user/reset-password-success';
// export const RESET_PASSWORD_FAILURE = '@user/reset-password-failure';

// export const FORGOT_PASSWORD_REQUEST = '@user/forgot-password-request';
// export const FORGOT_PASSWORD_SUCCESS = '@user/forgot-password-success';
// export const FORGOT_PASSWORD_FAILURE = '@user/forgot-password-failure';

// export const CHANGE_PASSWORD_REQUEST = '@user/change-password-request';
// export const CHANGE_PASSWORD_SUCCESS = '@user/change-password-success';
// export const CHANGE_PASSWORD_FAILURE = '@user/change-password-failure';

export const ROLES = {
  CUSTOMER: 'customer',
};
