export default {
  // these should be unique as the values are used as keys
  profileImageOfHost: 'Imagen de perfil del anfitrión {{hostName}}',
  hostedBy: 'Organizado por',
  experiences: 'experiencias',
  experience: 'experiencia',
  clear: 'Limpiar',
  cancel: 'Cancelar',
  apply: 'Aplicar',
  more: 'Más',
  experiencePhotoOfListing:
    'Foto de la experiencia {{photoNumber}} del anuncio {{listingTitle}}',
  noExperiencesFound: 'No se encontraron experiencias',
  noExperiencesFoundFor: 'No se encontraron experiencias para',
  discoverExperiences: 'Descubre experiencias',
  clearFilters: 'Limpiar filtros',
  buyTickets: 'Comprar entradas',
  reserve: 'Reservar',
  activateProductCardTitle: 'Eventos',
  reserveProductCardTitle: 'Actividades',
  hostProductCardTitle: 'Experiencias locales',
  mainLandingPageTitle: 'Todas las experiencias',
  activateLandingPageTitle: 'Eventos',
  reserveLandingPageTitle: 'Actividades y servicios',
  hostLandingPageTitle: 'Experiencias locales',
  exclusive: 'Exclusivo',
  filterExperiences: 'Filtrar experiencias',
};
