export default {
  date: 'Date',
  chooseExpDate: 'Choose a date',
  filterExpDate: 'Filter by date',
  experienceType: 'Type',
  filterExperienceType: 'Filter by type',
  experienceVibe: 'Vibe',
  filterExperienceVibe: 'Filter by vibe',
  search: 'Search',
  viewCalendar: 'Calendar',
  back: 'Back',
  previous: 'Previous',
  next: 'Next',
  noExperiences: 'No Experiences',
  cancelChanges: 'Cancel changes',
  saveChanges: 'Save changes',
  removeFromReservation: 'Remove from reservation',
  addToCart: 'Add to cart',
  proceedToCheckout: 'Proceed to checkout',
};
