import * as types from 'types/experience';
import * as appTypes from 'types/app';
import initialState from 'store/initial-state';
import { generateDummyData } from './helpers';

// TODO old experience api had `priceTiers` and `currency`.
// in the future we might want to support multiple currencies.
const formatExperience = (experience) => {
  const formattedExperience = { ...experience };
  formattedExperience.currency = experience.supportedCurrencies[0];
  formattedExperience.priceTiers = [...formattedExperience.defaultPriceTiers];
  formattedExperience.isNoHost = !formattedExperience.hostedBy;
  formattedExperience.maxParticipantsCount =
    formattedExperience.defaultMaxParticipantCount;
  delete formattedExperience.supportedCurrencies;
  delete formattedExperience.defaultPriceTiers;
  return formattedExperience;
};

export default function experienceReducer(
  state = initialState.experience,
  action,
) {
  switch (action.type) {
    case types.RESET_EXPERIENCE:
      return {
        ...state,
        experience: generateDummyData(1)[0],
        fetchExperienceStatus: appTypes.STATUS_IDLE,
      };
    // read
    case types.FETCH_REQUEST:
      return {
        ...state,
        error: '',
        responseStatus: null,
        experience: generateDummyData(1)[0],
        fetchExperienceResponseCode: null,
        fetchExperienceStatus: appTypes.STATUS_PENDING,
      };
    case types.FETCH_FAILURE:
      return {
        ...state,
        experience: {},
        fetchExperienceStatus: appTypes.STATUS_FAILURE,
        fetchExperienceResponseCode: action.payload.responseStatus,
        error: action.error,
      };
    case types.FETCH_SUCCESS:
      return {
        ...state,
        experience: action.payload.experience,
        fetchExperienceStatus: appTypes.STATUS_SUCCESS,
        fetchExperienceResponseCode: null,
      };

    // search
    case types.SEARCH_REQUEST:
      return {
        ...state,
        searchError: '',
        experiences: generateDummyData(3),
        searchExperienceStatus: appTypes.STATUS_PENDING,
      };
    case types.SEARCH_FAILURE:
      return {
        ...state,
        searchExperienceStatus: appTypes.STATUS_FAILURE,
        searchError: action.error,
      };
    case types.SEARCH_SUCCESS:
      return {
        ...state,
        ...action,
        pagination: action.payload.pagination,
        experiences: action.payload.append
          ? state.experiences.concat(
              action.payload.experiences.map(formatExperience),
            )
          : action.payload.experiences.map(formatExperience),
        searchExperienceStatus: appTypes.STATUS_SUCCESS,
      };
    // availabilities
    case types.FETCH_AVAILABILITY_REQUEST:
      return {
        ...state,
        fetchAvailabilityError: '',
        searchAvailabilityStatus: appTypes.STATUS_PENDING,
      };
    case types.FETCH_AVAILABILITY_FAILURE:
      return {
        ...state,
        searchAvailabilityStatus: appTypes.STATUS_FAILURE,
        fetchAvailabilityError: action.error,
      };
    case types.FETCH_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availabilities: action.shouldAppend
          ? Array.from(
              new Set([
                ...state.availabilities,
                ...action.payload.availabilities,
              ]),
            )
          : action.payload.availabilities,
        fetchedUntilDate: action.payload.fetchedUntilDate,
        searchAvailabilityStatus: appTypes.STATUS_SUCCESS,
      };
    case types.RESET_FIRST_AVAILABLE_DATE:
      return {
        ...state,
        firstAvailableDate: null,
        fetchFirstAvailabilityStatus: appTypes.STATUS_IDLE,
        availabilities: [],
        sessions: [],
      };
    case types.FETCH_FIRST_AVAILABLE_DATE_REQUEST:
      return {
        ...state,
        availabilities: [],
        // sessions: [],
        fetchFirstAvilableDateError: '',
        firstAvailableDate: null,
        fetchFirstAvailabilityStatus: appTypes.STATUS_PENDING,
      };
    case types.FETCH_FIRST_AVAILABLE_DATE_FAILURE:
      return {
        ...state,
        availabilities: [],
        // sessions: [],
        fetchFirstAvailabilityStatus: appTypes.STATUS_FAILURE,
        firstAvailableDate: null,
        fetchFirstAvilableDateError: action.error,
      };
    case types.FETCH_FIRST_AVAILABLE_DATE_SUCCESS:
      return {
        ...state,
        firstAvailableDate: action.payload.firstAvailableDate,
        // sessions: action.payload.sessions,
        fetchFirstAvailabilityStatus: appTypes.STATUS_SUCCESS,
      };

    // sessions
    case types.FETCH_SESSIONS_REQUEST:
      return {
        ...state,
        sessionsSelectedDate: action.payload.date,
        error: '',
        sessions: [],
        fetchSessionStatus: appTypes.STATUS_PENDING,
      };

    case types.FETCH_SESSIONS_FAILURE:
      return {
        ...state,
        fetchSessionStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };

    case types.FETCH_SESSIONS_SUCCESS: {
      const { cartExperiences, sessions, experienceId } = action.payload;

      if (
        state.sessionsSelectedDate &&
        state.sessionsSelectedDate !== action.payload.date
      ) {
        return state;
      }

      const availableSessions = sessions.map((sess) => {
        const cartSession = cartExperiences.find(
          (c) =>
            c.startTime === sess.startTime && c.experienceId === experienceId,
        );
        if (!cartSession) {
          return sess;
        }
        // deep clone
        const availableQuantityPerTier = JSON.parse(
          JSON.stringify(sess.availableQuantityPerTier),
        );

        // eslint-disable-next-line no-restricted-syntax
        for (const participant of cartSession.participants) {
          availableQuantityPerTier[participant.priceTierName] -= 1;
        }
        return {
          ...sess,
          availableQuantityPerTier,
          supportedParticipantsCount:
            sess.supportedParticipantsCount - cartSession.participants.length,
        };
      });

      return {
        ...state,
        sessions: availableSessions,
        fetchSessionStatus: appTypes.STATUS_SUCCESS,
      };
    }

    // Featured Experiences
    case types.FEATURED_REQUEST:
      return {
        ...state,
        error: '',
        featuredExperiences: generateDummyData(3),
        featuredExperiencesStatus: appTypes.STATUS_PENDING,
      };
    case types.FEATURED_FAILURE:
      return {
        ...state,
        featuredExperiencesStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.FEATURED_SUCCESS:
      return {
        ...state,
        featuredExperiences: action.payload.experiences,
        featuredExperiencesStatus: appTypes.STATUS_SUCCESS,
      };

    // Experience Settings
    case types.FETCH_EXPERIENCE_SETTINGS_REQUEST:
      return {
        ...state,
        fetchSettingsStatus: appTypes.STATUS_PENDING,
      };
    case types.FETCH_EXPERIENCE_SETTINGS_FAILURE:
      return {
        ...state,
        fetchSettingsStatus: appTypes.STATUS_FAILURE,
      };
    case types.FETCH_EXPERIENCE_SETTINGS_SUCCESS:
      return {
        ...state,
        fetchSettingsStatus: appTypes.STATUS_SUCCESS,
        experienceSettings: action.payload.data?.bookingForm[0],
      };

    case types.FETCH_SESSION_PRICE_TIERS_REQUEST:
      return {
        ...state,
        error: '',
        fetchSessionPriceTiersStatus: appTypes.STATUS_PENDING,
      };
    case types.FETCH_SESSION_PRICE_TIERS_FAILURE:
      return {
        ...state,
        error: action.error,
        fetchSessionPriceTiersStatus: appTypes.STATUS_FAILURE,
      };

    case types.FETCH_SESSION_PRICE_TIERS_SUCCESS:
      return {
        ...state,
        fetchSessionPriceTiersStatus: appTypes.STATUS_SUCCESS,
        sessionPriceTiers: action.payload.items,
      };

    default:
      return state;
  }
}
