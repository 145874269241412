export default {
  reviewCompleteHeader: 'Überprüfen und Abschließen',
  participants: 'Teilnehmer',
  enterParticipantInfoText:
    'Bitte geben Sie die Informationen für jeden Teilnehmer in dieser Erfahrung ein.',
  purchaser: 'Käufer',
  firstName: 'Vorname',
  lastName: 'Nachname',
  email: 'E-Mail',
  optionalEmail: 'E-Mail: (optional)',
  'phone:': 'Telefon',
  participantFieldPlaceholder:
    'Neuen Teilnehmer hinzufügen oder vorhandenen auswählen',
  reservationSummary: 'Reservierungsübersicht',
  editParticipantsDetails: 'Teilnehmerdetails bearbeiten',
  participantDetails: 'Teilnehmerdetails',
  duplicatePurchaserText: 'Gleich wie Käufer',
  phone: 'Telefon',
  addParticipant: 'Zusätzlich {{tierLabel}}',
  participant: 'Teilnehmer',
  removeParticipant: 'Entfernen',
  experienceLimitReached:
    'Entschuldigung, diese Erfahrung hat die maximale Teilnehmerzahl erreicht',
  payment: 'Zahlung',
  duration: 'Dauer',
  discountCode: 'Rabattcode',
  removeCode: 'Code entfernen',
  invalidCodeValidation: 'Ungültiger Gutscheincode',
  networkErrorDiscountCode: 'Etwas ist auf unserer Seite schief gelaufen',
  discount: 'Rabatt',
  promo: 'Promo',
  promoCode: 'Promocode',
  apply: 'Anwenden',
  total: 'Gesamt',
  subTotal: 'Zwischensumme',
  includes: 'Beinhaltet',
  and: 'und',
  addHostNotes: 'Notizen für Ihren Gastgeber hinzufügen',
  policyAggreementText:
    'Ich stimme den <0>Allgemeinen Geschäftsbedingungen</0> und der <1>Datenschutzerklärung</1> zu',
  brandAggreementText:
    'Ich stimme zu, oder ich bestätige anderweitig, dass ich berechtigt bin, im Namen der Teilnehmer die Allgemeinen Geschäftsbedingungen zu akzeptieren',
  termsAggreementValidation: 'Bitte stimmen Sie den Bedingungen zu',
  firstNameValidation: 'Vorname ist erforderlich',
  lastNameValidation: 'Nachname ist erforderlich',
  emailValidation: {
    emailRequiredText: 'E-Mail ist erforderlich',
    invalidEmail: 'Ungültige E-Mail-Adresse',
    not_specified: 'Falsche E-Mail-Adresse',
  },
  phoneValidation: {
    'Invalid Phone Number': 'Ungültige Telefonnummer',
    not_specified: 'Ungültig',
    phoneRequiredText: 'Telefonnummer ist erforderlich',
  },
  participantFirstNameValidation: 'Vorname des Teilnehmers ist erforderlich',
  participantLastNameValidation: 'Nachname des Teilnehmers ist erforderlich',
  complete: 'Vollständig',
  '404Message': 'Nicht gefunden',
  waiting: 'Warten...',
  complimentary: 'Kostenlos',
  confirmationHeader: 'Ihre Erfahrung wurde gebucht!',
  confirmationText:
    'Eine Bestätigungs-E-Mail wurde an {{email}} mit weiteren Details gesendet',
  viewAll: 'Alle Erfahrungen anzeigen',
  paymentMethod: 'Zahlungsmethode',
  creditCard: 'Kreditkarte',
  roomCharge: 'Zimmergebühr',
  memberCharge: 'Mitgliedergebühr',
  roomOrReservationNumber: 'Zimmernummer oder Bestätigungsnummer',
  roomNumber: 'Zimmernummer',
  roomNumberLegacy: 'Zimmernummer oder Buchungscode',
  memberNumber: 'Mitgliedsnummer',
  kicc: 'Einfache Bezahlung',
  chargeValidation: 'Zimmernummer und Nachname sind erforderlich',
  memberNumberValidation: 'Mitgliedsnummer ist erforderlich',
  invalidRoomNumber: 'Zimmernummer ist ungültig',
  invalidRoomNumberLegacy: 'Zimmernummer oder Buchungscode ist ungültig',
  invalidMemberNumber: 'Mitgliedsnummer ist ungültig',
  invalidPhoneNumber: 'Telefonnummer ist ungültig',
  requiredPhoneNumber: 'Telefonnummer ist erforderlich',
  guestNameIsRequired: 'Gastname ist erforderlich',
  roomNumberIsRequired: 'Zimmernummer ist erforderlich',
  roomNumberIsRequiredLegacy: 'Zimmernummer oder Buchungscode ist erforderlich',
  roomOrReservationNumberIsRequired:
    'Zimmernummer oder Bestätigungsnummer ist erforderlich',
  cardNumberIncomplete: 'Kartennummer unvollständig',
  lastNameValidationError: 'Nachname ist erforderlich',
  isReqd: 'ist erforderlich',
  reservationCode: 'Reservierungs-/Bestätigungsnummer',
  nonHotelGuest: 'Nicht Hotelgast',
  onPropertyGuest: 'Gast auf dem Anwesen',
  preArrivalGuest: 'Gast vor Ankunft',
  cardNumber: 'Kartennummer',
  pmsValidationErrorMessage:
    'Ungültiger Nachname oder Zimmernummer oder Bestätigungsnummer',
  pmsDateValidationErrorMessage:
    'Entschuldigung! Das Datum des Erlebnisses liegt nicht zwischen Ihrem Anreise- und Abreisedatum.',
  fullName: 'Vollständiger Name',
  checkout: 'Auschecken',
  editParticipants: 'Teilnehmer bearbeiten',
  private: 'Privat',
  browseAllExperieces: 'Alle erfahrungen durchsuchen',
  payWithCreditCard: 'Mit Kreditkarte bezahlen',
  payWithRoomCharge: 'Mit Zimmergebühr bezahlen',
  payWithMemberCharge: 'Mit Mitgliedsgebühr bezahlen',
  payWith: 'Bezahlen mit',
  bookBulkErrorByCard:
    'Es gab ein Problem bei der Bestätigung Ihrer Buchung. Ihre Karte wurde nicht belastet.',
  bookBulkErrorByRoomCharge: 'Ungültiger Nachname und Zimmernummer.',
  bookBulkErrorByMemberCharge:
    'Ungültiger Nachname und ungültige Mitgliedsnummer.',
  reviewCancellation: 'Überprüfung der Stornierung',
  selectAReservationToCancel: 'Wählen Sie eine Reservierung zum Stornieren',
  confirmCancellation: 'Stornierung bestätigen',
  cancellable: 'Stornierbar',
  cancelled: 'Abgesagt',
  deadlinePassed: 'Frist abgelaufen',
  noCancellations: 'Keine Stornierungen',
  youPaid: 'Sie haben bezahlt',
  refund: 'Rückerstattung',
  yourRefund: 'Ihre Rückerstattung',
  yourRefundWillBeProcessedToTheOriginalPayment:
    'Ihre Rückerstattung wird an die ursprüngliche Zahlungsmethode verarbeitet',
  thisReservationCanNoLongerBeCancelledPleaseEmail:
    'Diese Reservierung kann nicht mehr storniert werden. Bitte senden Sie eine E-Mail an {{brandEmail}}',
  reservationCancelled: 'Reservierung storniert',
  thisReservationCannotBeCancelled:
    'Diese Reservierung kann nicht storniert werden',
  thisReservationHasAlreadyBeenCancelled:
    'Diese Reservierung wurde bereits storniert',
  theHotel: 'das Hotel',
  cancelling: 'Stornierung',
  somethingWentWrong: 'Etwas ist schief gelaufen',
  previouslyRefunded: 'Bereits erstattet',
  remainingBalance: 'Restbetrag',
};
