import React from 'react';

export const ChevronDown: React.FC<{ color?: string }> = ({
  color = 'currentColor',
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0204 5.9797C11.2156 6.17496 11.2156 6.49154 11.0204 6.68678L8.35369 9.35345C8.15843 9.54872 7.84189 9.54872 7.64663 9.35345L4.97994 6.68678C4.78468 6.49154 4.78468 6.17496 4.97994 5.9797C5.1752 5.78444 5.49179 5.78444 5.68705 5.9797L8.00016 8.29278L10.3133 5.9797C10.5086 5.78444 10.8251 5.78444 11.0204 5.9797Z"
        fill={color}
      />
    </svg>
  );
};
