import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const prodEmbedScriptCode = `
  <div id='kouto-embed-root' data-brand-id='BRAND_ID'></div>
  <script src='https://storage.googleapis.com/embed-script.staging.kouto.co/v1-latest/main.js'></script>
`;

const NotFound = ({ isDev }) => (
  <div>
    <div>
      <StyledHeader>Way Embed Script</StyledHeader>
      <StyledBody>
        <p>
          The `brandId` is not set. You can find the brandId in the Developers
          &gt; Script embed section of your Way dashboard.
        </p>
        {isDev && (
          <p>
            For development mode, set `brandId` in url e.g&nbsp;
            <pre>http://0.0.0.0:PORT/brands/brandId</pre>
          </p>
        )}
        <p>For production mode, use the script below</p>
        <div>
          <pre>{prodEmbedScriptCode}</pre>
        </div>
      </StyledBody>
    </div>
  </div>
);

NotFound.propTypes = {
  isDev: PropTypes.bool.isRequired,
};

const StyledHeader = styled.h3`
  color: var(--way-palette-charcoal-100);
`;

const StyledBody = styled.div`
  padding: 20px;
  background-color: #eee;
`;
export default NotFound;
