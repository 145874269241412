import styled from 'styled-components';

// Flexbox container
export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 12px;
  max-width: 1380px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.font.secondaryFont};
  color: var(--way-colors-primaryTextColor);
  @media (min-width: 1200px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  * {
    box-sizing: border-box;
  }
  td {
    margin: 0;
    padding: 0;
    border: none;
  }
`;

export const WrapperFluid = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.font.secondaryFont};
  color: var(--way-colors-primaryTextColor);

  * {
    box-sizing: border-box;
  }

  td {
    margin: 0;
    padding: 0;
    border: none;
  }
`;

export const PageWithSidebar = styled.main`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 72px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 48px;

    & > * {
      width: 100%;
    }
  }
`;
