import React, { FC } from 'react';
import { getListingProductLine } from 'utils/listings';
import { ProductLine } from '@kouto/types';
import { InfoBoxProps } from './types';
import { HostInfoBox } from './HostInfoBox';
import { EventInfoBox } from './EventInfoBox';

export const ListingInfoBox: FC<InfoBoxProps> = ({ listing }) => {
  const listingProduct = getListingProductLine(listing);

  if (listingProduct === ProductLine.HOST) {
    return <HostInfoBox listing={listing} />;
  }

  if (listingProduct === ProductLine.ACTIVATE) {
    return <EventInfoBox listing={listing} />;
  }

  return null;
};
