import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useBrandId, useAppState } from 'AppProvider';
import { usePaymentOptions } from 'features/ShoppingCart/hooks/usePaymentOptions';
import usePrevious from 'hooks/usePrevious';
import useCartItems from 'hooks/useCartItems';
import { setCartId } from 'actions/cart';
import usePaymentIntent from './usePaymentIntent';
import { getCartItemsData } from '../checkoutUtils';

const MAX_PAYMENT_INTENT_ATTEMPTS = 3;

const useCartPaymentIntent = (cartTotal: number) => {
  const dispatch = useDispatch();
  const brandId = useBrandId();
  const paymentOptions = usePaymentOptions();
  const previousCartTotal = usePrevious(cartTotal);
  const [attempts, setAttempts] = useState(0);

  const { discountCode } = useAppState(
    (state: Record<string, unknown>) => state.discountCode,
  );

  const { cartId, cartItems } = useCartItems(false);
  const defaultCartItemsData = useMemo(() => {
    return getCartItemsData({ cartItems, discountCode });
  }, [cartItems, discountCode]);

  const { paymentIntent, paymentIntentSecret, isLoading } = usePaymentIntent({
    cartId,
    cartTotal,
    cartItemsData: defaultCartItemsData,
    paymentOptions,
  });

  const previousIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (
      typeof previousCartTotal !== 'undefined' &&
      previousCartTotal > 0 &&
      cartTotal === 0
    ) {
      dispatch(
        setCartId({
          brandId,
          cartId: uuidv4(),
        }),
      );
    }
  }, [cartTotal, previousCartTotal, brandId, dispatch]);

  useEffect(() => {
    if (
      previousIsLoading &&
      !isLoading &&
      !paymentIntentSecret &&
      cartTotal > 0 &&
      attempts < MAX_PAYMENT_INTENT_ATTEMPTS
    ) {
      setTimeout(async () => {
        await dispatch(
          setCartId({
            brandId,
            cartId: uuidv4(),
          }),
        );
        setAttempts(attempts + 1);
      }, 1000);
    }
  }, [
    brandId,
    isLoading,
    previousIsLoading,
    cartTotal,
    paymentIntentSecret,
    attempts,
    dispatch,
  ]);

  return {
    paymentIntent,
    paymentIntentSecret,
    isLoading,
  };
};

export default useCartPaymentIntent;
