import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductLine } from '@kouto/types';
import { getProductPageTitle } from 'utils/listings';
import { Listing } from 'types/listings';
import LandingPageActivateHero from './LandingPageActivateHero';
import LandingPageHostHero from './LandingPageHostHero';
import LandingPageReserveHero from './LandingPageReserveHero';

interface LandingPageHeroProps {
  product: ProductLine;
  listings: Listing[];
  isLoading: boolean;
}

export const LandingPageHero: FC<LandingPageHeroProps> = ({
  product,
  listings,
  isLoading,
}) => {
  const { t: translate } = useTranslation();
  const title = getProductPageTitle({
    product,
    translate,
  });

  if (!isLoading && listings.length < 3) {
    return null;
  }

  if (product === ProductLine.ACTIVATE) {
    return (
      <LandingPageActivateHero
        title={title}
        listings={listings}
        isLoading={isLoading}
      />
    );
  }

  if (product === ProductLine.HOST) {
    return (
      <LandingPageHostHero
        title={title}
        listings={listings}
        isLoading={isLoading}
      />
    );
  }

  return (
    <LandingPageReserveHero
      title={title}
      listings={listings}
      isLoading={isLoading}
    />
  );
};
