import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useResourceBookingSession from 'features/Reserve/hooks/useResourceBookingSession';
import { CollectionPageSkeleton } from 'features/Reserve/pages/Skeleton';
import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';
import { getProductFromResourceGroups } from 'features/analytics/services/analytics.helpers';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import { MappedCollectionPage } from './Mapped';
import { NonMappedCollectionPage } from './NonMapped';

export const CollectionPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { collection, isLoadingCollection } = useResourceBookingSession();

  const analyticsItems = useMemo(() => {
    const items = getProductFromResourceGroups({
      groups: collection?.resourceGroups ?? [],
      categoryName: collection?.category?.name,
    });

    const data = items.reduce(
      (acc, item) => {
        acc.ids.push(item.id ?? '');
        acc.titles.push(item.title ?? '');

        acc.categories.push(item.category ?? '');
        return acc;
      },
      {
        ids: [] as string[],
        titles: [] as string[],
        categories: [] as string[],
      },
    );

    return { items, data };
  }, [collection?.resourceGroups, collection?.category?.name]);

  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_COLLECTION,
    payload: {
      [ANALYTICS_PROPERTY.AllExperiences]:
        analyticsItems.data.titles.join(';') || '',
      [ANALYTICS_PROPERTY.Products]: JSON.stringify(analyticsItems.items),
    },
    optionalPayload: {
      [ANALYTICS_PROPERTY.ExperienceCount]:
        analyticsItems.items?.length?.toString(),
      [ANALYTICS_PROPERTY.AllCategories]:
        analyticsItems.data.categories.join(';') || '',
      [ANALYTICS_PROPERTY.ListId]: collection?.id,
      [ANALYTICS_PROPERTY.ListName]: collection?.title,
    },
    isNotReady: isLoadingCollection || !collection,
  });

  if (isLoadingCollection && !collection) {
    return <CollectionPageSkeleton />;
  }

  if (!collection) {
    return <h1>{translate('noCollectionFound')}</h1>;
  }

  if (collection.map) return <MappedCollectionPage />;

  return <NonMappedCollectionPage />;
};
