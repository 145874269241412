import { useWindowDimensions } from 'WindowDimensionProvider';

const ResponsiveLayout = ({
  breakPoint = 768,
  renderMobile,
  renderDesktop,
}) => {
  const { width } = useWindowDimensions();
  return width > breakPoint ? renderDesktop() : renderMobile();
};

export default ResponsiveLayout;
