import React, { FC, useState } from 'react';
import { z } from 'zod';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ExperienceImageSizeMediaSchema } from '@kouto/types';
import ResponsiveImage from 'components/common/ResponsiveImage/ResponsiveImage';
import { isImageRatioGreaterSixteenNine, currencyFormat } from 'utils';
import { Flex } from 'components/common/styled/common-styled';
import { useBrandCurrency } from 'hooks/useBrandCurrency';
import { formatDateShort } from 'utils/dateTime';
import { ExclusiveTagOverlay } from 'components/common/ExclusiveTagOverlay';
import {
  applyMobileFontSize,
  FontSizeProps,
} from '../theme/Typography/ResourceTypography';

interface Props {
  id: string;
  image: z.infer<typeof ExperienceImageSizeMediaSchema>;
  title: string;
  dates: string[];
  prices: number[];
  exclusiveTagName: string | null;
}

const EventItem: FC<Props> = ({
  id,
  image,
  title,
  dates,
  prices,
  exclusiveTagName,
}) => {
  const history = useHistory();
  const currency = useBrandCurrency();
  const [isHovered, setIsHovered] = useState(false);

  const [isSixteenNineRatio, setIsSixteenNineRatio] = useState(false);

  const onImgLoadSixteenNineRatio: HTMLImageElement['onload'] = (e) => {
    if (e.target instanceof HTMLImageElement) {
      setIsSixteenNineRatio(
        isImageRatioGreaterSixteenNine(
          e.target.naturalWidth,
          e.target.naturalHeight,
        ),
      );
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      history.push(`/event/${id}`);
    }
  };

  return (
    <CollectionViewItem
      onClick={() => {
        history.push(`/event/${id}`);
      }}
      tabIndex={0}
      role="button"
      onKeyDown={handleKeyDown}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardGroup className="collection-item__card-group">
        <CardImage className="collection-item__card-image">
          {exclusiveTagName && isHovered ? (
            <ExclusiveTagOverlay>{exclusiveTagName}</ExclusiveTagOverlay>
          ) : null}
          <CardImageRatio className="collection-item__card-image-ratio">
            <ResponsiveImage
              viewType="large"
              className="collection-item__collection-image"
              uriObject={image.uri}
              defaultSize="384w"
              isSixteenNineRatio={isSixteenNineRatio}
              onLoad={onImgLoadSixteenNineRatio}
              CustomRenderer={CollectionImage}
              alt={title}
            />
          </CardImageRatio>
        </CardImage>
        <Flex
          direction="column"
          alignItem="flex-start"
          justifyContent="flex-start"
          gap={12}
        >
          <Title
            mobileFontSize="16px"
            mobileFontWeight="normal"
            className="collection-item__title"
          >
            {title}
          </Title>
          {dates[0] && (
            <DateLabel>Next on {formatDateShort(dates[0])}</DateLabel>
          )}
          {prices[0] >= 0 && (
            <PriceLabel>
              {prices.length === 1 && prices[0] === 0
                ? 'Complimentary'
                : `${currencyFormat(currency)(Math.min(...prices))}${
                    prices[1] >= 0
                      ? ` - ${currencyFormat(currency)(Math.max(...prices))}`
                      : ''
                  }`}
            </PriceLabel>
          )}
        </Flex>
      </CardGroup>
    </CollectionViewItem>
  );
};

const CollectionViewItem = styled.div`
  cursor: pointer;
  max-width: calc(100vw - 24px);
`;

const CardGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CardImage = styled.div`
  margin-bottom: 20px;
  border-radius: var(--way-design-borderRadiusDefault);
  background: inherit;
  position: relative;
`;

const CardImageRatio = styled.div`
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
`;

const CollectionImage = styled.img<{ isSixteenNineRatio: boolean }>`
  width: 100%;
  height: ${({ isSixteenNineRatio }) => (isSixteenNineRatio ? 'auto' : '100%')};
  object-fit: cover;
  top: 50%;
  left: 50%;
  position: absolute;
  border-radius: var(--way-design-borderRadiusDefault);
  object-position: center;
  transform: translate(-50%, -50%);
`;

const Title = styled.h3<FontSizeProps>`
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  line-height: 24px;
  margin: 0;
  color: var(--way-colors-primaryTextColor);

  ${(props) => applyMobileFontSize(props)};
`;

const DateLabel = styled.span`
  font-size: 16px;
  font-weight: 350;
  line-height: 16px;
  color: var(--way-colors-secondaryTextColor);
`;

const PriceLabel = styled.span`
  font-size: 13px;
  font-weight: 350;
  line-height: 16px;
  color: var(--way-colors-secondaryTextColor);
`;

export default EventItem;
