import React from 'react';
import PropTypes from 'prop-types';

export default function LoadingIndicator({ width, height }) {
  return (
    <svg
      version="1.0"
      width={width}
      height={height}
      viewBox="0 0 128 128"
      xmlSpace="preserve"
    >
      <rect x="0" y="0" width="100%" height="100%" fill="transparent" />
      <g>
        <path
          d="M64 128A64 64 0 0 1 18.34 19.16L21.16 22a60 60 0 1 0 52.8-17.17l.62-3.95A64 64 0 0 1 64 128z"
          fill="#777777"
          fillOpacity="1"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 64 64"
          to="360 64 64"
          dur="1800ms"
          repeatCount="indefinite"
        />
      </g>
    </svg>
  );
}

LoadingIndicator.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  width: '25px',
  height: '25px',
};
