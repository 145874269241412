async function preloadImage(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve();
    img.onerror = () => reject();
  });
}

export async function preloadImages(srcs: string[]): Promise<void> {
  const result = await Promise.allSettled(srcs.map(preloadImage));

  if (result.some((r) => r.status === 'rejected')) {
    return Promise.reject();
  }

  return Promise.resolve();
}
