export default {
  date: 'Date',
  chooseExpDate: 'Choisissez la date',
  filterExpDate: 'Choisissez la date',
  experienceType: 'Type',
  filterExperienceType: 'Filtrer par type',
  experienceVibe: 'Ambiance',
  filterExperienceVibe: 'Filtrer par ambiance',
  search: 'Rechercher',
  viewCalendar: 'Voir le Calendrier',
  back: 'Retour',
  previous: 'Précédent',
  next: 'Suivant',
  noExperiences: 'Pas d’expériences trouvées',
};
