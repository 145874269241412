import { useAppState } from 'AppProvider';
import { ITextFieldProps } from 'components/common/TextFieldInput/TextFieldInput';
import React from 'react';
import { validateEmpty } from 'utils/validators';
import PhoneNumberController from '../PhoneNumber/PhoneNumberController';

interface IPhoneFieldProps extends ITextFieldProps {
  scope: string;
}

const BookingPhone: React.FC<IPhoneFieldProps> = ({
  scope,
  name,
  label,
  ...props
}) => {
  const { experienceSettings: settings } = useAppState(
    (state: Record<string, unknown>) => state.experience,
  );
  const isCurrentScope = settings?.scope === scope;

  const getFieldsConfig = (config: string) => {
    if (!settings || !settings.fields) {
      return false;
    }
    return settings.fields.phoneNumber[config] ?? false;
  };

  const isFieldIncluded = getFieldsConfig('isActive');

  const phoneValidationPattern = () => {
    const isRequired = getFieldsConfig('isRequired');
    return {
      ...(isCurrentScope && isRequired && { ...validateEmpty() }),
    };
  };

  if (!isCurrentScope && scope === 'participant') {
    return null;
  }

  if (isCurrentScope && !isFieldIncluded) {
    return null;
  }

  const { required } = phoneValidationPattern();

  return (
    <PhoneNumberController
      name={name}
      rules={{ ...phoneValidationPattern() }}
      label={`${label}${required ? ':*' : ''}`}
      {...props}
    />
  );
};

export default BookingPhone;
