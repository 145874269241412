import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { ListingPictureKind, AVAILABLE_BRAND_FEATURES } from '@kouto/types';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import useEventBookingSession from 'features/Events/hooks/useEventBookingSession';
import { SkeletonLineSlim } from 'components/theme/Skeleton/Skeleton';
import MinimalCarousel from 'components/Carousel/MinimalCarousel';
import MediaCarousel from 'components/Carousel/MediaCarousel';
import { formatListingMedia } from 'utils/listings';

interface Props {
  scrollAnimationDelay: number;
}

const EventCoverMediaMobile: FC<Props> = ({ scrollAnimationDelay }) => {
  const { isLoading, event } = useEventBookingSession();
  const disableAnimateScroll = useBrandToggleFeature(
    AVAILABLE_BRAND_FEATURES.DISABLE_ACTIVATE_SCROLLING_ANIMATION,
  );

  const eventCoverMedias = useMemo(
    () =>
      formatListingMedia(
        (event?.medias || []).filter(
          (m) => m.kind === ListingPictureKind.COVER,
        ),
      ).splice(0, 3),
    [event?.medias],
  );

  if (isLoading || !event) {
    return (
      <Wrapper shouldStick={!disableAnimateScroll} scrollAnimationDelay={0}>
        <MinimalCarousel>
          <CoverPhotoSkeleton />
        </MinimalCarousel>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      shouldStick={!disableAnimateScroll}
      scrollAnimationDelay={scrollAnimationDelay}
    >
      <MediaCarousel medias={eventCoverMedias} />
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs<{
  scrollAnimationDelay: number;
}>(({ scrollAnimationDelay }) => ({
  style: {
    opacity: 1 - (0.6 * scrollAnimationDelay) / 100,
  },
}))<{
  shouldStick: boolean;
  scrollAnimationDelay: number;
}>`
  width: 100%;
  height: 300px;
  position: ${({ shouldStick }) => (shouldStick ? 'sticky' : 'relative')};
  top: ${({ shouldStick }) => (shouldStick ? 8 : 0)}px;
`;

const CoverPhotoSkeleton = styled(SkeletonLineSlim)`
  width: 100%;
  height: 100%;
`;

export default EventCoverMediaMobile;
