import React, { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex } from 'components/common/styled/common-styled';
import { PrimaryButton } from 'components/theme/Button/Button';
import { Error, ErrorContainer, StyledInput } from 'components/theme/Form/Form';
import { ResourceParagraph } from 'components/theme/Typography/ResourceTypography';
import { isEmpty } from 'lodash';
import { useIsMobile } from 'WindowDimensionProvider';

interface BaseProps {
  renderActionButtons?: (value: string) => ReactNode;
  fullWidth?: boolean;
  withHeading?: boolean;
  isExclusive?: boolean;
  errorMessage: string;
}

interface WithRenderActionButton extends BaseProps {
  renderActionButtons: (value: string) => ReactNode;
  onContinue?: never;
}

interface WithoutRenderActionButton extends BaseProps {
  renderActionButtons?: undefined;
  onContinue: (value: string) => void; // Require onContinue when renderActionButton is undefined
}

type Props = WithRenderActionButton | WithoutRenderActionButton;

const ResourceAccessCode: FC<Props> = ({
  onContinue,
  fullWidth,
  renderActionButtons,
  withHeading = true,
  isExclusive,
  errorMessage,
}) => {
  const isMobile = useIsMobile();
  const [value, onChange] = useState('');
  const { t: translate } = useTranslation();

  return (
    <Flex
      direction="column"
      alignItem="flex-start"
      gap={isMobile ? 36 : 48}
      width="100%"
    >
      {isExclusive && (
        <Flex
          direction="column"
          gap={isMobile ? 24 : 12}
          alignItem="flex-start"
          width={isMobile || fullWidth ? '100%' : 'auto'}
        >
          <Flex direction="column" gap={4} alignItem="flex-start">
            {withHeading && <AccessCodeHeading />}
            <Paragraph
              className="access-code__subheading"
              lineHeight="17px"
              fontSize="14px"
              color="rgba(0, 0, 0, 0.6)"
              fontWeight="400"
              doesHeadingExist={withHeading}
            >
              This experience requires an access code to book &nbsp; &nbsp;
            </Paragraph>
          </Flex>
          <Flex direction="column" gap={4} alignItem="flex-start" width="100%">
            <StyledInput
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Enter code"
            />
            {errorMessage && (
              <ErrorContainer>
                <Error>{errorMessage}</Error>
              </ErrorContainer>
            )}
          </Flex>
        </Flex>
      )}
      {renderActionButtons ? (
        renderActionButtons(value)
      ) : (
        <PrimaryButton
          disabled={isExclusive ? isEmpty(value) : false}
          big
          onClick={() => onContinue(value)}
        >
          {translate('continue')}
        </PrimaryButton>
      )}
    </Flex>
  );
};

export const AccessCodeHeading = () => {
  const isMobile = useIsMobile();
  return (
    <ResourceParagraph
      className="access-code__heading"
      mobileFontSize="21px"
      mobileFontWeight="700"
      fontSize="16px"
      fontWeight="400"
      lineHeight="19px"
      color="#000"
    >
      Access code{!isMobile && <RedAsterik>*</RedAsterik>}
    </ResourceParagraph>
  );
};

const Paragraph = styled(ResourceParagraph)<{ doesHeadingExist: boolean }>`
  ${({ className, doesHeadingExist }) => `
      &.${className} {
        margin-top: ${doesHeadingExist ? 0 : '-8px'} !important;
      }
    `}
`;

const RedAsterik = styled.span`
  color: red;
`;

export default ResourceAccessCode;
