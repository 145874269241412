import { ANALYTICS_EVENT } from '@kouto/types/src/api/integration/analytics';
import {
  AnalyticsClient,
  type IAnalyticsDataPayload,
} from 'features/analytics/types/analytics';
import { debug } from 'utils/debug';
import { IntegrationName } from '@kouto/types';
import { Ga4EventsFormatter } from './Ga4Client/events.formatter';

export class WayGtmClient extends AnalyticsClient {
  readonly provider = IntegrationName.GA4_ANALYTICS;

  constructor() {
    super();
    this.addGtmScript();
  }

  private addGtmScript() {
    if (!window.wayDataLayer) {
      window.wayDataLayer = [];
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.GTM_CLIENT_ID}&l=wayDataLayer`;
      script.onload = () => {
        this.ready = true;
        this.runWhenAvailable();
      };
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode?.insertBefore(script, firstScript);

      window.wayDataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
    } else {
      this.ready = true;
      this.runWhenAvailable();
    }
  }

  async init() {
    await this.runWhenAvailable();

    this.ready = true;

    this.events.forEach((event) =>
      this.sendAnalyticsEvent(event.eventName, event.payload),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  hasEvent() {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  isAvailable(): boolean {
    return !!window.wayDataLayer;
  }

  private sendAnalyticsEvent(
    eventName: ANALYTICS_EVENT,
    payload: IAnalyticsDataPayload,
  ) {
    if (!this.hasEvent()) {
      return;
    }

    const isView = eventName.startsWith('view_');

    const data = Ga4EventsFormatter(eventName, payload);

    if (!data) {
      return;
    }

    delete data.products;

    const eventProperties = {
      isWay: true,
      ...data,
    };

    window.wayDataLayer?.push({ ecommerce: null });

    window.wayDataLayer?.push(eventProperties);

    if (isView) {
      debug('log', this.provider, {
        type: 'View Event triggered',
        eventProperties,
      });
      return;
    }

    debug('log', this.provider, {
      type: 'Link Event triggered',
      eventProperties,
    });
  }

  sendEvent(eventName: ANALYTICS_EVENT, payload: IAnalyticsDataPayload): void {
    if (!this.hasEvent()) {
      return;
    }
    if (!this.ready) {
      this.events.push({
        eventName,
        payload,
      });
      return;
    }

    this.sendAnalyticsEvent(eventName, payload);
  }
}

declare global {
  interface Window {
    wayDataLayer?: object[];
  }
}
