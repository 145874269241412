export default {
  weekdays: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
  months: [
    'Leden',
    'Únor',
    'Březen',
    'Duben',
    'Květen',
    'Červen',
    'Červenec',
    'Srpen',
    'Září',
    'Říjen',
    'Listopad',
    'Prosinec',
  ],
  formattedShortMonths: [
    'Led',
    'Úno',
    'Bře',
    'Dub',
    'Kvě',
    'Čer',
    'Čvc',
    'Srp',
    'Zář',
    'Říj',
    'Lis',
    'Pro',
  ],
  weekdays_calendar: {
    Sun: 'Ne',
    Mon: 'Po',
    Tue: 'Út',
    Wed: 'St',
    Thu: 'Čt',
    Fri: 'Pá',
    Sat: 'So',
  },
  months_calendar: {
    January: 'Leden',
    February: 'Únor',
    March: 'Březen',
    April: 'Duben',
    May: 'Květen',
    June: 'Červen',
    July: 'Červenec',
    August: 'Srpen',
    September: 'Září',
    October: 'Říjen',
    November: 'Listopad',
    December: 'Prosinec',
  },
  weekdays_booking_container: {
    Sunday: 'Neděle',
    Monday: 'Pondělí',
    Tuesday: 'Úterý',
    Wednesday: 'Středa',
    Thursday: 'Čtvrtek',
    Friday: 'Pátek',
    Saturday: 'Sobota',
  },
};
