export default {
  unavailable: 'Unavailable',
  person: 'person',
  people: 'people',
  reserveNow: 'Reserve now',
  selectTime: 'Select time',
  select: 'Select',
  reserve: 'Reserve',
  time: 'Time',
  tier: 'Tier',
  questions: 'Questions',
  addToCart: 'Add to cart',
  checkout: 'Checkout',
  total: 'Total',
  availability: 'Availability',
  whatsIncluded: "What's included",
  showMore: 'Show more',
  showLess: 'Show less',
  session: 'Session',
  viewDetails: 'View details',
  findAvailability: 'Find Availability',
  loading: 'Loading',
  capacity: 'Capacity',
  noAvailableSessionsOnThisDate: 'No available sessions on this date',
  noGroupAvailableOnThisDateAndTime:
    'No {{groupTitle}} available at this date and time',
  noCollectionFound: 'No collection found!',
  noResourceGroupFound: 'No resource group found!',
  noData: 'No data!',
  continue: 'Continue',
};
