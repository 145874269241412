import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { useAppState } from 'AppProvider';

import LoadingIndicator from 'components/theme/LoadingIndicator';
import { STATUS_PENDING, STATUS_SUCCESS, STATUS_FAILURE } from 'types/app';

import { formatTimeInAMPM, formatDateForReq } from 'utils';
import CalendarIcon from 'assets/calendar';
import { useTranslation } from 'react-i18next';

const AvailableDateOneTime = styled.div`
  margin-top: -15px;
`;

const AvailableDatesRecurring = styled.div`
  margin-top: -15px;
  margin-bottom: 5px;
`;

const MAX_ITEMS = 5;

function roundToMaxItems(x, max) {
  return Math.ceil(x / max) * max;
}

function eventDate(evtDate, format = 'MMMM Do') {
  return moment(evtDate).format(format);
}

const DateView = ({ type, availableDates, experience, onNextDateRange }) => {
  const { t: translate } = useTranslation();

  const [pagination, setPagination] = useState({
    start: 0,
    end: MAX_ITEMS,
    fetchFromAPI: false,
  });

  const [navButtonType, setNavButtonType] = useState(null);
  const { start, end, fetchFromAPI } = pagination;

  const {
    fetchFirstAvailabilityStatus,
    searchAvailabilityStatus,
    sessions,
    fetchedUntilDate,
  } = useAppState((state) => state.experience);

  const isRecurring = type === 'recurring';

  const formattedDates = useMemo(() => {
    return availableDates.filter((date) => date).slice(start, end);
  }, [availableDates, start, end]);

  const { start: availabilityStartDate, end: availabilityEndDate } =
    useMemo(() => {
      return {
        start: eventDate(formattedDates[0]?.date, 'DD MMM YYYY'),
        end: eventDate(fetchedUntilDate, 'DD MMM YYYY'),
      };
    }, [formattedDates, fetchedUntilDate]);

  const onNextRange = () => {
    setNavButtonType('next');
    const defaultPagination = {
      start: 0,
      end: end + MAX_ITEMS,
    };
    if (availableDates.length > end) {
      setPagination({
        ...defaultPagination,
        fetchFromAPI: false,
      });
      return;
    }
    onNextDateRange();
    setPagination({
      ...defaultPagination,
      fetchFromAPI: true,
    });
  };

  useEffect(() => {
    if (
      fetchFromAPI &&
      start === 0 &&
      navButtonType === 'prev' &&
      availableDates.length > 0
    ) {
      const roundedValue = roundToMaxItems(availableDates.length, MAX_ITEMS);
      setPagination({
        start: roundedValue - MAX_ITEMS,
        end: roundedValue,
      });
    }
  }, [availableDates, navButtonType, start, fetchFromAPI]);

  const DatesWrapper = isRecurring
    ? AvailableDatesRecurring
    : AvailableDateOneTime;
  const isAvailabilityComplete =
    searchAvailabilityStatus === STATUS_SUCCESS ||
    searchAvailabilityStatus === STATUS_FAILURE;
  const isFirstAvailableComplete =
    fetchFirstAvailabilityStatus === STATUS_SUCCESS ||
    fetchFirstAvailabilityStatus === STATUS_FAILURE;

  const isFetching =
    searchAvailabilityStatus === STATUS_PENDING ||
    fetchFirstAvailabilityStatus === STATUS_PENDING;

  const shouldHideDates =
    availableDates.length < 1 &&
    (isAvailabilityComplete || isFirstAvailableComplete) &&
    !isFetching;

  return (
    <DateViewSection>
      <Remark className="date-view__remark">
        <h4>{experience?.title}</h4>
      </Remark>
      {searchAvailabilityStatus === STATUS_PENDING &&
      availableDates.length < 1 ? (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      ) : null}
      {shouldHideDates ? null : (
        <Container>
          {!experience?.isBookable ? (
            <DatesWrapper>
              {formattedDates?.map((formattedDate) =>
                formattedDate?.sessions?.map(({ startTime }) => {
                  const experienceTime = startTime
                    ? formatTimeInAMPM(startTime)
                    : '';
                  return (
                    <DateItem
                      expTime={experienceTime}
                      date={formattedDate.date}
                    />
                  );
                }),
              )}
            </DatesWrapper>
          ) : (
            <DatesWrapper>
              {formattedDates?.map((formattedDate) => {
                const experienceTime = sessions[0]?.startTime
                  ? formatTimeInAMPM(sessions[0]?.startTime)
                  : '';
                return (
                  <DateItem expTime={experienceTime} date={formattedDate} />
                );
              })}
            </DatesWrapper>
          )}
          {(isRecurring && isFirstAvailableComplete && (
            <Navigation>
              <SeeMoreText onClick={onNextRange}>
                + {translate('seeOtherDates')}
              </SeeMoreText>
              <span>
                &nbsp;({availabilityStartDate} - {availabilityEndDate})
              </span>
            </Navigation>
          )) ||
            null}
        </Container>
      )}
    </DateViewSection>
  );
};

const DateItem = ({ date, expTime }) => {
  const { t: translate } = useTranslation();
  const translatedWeekday = translate(
    `weekdays_booking_container.${moment(date).format('dddd')}`,
  );
  return (
    <AvailableNavBtn
      className="date-view__available-navigation-button"
      key={formatDateForReq(date) + expTime}
    >
      <IconBack>
        <CalendarIcon />
      </IconBack>
      <div>
        <div>
          <small>{translatedWeekday}</small>
        </div>
        <div>
          <span>
            {eventDate(date)} {expTime ? `${translate('at')} ${expTime}` : ''}
          </span>
        </div>
      </div>
    </AvailableNavBtn>
  );
};

DateItem.propTypes = {
  date: PropTypes.string.isRequired,
  expTime: PropTypes.string.isRequired,
};

DateView.propTypes = {
  type: PropTypes.string.isRequired,
  availableDates: PropTypes.array.isRequired,
  experience: PropTypes.object.isRequired,
  onNextDateRange: PropTypes.func.isRequired,
};

const DateViewSection = styled.section``;

const Container = styled.div`
  svg path,
  svg rect {
    stroke: var(--way-colors-svgColor);
  }
`;

const Remark = styled.div`
  margin: 20px 0;

  & > h2 {
    font-size: 24px;
    font-weight: 400;
  }
`;

const LoadingContainer = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AvailableNavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & > *:nth-child(1) {
    transform: scale(1.4);
  }

  & > div {
    margin-left: 15px;

    & span {
      font-size: 17px;
      font-weight: 500;
    }

    & small {
      font-size: 12px;
      font-weight: 500;
    }
  }
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  /* stylelint-disable-next-line */
  & span {
    color: gray;
  }
`;

const SeeMoreText = styled.span`
  color: gray;
  cursor: pointer;

  &:hover {
    border-bottom: 0.5px solid var(--way-colors-borderColor);
  }
`;

const IconBack = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export default DateView;
