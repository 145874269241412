import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { roundToDecimal } from 'utils';

import RateSvg from 'components/common/rating-small.svg';
import { useTranslation } from 'react-i18next';

const Rating = ({ originalRating, totalReviews, isAggregateMode }) => {
  const { t: translateText } = useTranslation();

  const renderTotalReviewsCount = () => {
    const reviewCountNoun =
      totalReviews === 1 ? 'reviewSingular' : 'reviewPlural';
    return `(${totalReviews}${' '}${translateText(reviewCountNoun)})`;
  };

  return (
    <RatingContainer>
      {isAggregateMode ? (
        <ThemedRate height={24} width={24} />
      ) : (
        <Stars rating={originalRating * 20}>
          <>
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
          </>
        </Stars>
      )}
      {isAggregateMode && (
        <AverageRating>{roundToDecimal(originalRating)}</AverageRating>
      )}

      {totalReviews !== undefined && (
        <ReviewTotal>{renderTotalReviewsCount()}</ReviewTotal>
      )}
    </RatingContainer>
  );
};

export default Rating;

Rating.propTypes = {
  originalRating: PropTypes.number.isRequired,
  totalReviews: PropTypes.number,
  isAggregateMode: PropTypes.bool,
};

Rating.defaultProps = {
  totalReviews: undefined,
  isAggregateMode: false,
};

const RatingContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const AverageRating = styled.span`
  padding-left: 9px;
  font-size: 16px;
`;

const ReviewTotal = styled.div`
  display: inline-block;
  margin-left: 14px;
  font-family: ${({ theme }) => theme.font.primaryFont};
  font-size: 16px;
`;
/* stylelint-disable property-no-vendor-prefix */
const Stars = styled.div`
  display: inline-block;
  & > span {
    cursor: default;
    font-size: 16px;
    display: inline-block;
    height: 24px;
    width: 24px;
  }

  background: ${({ theme, rating }) =>
    `-webkit-linear-gradient(
      0deg,
      ${theme.colors.primaryColorShades[100]} 0,
      ${theme.colors.primaryColorShades[100]} ${rating - 1}%,
      ${theme.colors.primaryColorShades[50]} ${rating}%,
      ${theme.colors.primaryColorShades[50]} 100% )`};
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

const ThemedRate = styled(RateSvg)`
  & > path {
    fill: var(--way-colors-primaryColorShades-100);
    stroke: var(--way-colors-primaryColorShades-100);
  }
`;
