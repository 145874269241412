import {
  ANALYTICS_EVENT,
  type AnalyticsEventConfiguration,
  type IAnalyticsConfiguration,
} from '@kouto/types';
import type { IAnalyticsDataPayload } from 'features/analytics/types/analytics';
import { unescape } from 'lodash';
import { inflateJson } from 'services/InflateTemplate';
import { decodeHTML } from 'utils';

export const getAnalyticsEventParams = (
  config: Pick<IAnalyticsConfiguration, 'globalVariables' | 'events'>,
  eventName: ANALYTICS_EVENT,
): Record<string, string> => {
  const event = config.events[eventName] as AnalyticsEventConfiguration;

  if (!event.enabled) {
    return {};
  }

  const globalVariables = config.globalVariables as Record<string, string>;

  return {
    ...globalVariables,
    ...(event.property_mapping as Record<string, string>),
  };
};

function maybeHasHTMLEntities(str: string) {
  return str.includes('&') && str.includes(';');
}

function unescapeHTMLEntities(obj: Record<string, string>) {
  return Object.keys(obj).reduce((acc, key) => {
    if (maybeHasHTMLEntities(obj[key])) {
      return {
        ...acc,
        [key]: decodeHTML(unescape(obj[key])),
      };
    }

    return {
      ...acc,
      [key]: unescape(obj[key]),
    };
  }, {});
}

export const getResolvedPropertyMapping = (
  propertyMapping: Record<string, string>,
  parameters: IAnalyticsDataPayload,
): Record<string, string> => {
  return unescapeHTMLEntities(inflateJson(propertyMapping, parameters));
};
