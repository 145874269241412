import styled from 'styled-components';
import { brightenColor } from 'utils';
import { THEMES } from 'types/brand';

export const A = styled.a`
  color: var(--way-colors-linkColor);
  text-decoration: none;
  &:hover {
    color: ${({ theme }) =>
      brightenColor(theme.colors.linkColor, 20, theme.mode === THEMES.DARK)};
  }
`;
