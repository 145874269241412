import React, { FC, useState, MouseEvent, useCallback } from 'react';
import { z } from 'zod';
import styled from 'styled-components';
import {
  ANALYTICS_EVENT,
  ANALYTICS_PROPERTY,
  ExperienceImageSizeMediaSchema,
  IPriceTier,
} from '@kouto/types';
import ResponsiveImage from 'components/common/ResponsiveImage/ResponsiveImage';
import { isImageRatioGreaterSixteenNine } from 'utils';
import { Flex } from 'components/common/styled/common-styled';
import { SecondaryButton } from 'components/theme/Button/Button';
import { useHistory } from 'react-router-dom';
import { ExclusiveTagOverlay } from 'components/common/ExclusiveTagOverlay';
import {
  analyticsManager,
  getProductFromResourceGroups,
} from 'features/analytics/services';
import { truncateString } from 'utils/string';
import {
  applyMobileFontSize,
  FontSizeProps,
} from '../theme/Typography/ResourceTypography';

interface Props {
  id: string;
  image: z.infer<typeof ExperienceImageSizeMediaSchema>;
  title: string;
  preselectGroupOnClick: boolean;
  collectionResourceGroups: Array<{
    id: string;
    title: string;
  }>;
  exclusiveTagName: string | null;
  categoryName?: string;
  priceTiers?: IPriceTier[];
  index?: number;
}

const CollectionItem: FC<Props> = ({
  id,
  image,
  title,
  preselectGroupOnClick,
  categoryName,
  priceTiers,
  collectionResourceGroups,
  exclusiveTagName,
  index,
}) => {
  const history = useHistory();
  const [isSixteenNineRatio, setIsSixteenNineRatio] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const onImgLoadSixteenNineRatio: HTMLImageElement['onload'] = (e) => {
    if (e.target instanceof HTMLImageElement) {
      setIsSixteenNineRatio(
        isImageRatioGreaterSixteenNine(
          e.target.naturalWidth,
          e.target.naturalHeight,
        ),
      );
    }
  };

  const handleGroupClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>, groupId: string) => {
      e.stopPropagation();

      if (preselectGroupOnClick) {
        history.push(`/collection/${id}/group/${groupId}`);
      } else {
        history.push(`/collection/${id}`);
      }
    },
    [id, preselectGroupOnClick, history],
  );

  const handleCollectionClick = () => {
    if (collectionResourceGroups.length === 1) {
      analyticsManager.sendEvent(ANALYTICS_EVENT.CLICK_EXPERIENCE, {
        [ANALYTICS_PROPERTY.ExperienceOrder]: `${index}`,

        [ANALYTICS_PROPERTY.ExperienceId]: collectionResourceGroups[0]?.id,
        [ANALYTICS_PROPERTY.ExperienceTitle]:
          collectionResourceGroups[0]?.title,
        [ANALYTICS_PROPERTY.ExperienceCategory]: categoryName,
        [ANALYTICS_PROPERTY.Products]: JSON.stringify(
          getProductFromResourceGroups({
            groups: collectionResourceGroups,
            categoryName,
            collectionId: id,
            collectionName: title,
            priceTiers,
          }),
        ),
      });
    }
    history.push(`/collection/${id}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleCollectionClick();
    }
  };

  return (
    <CollectionViewItem
      onClick={handleCollectionClick}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      tabIndex={0}
      role="button"
      onKeyDown={handleKeyDown}
    >
      <CardGroup className="collection-item__card-group">
        <CardImage className="collection-item__card-image">
          <CardImageRatio className="collection-item__card-image-ratio">
            {exclusiveTagName && isHovered ? (
              <ExclusiveTagOverlay>{exclusiveTagName}</ExclusiveTagOverlay>
            ) : null}
            <ResponsiveImage
              viewType="large"
              className="collection-item__collection-image"
              uriObject={image.uri}
              defaultSize="384w"
              isSixteenNineRatio={isSixteenNineRatio}
              onLoad={onImgLoadSixteenNineRatio}
              CustomRenderer={CollectionImage}
              alt={title}
            />
          </CardImageRatio>
        </CardImage>
        <Flex
          direction="column"
          alignItem="flex-start"
          justifyContent="flex-start"
          gap={12}
        >
          <Title
            mobileFontSize="16px"
            mobileFontWeight="normal"
            className="collection-item__title"
          >
            {title}
          </Title>
          <ResourceGroupButtonWrapper
            direction="row"
            alignItem="flex-start"
            justifyContent="flex-start"
            gap={10}
          >
            {collectionResourceGroups.map((resourceGroup) => {
              return (
                <StyledSecondaryButton
                  key={resourceGroup.id}
                  className="collection-item__resource-group"
                  onClick={(e) => handleGroupClick(e, resourceGroup.id)}
                  mobileFontWeight="normal"
                  title={resourceGroup.title}
                >
                  {truncateString(resourceGroup.title, 30)}
                </StyledSecondaryButton>
              );
            })}
          </ResourceGroupButtonWrapper>
        </Flex>
      </CardGroup>
    </CollectionViewItem>
  );
};

const CollectionViewItem = styled.div`
  cursor: pointer;
  max-width: calc(100vw - 24px);
`;

const CardGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CardImage = styled.div`
  margin-bottom: 20px;
  border-radius: var(--way-design-borderRadiusDefault);
  background: inherit;
  position: relative;
`;

const CardImageRatio = styled.div`
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
`;

const CollectionImage = styled.img<{ isSixteenNineRatio: boolean }>`
  width: 100%;
  height: ${({ isSixteenNineRatio }) => (isSixteenNineRatio ? 'auto' : '100%')};
  object-fit: cover;
  top: 50%;
  left: 50%;
  position: absolute;
  border-radius: var(--way-design-borderRadiusDefault);
  object-position: center;
  transform: translate(-50%, -50%);
`;

const Title = styled.h3<FontSizeProps>`
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  line-height: 24px;
  margin: 0;
  color: var(--way-colors-primaryTextColor);

  ${(props) => applyMobileFontSize(props)};
`;

const ResourceGroupButtonWrapper = styled(Flex)`
  width: 100%;
  overflow-x: auto;
  padding-bottom: 12px;

  scrollbar-width: thin;
`;

const StyledSecondaryButton = styled(SecondaryButton)<FontSizeProps>`
  padding: 8px 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  border: solid 0.5px;
  white-space: nowrap;
  flex-shrink: 0;

  ${(props) => applyMobileFontSize(props)};
`;

export default CollectionItem;
