import React from 'react';

const ChevronDownIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20 * scale}
      height={20 * scale}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.95182 12.2449L15.6943 6.50234L16.4014 7.20945L10.3054 13.3055L9.59827 13.3055L3.5022 7.20945L4.2093 6.50234L9.95182 12.2449Z"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
    </svg>
  );
};

export default ChevronDownIcon;
