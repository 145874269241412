import * as types from 'types/discount-code';
import * as appTypes from 'types/app';
import initialState from 'store/initial-state';
import i18n from 'i18next';

export default function discountCodeReducer(
  state = initialState.discountCode,
  action,
) {
  switch (action.type) {
    case types.DELETE_DISCOUNT_CODE:
      return {
        ...state,
        discountCode: null,
        error: '',
      };
    case types.VALIDATE_DISCOUNT_CODE_REQUEST:
      return {
        ...state,
        error: '',
        responseStatus: null,
        validateDiscountCodeStatus: appTypes.STATUS_PENDING,
      };
    case types.VALIDATE_DISCOUNT_CODE_FAILURE:
      return {
        ...state,
        discountCode: null,
        validateDiscountCodeStatus: appTypes.STATUS_FAILURE,
        responseStatus: action.payload.responseStatus,
        validationMessages: action.payload.validationMessages,
        error:
          action.payload.responseStatus === 404
            ? i18n.t('invalidCodeValidation')
            : i18n.t('networkErrorDiscountCode'),
      };
    case types.VALIDATE_DISCOUNT_CODE_SUCCESS:
      return {
        ...state,
        discountCode: action.payload.discountCode,
        validateDiscountCodeStatus: appTypes.STATUS_SUCCESS,
      };
    default:
      return state;
  }
}
