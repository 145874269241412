import React, { PropsWithChildren } from 'react';
import { ErrorBoundary } from './ErrorBoundary';

export const withErrorBoundary = <P extends JSX.IntrinsicAttributes>(
  WrappedComponent: React.ComponentType<PropsWithChildren<P>>,
) => {
  const WithErrorBoundary = (props: P) => {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };

  return WithErrorBoundary;
};
