import styled from 'styled-components';
import {
  Map,
  useGetImageDimensions,
  getShapesFromResourceGroups,
} from '@kouto/map';
import { ShapeProps } from '@kouto/types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import { Map as LeafletMap } from 'leaflet';
import debounce from 'lodash/debounce';
import { ResourceGroup } from 'types/listings';
import useResourceBookingSession from 'features/Reserve/hooks/useResourceBookingSession';
import { useTranslation } from 'react-i18next';
import { useResourceMapContext } from '../../features/Reserve/contexts/ResourceMapContext';

type ExperienceWithAvailability = ResourceGroup['experiences'][number] & {
  unavailable?: boolean;
};

interface Props {
  assetUrl: string;
  resourceGroups: (Omit<ResourceGroup, 'experiences'> & {
    experiences: ExperienceWithAvailability[];
  })[];
  collectionName?: string;
}

const ResourceMap: React.FC<Props> = ({
  assetUrl,
  resourceGroups,
  collectionName,
}) => {
  const mapRef = useRef<LeafletMap | null>(null);
  const { t } = useTranslation();
  const {
    focusedResources,
    selectResource,
    selectedResource,
    unselectResource,
  } = useResourceMapContext();
  const { groupId, setResourceBookingSessionParams } =
    useResourceBookingSession();
  const mapImageDimensions = useGetImageDimensions(assetUrl);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerRect, setContainerRect] = useState(
    containerRef.current?.getBoundingClientRect(),
  );

  const onResourceSelect = useCallback(
    (shape: ShapeProps) => {
      if (shape.id !== selectedResource) {
        selectResource(shape.id);
        if (!groupId) {
          const group = resourceGroups.find((g) =>
            g.experiences.some((e) => e.id === shape.id),
          );
          if (group) {
            setResourceBookingSessionParams({
              groupId: group.id,
              resourceId: shape.id,
            });
          }
        }
      } else {
        unselectResource();
      }
    },
    [selectResource, selectedResource, unselectResource],
  );

  const shapes = useMemo(
    () =>
      containerRect && mapImageDimensions
        ? getShapesFromResourceGroups(
            resourceGroups,
            containerRect,
            mapImageDimensions,
            {
              focusedResources,
              selectedResource: selectedResource ?? undefined,
            },
          )
        : {},
    [
      resourceGroups,
      focusedResources,
      selectedResource,
      containerRect,
      mapImageDimensions,
    ],
  );

  const [, startTransition] = useTransition();

  useEffect(() => {
    const updateDimensions = debounce(() => {
      startTransition(() => {
        setContainerRect(containerRef.current?.getBoundingClientRect());
      });
    }, 250);

    const resizeObserver = new ResizeObserver(updateDimensions);
    if (containerRef.current) {
      setContainerRect(containerRef.current.getBoundingClientRect());
      resizeObserver.observe(document.body);
    }

    return () => {
      resizeObserver.unobserve(document.body);
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  return (
    <MapWrapper ref={containerRef}>
      {!mapImageDimensions?.width || !containerRect ? null : (
        <Map
          translate={t}
          height={containerRect.height}
          width={containerRect.width}
          key={`${containerRect.width}-${containerRect.height}`}
          mapRef={mapRef}
          shapes={shapes}
          onShapeClick={onResourceSelect}
          assetUrl={assetUrl}
          draggable={false}
          alt={t('interactiveMapAltText', {
            title: collectionName || resourceGroups[0]?.title,
          })}
        />
      )}
    </MapWrapper>
  );
};

export default ResourceMap;

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
