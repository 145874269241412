import { useEffect } from 'react';
import { fetchReviews } from 'actions/review';
import { useAppState, useDispatch } from 'AppProvider';

const useFetchReviews = (id, loading) => {
  const dispatch = useDispatch();
  const reviewState = useAppState((state) => state.review);

  useEffect(() => {
    if (!loading) {
      dispatch(fetchReviews(id));
    }
  }, [id]);

  return reviewState;
};

export default useFetchReviews;
