import React from 'react';

interface Props {
  color?: string;
}

export const Check: React.FC<Props> = ({ color = 'currentColor' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0204 5.31319C12.2156 5.50845 12.2156 5.82504 12.0204 6.0203L7.35369 10.6869C7.15843 10.8822 6.84189 10.8822 6.64661 10.6869L3.97994 8.02028C3.78468 7.82501 3.78468 7.50848 3.97994 7.31321C4.1752 7.11795 4.49179 7.11795 4.68705 7.31321L7.00016 9.62628L11.3133 5.31319C11.5086 5.11793 11.8251 5.11793 12.0204 5.31319Z"
        fill={color}
      />
    </svg>
  );
};
