import TRANSLATION_DATES_CZ from './weekdays/translation';
import TRANSLATION_BOOKING_CZ from './booking/translation';
import TRANSLATION_FORM_CZ from './form/translation';
import TRANSLATION_EXPERIENCES_CZ from './experiences/translation';
import TRANSLATION_LANDING_PAGES_CZ from './landingPages/translation';
import TRANSLATION_EVENTS_CZ from './events/translation';
import TRANSLATION_RESERVE_CZ from './reserve/translation';
import TRANSLATION_ACCESSIBILITY_CZ from './accessibility/translation';

export default {
  ...TRANSLATION_DATES_CZ,
  ...TRANSLATION_BOOKING_CZ,
  ...TRANSLATION_FORM_CZ,
  ...TRANSLATION_EXPERIENCES_CZ,
  ...TRANSLATION_LANDING_PAGES_CZ,
  ...TRANSLATION_EVENTS_CZ,
  ...TRANSLATION_RESERVE_CZ,
  ...TRANSLATION_ACCESSIBILITY_CZ,
};
