import { compact, keys } from 'lodash';
import values from 'lodash/values';
import moment from 'moment';
import { ICartExperience } from 'types/cart';
import { formatBulkBookingCartItemsData } from 'actions/booking';

type Props = {
  cartItems: Record<string, ICartExperience>;
  purchaserFirstName?: string;
  purchaserLastName?: string;
  discountCode?: {
    experienceIds: string[];
    code: string;
  };
};

export const getCartItemsData = ({
  cartItems,
  purchaserFirstName,
  purchaserLastName,
  discountCode,
}: Props) => {
  const items = values(cartItems).map((item) => {
    const additionalCustomQuestionResponses = compact(
      keys(item.additionalCustomQuestionResponses).map((key) => ({
        questionId: key,
        answer: item.additionalCustomQuestionResponses[key] || [],
      })),
    );
    const participants = item.participants.map((data) => {
      const p = {
        ...data,
        ...(data.fullName === 'Resource Purchaser' &&
        purchaserFirstName &&
        purchaserLastName
          ? {
              firstName: purchaserFirstName,
              lastName: purchaserLastName,
              fullName: `${purchaserFirstName} ${purchaserLastName}`,
            }
          : null),
      };

      if (!p.customQuestionResponses) {
        return p;
      }

      const customQuestionResponses = compact(
        keys(p.customQuestionResponses),
      ).map((key) => ({
        questionId: key,
        answer: p.customQuestionResponses?.[key] || [],
      }));

      return {
        ...p,
        customQuestionResponses,
      };
    });

    return {
      ...item,
      sessionDuration: moment.duration(item.sessionDuration).asMinutes(),
      additionalCustomQuestionResponses,
      participants,
    };
  });

  return formatBulkBookingCartItemsData(items, discountCode);
};
