import { Modal } from 'components/Modal';
import React, { FC, useEffect } from 'react';

import { AnswersRequest, useGuestSurvey } from '../hooks/useGuestSurvey';
import { GuestSurvey } from './GuestSurvey';

interface Props {
  open: boolean;
  cartId: string | null;
  onClose: () => void;
}

export const GuestSurveyModal: FC<Props> = ({ open, onClose, cartId }) => {
  const { survey, surveyLoaded, postAnswers } = useGuestSurvey(cartId);

  useEffect(() => {
    if (surveyLoaded && !survey) {
      onClose();
    }
  }, [survey, surveyLoaded]);

  if (!cartId || !open || !survey || !surveyLoaded || (open && !cartId)) {
    return null;
  }

  return (
    <Modal
      size="medium"
      isVisible={open}
      onClose={onClose}
      isClosable
      gap={24}
      disableDismissOnClickOutside
      content={
        <GuestSurvey
          postAnswers={(surveyId: string, anwsers: AnswersRequest) => {
            postAnswers(surveyId, anwsers);
            onClose();
          }}
          survey={survey}
        />
      }
    />
  );
};
