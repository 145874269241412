import { ProductLine } from '@kouto/types';
import { useAppState } from 'AppProvider';

export const useBrandProducts = (): ProductLine[] => {
  const { settings } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );

  return settings?.products ?? [];
};
