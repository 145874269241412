import React, { useEffect, forwardRef, ForwardedRef } from 'react';
import styled, { css } from 'styled-components';
import { withTruncationStyles } from 'components/TruncatedText/utils';
import useCustomHistory from 'hooks/use-custom-history';

interface Props {
  htmlDescription: string;
  shouldTruncate: boolean;
  maxLines?: number;
  onInitialHTMLInsert?: (value: boolean) => void;
  disableTruncation: boolean;
  customStyles?: ReturnType<typeof css>;
}

const HTMLBlockDescription = forwardRef<HTMLDivElement, Props>(
  (
    {
      htmlDescription,
      shouldTruncate,
      maxLines,
      onInitialHTMLInsert,
      disableTruncation,
      customStyles,
    },
    ref,
  ) => {
    const { push } = useCustomHistory();

    const injectHTMLInsideContent = (htmlDivElement: HTMLDivElement | null) => {
      htmlDivElement?.insertAdjacentHTML('afterbegin', htmlDescription);
      onInitialHTMLInsert?.(true);
    };

    const getReferencedParentElement = (ref: ForwardedRef<HTMLDivElement>) => {
      if (ref && 'current' in ref) {
        return ref.current;
      }
      return null;
    };

    useEffect(() => {
      const referencedElement = getReferencedParentElement(ref);

      if (referencedElement) {
        const linksArray = Array.from(
          referencedElement.getElementsByTagName('a'),
        );

        linksArray.forEach((ankerElement) => {
          const hrefLink = ankerElement.getAttribute('href');
          if (
            !hrefLink?.startsWith('https://') &&
            !hrefLink?.startsWith('http://')
          ) {
            ankerElement.setAttribute('href', `https://${hrefLink}`);
          }

          if (
            hrefLink?.includes(window.location.host) &&
            hrefLink?.includes('#!') &&
            ankerElement.getAttribute('target') !== '_blank'
          ) {
            ankerElement.addEventListener('click', (e) => {
              e.preventDefault();
              const pathname = hrefLink.split('#!')[1];

              push({ pathname });
              window.location.reload();
            });
          }
        });
      }
    }, [ref]);

    useEffect(() => {
      const referencedElement = getReferencedParentElement(ref);

      if (disableTruncation) {
        injectHTMLInsideContent(referencedElement);
        return;
      }

      if (referencedElement && referencedElement.innerHTML) {
        referencedElement.replaceChildren();
        referencedElement.setAttribute(
          'style',
          shouldTruncate ? 'display: -webkit-box;' : 'display: block;',
        );
      }
      injectHTMLInsideContent(referencedElement);
    }, [htmlDescription, shouldTruncate, disableTruncation, ref]);

    if (disableTruncation) {
      return <ModalDescriptionSection ref={ref} />;
    }

    return (
      <TruncatedDescription
        ref={ref}
        data-max-lines={maxLines}
        className="experience-view__html-block-description"
        shouldTruncate={shouldTruncate}
        maxLines={maxLines}
        customStyles={customStyles}
      />
    );
  },
);

export const DescriptionSection = styled.div`
  font-size: 14px;
  line-height: 32px;
  margin-top: 0;
  min-height: 10px;
  margin-bottom: 2.5px;
  color: var(--way-colors-primaryTextColor);

  & > p {
    font-size: 14px;
    line-height: 32px;
    margin-top: 0;
    white-space: pre-wrap;
    min-height: 10px;
    margin-bottom: 2.5px;
  }

  & > ol,
  & > ul {
    white-space: normal;
    padding-left: 20px;
  }

  & > *:last-child {
    margin-bottom: 0px;
  }
`;

const ModalDescriptionSection = styled(DescriptionSection)`
  color: var(--way-palette-black-100) !important;
`;

const TruncatedDescription = withTruncationStyles(DescriptionSection);

export default HTMLBlockDescription;
