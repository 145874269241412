import { ResourceGroup, Session } from 'types/listings';
import React, { ComponentProps, useMemo, useState } from 'react';
import { CollapsibleResource } from 'components/CollapsibleResource';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PriceTierWithParticipants } from 'components/Booking/PriceTier/types';
import { IPriceTier } from 'types/experience.types';
import { SessionTimeOption } from 'features/Reserve/hooks/useSessionTimeOptions';
import moment from 'moment/moment';
import { DATE_FORMAT, serializeParams, TIME_FORMAT } from 'utils';
import { BOOKING_MODES } from 'types/booking';
import { filterEmpty } from 'actions/helpers';
import useSearchQueryParams from 'hooks/use-search-params';
import { useDispatch } from 'AppProvider';
import {
  integrateSessionParticipants,
  setTierParticipants,
} from 'actions/participants';
import { useTierParticipants } from 'hooks/use-selected-participants';
import { useResourceMapContext } from 'features/Reserve/contexts/ResourceMapContext';
import { analyticsManager } from 'features/analytics';
import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';
import { getProductFromResourceGroups } from 'features/analytics/services/analytics.helpers';
import useBrandToggleFeature from '../../BrandToggleFeature/use-brand-toggle-feature';
import { SessionDateAndDuration } from '../../CollapsibleResource/CollapsibleResource';

type PriceTier = IPriceTier & {
  id: string;
};

interface Props {
  collectionId: string;
  group: ResourceGroup;
  sessions: Session[];
  showTruncatedView?: boolean;
  allSessionTimes: SessionTimeOption[];
  latest: boolean;
  handleGroupSelect: (groupId: string) => void;
  collectionName?: string;
  categoryName?: string;
}

export const ResourceGroupCard: React.FC<Props> = ({
  collectionId,
  group,
  sessions,
  showTruncatedView,
  allSessionTimes,
  latest,
  handleGroupSelect,
  collectionName,
  categoryName,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const { resource, ...props } = useMemo(() => {
    const priceTiers = sessions
      .flatMap<PriceTier>((e) => (e ? e.priceTiers ?? [] : []))
      .sort((a, b) => a.price - b.price);
    const startingPrice = priceTiers[0]?.price || 0;
    const displayedCapacity = Number(group.experiences?.[0]?.displayedCapacity);

    return {
      resource: group.experiences[0],
      resourceImage: group.pictures[0],
      initialPrice: `${startingPrice}`,
      capacityLabel:
        displayedCapacity > 0
          ? `${displayedCapacity} ${translate(
              displayedCapacity === 1 ? 'person' : 'people',
            )}`
          : '',
    };
  }, [sessions, group, translate]);

  const [participantsPerTier, setParticipantsPerTier] = useState<
    Record<string, number>
  >({});

  const priceTierParticipants = useMemo<PriceTierWithParticipants[]>(() => {
    return (
      sessions[0]?.priceTiers?.map((t) => ({
        ...t,
        participants: participantsPerTier[t.id] ?? 0,
      })) || []
    );
  }, [participantsPerTier, sessions]);

  const onParticipantsChange: ComponentProps<
    typeof CollapsibleResource
  >['onParticipantsChange'] = (tierId, increment) => {
    if (!participantsPerTier[tierId]) {
      participantsPerTier[tierId] = 0;
    }

    participantsPerTier[tierId] += increment;

    setParticipantsPerTier({ ...participantsPerTier });
  };

  const isCartFeatureEnabled = useBrandToggleFeature('shoppingCart');
  const { searchParams } = useSearchQueryParams();
  const [, setSelectedTierCustomers] = useTierParticipants();

  const onReserveClick = (session: SessionDateAndDuration) => {
    const noOfGuests = priceTierParticipants.reduce(
      (sum, data) => sum + data.participants,
      0,
    );
    if (noOfGuests === 0) {
      return;
    }
    const bookingData = {
      mode: BOOKING_MODES.SHARED,
      noOfGuests,
      sessionDate: moment(session.startDateTime).format(DATE_FORMAT),
      sessionTime: moment(session.startDateTime).format(TIME_FORMAT),
      duration: moment.duration(session.duration, 'minutes').toISOString(),
      supportedParticipantsCount: group.experiences.length,
      groupId: group.id,
      ...(isCartFeatureEnabled ? searchParams : {}),
    };

    const participants = priceTierParticipants.flatMap((p) => {
      if (!p.participants) {
        return [];
      }
      return [
        {
          ...p,
          selectedNumber: p.participants,
        },
      ];
    });

    setSelectedTierCustomers({
      selectedParticipants: participants,
      currentExperience: resource.id,
      groupTitle: group.title,
    });

    dispatch(
      integrateSessionParticipants({
        session,
      }),
    );

    dispatch(
      setTierParticipants({
        participants,
        currentExperience: resource.id,
        groupTitle: group.title,
      }),
    );

    if (latest) {
      bookingData.latest = true;
    }
    const filteredBookingData = filterEmpty(bookingData);

    history.push({
      pathname: `/e/${resource.id}/${
        isCartFeatureEnabled ? 'participants' : 'booking'
      }`,
      search: serializeParams(filteredBookingData),
    });
  };

  const { focusResources, unfocusResources } = useResourceMapContext();

  const handleMouseEnter = () => {
    focusResources(group.experiences.map(({ id }) => id));
  };

  const handleMouseLeave = () => {
    unfocusResources(group.experiences.map(({ id }) => id));
  };

  const handleSelection = (groupId: string) => {
    analyticsManager.sendEvent(ANALYTICS_EVENT.CLICK_EXPERIENCE, {
      [ANALYTICS_PROPERTY.ExperienceId]: group?.id,
      [ANALYTICS_PROPERTY.ExperienceTitle]: group?.title,
      [ANALYTICS_PROPERTY.ExperienceCategory]: categoryName,
      [ANALYTICS_PROPERTY.ListId]: collectionId,
      [ANALYTICS_PROPERTY.ListName]: collectionName,
      [ANALYTICS_PROPERTY.Products]: JSON.stringify(
        getProductFromResourceGroups({
          groups: [group],
          categoryName,
        }),
      ),
    });
    handleGroupSelect(groupId);
  };

  return (
    <CollapsibleResource
      collectionId={collectionId}
      group={group}
      title={group.title}
      allSessionTimes={allSessionTimes}
      description={group.description}
      includedItems={group.included}
      sessions={sessions}
      {...props}
      showTruncatedView={showTruncatedView}
      onReserveClick={onReserveClick}
      priceTiers={priceTierParticipants}
      onParticipantsChange={onParticipantsChange}
      handleGroupSelect={handleSelection}
      onCardClick={
        showTruncatedView ? () => handleSelection(group.id) : undefined
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};
