import * as appTypes from 'types/app';

export const DELETE_DISCOUNT_CODE = '@discount-code/delete-discount-code';

export const VALIDATE_DISCOUNT_CODE_REQUEST =
  '@discount-code/validate-discount-code-request';
export const VALIDATE_DISCOUNT_CODE_SUCCESS =
  '@discount-code/validate-discount-code-success';
export const VALIDATE_DISCOUNT_CODE_FAILURE =
  '@discount-code/validate-discount-code-failure';

export interface DiscountCode {
  discountCode: {
    code: string;
    type: string;
    value: number;
    experienceIds: string[];
  };
  responseStatus: number;
  error: string;
  validationMessages: string;
  validateDiscountCodeStatus: (typeof appTypes)[keyof typeof appTypes];
}
