import React, { ReactNode } from 'react';
import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';
import { analyticsManager } from 'features/analytics';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  analyticEvent?: ANALYTICS_EVENT;
  analyticData?: Partial<Record<ANALYTICS_PROPERTY, string>>;
  isPrimary?: boolean;
  big?: boolean;
  selected?: boolean;
}

const BaseButton: React.FC<ButtonProps> = ({
  children,
  analyticEvent,
  analyticData,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  isPrimary,
  selected,
  big,
  /* eslint-enable @typescript-eslint/no-unused-vars */
  ...props
}) => {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={props.type || 'button'}
      {...props}
      onClick={(e) => {
        if (analyticEvent) {
          analyticsManager.sendEvent(analyticEvent, analyticData);
        }
        props.onClick?.(e);
      }}
    >
      {children}
    </button>
  );
};

export default BaseButton;
