import fetchApi from 'utils/fetch';
import * as types from 'types/discount-code';
import * as helpers from './helpers';

export const deleteDiscountCode = () => ({
  type: types.DELETE_DISCOUNT_CODE,
});
/**
 * Create
 */
const validateDiscountCodeRequest = () => ({
  type: types.VALIDATE_DISCOUNT_CODE_REQUEST,
});

const validateDiscountCodeSuccess = (data) => ({
  type: types.VALIDATE_DISCOUNT_CODE_SUCCESS,
  payload: {
    discountCode: data,
  },
});

export const validateDiscountCode =
  (couponCode, brandId, payload) => async (dispatch) => {
    dispatch(validateDiscountCodeRequest());
    try {
      const { data } = await fetchApi
        .post(`v1/brands/${brandId}/coupon-codes/${couponCode}/validate`, {
          json: payload,
        })
        .json();
      return dispatch(validateDiscountCodeSuccess(data));
    } catch (errorResponse) {
      return dispatch(
        helpers.errorAction(
          types.VALIDATE_DISCOUNT_CODE_FAILURE,
          errorResponse,
        ),
      );
    }
  };
