import * as types from 'types/participants';

export const setTierParticipants = ({
  participants,
  currentExperience,
  groupTitle = '',
}) => ({
  type: types.SET_TIER_PARTICIPANTS,
  payload: {
    selectedParticipants: participants,
    currentExperience,
    groupTitle,
  },
});

export const applyTierParticipants = ({ label }) => ({
  type: types.APPLY_TIER_PARTICIPANTS,
  payload: {
    label,
  },
});

export const integrateSessionParticipants = ({ session }) => ({
  type: types.INTEGRATE_SESSION_PARTICIPANTS,
  payload: {
    session,
  },
});

export const deductTierParticipants = ({ label }) => ({
  type: types.DEDUCT_TIER_PARTICIPANTS,
  payload: {
    label,
  },
});

export const resetTierParticipants = () => ({
  type: types.RESET_TIER_PARTICIPANTS,
});
