import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useAppState } from 'AppProvider';
import { MainTitle, P1 } from 'components/theme/Typography';
import { Wrapper } from 'components/theme/Wrapper';
import { Button } from 'components/theme/Button';

const GuestCancellationConfirmationPage: FC = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { settings } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );

  const onViewAllListings = () => {
    history.push({
      pathname: `/e/`,
      search: '',
    });
  };

  return (
    <div>
      <Helmet>
        <title>
          Experience Cancelled {settings?.name ? `| ${settings.name}` : ''}
        </title>
      </Helmet>
      <Wrapper>
        <Center>
          <Heading>{translate('reservationCancelled')}</Heading>
          <P1>{translate('yourRefundWillBeProcessedToTheOriginalPayment')}</P1>
          <LinkBackButton onClick={onViewAllListings}>
            {translate('viewAll')}
          </LinkBackButton>
        </Center>
      </Wrapper>
    </div>
  );
};

export default GuestCancellationConfirmationPage;

const Heading = styled(MainTitle)`
  font-weight: normal;
  padding-top: 64px;
  margin-bottom: 30px;
  padding-bottom: 0;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  display: block;
`;

const Center = styled.div`
  text-align: center;
`;

const LinkBackButton = styled(Button)`
  border: none;
  outline: none;
  margin-top: 30px;
  display: inline-block;
`;
