export default {
  date: 'Data',
  weekdays: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
  months: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ],
  formattedShortMonths: [
    'Gen',
    'Feb',
    'Mar',
    'Apr',
    'Mag',
    'Giu',
    'Lug',
    'Ago',
    'Set',
    'Ott',
    'Nov',
    'Dic',
  ],
  weekdays_calendar: {
    Sun: 'Dom',
    Mon: 'Lun',
    Tue: 'Mar',
    Wed: 'Mer',
    Thu: 'Gio',
    Fri: 'Ven',
    Sat: 'Sab',
  },
  weekdays_booking_container: {
    Sunday: 'Domenica',
    Monday: 'Lunedì',
    Tuesday: 'Martedì',
    Wednesday: 'Mercoledì',
    Thursday: 'Giovedì',
    Friday: 'Venerdì',
    Saturday: 'Sabato',
  },
};
