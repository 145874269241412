import React, { FC, useState, useMemo, useCallback } from 'react';
import PrevArrowIcon from 'assets/chevron-left';
import { useTranslation } from 'react-i18next';
import { Flex } from 'components/common/styled/common-styled';
import { CartIcon } from 'features/ShoppingCart/components/CartIcon/CartIcon';
import {
  LinksBack,
  NavigationHeaderContainer,
  IconBack,
  BackLabel,
} from './NavigationHeader.styles';
import { useNavigationBack } from './useNavigationBack';

type Props = {
  goBackLabel?: string;
  showCart?: boolean;
  onGoBack?: () => void;
  preventDefaultBack?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export const NavigationHeader: FC<Props> = ({
  goBackLabel,
  showCart = true,
  preventDefaultBack,
  className,
  onGoBack,
  style,
}) => {
  const [hovered, setHovered] = useState(false);
  const { t: translate } = useTranslation();
  const label = useMemo(
    () => goBackLabel || translate('back'),
    [goBackLabel, translate],
  );
  const goBack = useNavigationBack();

  const onBack = useCallback(
    (e?: React.MouseEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
      }

      onGoBack?.();
      if (!preventDefaultBack) {
        goBack();
      }
    },
    [preventDefaultBack, onGoBack, goBack],
  );

  return (
    <NavigationHeaderContainer
      role="navigation"
      style={style}
      className={className}
    >
      <Flex
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        tabIndex={0}
        role="button"
        onClick={onBack}
        onKeyDown={(e) => e.key === 'Enter' && onBack()}
        style={{ paddingRight: 8 }}
      >
        <LinksBack
          role="button"
          aria-label={translate('goBack')}
          title={label}
          className="header__back-button"
          onClick={onBack}
        >
          <IconBack className="header__back-button-icon">
            <PrevArrowIcon
              color={`var(--way-colors-contrastColorShades-${
                hovered ? 100 : 70
              })`}
            />
          </IconBack>
        </LinksBack>
        <BackLabel hovered={hovered} onClick={onBack}>
          {label}
        </BackLabel>
      </Flex>
      {showCart && <CartIcon />}
    </NavigationHeaderContainer>
  );
};
