import { loadStripe } from '@stripe/stripe-js';
import { useAppState } from 'AppProvider';
import { useMemo } from 'react';
import { PAYMENT_PLATFORMS } from 'types/brand';

const useStripePromise = () => {
  const { settings } = useAppState((state) => state.brand);
  const publishableKey =
    settings.paymentPlatform === PAYMENT_PLATFORMS.STRIPE_EU
      ? process.env.KOUTO_EU_STRIPE_API_KEY
      : process.env.KOUTO_STRIPE_API_KEY;

  const stripePromise = useMemo(
    () => loadStripe(publishableKey),
    [publishableKey],
  );

  return stripePromise;
};

export default useStripePromise;
