// Examples:
// console.log(incrementalLabelToNumber('A')) // Output: 0
// console.log(incrementalLabelToNumber('C')) // Output: 2
// console.log(incrementalLabelToNumber('AA')) // Output: 26
// console.log(incrementalLabelToNumber('AAA')) // Output: 702
export const incrementalLabelToNumber = (label: string): number => {
  const base = 26; // number of letters in the alphabet
  return (
    label
      .toUpperCase()
      .split('')
      .reverse()
      .map((l, i) => (l.charCodeAt(0) - 64) * base ** i)
      .reduce((a, b) => a + b, 0) - 1
  );
};

const formatExperienceNumber = (number: string) => {
  return number.replace('#', '').trim().padStart(10, '0');
};

export const sortResourcesTitlesfn = (
  title1: string,
  title2: string,
): number => {
  return Math.abs(incrementalLabelToNumber(formatExperienceNumber(title1))) <
    Math.abs(incrementalLabelToNumber(formatExperienceNumber(title2)))
    ? 1
    : -1;
};
