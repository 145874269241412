import React from 'react';

const ChevronLeftIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={8 * scale}
      height={14 * scale}
      viewBox="0 0 8 14"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.646484 6.74645L6.74648 0.646446L7.45359 1.35355L1.70714 7.1L7.45359 12.8464L6.74648 13.5536L0.646484 7.45355V6.74645Z"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
    </svg>
  );
};

export default ChevronLeftIcon;
