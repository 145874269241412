import React, { HTMLAttributes, ReactNode, useRef } from 'react';
import styled from 'styled-components';
import { SecondaryButton } from 'components/theme/Button';

interface Props
  extends Omit<
    HTMLAttributes<HTMLInputElement>,
    'name' | 'className' | 'type'
  > {
  name: string;
  disabled?: boolean;
  checked?: boolean;
  value?: string | number;
  children: ReactNode;
}

const RadioItemUi = ({ name, children, disabled, ...props }: Props) => (
  <RadioWrapper className="way__radio-component__wrapper">
    <LabelWrap className={disabled ? 'label-disabled' : ''}>
      <Input
        name={name}
        disabled={disabled}
        className={disabled ? 'input-disabled' : ''}
        type="radio"
        {...props}
      />
      <Mark />
      <SpanChild
        checked={props.checked ?? false}
        className="way__radio-component__span-child"
      >
        {children}
      </SpanChild>
    </LabelWrap>
  </RadioWrapper>
);

export const RadioButtonItem = ({
  name,
  children,
  disabled,
  checked,
  ...props
}: Props) => {
  const radioRef = useRef<HTMLInputElement>(null);

  return (
    <RadioBtnWrapper
      className="way__radio-component__wrapper"
      selected={checked}
      disabled={disabled}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && radioRef.current) {
          e.preventDefault();
          e.stopPropagation();
          radioRef.current.click();
        }
      }}
    >
      <BtnLabelWrap>
        <Input
          ref={radioRef}
          name={name}
          disabled={disabled}
          className={disabled ? 'input-disabled' : ''}
          type="radio"
          {...props}
        />
        {children}
      </BtnLabelWrap>
    </RadioBtnWrapper>
  );
};

export default function RadioButton({
  asBtn,
  ...props
}: Props & { asBtn?: boolean }) {
  if (asBtn) {
    return <RadioButtonItem {...props} />;
  }

  return <RadioItemUi {...props} />;
}

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  & .label-disabled {
    cursor: not-allowed !important;
    opacity: 0.4 !important;
  }
  & .input-disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

const RadioBtnWrapper = styled(SecondaryButton)`
  cursor: pointer;
  padding: 0;
  width: 100%;
  display: grid;
  place-items: center;

  > * {
    cursor: pointer;
  }
`;

const Mark = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  left: 0;
  top: 0;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    background-color: var(--way-colors-primaryColorShades-100);
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: all 110ms;
  }
`;

const SpanChild = styled.span<{ checked?: boolean }>`
  position: relative;
  display: block;
  width: 100%;
  color: ${({ checked }) =>
    checked
      ? 'var(--way-colors-primaryColorContrastShades-100)'
      : 'var(--way-colors-secondaryColorContrastShades-100)'};
`;

const Input = styled.input`
  position: absolute;
  visibility: hidden;
  display: none;
  &:checked + ${Mark} {
    + ${SpanChild} {
      color: var(--way-colors-primaryColorContrastShades-100);
    }
    &::after {
      opacity: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

const LabelWrap = styled.label<{ checked?: boolean }>`
  display: flex;
  padding: 6px 6px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  background-color: ${({ checked }) =>
    checked
      ? 'var(--way-colors-primaryColorShades-100)'
      : 'var(--way-colors-secondaryColorShades-100)'};
  align-items: center;
  width: 100%;
  text-align: center;
  border-radius: 2px;
  font-weight: normal;
  opacity: 1;
  cursor: pointer;
`;

const BtnLabelWrap = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
`;
