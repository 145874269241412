import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Select from 'react-select';
import { selectStyles } from './Styles';
import { IFilterProps } from './types';
import { useFilterState } from './useFilterState';
import { Option, SelectContainer } from './CustomComponents';
import { SelectButton } from './SelectButton';

export const Filter: React.FC<IFilterProps> = (props) => {
  const { t: translate } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    const node = document.getElementById(`filter-${props.name}`);

    if (node && node.contains(event.target as Node)) {
      return;
    }

    setIsOpen(false);
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const {
    name,
    label,
    placeholder,
    options: rawOptions,
    initialValues,
    onChange,
    isMulti = false,
    closeMenuOnSelect = true,
    useQueryParams,
  } = props;

  const { values, onValuesChange, options } = useFilterState({
    key: `${name}Ids`,
    rawOptions,
    initialValues,
    useQueryParams,
    onChange,
    isMulti,
  });

  return (
    <Dropdown
      id={`filter-${name}`}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <SelectButton
          onClick={() => setIsOpen(!isOpen)}
          label={translate(label)}
          value={values}
        />
      }
    >
      <Select
        autoFocus
        placeholder={translate(placeholder)}
        components={{
          Option,
          SelectContainer,
          IndicatorsContainer: () => null,
        }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen={isOpen}
        name={translate(label)}
        options={options}
        onChange={onValuesChange}
        value={values ?? []}
        styles={selectStyles}
        isMulti={isMulti}
        isSearchable
        closeMenuOnSelect={closeMenuOnSelect}
      />
    </Dropdown>
  );
};

const Menu = styled.div`
  margin: 5px 0 0 6px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  box-shadow: var(--way-design-boxShadow-s);
  background-color: var(--way-palette-white-100);
  border: 0.5px solid var(--way-palette-black-5);
  border-bottom-width: 0;
  position: absolute;
  z-index: 100;
`;

const Blanket = styled.div`
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: 'fixed';
  z-index: 1;
`;

const Dropdown = ({
  id,
  children,
  isOpen,
  target,
  onClose,
}: {
  id: string;
  children?: React.ReactNode;
  readonly isOpen: boolean;
  readonly target: React.ReactNode;
  readonly onClose: () => void;
}) => (
  <div id={id} style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
