import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite/no-important';
// import { withStyles, css } from 'react-with-styles';
// import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';

ThemedStyleSheet.registerInterface(aphroditeInterface);

// console.log(DefaultTheme);
// ThemedStyleSheet.registerTheme({
//   reactDates: {
//     ...DefaultTheme.reactDates,
//     color: {
//       ...DefaultTheme.reactDates.color,
//       highlighted: {
//         backgroundColor: '#f60404',
//         backgroundColor_active: '#f60404',
//         backgroundColor_hover: '#f60404',
//         color: '#f60404',
//         color_active: '#f60404',
//         color_hover: '#f60404',
//       },
//     },
//   },
// });

// console.log(DefaultTheme);
