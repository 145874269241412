import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  PropsWithChildren,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  ResponsiveFlex,
  Sep,
} from 'components/common/styled/common-styled';
import { defaultBreakpoints, useIsMobile } from 'WindowDimensionProvider';
import useResourceBookingSession from 'features/Reserve/hooks/useResourceBookingSession';
import {
  ResourceHeading2,
  ResourceParagraph,
} from 'components/theme/Typography/ResourceTypography';
import styled from 'styled-components';
import ResourceGroupSummary from 'components/Resource/ResourceGroupSummary';
import { PriceTierWithParticipants } from 'components/Booking/PriceTier/types';
import { listingShouldGoThroughParticipantsPage } from 'utils/listings';
import { useSessionTimeOptions } from 'features/Reserve/hooks/useSessionTimeOptions';
import NonMappedSessionSelector from 'components/Resource/NonMappedSessionSelector';
import { CartItemState } from 'types/cart';
import { NonMappedPriceTierSelector } from 'components/Resource/ResourcePriceTierSelector';
import { PrimaryButton, SecondaryButton } from 'components/theme/Button';
import moment from 'moment';
import { DATE_FORMAT, formatUTCDate, TIME_FORMAT } from 'utils';
import {
  integrateSessionParticipants,
  setTierParticipants,
} from 'actions/participants';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import { useTierParticipants } from 'hooks/use-selected-participants';
import { useDispatch } from 'AppProvider';
import { DateSelector } from 'components/DatePicker/DateSelector';
import useAvailableDates from 'features/Reserve/hooks/useAvailableDates';
import { SkeletonPulse } from 'components/theme/Skeleton/Skeleton';
import { useCart } from 'hooks/useCart';
import { BookingMode } from 'types/payment';
import ResourceAccessCode, {
  AccessCodeHeading,
} from 'components/Resource/ResourceAccessCode';
import { validateAccessCode } from 'actions/access-code';
import { VALIDATE_ACCESS_CODE_FAILURE } from 'types/access-code';
import { BottomDrawer, BottomDrawerProps } from 'components/BottomDrawer';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';
import {
  getAnalyticsDataFromCartItem,
  getProductFromResourceGroups,
} from 'features/analytics/services/analytics.helpers';
import { analyticsManager } from 'features/analytics';

interface Props extends BottomDrawerProps {}

export const NonMappedGroupContent: React.FC<Props> = (props) => {
  const {
    isLoadingCollection,
    resourceGroup,
    collectionId,
    date,
    sessions,
    collection,
    groupId,
    setResourceBookingSessionParams,
    time,
    duration,
    latest,
    isLoading,
  } = useResourceBookingSession();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const allSessionTimes = useSessionTimeOptions(sessions);
  const isMobileView = useIsMobile();
  const { onAddToCart } = useCart();
  const [dateForCalendarAvailability, setDateForCalendarAvailability] =
    useState<moment.Moment>(date ? moment(date) : moment());

  useEffect(() => {
    if (date) {
      setDateForCalendarAvailability(moment(date));
    }
  }, [date]);

  const { availableDates, isLoadingAvailableDates } = useAvailableDates({
    from: dateForCalendarAvailability
      .clone()
      .startOf('month')
      .format(DATE_FORMAT),
    to: dateForCalendarAvailability.clone().endOf('month').format(DATE_FORMAT),
    collectionId: collection?.id,
    resourceGroupId: groupId,
    latest,
  });

  const handleDateChange = useCallback(
    (date: string) => {
      setResourceBookingSessionParams({
        date,
      });
    },
    [setResourceBookingSessionParams],
  );

  const isCartFeatureEnabled = useBrandToggleFeature('shoppingCart');
  const [, setSelectedTierCustomers] = useTierParticipants();

  const [isCodeValidated, setIsAccessCodeValidated] = useState(false);
  const [accessCodeError, setAccessCodeError] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleDateSelectorFocusChange = ({ focused }: { focused: boolean }) =>
    setShowDatePicker(focused);

  const handleSessionChange = useCallback(
    (time: string, duration: number) => {
      setResourceBookingSessionParams({
        time,
        duration,
      });
    },
    [setResourceBookingSessionParams],
  );

  const [participantsPerTier, setParticipantsPerTier] = useState<
    Record<string, number>
  >({});

  const onParticipantsChange = (tierId: string, increment: number) => {
    if (!participantsPerTier[tierId]) {
      participantsPerTier[tierId] = 0;
    }
    participantsPerTier[tierId] += increment;
    setParticipantsPerTier({ ...participantsPerTier });
  };

  const selectedSession = allSessionTimes.find(
    (sess) => sess.value === `${time}-${duration}`,
  );

  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_EXPERIENCE_DETAIL,
    payload: {
      [ANALYTICS_PROPERTY.ExperienceId]: resourceGroup?.id,
      [ANALYTICS_PROPERTY.ExperienceTitle]: resourceGroup?.title,
      [ANALYTICS_PROPERTY.Products]: JSON.stringify(
        getProductFromResourceGroups({
          groups: resourceGroup ? [resourceGroup] : [],
          categoryName: collection?.category?.name,
          session: selectedSession?.original,
        }),
      ),
    },
    optionalPayload: {
      [ANALYTICS_PROPERTY.ExperienceCategory]: collection?.category?.name,
      [ANALYTICS_PROPERTY.ListId]: collection?.id,
      [ANALYTICS_PROPERTY.ListName]: collection?.title,
    },
    isNotReady: !resourceGroup?.id,
  });

  const priceTierParticipants = useMemo<
    (PriceTierWithParticipants & { selectedNumber: number })[]
  >(() => {
    return (
      selectedSession?.original.priceTiers?.map((t) => ({
        ...t,
        selectedNumber: participantsPerTier[t.id] ?? 0,
        participants: participantsPerTier[t.id] ?? 0,
      })) || []
    );
  }, [participantsPerTier, selectedSession]);

  const maxParticipants = useMemo(() => {
    if (selectedSession?.original) {
      return sessions.filter(
        (session) =>
          selectedSession.original.startDateTime === session.startDateTime &&
          selectedSession.original.duration === session.duration,
      ).length;
    }

    return 0;
  }, [sessions, selectedSession]);

  const selectedParticipants = useMemo(() => {
    return priceTierParticipants.reduce((sum, tier) => {
      return sum + tier.participants;
    }, 0);
  }, [priceTierParticipants]);

  if (isLoadingCollection) {
    return <div>{translate('loading')}...</div>;
  }

  if (!resourceGroup || !collection) {
    return <h1>{translate('noData')}</h1>;
  }

  const { hasAddOns, askPartiticpantsInfo, hasCustomQuestions } =
    listingShouldGoThroughParticipantsPage(
      collection,
      resourceGroup.id,
      resourceGroup.experiences[0].id,
    );

  const isAccessCodeValidated = resourceGroup.isExclusive
    ? isCodeValidated
    : true;

  const hasANextStep = hasAddOns || askPartiticpantsInfo || hasCustomQuestions;

  const syncToCart = async (
    route?: string,
    state: CartItemState = CartItemState.READY,
    overrideSearch = '',
  ) => {
    const session = selectedSession?.original;
    if (!session) {
      return false;
    }
    const resource = resourceGroup.experiences[0];
    const noOfGuests = priceTierParticipants.reduce(
      (sum, data) => sum + data.participants,
      0,
    );
    if (noOfGuests === 0) {
      return false;
    }

    const participants = priceTierParticipants.flatMap((p) => {
      if (!p.participants) {
        return [];
      }
      return [
        {
          ...p,
          selectedNumber: p.participants,
        },
      ];
    });

    setSelectedTierCustomers({
      selectedParticipants: participants,
      currentExperience: resource.id,
      groupTitle: resourceGroup.title,
    });

    dispatch(
      integrateSessionParticipants({
        session,
      }),
    );

    dispatch(
      setTierParticipants({
        participants,
        currentExperience: resource.id,
        groupTitle: resourceGroup.title,
      }),
    );

    const cartData = {
      participants: priceTierParticipants
        .flatMap((p) => {
          if (!p.participants) {
            return [];
          }
          return new Array(p.participants).fill(p);
        })
        .map((p) => ({
          fullName: askPartiticpantsInfo ? '' : 'Resource Purchaser',
          firstName: askPartiticpantsInfo ? '' : 'Resource',
          lastName: askPartiticpantsInfo ? '' : 'Purchaser',
          terms: false,
          price: p.price,
          priceTierName: p.name,
        })),
      notes: '',
      state,
      bookingMode: BookingMode.SHARED,
      listingId: collectionId,
      experienceId: resource?.id,
      groupId,
      additionalCustomQuestionResponses: {},
      sessionDateTime: `${date}T${
        session.startDateTime
          ? moment(session.startDateTime).format(TIME_FORMAT)
          : undefined
      }`,
      sessionDuration: session.duration
        ? moment.duration(session.duration, 'minutes').toISOString()
        : '',
    };

    if (state === CartItemState.READY) {
      analyticsManager.sendEvent(
        !route
          ? ANALYTICS_EVENT.CLICK_ADD_TO_CART_BUTTON
          : ANALYTICS_EVENT.CLICK_PROCEED_TO_CHECKOUT_BUTTON,
        getAnalyticsDataFromCartItem({
          cartData,
          collection,
          resourceGroup,
        }),
      );
    }

    return onAddToCart(cartData, route, overrideSearch);
  };

  const withAccessCodeValidation = async (
    code: string,
    onSuccess: CallableFunction,
  ) => {
    if (!resourceGroup.isExclusive || isAccessCodeValidated) {
      onSuccess();
      return;
    }

    if (!code) {
      setAccessCodeError('Access code is required');
      return;
    }
    const response = await dispatch(
      validateAccessCode({
        code,
        brandId: collection?.brandId,
        experienceId: resourceGroup.experiences[0].id,
        sessionDateTime: formatUTCDate(date, time),
        isCartFeatureEnabled,
      }),
    );
    if (response.type === VALIDATE_ACCESS_CODE_FAILURE) {
      setAccessCodeError('Invalid code');
      return;
    }
    onSuccess();
  };

  const onContinue = () => {
    if (hasAddOns) {
      syncToCart('/addons/{cartItemId}', CartItemState.PENDING);
    } else {
      const params = new URLSearchParams();
      params.set(
        'skipParticipantsName',
        askPartiticpantsInfo ? 'false' : 'true',
      );
      if (date) {
        params.set('date', date);
      }
      if (latest) {
        params.set('latest', 'true');
      }
      if (latest) {
        params.set('latest', 'true');
      }
      const resource = resourceGroup.experiences[0];
      syncToCart(
        `/e/${resource.id}/${
          isCartFeatureEnabled ? 'participants' : 'booking'
        }/{cartItemId}`,
        CartItemState.PENDING,
        params.toString(),
      );
    }
  };

  const addToCart = async () => {
    if (await syncToCart()) {
      setResourceBookingSessionParams({
        groupId: undefined,
      });
    }
  };

  const onCheckout = () => {
    syncToCart('/checkout');
  };

  if (isMobileView && !isAccessCodeValidated) {
    return (
      <BottomDrawer {...props} heading={<AccessCodeHeading />}>
        <ResourceAccessCode
          errorMessage={accessCodeError}
          withHeading={false}
          isExclusive
          onContinue={(code) =>
            withAccessCodeValidation(code, () => {
              setIsAccessCodeValidated(true);
            })
          }
        />
      </BottomDrawer>
    );
  }

  return (
    <NonMappedGroupWrapper {...props}>
      <ResponsiveFlex
        direction="row"
        justifyContent="space-between"
        alignItem="flex-start"
        gap={72}
      >
        <ResponsiveFlex
          flex={1}
          justifyContent="flex-start"
          alignItem="flex-start"
          direction="column"
          gap={36}
        >
          {isMobileView ? null : (
            <>
              <ResponsiveFlex
                direction="column"
                alignItem="flex-start"
                gap={12}
              >
                <ResourceHeading2 className="resource-title">
                  {resourceGroup.title}
                </ResourceHeading2>
                <DateSelectorWrapper>
                  {isMobileView ? null : (
                    <DateSelector
                      date={date ?? ''}
                      loading={isLoadingAvailableDates}
                      availableDates={availableDates}
                      onMonthChange={setDateForCalendarAvailability}
                      onChange={handleDateChange}
                      onFocusChange={handleDateSelectorFocusChange}
                      showDatePicker={showDatePicker}
                    />
                  )}
                  <ResourceParagraph
                    className="current-date"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="20px"
                  >
                    {moment(date).format('dddd, MMMM Do')}
                  </ResourceParagraph>
                </DateSelectorWrapper>
              </ResponsiveFlex>

              <Sep />
            </>
          )}

          {isLoading ? (
            <Flex direction="row" gap={12}>
              <SkeletonPulse style={{ height: 43, width: 168 }} />
              <SkeletonPulse style={{ height: 43, width: 168 }} />
            </Flex>
          ) : (
            <NonMappedSessionSelector
              sessions={allSessionTimes}
              onChange={({ original }) => {
                const [, time] = original.startDateTime.split('T');
                handleSessionChange(time, original.duration);
                setParticipantsPerTier({});
              }}
              selectedSession={selectedSession}
            />
          )}

          <Sep />
          {isLoading ? (
            <Flex direction="column" gap={20} style={{ width: '100%' }}>
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItem="center"
                style={{ width: '100%' }}
              >
                <SkeletonPulse style={{ height: 46, width: 120 }} />
                <SkeletonPulse style={{ height: 24, width: 80 }} />
              </Flex>
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItem="center"
                style={{ width: '100%' }}
              >
                <SkeletonPulse style={{ height: 46, width: 120 }} />
                <SkeletonPulse style={{ height: 24, width: 80 }} />
              </Flex>
            </Flex>
          ) : (
            <NonMappedPriceTierSelector
              maxParticipants={maxParticipants}
              onChange={onParticipantsChange}
              priceTiers={priceTierParticipants}
            />
          )}
          <Sep />

          <ResourceAccessCode
            errorMessage={accessCodeError}
            isExclusive={
              isMobileView ? !isAccessCodeValidated : resourceGroup?.isExclusive
            }
            renderActionButtons={(code) => (
              <>
                {hasANextStep ? (
                  <PrimaryButton
                    big
                    disabled={!selectedParticipants}
                    onClick={() => withAccessCodeValidation(code, onContinue)}
                  >
                    {translate('continue')}
                  </PrimaryButton>
                ) : (
                  <ButtonsWrapper>
                    <SecondaryButton
                      disabled={!selectedParticipants}
                      onClick={() => withAccessCodeValidation(code, addToCart)}
                      className="add-to-cart-button"
                    >
                      {translate('addToCart')}
                    </SecondaryButton>

                    <PrimaryButton
                      className="checkout-button"
                      disabled={!selectedParticipants}
                      onClick={() => withAccessCodeValidation(code, onCheckout)}
                    >
                      {translate('checkout')}
                    </PrimaryButton>
                  </ButtonsWrapper>
                )}
              </>
            )}
          />
        </ResponsiveFlex>

        {isMobileView ? null : (
          <ResourceGroupSummary
            priceTiers={priceTierParticipants}
            resourceGroup={resourceGroup}
          />
        )}
      </ResponsiveFlex>
    </NonMappedGroupWrapper>
  );
};

const NonMappedGroupWrapper = ({
  children,
  ...props
}: PropsWithChildren<BottomDrawerProps>) => {
  const isMobileView = useIsMobile();
  if (isMobileView) {
    return (
      <BottomDrawerWrapper>
        <BottomDrawer {...props}>{children}</BottomDrawer>
      </BottomDrawerWrapper>
    );
  }
  return children;
};

const DateSelectorWrapper = styled.div`
  width: fit-content;
  height: 19px;
  position: relative;

  > *:first-child {
    background: transparent !important;
    border: 0 !important;
    padding: 0;
    margin: 0;
    position: absolute !important;
    inset: 0;

    > div > div > div {
      > button {
        opacity: 0;
      }
      > div:nth-child(2) {
        opacity: 0;
        max-height: 19px;
        * {
          max-height: 19px;
        }
        ~ div {
          top: 45px !important;
        }
      }
    }
  }
  > * {
    width: 100%;
    height: 100% !important;
    cursor: pointer;

    &:last-child {
      display: grid;
      place-items: center;
      z-index: +2;
      pointer-events: none;
      white-space: nowrap;
      text-decoration: underline;
    }
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  & button {
    width: 50%;
  }
`;

const BottomDrawerWrapper = styled.div`
  @media screen and (max-width: ${defaultBreakpoints.mobile}px) {
    display: contents;

    .drawer-header {
      height: 98px !important;
      align-items: center;
    }
  }
`;
