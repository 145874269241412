import React from 'react';

const PauseIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      width={20 * scale}
      height={20 * scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 16L6 4L7 4L7 16L6 16Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 16L13 4L14 4L14 16L13 16Z"
        fill={color}
      />
    </svg>
  );
};

export default PauseIcon;
