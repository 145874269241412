export default {
  reviewCompleteHeader: 'Completer et valider',
  participants: 'Participants',
  enterParticipantInfoText:
    'Veuillez completer les informations de tous les participants à l’expérience.',
  purchaser: 'Coordonnées de l’acheteur',
  firstName: 'Prénom',
  lastName: 'Nom',
  email: 'Email*:',
  optionalEmail: 'Email: (optionnel)',
  duplicatePurchaserText: 'Identique à l’acheteur',
  phone: 'Téléphone: (optionnel)',
  addParticipant: 'Ajouter {{tierLabel}}',
  participant: 'Participant',
  removeParticipant: 'Enlever Participant',
  experienceLimitReached: 'Désolé cette expérience est complète',
  payment: 'Paiement',
  duration: 'Durée',
  discountCode: 'Code promotionnel',
  removeCode: 'Supprimer le code',
  invalidCodeValidation: 'Code invalide',
  networkErrorDiscountCode: 'Une erreur s’est produite',
  discount: 'Réduction',
  promo: 'Promo',
  promoCode: 'Code promo',
  apply: 'Appliquer',
  total: 'Total',
  subTotal: 'Sous-total',
  includes: 'Inclus',
  and: 'et',
  addHostNotes: 'Ajouter des commentaires pour votre hôte',
  policyAggreementText:
    "J'ai lu et j'accepte les <0>conditions générales</0> de vente et de <1>confidentialité</1>",
  brandAggreementText: 'J’ai lu et j’accepte les conditions de',
  termsAggreementValidation: 'Veuillez accepter les termes',
  firstNameValidation: 'Prénom requis',
  lastNameValidation: 'Nom requis',
  emailValidation: {
    emailRequiredText: 'Email requis',
    'Invalid Email Address': 'Adresse email invalide',
    not_specified: 'Adresse électronique incorrecte',
  },
  phoneValidation: {
    'Invalid Phone Number': 'téléphone invalide',
    not_specified: 'invalide',
  },
  participantFirstNameValidation: 'Prénom du participant requis',
  participantLastNameValidation: 'Nom du participant requis',
  complete: 'Valider',
  '404Message': 'Non trouvé',
  waiting: 'reservation en cours...',
  complimentary: 'Gratuit',
  confirmationHeader: 'Vous avez réservé votre expérience',
  confirmationText:
    'Un email de confirmation va vous être envoyé avec les détails de la réservation',
  viewAll: 'Voir toutes les expériences',
  paymentMethod: 'Méthode de paiement',
  creditCard: 'Carte de crédit',
  roomCharge: 'Paiement par numéro de chambre',
  memberCharge: 'Paiement par numéro de membre',
  roomNumber: 'Numéro de chambre',
  memberNumber: 'Numéro de membre',
  chargeValidation: 'Le numéro de chambre et nom sont requis',
  memberNumberValidation: 'Le numéro de membre et nom sont requis',
  invalidRoomNumber: 'Numéro de chambre invalide',
  invalidMemberNumber: 'Numéro de membre invalide',
  cardNumberIncomplete: 'Numéro de carte incomplet',
  lastNameValidationError: 'Le nom de famille est obligatoire',
  isReqd: 'requis',
  // cart
  proceedToCheckout: 'Finaliser la commande',
  addToCart: 'Ajouter au panier',
  participantDetails: 'Liste des participants',
  editParticipantsDetails: 'Modifier les participants',
  fullName: 'Nom et prénom',
  checkout: 'Finaliser la commande',
  reservationSummary: 'Votre commande',
  editParticipants: 'Modifier',
  private: 'Privé',
  browseAllExperieces: 'Voir toutes les expériences',
  payWithCreditCard: 'Payer par carte de crédit',
  payWithRoomCharge: 'Payer par numéro de chambre',
  payWithMemberCharge: 'Payer par numéro de membre',
  payWith: 'Payer avec',
  bookBulkErrorByCard:
    "Un problème est survenu lors de la confirmation de votre réservation. Votre carte n'a pas été débitée.",
  bookBulkErrorByRoomCharge: 'Nom de famille et numéro de chambre non valides.',
  bookBulkErrorByMemberCharge:
    'Nom de famille et numéro de membre non valides.',
  reviewCancellation: 'Confirmation de l’annulation',
  selectAReservationToCancel: 'Sélectionnez une réservation à annuler',
  confirmCancellation: 'Confirmer l’annulation',
  cancellable: 'Annulable',
  cancelled: 'Annulé',
  deadlinePassed: 'Date limite dépassée',
  noCancellations: 'Non annulable',
  youPaid: 'Vous avez payé',
  refund: 'Remboursement',
  yourRefund: 'Votre remboursement',
  yourRefundWillBeProcessedToTheOriginalPayment:
    'Votre remboursement sera traité sur le paiement original.',
  thisReservationCanNoLongerBeCancelledPleaseEmail:
    'Cette réservation ne peut plus être annulée. Veuillez envoyer un email à {{brandEmail}}',
  reservationCancelled: 'Réservation annulée',
  thisReservationCannotBeCancelled:
    'Cette réservation ne peut pas être annulée',
  thisReservationHasAlreadyBeenCancelled:
    'Cette réservation a déjà été annulée',
  theHotel: 'l’Hôtel',
  cancelling: 'Annulation',
  somethingWentWrong: 'Une erreur est survenue',
  previouslyRefunded: 'Remboursé précédemment',
  remainingBalance: 'Solde restant',
};
