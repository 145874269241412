import { ProductLine } from '@kouto/types';
import { useRouteMatch } from 'react-router-dom';
import useSearchQueryParams from './use-search-params';

const sanitizeProductParam = (
  product: string | undefined,
): ProductLine | undefined => {
  if (!product) return undefined;

  switch (product) {
    case 'activate':
      return ProductLine.ACTIVATE;
    case 'host':
      return ProductLine.HOST;
    case 'reserve':
      return ProductLine.RESERVE;
    default:
      return undefined;
  }
};

export const useLandingPageRouteParams = () => {
  const match = useRouteMatch<{ product?: ProductLine; search?: string }>({
    path: ['/landing/:product/search', '/landing/:product', '/search', '/e'],
    strict: false,
  });

  const { searchParams } = useSearchQueryParams();

  const isResults =
    match?.path.includes('search') || match?.path.includes('/e');

  return {
    ...match?.params,
    product:
      match?.params.product ?? sanitizeProductParam(searchParams.product),
    isResults,
  };
};
