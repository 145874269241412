import React, { useCallback } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useBrandId } from 'AppProvider';
import useSearchQueryParams from 'hooks/use-search-params';
import { useLandingPageRouteParams } from 'hooks/useLandingPageRouteParams';
import { SecondaryButton } from 'components/theme/Button';
import useBrandCategories from 'features/LandingPage/hooks/useBrandCategories';
import IconX from 'assets/icon-x';
import { ProductsList } from './ProductsList/ProductsList';

const NoResultsPage = () => {
  const brandId = useBrandId();
  const { t: translate } = useTranslation();
  const { searchParams, replaceParams } = useSearchQueryParams();
  const params = useLandingPageRouteParams();
  const { categories } = useBrandCategories(brandId, params.product);

  const categoryIds = searchParams.categoryIds || [];
  const currentCategoryIds = Array.isArray(categoryIds)
    ? categoryIds
    : [searchParams.categoryIds];

  const hasDateFilter = !!searchParams.from && !!searchParams.to;

  let message = translate('noExperiencesFound');
  if (hasDateFilter && currentCategoryIds.length === 0) {
    message = `${translate('noExperiencesFoundFor')} "${moment(
      searchParams.from,
    ).format('MMM DD')} - ${moment(searchParams.to).format('MMM DD')}"`;
  } else if (!hasDateFilter && currentCategoryIds.length === 1) {
    const category = categories.find((c) => c.id === currentCategoryIds[0]);
    if (category) {
      message = `${translate('noExperiencesFoundFor')} "${category.name}"`;
    }
  }

  const clearFilters = useCallback(() => {
    replaceParams({});
  }, [replaceParams]);

  return (
    <Wrapper className="listing-no-result-page">
      <span className="listing-no-result-title">{message}</span>
      <SecondaryButton
        className="listing-no-result-button"
        type="button"
        onClick={clearFilters}
      >
        <IconX />
        {translate('clearFilters')}
      </SecondaryButton>
      <ProductsList title={translate('discoverExperiences')} smallTitle />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    max-width: 100%;
  }

  & > span {
    font-size: 18px;
    color: var(--way-colors-primaryTextColor);
  }

  & > button {
    margin-top: 28px;
    margin-bottom: 28px;
  }
`;

export default NoResultsPage;
