import Handlebars from 'handlebars';
import { debug } from 'utils/debug';

const SEPARATORS = ['-', '•', '▪', '|', ',', ';'];

const stripLastSeparatorIfNeeded = (str: string) => {
  if (!str) {
    return str;
  }

  if (SEPARATORS.includes(str[str.length - 1])) {
    return str.slice(0, str.length - 1);
  }

  return str;
};

Handlebars.registerHelper('repeat', (times, options) => {
  let out = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < times; i++) {
    out += options.fn(this);
  }

  return stripLastSeparatorIfNeeded(out);
});

Handlebars.registerHelper('replace', (str, search, replacement) => {
  return str.replace(search, replacement);
});

export const inflateTemplate = (content: string, data: object) => {
  try {
    debug('log', { content, data });
    const template = Handlebars.compile(content);
    return template(data);
  } catch (e) {
    debug('error', 'failed to inflate template', { content, data, e });
    return JSON.stringify({});
  }
};

export const inflateJson = (content: Record<string, unknown>, data: object) => {
  const stringPayload = inflateTemplate(JSON.stringify(content), data);

  try {
    return JSON.parse(stringPayload);
  } catch (e) {
    debug('error', 'failed to parse JSON payload', { stringPayload, e });
    return {};
  }
};
