import * as React from 'react';
import styled from 'styled-components';

interface IExperienceCountBadge {
  count: number;
}

export const ExperienceCountBadge: React.FC<IExperienceCountBadge> = ({
  count = 0,
}) => {
  if (count === 0) return null;

  return <Badge>{count}</Badge>;
};

const Badge = styled.div`
  position: absolute;
  left: -8px;
  top: -8px;
  background-color: var(--way-colors-buttonColorShades-100);
  height: 24px;
  width: 24px;
  color: var(--way-colors-buttonColorContrastShades-100);
  border-radius: 50%;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;
