import styled from 'styled-components';

export const Wrapper = styled.div<{ customCss?: string }>`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;

  & > .play-pause-button {
    bottom: 8px;
    right: 8px;
  }

  & > .mute-button {
    bottom: 8px;
    right: calc(8px + 32px + 8px);

    @media (max-width: 768px) {
      right: calc(8px + 42px + 8px);
    }
  }

  > .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  ${({ customCss }) => customCss}
`;

export const Video = styled.video<{ fit: 'contain' | 'cover' }>`
  top: 0%;
  left: 0%;
  z-index: 10;

  ${({ fit }) =>
    fit === 'cover'
      ? `
    width: 100%;
    height: 100%;
    object-fit: cover;
  `
      : `
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  `}
`;
