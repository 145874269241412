import React, { useState } from 'react';
import get from 'lodash/get';
// @ts-ignore
import CreatableSelect from 'react-select/creatable';
import { Controller, ControllerProps, useFormContext } from 'hook-form';
import {
  Error,
  ErrorContainer,
  StyledLabel,
  StyledWrapInput,
} from 'components/theme/Form/Form';
import { uuidv4 } from 'utils';
import { useTranslation } from 'react-i18next';
import { ISelectOptions } from 'features/ShoppingCart/types';

interface ISelectProps extends Partial<ControllerProps> {
  options: ISelectOptions[];
  setOptions: React.Dispatch<React.SetStateAction<ISelectOptions[]>>;
  name: string;
  label?: string;
  onValidate?: (b: string) => boolean;
}

const SelectableInput: React.FC<ISelectProps> = ({
  options,
  setOptions,
  name,
  label,
  onValidate,
  ...props
}) => {
  const { t: translate } = useTranslation();
  const { control } = useFormContext();
  const [inputValue, setInputValue] = useState('');

  const onSetValues = () => {
    const newOption = {
      label: inputValue,
      value: `${uuidv4()}T${inputValue}`,
    };
    setOptions((opts) => [
      ...opts,
      {
        ...newOption,
      },
    ]);
    return newOption;
  };

  return (
    <Controller
      {...props}
      control={control}
      defaultValue={props.defaultValue || ''}
      name={name}
      render={({ field, formState }) => {
        const errorMessage = get(formState, `errors.${name}.message`) as string;
        return (
          <StyledWrapInput>
            {label && <StyledLabel>{label}</StyledLabel>}
            <CreatableSelect
              className="input-field"
              options={options}
              components={{
                DropdownIndicator: null,
              }}
              inputValue={inputValue}
              onChange={field.onChange}
              onInputChange={(newValue: string) => setInputValue(newValue)}
              value={field.value}
              formatCreateLabel={(inputValue: string) => `Add "${inputValue}"`}
              onCreateOption={(value: string) => {
                const isValid = onValidate?.(value);
                if (isValid) {
                  const newOption = onSetValues();
                  field.onChange(newOption);
                }
              }}
              placeholder={translate('participantFieldPlaceholder')}
              // styles={{
              //   control: (baseStyles: Record<string, string>) => ({
              //     ...baseStyles,
              //     border: '1px solid var(--way-colors-borderColor)',
              //     boxShadow: 'var(--way-design-boxShadow-s)',
              //     width: '100%',
              //     borderRadius: '5px',
              //     height: '40px',
              //     background: 'transparent',
              //     color: 'var(--way-colors-inputColor)',
              //   }),
              // }}
            />
            {errorMessage && (
              <ErrorContainer>
                <Error>{translate(errorMessage)}</Error>
              </ErrorContainer>
            )}
          </StyledWrapInput>
        );
      }}
    />
  );
};

export default SelectableInput;
