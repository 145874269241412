import { generateDummyData } from 'reducers/helpers';
import * as appTypes from 'types/app';
import { v4 as uuidv4 } from 'uuid';
import themeState from '../theme/themeState';

const state = {
  app: {
    isLoading: false,
    error: '',
    message: '',
    lang: 'en',
  },
  booking: {
    booking: null,
    error: '',
    message: '',
    validationMessages: [],
    validatingStatus: '',
    validationData: null,
    createBulkBookingStatus: '',
    createBookingStatus: '',
    contextFetchStatus: appTypes.STATUS_IDLE,
    bookingContext: null,
  },
  brand: {
    brand: null,
    settings: generateDummyData(1)[0],
    integrations: [],
    vibes: [],
    categories: [],
    error: '',
    message: '',
    validationMessages: [],
  },
  experience: {
    append: false,
    availabilities: [],
    error: '',
    sessionsSelectedDate: null,
    experience: generateDummyData(1)[0],
    experiences: generateDummyData(3),
    featuredExperiences: generateDummyData(3),
    message: '',
    pagination: null,
    sessions: [],
    sessionPriceTiers: [],
    validationMessages: [],
    fetchSettingsStatus: appTypes.STATUS_IDLE,
    fetchSessionStatus: appTypes.STATUS_IDLE,
    fetchFirstAvailabilityStatus: appTypes.STATUS_IDLE,
    searchAvailabilityStatus: appTypes.STATUS_IDLE,
    searchExperienceStatus: appTypes.STATUS_IDLE,
  },
  review: {
    status: appTypes.STATUS_SUCCESS,
    responseStatus: 200,
    error: '',
    reviews: [],
    pagination: null,
  },
  selectedParticipants: {
    selectedParticipants: [],
    selectedSession: null,
    currentExperience: null,
  },
  theme: themeState,
  user: {
    user: null,
    error: '',
    message: '',
    accessToken: '',
  },
  discountCode: {
    discountCode: null,
    error: '',
    message: '',
  },
  accessCode: {
    accessCode: null,
    error: '',
    message: '',
  },
  cart: {
    cartId: uuidv4(),
    loading: true,
    experiences: {},
    purchaser: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
    },
    paymentDetails: {},
  },
  waitlist: {
    item: null,
    experience: null,
    waitlistError: false,
    isFetchingExperience: false,
  },
  customQuestionResponses: {},
  priceTierData: {
    currentExperience: '',
    selectedParticipants: [],
  },
};

export default state;
