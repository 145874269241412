import { z } from 'zod';
import { Currency, SupportedCountryCode } from '@kouto/types';
import { useAppState } from 'AppProvider';
import { getRoundingOptions } from 'utils';
import { useBrandCurrency } from './useBrandCurrency';

const LOCALES: Record<SupportedCountryCode, string> = {
  AE: 'ar-AE',
  AU: 'en-AU',
  CA: 'en-CA',
  CH: 'de-CH',
  CZ: 'cs-CZ',
  DE: 'de-DE',
  ES: 'es-ES',
  FR: 'fr-FR',
  GB: 'en-GB',
  HU: 'hu-HU',
  IT: 'it-IT',
  JP: 'ja-JP',
  KR: 'ko-KR',
  MX: 'es-MX',
  NL: 'nl-NL',
  PT: 'pt-PT',
  US: 'en-US',
};

export const useCurrencyFormatter = () => {
  const { settings } = useAppState((app: Record<string, unknown>) => app.brand);
  const currency = useBrandCurrency();

  const format = (amount: number, options?: Intl.NumberFormatOptions) => {
    const countryCode = z
      .nativeEnum(SupportedCountryCode)
      .safeParse(settings.countryCode);

    const locale = countryCode.success ? LOCALES[countryCode.data] : LOCALES.US;

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency?.code || Currency.USD,
      currencySign: 'accounting',
      ...(options ?? {}),
    }).format(amount);
  };

  const formattedNumber = (amount: number) => {
    const formattedNumber = format(Number(amount), {
      style: 'decimal',
      ...getRoundingOptions(currency),
    });

    return formattedNumber;
  };

  return {
    currency,
    format,
    formattedNumber,
  };
};
