export default {
  featuredExperiences: '하이라이트 체험들',
  searchAllExperiences: '모든 체험 검색',
  searchAll: '모두 검색',
  groupSize: '그룹 규모',
  eventSize: '이벤트 규모',
  upTo: '최대',
  whatWeDo: '우리가 할 일',
  hostedBy: '호스트',
  whatsIncluded: '포함 내역',
  avail: '가능 여부',
  whereWeAre: '장소',
  experienceStartingPrice: '{{price}} 부터',
  experienceTotalPrice: '합계 {{price}}',
  at: '시간',
  viewPrivateSessAvailability: '개인 세션 가능여부 보기',
  selectSession: '세션 선택',
  select: '선택',
  slotLeft: '{{remainSlots}} 자리 남음',
  slotsLeft: '{{remainSlots}} 자리 남음',
  book: '예약',
  bookExperience: '체험 예약',
  apply: '신청하기',
  maxPartySize: '그룹당 최대 {{maxGroupSize}} 명',
  maxPartySizes: '그룹당 최대 {{maxGroupSize}}명',
  selectSessionValidation: '세션을 선택해 주세요',
  selectParticipantValidation: '참가자를 선택해 주세요',
  selectDateValidation: '날짜를 선택해 주세요',
  reminders: '알림',
  seeOtherDates: '다른 날짜 보기',
  location: '위치',
  about: '소개',
  soldOut: '품절',
  reviews: '이용 후기',
  reviewPlural: '리뷰',
  reviewSingular: '리뷰',
  dateOfExperience: '체험 날짜',
  readLess: '짧게 보기',
  readMore: '자세히 보기',
  seeMore: '더 보기',
  details: '소개',
  theFollowingVideoDoesNotContainAnyAudio:
    '다음 비디오에는 오디오가 포함되어 있지 않습니다',
};
