import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';

import { brightenColor, getSanitizedPathName } from 'utils';
import { THEMES } from 'types/brand';

// TODO change styles according to brand theme
const StyledLink = styled(Link)`
  color: var(--way-colors-linkColor);
  text-decoration: none;
  &:hover {
    color: ${({ theme }) =>
      brightenColor(theme.colors.linkColor, 20, theme.mode === THEMES.DARK)};
  }
`;

const CustomLink = ({ to, children, ...props }) => {
  const params = useParams();
  const toUrl = to ? getSanitizedPathName(params, to) : to;

  return (
    <StyledLink to={toUrl} {...props}>
      {children}
    </StyledLink>
  );
};

CustomLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
};

CustomLink.defaultProps = {
  to: '',
};

export default CustomLink;
