import { ICartExperienceItem } from 'features/ShoppingCart/types';
import useCartItems from 'hooks/useCartItems';
import { useEffect, useState } from 'react';
import { ICartExperience } from 'types/cart';
import { ISessionPayload } from 'types/experience.types';
import { formatDateForReq, ISO_DATE_FORMAT } from 'utils';

const useCartExperienceSession = (
  selectedEventSession: Pick<ISessionPayload, 'startTime' | 'duration'>,
  currentDate: string,
  experienceId: string,
) => {
  const [cartExperience, setCartExperience] = useState<ICartExperience | null>(
    null,
  );
  const { cartItems } = useCartItems();

  useEffect(() => {
    const item = Object.values(cartItems as ICartExperienceItem).find((e) => {
      const activeDateTime = formatDateForReq(
        `${currentDate}T${selectedEventSession?.startTime}`,
        ISO_DATE_FORMAT,
      );
      return (
        e.sessionDateTime === activeDateTime &&
        e.sessionDuration === selectedEventSession.duration &&
        e.experienceId === experienceId
      );
    });
    setCartExperience(item ?? null);
  }, [cartItems, selectedEventSession, currentDate, experienceId]);

  return cartExperience;
};

export default useCartExperienceSession;
