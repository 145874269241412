export default {
  // these should be unique as the values are used as keys
  profileImageOfHost: "Foto profilo dell'host {{hostName}}",
  hostedBy: 'Offerto da',
  experiences: 'experienze',
  experience: 'experienza',
  clear: 'Cancella',
  cancel: 'Annulla',
  apply: 'Salva',
  more: 'Altro',
  experiencePhotoOfListing:
    "Foto {{photoNumber}} dell'esperienza {{listingTitle}}",
  noExperiencesFound: 'Nessuna esperienza trovata',
  noExperiencesFoundFor: 'Nessuna esperienza trovata per',
  discoverExperiences: 'Esplora esperienze',
  clearFilters: 'Cancella filtri',
  buyTickets: 'Acquista biglietti',
  reserve: 'Prenota',
  activateProductCardTitle: 'Eventi',
  reserveProductCardTitle: 'Attività',
  hostProductCardTitle: 'Esperienze locali',
  mainLandingPageTitle: 'Tutte le esperienze',
  activateLandingPageTitle: 'Eventi',
  reserveLandingPageTitle: 'Attività e servizi',
  hostLandingPageTitle: 'Esperienze locali',
  exclusive: 'Esclusivo',
  filterExperiences: 'Filtra esperienze',
};
