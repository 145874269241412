import { z } from 'zod';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { get, post } from 'utils/axios';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
  GetBookingsForGuestCancellationEndpoint,
  PostGuestCancellationEndpoint,
  GuestCancellationBookingStatus,
} from '@kouto/types';
import useQueryParams from 'hooks/useQueryParams';
import moment from 'moment';
import { useBrandId } from 'AppProvider';

type ParamsType = {
  token?: string;
  cartId?: string;
  bookingId?: string;
};

export type CancellationBooking = z.infer<
  typeof GetBookingsForGuestCancellationEndpoint.responseSchema
>[number] & {
  sessionLabel: string;
};

const formatNextUrl = (
  formattedBookings: Record<string, CancellationBooking>,
  cartId?: string,
  token?: string,
  bookingId?: string,
) => {
  let url = `/guest-cancellation`;

  if (cartId) {
    url = `/carts/${cartId}${url}`;
  }

  const bookingsArray = Object.values(formattedBookings);
  if (
    bookingsArray.length === 1 &&
    bookingsArray[0].status === GuestCancellationBookingStatus.CANCELLED
  ) {
    url += `/confirmation`;
  } else if (bookingId) {
    url += `/booking/${bookingId}`;
  }
  if (token) {
    url += `?token=${token}`;
  }

  return url;
};

const useGuestCancellation = () => {
  const history = useHistory();
  const brandId = useBrandId();
  const location = useLocation();

  const matchGuestCancellation = useRouteMatch<ParamsType>(
    '/carts/:cartId/guest-cancellation',
  );
  const matchGuestCancellationSelectedBooking = useRouteMatch<ParamsType>(
    '/carts/:cartId/guest-cancellation/booking/:bookingId',
  );
  const emptyParams: ParamsType = {};
  let { cartId } = matchGuestCancellation?.params ?? emptyParams;
  const { bookingId } =
    matchGuestCancellationSelectedBooking?.params ?? emptyParams;
  cartId = sanitizeIdParam(cartId);
  const { token } = useQueryParams<{
    token?: string;
  }>();

  const [isCancelling, setIsCancelling] = useState(false);
  const [manuallyDeletedBookingIds, setManuallyDeletedBookingIds] = useState<
    string[]
  >([]);
  const [selectedBookingId, setSelectedBookingId] = useState<
    string | undefined
  >(bookingId);

  let bookingsApiUrl: string | null = null;
  if (cartId && token && brandId) {
    bookingsApiUrl = GetBookingsForGuestCancellationEndpoint.url({
      cartId,
      brandId,
      query: {
        token,
      },
    });
  }

  const { data, isLoading } = useSWR(
    bookingsApiUrl,
    get<{
      data: z.infer<
        typeof GetBookingsForGuestCancellationEndpoint.responseSchema
      >;
    }>,
  );
  const bookings = data?.data || [];

  useEffect(() => {
    if (
      selectedBookingId &&
      bookings &&
      bookings.length > 0 &&
      !bookings.some((booking) => booking.bookingId === selectedBookingId)
    ) {
      setSelectedBookingId(undefined);
    }
  }, [bookings, selectedBookingId]);

  useEffect(() => {
    if (bookings && bookings.length === 1) {
      setSelectedBookingId(bookings[0].bookingId);
    }
  }, [bookings]);

  const formattedBookings = useMemo(
    () =>
      (bookings || []).reduce(
        (tot, booking) => ({
          ...tot,
          [booking.bookingId]: {
            ...booking,
            status: manuallyDeletedBookingIds.includes(booking.bookingId)
              ? GuestCancellationBookingStatus.CANCELLED
              : booking.status,
            sessionLabel: formatSessionLabel(
              booking.sessionDateTime,
              booking.sessionDurationInMinutes,
            ),
          },
        }),
        {} as Record<string, CancellationBooking>,
      ),
    [bookings, manuallyDeletedBookingIds],
  );

  useEffect(() => {
    const cleanedUrl = formatNextUrl(
      formattedBookings,
      cartId,
      token,
      selectedBookingId,
    );
    if (`${location.pathname}${location.search}` !== cleanedUrl) {
      const [pathname, search] = cleanedUrl.split('?');
      history.push({
        pathname,
        search,
      });
    }
  }, [formattedBookings, cartId, token, selectedBookingId, history, location]);

  const cancelOneBooking = useCallback(
    async (bookingId: string) => {
      let result = false;
      if (brandId && cartId && bookingId && token) {
        setIsCancelling(true);
        try {
          const url = PostGuestCancellationEndpoint.url({
            brandId,
            cartId,
          });
          await post<
            void,
            z.infer<typeof PostGuestCancellationEndpoint.requestSchema>
          >(url, {
            bookingId,
            token,
          });
          setManuallyDeletedBookingIds((currentArray) => [
            ...currentArray,
            bookingId,
          ]);
          setSelectedBookingId(undefined);
          result = true;
        } catch (error) {
          result = false;
        } finally {
          setIsCancelling(false);
        }
      }
      return result;
    },
    [brandId, cartId, token],
  );

  return {
    cartId,
    token,
    cleanedUrl: formatNextUrl(
      formattedBookings,
      cartId,
      token,
      selectedBookingId,
    ),
    isLoading,
    isCancelling,
    bookings: formattedBookings,
    selectedBookingId,
    selectBookingId: setSelectedBookingId,
    cancelOneBooking,
  };
};

export default useGuestCancellation;

const formatSessionLabel = (
  sessionDateTime: string,
  sessionDurationInMinutes: number,
) => {
  const momentStartDate = moment(sessionDateTime);

  const formattedDate = momentStartDate.format('MMMM Do');
  const formattedStartTime =
    momentStartDate.minute() === 0
      ? momentStartDate.format('h A')
      : momentStartDate.format('h:mm A');

  const momentEndDate = momentStartDate.add(
    sessionDurationInMinutes,
    'minutes',
  );
  const formattedEndTime =
    momentEndDate.minute() === 0
      ? momentEndDate.format('h A')
      : momentEndDate.format('h:mm A');

  return `${formattedDate}, ${formattedStartTime} - ${formattedEndTime}`;
};

const sanitizeIdParam = (value?: string) => {
  return (
    decodeURIComponent(value ?? '')
      .replace(/"/g, '')
      .split('&')[0] || undefined
  );
};
