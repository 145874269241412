import { IReducerAction } from './reducer';

export const QUESTION_FIELD_TYPES = {
  INPUT: 'input',
  TEXTAREA: 'textarea',
  MULTIPLE_SELECT: 'multiple_select',
  SINGLE_SELECT: 'single_select',
  TERMS_AND_CONDITIONS: 'terms_and_conditions',
} as const;

export type QuestionFieldType =
  (typeof QUESTION_FIELD_TYPES)[keyof typeof QUESTION_FIELD_TYPES];

export const QUESTION_SCOPES = {
  PURCHASER: 'purchaser',
  PARTICIPANT: 'participant',
} as const;

export type QuestionScope =
  (typeof QUESTION_SCOPES)[keyof typeof QUESTION_SCOPES];

export interface Question {
  readonly config: {
    readonly id: string;
    readonly experienceId: string;
    readonly isRequired: boolean;
    readonly isActive: boolean;
    readonly order?: number;
    readonly scope: QuestionScope;
    readonly conditions?: QuestionCondition[];
    readonly conditionsMatchType?: 'any' | 'all';
  };
  readonly id: string;
  readonly prompt: string;
  readonly fieldType: QuestionFieldType;
  readonly options?: ReadonlyArray<{
    readonly key: string;
    readonly value: string;
  }>;
  readonly termsAndConditions?: {
    readonly label: string;
    readonly mediaURL: string;
    readonly isOverrided: boolean;
  };
}

export const CONDITION_RULES = {
  VALUE_MATCHES: 'value_matches',
} as const;

interface QuestionCondition {
  rule: (typeof CONDITION_RULES)[keyof typeof CONDITION_RULES];
  conditionKey: string;
  conditionValue: string | boolean;
}

export interface AnswerState {
  participantIndex: number;
  questionId: string;
  answer: CustomQuestionResponse[];
}

export type ICustomQuestionResponsesState = {
  [QUESTION_SCOPES.PARTICIPANT]: AnswerState[];
  [QUESTION_SCOPES.PURCHASER]: Omit<AnswerState, 'participantIndex'>[];
};

export const UPDATE_PARTICIPANT_CUSTOM_QUESTION_RESPONSE =
  '@custom-question/update-participant-response';
export const REMOVE_PARTICIPANT_CUSTOM_QUESTION_RESPONSE =
  '@custom-question/remove-participant-response';
export const UPDATE_PURCHASER_CUSTOM_QUESTION_RESPONSE =
  '@custom-question/update-purchaser-response';
export const CLEAR_CUSTOM_QUESTION_RESPONSES =
  '@custom-question/clear-response';

export interface IUpdateParticipantResponseAction
  extends IReducerAction<
    typeof UPDATE_PARTICIPANT_CUSTOM_QUESTION_RESPONSE,
    {
      participantIndex: number;
      questionId: Question['id'];
      response: CustomQuestionResponse[];
    }
  > {}

export interface IRemoveParticipantResponseAction
  extends IReducerAction<
    typeof REMOVE_PARTICIPANT_CUSTOM_QUESTION_RESPONSE,
    {
      participantIndex: number;
    }
  > {}
export interface IUpdatePurchaserResponseAction
  extends IReducerAction<
    typeof UPDATE_PURCHASER_CUSTOM_QUESTION_RESPONSE,
    {
      questionId: Question['id'];
      response: CustomQuestionResponse[];
    }
  > {}

export interface IClearCustomQuestionResponsesAction
  extends IReducerAction<typeof CLEAR_CUSTOM_QUESTION_RESPONSES, undefined> {}

export type ICustomQuestionResponseAction =
  | IUpdateParticipantResponseAction
  | IRemoveParticipantResponseAction
  | IUpdatePurchaserResponseAction
  | IClearCustomQuestionResponsesAction;

export interface CustomQuestionResponse {
  value: boolean | string;
  key: string | null;
}

export interface IExperienceSettingsResponse {
  data: {
    bookingForm: [
      {
        scope: QuestionScope;
        fields: {
          emailAddress: {
            isRequired: boolean;
            isActive: boolean;
          };
          phoneNumber: {
            isRequired: boolean;
            isActive: boolean;
          };
        };
      },
    ];
  };
}
