import React, { useEffect, useMemo } from 'react';
import values from 'lodash/values';
import { useAppState, useBrandId } from 'AppProvider';
import { useFormContext } from 'hook-form';
import { useTranslation } from 'react-i18next';
import * as Styled from 'features/ShoppingCart/utils/styles';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';

import { PaymentError } from 'features/ShoppingCart/components/PaymentError';
import {
  isIntegratedPaymentMethodEnabledForBrand,
  isIntegratedPreArrivalChargeEnabled,
  isIntegratedRoomChargeEnabledForExperience,
} from 'components/helpers';
import {
  lastNameValidation,
  roomNumberLegacyValidation,
  roomNumberValidation,
} from 'features/ShoppingCart/utils/validate';
import { FieldName } from 'features/ShoppingCart/types';
import useFetchExperienceList from 'selectors/experienceList';
import { findItemById } from 'features/ShoppingCart/utils';
import useCartItems from 'hooks/useCartItems';

const RoomChargePayment = () => {
  const {
    register,
    unregister,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    return () => {
      // we need to unregister these fields when the payment method
      // unmounts, otherwise the validations specific to this payment
      // method will be applied to other payment methods
      unregister([FieldName.PAYMENT_NAME, FieldName.RESERVATION_NUMBER]);
    };
  }, []);

  const brandId = useBrandId();
  const { t: translateText } = useTranslation();
  const { integrations, settings } = useAppState(
    (state: Record<string, unknown>) => state.brand,
  );
  const isIntegrationConfigured = isIntegratedPaymentMethodEnabledForBrand(
    integrations,
    settings,
  );

  const isPreArrivalChargeEnabled =
    isIntegratedPreArrivalChargeEnabled(integrations);
  const placeholder = isPreArrivalChargeEnabled
    ? 'roomOrReservationNumber'
    : 'roomNumber';

  const { cartItems } = useCartItems();
  const { experienceList, isFetchingList } = useFetchExperienceList();

  const isIntegrationEnabled = useMemo(() => {
    return values(cartItems).every((item) => {
      const experience = findItemById(experienceList, item.experienceId);
      return isIntegratedRoomChargeEnabledForExperience(experience);
    });
  }, [cartItems[brandId], experienceList]);

  const getValidationMessage = () => {
    return (
      errors.paymentName?.message || errors.reservationNumber?.message || null
    );
  };

  if (isFetchingList) {
    return (
      <Styled.AccordionContent>
        <SkeletonLine style={{ height: 50 }} translucent />
      </Styled.AccordionContent>
    );
  }

  if (isIntegrationEnabled && isIntegrationConfigured) {
    return (
      <>
        <Styled.AccordionContent>
          <Styled.InputWrapper>
            <input
              type="text"
              placeholder={translateText(FieldName.LAST_NAME)}
              {...register(FieldName.PAYMENT_NAME, { ...lastNameValidation })}
            />
            <input
              type="text"
              placeholder={translateText(placeholder)}
              {...register(FieldName.RESERVATION_NUMBER, {
                ...roomNumberValidation,
              })}
            />
          </Styled.InputWrapper>
        </Styled.AccordionContent>
        <PaymentError error={getValidationMessage() as string} />
      </>
    );
  }

  return (
    <>
      <Styled.ChargeAccordionContent>
        <Styled.InputWrapper>
          <input
            type="text"
            placeholder={translateText(FieldName.LAST_NAME)}
            {...register(FieldName.PAYMENT_NAME, { ...lastNameValidation })}
          />
          <input
            type="text"
            placeholder={translateText('roomNumberLegacy')}
            {...register(FieldName.RESERVATION_NUMBER, {
              ...roomNumberLegacyValidation,
            })}
          />
        </Styled.InputWrapper>
      </Styled.ChargeAccordionContent>
      <PaymentError error={getValidationMessage() as string} />
    </>
  );
};

export default RoomChargePayment;
