import { type ICartExperience } from 'types/cart';
import useOneListing from 'hooks/useOneListing';
import useCartItems from './useCartItems';

const useOneCartItemListing = (cartItemId: string) => {
  const { cartItems, isLoading: isLoadingCartItem } = useCartItems(true);

  const cartItem = cartItems[cartItemId] as ICartExperience | undefined;

  const { listing, isLoading: isLoadingListing } = useOneListing(
    isLoadingCartItem
      ? undefined
      : cartItem?.listingId || cartItem?.experienceId || undefined,
  );

  if (
    cartItem &&
    listing &&
    !cartItem.groupId &&
    cartItem.listingId &&
    cartItem.experienceId &&
    cartItem.experienceId !== cartItem.listingId
  ) {
    cartItem.groupId = listing.resourceGroups?.find((rg) =>
      rg.experiences.find((e) => e.id === cartItem?.experienceId),
    )?.id;
  }

  return {
    cartItem,
    listing,
    isLoading: isLoadingCartItem || isLoadingListing,
    isLoadingCartItem,
    isLoadingListing,
  };
};

export default useOneCartItemListing;
