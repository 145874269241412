import React, { useState } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { forbidExtraProps } from 'airbnb-prop-types';
import moment from 'moment';
import omit from 'lodash/omit';

import { DayPickerSingleDateController } from 'react-dates';

import ScrollableOrientationShape from 'react-dates/src/shapes/ScrollableOrientationShape';

import { HORIZONTAL_ORIENTATION } from 'react-dates/src/constants';
import isInclusivelyAfterDay from 'react-dates/src/utils/isInclusivelyAfterDay';

const propTypes = forbidExtraProps({
  // example props for the demo
  autoFocus: PropTypes.bool,
  initialDate: momentPropTypes.momentObj,
  showInput: PropTypes.bool,

  keepOpenOnDateSelect: PropTypes.bool,
  isOutsideRange: PropTypes.func,
  isDayBlocked: PropTypes.func,
  isDayHighlighted: PropTypes.func,

  // DayPicker props
  enableOutsideDays: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  orientation: ScrollableOrientationShape,
  withPortal: PropTypes.bool,
  initialVisibleMonth: PropTypes.func,
  renderCalendarInfo: PropTypes.func,

  navPrev: PropTypes.node,
  navNext: PropTypes.node,
  renderNavPrevButton: PropTypes.func,
  renderNavNextButton: PropTypes.func,

  onChange: PropTypes.func.isRequired,
  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
  renderCalendarDay: PropTypes.func,
  renderDayContents: PropTypes.func,

  // i18n
  monthFormat: PropTypes.string,

  isRTL: PropTypes.bool,
  isMobile: PropTypes.bool,
});

const defaultProps = {
  // example props for the demo
  autoFocus: false,
  initialDate: null,
  showInput: false,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  isDayBlocked: () => false,
  isOutsideRange: (day) => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: false,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  renderNavPrevButton: null,
  renderNavNextButton: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: 'MMMM YYYY',
  isMobile: false,
};

export default function DayPickerSingleDateControllerWrapper(props) {
  const { isMobile } = props;
  const [focused, setFocused] = useState(true);
  // const [date, setDate] = useState(props.initialDate);

  const onDateChange = (newDate) => {
    // setDate(newDate);
    props.onChange(newDate);
  };

  const onFocusChange = () => {
    // Force the focused states to always be truthy so that date is always selectable
    setFocused(true);
  };

  const allowedProps = omit(props, [
    'autoFocus',
    'initialDate',
    'onChange',
    'showInput',
    'isMobile',
  ]);

  return (
    <DayPickerSingleDateController
      {...allowedProps}
      onDateChange={onDateChange}
      onFocusChange={onFocusChange}
      focused={focused}
      date={props.initialDate}
      hideKeyboardShortcutsPanel
      transitionDuration={isMobile ? 0 : 300}
      verticalHeight={350}
    />
  );
}

DayPickerSingleDateControllerWrapper.propTypes = propTypes;
DayPickerSingleDateControllerWrapper.defaultProps = defaultProps;
