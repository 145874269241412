import React, { FC } from 'react';

import {
  useLocation,
  Redirect,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import { Wrapper } from 'components/theme/Wrapper';
import { CollectionLayout } from 'components/layouts/CollectionLayout';
import EventDetailsPage from './pages/EventDetailsPage/EventDetailsPage';
import useEventBookingSession from './hooks/useEventBookingSession';
import { EventTicketsRoute } from './EventTicketsRoute';

const EventPage: FC = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const { isLoading, cleanedUrl } = useEventBookingSession();

  if (
    !isLoading &&
    cleanedUrl &&
    `${location.pathname}${location.search}` !== cleanedUrl
  ) {
    const search = cleanedUrl.split('?')[1];
    return (
      <Redirect
        to={{
          pathname: cleanedUrl.split('?')[0],
          ...(search ? { search: `?${search}` } : {}),
        }}
      />
    );
  }

  return (
    <Wrapper>
      <CollectionLayout>
        <Switch>
          <Route
            path={`${match.path}/tickets/:date`}
            component={EventTicketsRoute}
          />
          <Route path={match.path} component={EventDetailsPage} />
        </Switch>
      </CollectionLayout>
    </Wrapper>
  );
};

export default EventPage;
