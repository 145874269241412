import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { currencyFormat, getCurrencyFromCode } from 'utils';

import Add from 'assets/plus-circle';
import Minus from 'assets/minus-circle';
import type { IExperienceFields } from 'types/experience.types';
import { PriceTierWithParticipants } from './types';

interface Props {
  priceTier: PriceTierWithParticipants;
  maxParticipants: number;
  currency: IExperienceFields['currency'];
  onChange: (increment: number) => void;
}

export const PriceTierSelector: React.FC<Props> = ({
  priceTier,
  maxParticipants,
  currency,
  onChange,
}) => {
  const { t: translate } = useTranslation();
  const isSoldOut =
    maxParticipants === 0 || maxParticipants <= priceTier.participants;

  const onIncreaseParticipantNumber = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isSoldOut) return;

    onChange(1);
  };

  const onDecreaseParticipantsNumber = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!priceTier.participants) return;

    onChange(-1);
  };

  return (
    <>
      <PriceCategory>
        <PriceCategoryLabel className="participants-dropdown__price-category-label">
          <p>{priceTier.name}</p>
          {priceTier.price === 0 ? (
            <p>{translate('complimentary')}</p>
          ) : (
            <p>
              {currencyFormat(getCurrencyFromCode(currency))(priceTier.price)}
            </p>
          )}
          <SoldOut className="soldOutLabel">{isSoldOut && 'Sold out'}</SoldOut>
        </PriceCategoryLabel>
        <PriceCategorySelector className="participants-dropdown__price-category-selector">
          <MinusIcon
            onClick={onDecreaseParticipantsNumber}
            disabled={!priceTier.participants}
          />
          <span>{priceTier.participants}</span>
          <PlusIcon
            onClick={onIncreaseParticipantNumber}
            disabled={isSoldOut}
          />
        </PriceCategorySelector>
      </PriceCategory>
    </>
  );
};

const PriceCategory = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SoldOut = styled.p`
  color: red;
  font-size: 14px;
  font-weight: 200;
  margin-top: 8px;
  margin-bottom: 0;
`;

const PlusIcon = styled(Add)<{ disabled: boolean }>`
  transform: scale(0.85);
  margin-top: -2px;
  pointer-events: auto;
  color: ${({ disabled }) =>
    disabled
      ? 'var(--way-colors-contrastColorShades-70)'
      : 'var(--way-colors-buttonColorShades-100)'};
  cursor: ${({ disabled }) => (disabled && 'not-allowed') || 'pointer'};
  &:hover {
    opacity: 0.71;
  }
`;

const MinusIcon = styled(Minus)<{ disabled: boolean }>`
  transform: scale(0.85);
  margin-top: -2px;
  pointer-events: auto;
  color: ${({ disabled }) =>
    disabled
      ? 'var(--way-colors-contrastColorShades-70)'
      : 'var(--way-colors-buttonColorShades-100)'};
  cursor: ${({ disabled }) => (disabled && 'not-allowed') || 'pointer'};
  &:hover {
    opacity: 0.71;
  }
`;

const PriceCategorySelector = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-left: 8px;
  }
  & > span {
    min-width: 20px;
    text-align: center;
    user-select: none;
  }
`;

const PriceCategoryLabel = styled.div`
  & > p {
    margin: 0;
    user-select: none;
  }
  & > p:nth-child(2) {
    margin-bottom: 4px;
    color: var(--way-palette-black-60);
    font-size: 12px;
    user-select: none;
  }
  & > p:nth-child(1) {
    font-size: 16px;
  }
  & > .soldOutLabel {
    margin-top: 4px;
  }
`;
