import React, { useMemo, MouseEvent } from 'react';
import { Popover } from 'components/Popover/Popover';
import styled from 'styled-components';
import { ButtonBlock } from 'components/theme/Button';

import { currencyFormat, getCurrencyFromCode } from 'utils';
import type { IExperienceFields } from 'types/experience.types';
import { PriceTierSelector } from './PriceTierSelector';
import { PriceTierWithParticipants } from './types';

interface Props {
  children: React.ReactNode;
  priceTiers: PriceTierWithParticipants[];
  maxParticipants: number;
  currency: IExperienceFields['currency'];
  onChange: (
    tierId: PriceTierWithParticipants['id'],
    increment: number,
  ) => void;
  onSubmit: (e: MouseEvent) => void;
  asChild?: boolean;
}

export const PriceTierPopover: React.FC<Props> = ({
  children,
  priceTiers,
  maxParticipants,
  currency,
  onChange,
  onSubmit,
  asChild = true,
}) => {
  const total = useMemo(() => {
    return priceTiers.reduce((sum, tier) => {
      return sum + tier.participants * tier.price;
    }, 0);
  }, [priceTiers]);

  const selectedParticipants = useMemo(() => {
    return priceTiers.reduce((sum, tier) => {
      return sum + tier.participants;
    }, 0);
  }, [priceTiers]);

  return (
    <Popover trigger={children} asChild={asChild} portalled sideOffset={20}>
      <StyledPopoverContent>
        {priceTiers?.map((priceTier) => (
          <PriceTierSelector
            key={priceTier.id}
            priceTier={priceTier}
            maxParticipants={
              maxParticipants - selectedParticipants + priceTier.participants
            }
            currency={currency}
            onChange={(increment) => onChange(priceTier.id, increment)}
          />
        ))}

        <HorizontalSeparator />

        <TotalContainer>
          <span>Total</span>{' '}
          <span> {currencyFormat(getCurrencyFromCode(currency))(total)}</span>
        </TotalContainer>

        <ReserveButton onClick={onSubmit}>Reserve Now</ReserveButton>
      </StyledPopoverContent>
    </Popover>
  );
};

const StyledPopoverContent = styled.div`
  background: var(--way-palette-white-100);
  z-index: 10;
  box-shadow: var(--way-design-boxShadow-l);
  border: 0.5px solid var(--way-colors-contrastColorShades-5);
  width: 272px;
  border-radius: var(--way-design-borderRadiusDefault);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
`;

const HorizontalSeparator = styled.hr`
  border: 0.5px solid var(--way-colors-contrastColorShades-5);
  width: calc(100% + 2 * 12px);
  margin-left: -12px;
`;

const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > *:first-child {
    font-size: 16px;
  }
`;

const ReserveButton = styled(ButtonBlock)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
