export default {
  unavailable: 'Indisponível',
  person: 'pessoa',
  people: 'pessoas',
  reserveNow: 'Reserve agora',
  selectTime: 'Selecionar horário',
  select: 'Selecionar',
  reserve: 'Reservar',
  time: 'Hora',
  tier: 'Nível',
  questions: 'Perguntas',
  addToCart: 'Adicionar ao carrinho',
  checkout: 'Finalizar compra',
  total: 'Total',
  availability: 'Disponibilidade',
  whatsIncluded: 'O que está incluído',
  showMore: 'Mostrar mais',
  showLess: 'Mostrar menos',
  session: 'Sessão',
  viewDetails: 'Ver detalhes',
  findAvailability: 'Encontrar disponibilidade',
  loading: 'Carregando',
  capacity: 'Capacidade',
  noAvailableSessionsOnThisDate: 'Nenhuma sessão disponível nesta data',
  noGroupAvailableOnThisDateAndTime:
    'Nenhum(a) {{groupTitle}} disponível nesta data e hora',
  noCollectionFound: 'Nenhuma coleção encontrada!',
  noResourceGroupFound: 'Nenhum grupo de recursos encontrado!',
  noData: 'Sem dados!',
  continue: 'Continuar',
};
