import styled, { css } from 'styled-components';
import { CSSProperties } from 'react';
import { tabletBreakpoint } from 'WindowDimensionProvider';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

export interface FontSizeProps {
  fontSize?: CSSProperties['fontSize'];
  mobileFontSize?: CSSProperties['fontSize'];
  mobileFontWeight?: CSSProperties['fontWeight'];
  fontWeight?: CSSProperties['fontWeight'];
  color?: CSSProperties['color'];
  mobileColor?: CSSProperties['color'];
}

export const applyMobileFontSize = (props: FontSizeProps) => {
  if (
    isEmpty(pick(props, ['mobileColor', 'mobileFontWeight', 'mobileFontSize']))
  ) {
    return '';
  }

  return `
    @media all and (max-width: ${tabletBreakpoint}) {
      font-size: ${props.mobileFontSize};
      font-weight: ${props.mobileFontWeight};
      color: ${props.mobileColor};
    }
  `;
};

const applyCommonStyles = () => css`
  font-weight: normal;
  font-style: normal;
  text-transform: none;
  line-height: normal;
  font-family: ${({ theme }) => theme.font.primaryFont};
  color: var(--way-colors-primaryTextColor);
  margin: 0;
  padding: 0;
`;

export const ResourceHeading2 = styled.h2<
  FontSizeProps & {
    className: string;
  }
>`
  ${applyCommonStyles()};

  ${({ className, fontWeight, fontSize, ...props }) => `
      &.${className} {
        font-weight: ${fontWeight ?? '700'};
        font-size: ${fontSize ?? '21px'};
        
        ${applyMobileFontSize(props)};
      }
    `}
`;

export const ResourceParagraph = styled.p<
  FontSizeProps & {
    lineHeight?: string;
    className: string;
    opacity?: string;
  }
>`
  ${applyCommonStyles()};
  ${({ theme, className, color, opacity }) => {
    const txtOpacity = opacity ?? '0.8';

    const txtColor =
      theme.mode === 'dark'
        ? `rgba(247, 248, 250, ${txtOpacity})`
        : `rgba(25, 25, 26, ${txtOpacity})`;

    return css`
      &.${className} {
        color: ${color ?? txtColor};
      }
    `;
  }};

  ${({ className, fontWeight, lineHeight, fontSize, ...props }) => `
      &.${className} {
        line-height: ${lineHeight ?? '23px'};
        font-weight: ${fontWeight ?? 400};
        font-size:  ${fontSize ?? '16px'};

        ${applyMobileFontSize(props)};
      }
    `}
`;

export const ResourceLinkButton = styled(ResourceParagraph)`
  text-decoration: underline;
  cursor: pointer;
`;

export const Span = styled.span<
  FontSizeProps & {
    lineHeight?: CSSProperties['lineHeight'];
    className: string;
    color?: CSSProperties['color'];
  }
>`
  ${applyCommonStyles()};
  ${({ className, fontWeight, lineHeight, fontSize, color, ...props }) => `
      &.${className} {
        line-height: ${lineHeight ?? '23px'};
        font-weight: ${fontWeight ?? 'normal'};
        font-size:  ${fontSize ?? '16px'};
        color: ${color ?? 'var(--way-colors-primaryTextColor)'}

        ${applyMobileFontSize(props)}
      }
    `}
  font-style: normal;
`;
