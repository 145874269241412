import { analyticsManager } from 'features/analytics/services';
import { ANALYTICS_PROPERTY } from '@kouto/types';
import { useEffect } from 'react';

export interface ITierAnalyticsData {
  title: string[];
  category: string[];
  count: number;
  tiers: string[];
  participantsCount: number[];
  perParticipantTierPrice: number[];
  priceTierPrices: number[];
}

export interface IBasicTierData {
  name: string;
  selectedNumber: number;
  price?: number;
}

export function setTierBasedData(data: ITierAnalyticsData) {
  analyticsManager.setAppData({
    [ANALYTICS_PROPERTY.CartExperiencesPriceTiersCount]: `${data.count}`,
    [ANALYTICS_PROPERTY.CartExperiencesByPriceTier]: data.title.join(';'),
    [ANALYTICS_PROPERTY.CartExperiencesCategoriesByPriceTier]:
      data.category.join(';'),
    [ANALYTICS_PROPERTY.CartPriceTiers]: data.tiers.join(';'),
    [ANALYTICS_PROPERTY.CartParticipantsCountByPriceTier]:
      data.participantsCount.join(';'),
    [ANALYTICS_PROPERTY.CartExperiencesSubtotalByPriceTier]:
      data.priceTierPrices.map((n) => n.toFixed(2)).join(';'),
  });
}

export function setCurrentExperienceTierBasedData(data: ITierAnalyticsData) {
  analyticsManager.setAppData({
    [ANALYTICS_PROPERTY.CurrentExperiencesPriceTiersCount]: `${data.count}`,
    [ANALYTICS_PROPERTY.CurrentExperiencesByPriceTier]: data.title.join(';'),
    [ANALYTICS_PROPERTY.CurrentExperiencesCategoriesByPriceTier]:
      data.category.join(';'),
    [ANALYTICS_PROPERTY.CurrentPriceTiers]: data.tiers.join(';'),
    [ANALYTICS_PROPERTY.CurrentParticipantsCountByPriceTier]:
      data.participantsCount.join(';'),
    [ANALYTICS_PROPERTY.CurrentExperiencesSubtotalByPriceTier]:
      data.perParticipantTierPrice.map((n) => n.toFixed(2)).join(';'),
  });
}

function removeTierBasedAnalyticsData() {
  analyticsManager.removeAppData(ANALYTICS_PROPERTY.CurrentPriceTiers);
  analyticsManager.removeAppData(
    ANALYTICS_PROPERTY.CurrentExperiencesPriceTiersCount,
  );
  analyticsManager.removeAppData(
    ANALYTICS_PROPERTY.CurrentExperiencesByPriceTier,
  );
  analyticsManager.removeAppData(
    ANALYTICS_PROPERTY.CurrentExperiencesCategoriesByPriceTier,
  );
  analyticsManager.removeAppData(
    ANALYTICS_PROPERTY.CurrentParticipantsCountByPriceTier,
  );
  analyticsManager.removeAppData(
    ANALYTICS_PROPERTY.CurrentExperiencesSubtotalByPriceTier,
  );
}

interface IGetAnalayticsDataParams {
  title: string;
  category?: string;
  tiers: IBasicTierData[];
}
export function getAnalyticsDataForExperience({
  title,
  category,
  tiers,
}: IGetAnalayticsDataParams): ITierAnalyticsData {
  return tiers
    .filter((p) => p.selectedNumber > 0)
    .reduce(
      (acc, p) => {
        acc.title.push(title);

        if (category) {
          acc.category.push(category);
        }

        acc.count += 1;
        acc.participantsCount.push(p.selectedNumber);
        acc.perParticipantTierPrice.push(
          (p.price && p.price * p.selectedNumber) ?? 0,
        );

        acc.priceTierPrices.push(p.price ?? 0);

        acc.tiers.push(p.name);
        return acc;
      },
      {
        title: [] as string[],
        category: [] as string[],
        count: 0,
        tiers: [] as string[],
        participantsCount: [] as number[],
        perParticipantTierPrice: [] as number[],
        priceTierPrices: [] as number[],
      },
    );
}

export function usePriceTierDataFromSelectedParticipants(
  params: IGetAnalayticsDataParams,
) {
  const { title, category, tiers } = params;

  useEffect(() => {
    const data = getAnalyticsDataForExperience(params);
    setCurrentExperienceTierBasedData(data);

    return () => {
      removeTierBasedAnalyticsData();
    };
  }, [JSON.stringify(tiers), title, category]);
}
