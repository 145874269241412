import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Modal = ({ children }) => {
  return (
    <Overlay>
      <OverlayContent>{children}</OverlayContent>
    </Overlay>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Modal;

const Overlay = styled.div`
  position: fixed;
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--way-palette-white-100);
  z-index: 9999999;
`;

const OverlayContent = styled.div`
  position: absolute;
  width: 50vw;
  top: 15%;
  left: 25%;
  @media (max-width: 1025px) {
    top: 24%;
    left: 20%;
    width: 60vw;
  }
  @media (min-width: 1400px) {
    top: 20%;
    left: 25%;
  }
`;
