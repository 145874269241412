import * as types from 'types/participants';
import initialState from 'store/initial-state';

export default function participantsReducer(
  state = initialState.selectedParticipants,
  action,
) {
  switch (action.type) {
    case types.SET_TIER_PARTICIPANTS:
      return {
        ...state,
        selectedParticipants: action.payload.selectedParticipants,
        currentExperience: action.payload.currentExperience,
        groupTitle: action.payload.groupTitle || '',
      };

    case types.APPLY_TIER_PARTICIPANTS:
      return {
        ...state,
        selectedParticipants: state.selectedParticipants.map(
          (participantCategory) => {
            if (participantCategory.name === action.payload.label) {
              return {
                ...participantCategory,
                selectedNumber: participantCategory.selectedNumber + 1,
              };
            }
            return participantCategory;
          },
        ),
      };

    case types.DEDUCT_TIER_PARTICIPANTS:
      return {
        ...state,
        selectedParticipants: state.selectedParticipants.map(
          (participantCategory) => {
            if (participantCategory.name === action.payload.label) {
              return {
                ...participantCategory,
                selectedNumber: participantCategory.selectedNumber - 1,
              };
            }
            return participantCategory;
          },
        ),
      };

    case types.INTEGRATE_SESSION_PARTICIPANTS:
      return {
        ...state,
        selectedSession: action.payload.session,
      };

    case types.RESET_TIER_PARTICIPANTS:
      return {
        ...state,
        selectedParticipants: state.selectedParticipants?.map(
          (tierParticipant) => ({ ...tierParticipant, selectedNumber: 0 }),
        ),
      };

    default:
      return state;
  }
}
