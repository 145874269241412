import styled from 'styled-components';

export const InfoBox = styled.div<{ asColumn?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ asColumn }) => (asColumn ? 'column' : 'row')};
  gap: 4px;
  backdrop-filter: blur(10px);
  position: absolute;
  top: 0px;
  right: 0px;
  min-width: 50px;
  min-height: 40px;
  background: var(--way-palette-black-20);
  box-shadow: -1px 1px 1px -1px var(--way-palette-white-10),
    -3px 3px 12px var(--way-palette-white-20);
  padding: 10px 10px;
  z-index: 90;

  & > img.listing-card-host-photo {
    border-radius: 50%;
  }
`;

export const HostName = styled.span`
  color: var(--way-palette-white-100);
  font-size: 12px;
`;

export const HostPhoto = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
`;

export const Month = styled.span`
  color: var(--way-palette-white-100);
  font-weight: 100;
  font-size: 12px;
  line-height: 14px;
`;

export const Day = styled.span`
  color: var(--way-palette-white-100);
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
`;
