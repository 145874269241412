import React from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import styled from 'styled-components';
import CustomizableCalendarDay from 'react-dates/lib/components/CustomizableCalendarDay';
import 'components/theme/ReactDate';

import LoadingIndicator from 'components/theme/LoadingIndicator';
// import { formatDateForReq } from 'utils';
import DayPickerSingleDateControllerWrapper from './DayPickerSingleDateControllerWrapper';

const DayStyleShape = {
  borderRadius: '0px',
  background: 'rgba(45, 54, 87, 0.0893247)',
  border: '3px solid var(--way-palette-white-100)',
  padding: 0,
  margin: 0,
  lineHeight: '33px',
  fontWeight: 'normal',
};

const hoveredStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
};

const selectedStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',

  // hover: {
  //   background: 'rgba(45, 54, 87, 0.4)',
  //   border: '1px solid #e4e7e7',
  //   color: 'var(--way-palette-white-100)',
  // },
};

const selectedSpanStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',

  hover: {
    background: 'rgba(45, 54, 87, 0.4)',
    border: '3px solid var(--way-palette-white-100)',
    color: '#051E39',
  },
};

const blockedCalendarStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
};
const blockedOutOfRangeStyles = {
  background: 'rgba(45, 54, 87, 0.0893247)',
  border: '3px solid var(--way-palette-white-100)',
  color: 'rgba(5, 30, 57,.33)',
  borderRadius: '0px',
};

const highlightedCalendarStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
  hover: {
    background: 'rgba(45, 54, 87, 0.4)',
    border: '3px solid var(--way-palette-white-100)',
    color: '#051E39',
  },
};

const outsideStyles = {
  background: 'rgba(45, 54, 87, 0.25)',
  border: '3px solid var(--way-palette-white-100)',
  color: '#051E39',
  borderRadius: '0px',
};

export const customDayStyles = {
  defaultStyles: {
    ...DayStyleShape,
  },
  selectedStartStyles: {
    ...selectedStyles,
    // background: colors.buttonColor,
  },
  selectedEndStyles: {
    ...selectedStyles,
    // background: colors.buttonColor,
  },
  hoveredSpanStyles: {
    ...hoveredStyles,
    // background: colors.buttonColor,
    // color: 'var(--way-palette-white-100)',
  },
  selectedSpanStyles,
  selectedStyles: {
    ...selectedStyles,
    // background: colors.buttonColor,
    // border: '1px solid #e4e7e7',
    // color: 'var(--way-palette-white-100)',
  },
  highlightedCalendarStyles,
  outsideStyles,
  blockedCalendarStyles,
  blockedOutOfRangeStyles,
};

const loadingDay = {
  ...DayStyleShape,
  opacity: 0.6,
  animation: 'load 1s infinite',
};

export const loadingDayStyles = {
  ...customDayStyles,
  defaultStyles: loadingDay,
  selectedStartStyles: loadingDay,
  selectedEndStyles: loadingDay,
  hoveredSpanStyles: loadingDay,
  selectedSpanStyles: loadingDay,
  selectedStyles: loadingDay,
  highlightedCalendarStyles: loadingDay,
  blockedCalendarStyles: loadingDay,
  blockedOutOfRangeStyles: loadingDay,
};

const AvailabilityCalendar = ({
  date,
  onChange,
  orientation,
  numberOfMonths,
  isLoading,
  ...props
}) => {
  return (
    <PickerContainer className="availability-calendar__picker-container">
      {/* <PickerContainer key={formatDateForReq(date)}> */}
      {isLoading && (
        <div>
          <LoadingIndicatorContainer>
            <LoadingIndicator />
          </LoadingIndicatorContainer>
        </div>
      )}
      <DayPickerSingleDateControllerWrapper
        initialDate={date}
        onChange={onChange}
        enableOutsideDays={false}
        keepOpenOnDateSelect={false}
        numberOfMonths={numberOfMonths}
        orientation={orientation}
        renderCalendarDay={(dayProps) => (
          <CustomizableCalendarDay {...dayProps} {...customDayStyles} />
        )}
        daySize={39}
        initialVisibleMonth={() => date}
        {...props}
      />
    </PickerContainer>
  );
};

AvailabilityCalendar.propTypes = {
  date: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  orientation: PropTypes.string,
  numberOfMonths: PropTypes.number,
  isLoading: PropTypes.bool,
};

AvailabilityCalendar.defaultProps = {
  numberOfMonths: 1,
  date: null,
  orientation: 'horizontal',
  isLoading: false,
};

const PickerContainer = styled.div`
  position: relative;
  margin-left: -12px;

  > div {
    box-shadow: none;
    background-color: var(--way-palette-white-100);
  }
`;

// TODO fix centering for mobile.
const LoadingIndicatorContainer = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -45px;
  width: 100%;
  z-index: 999;
  background-color: none;
`;

export default AvailabilityCalendar;
