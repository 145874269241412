import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { DATE_FORMAT } from 'utils';
import { Listing } from 'types/listings';
import { InfoBoxProps } from './types';
import { Day, InfoBox, Month } from './styles';

export const getNextDateLabels = (listing: Listing) => {
  const today = moment().format(DATE_FORMAT);
  let dates = listing.dates || [];
  dates = dates.filter((date) => date >= today).sort();

  if (dates.length === 1) {
    const nextDateMoment = moment(dates[0]);
    return {
      year: nextDateMoment.format('YYYY'),
      month: nextDateMoment.format('MMM'),
      day: nextDateMoment.format('D'),
    };
  }

  if (dates.length > 1) {
    const firstDateMoment = moment(dates[0]);
    const lastDateMoment = moment(dates[dates.length - 1]);
    return {
      year: firstDateMoment.format('YYYY'),
      month: firstDateMoment.format('MMM'),
      day: `${firstDateMoment.format('D')}-${lastDateMoment.format('D')}`,
    };
  }

  if (listing.firstAvailableDate?.scheduledDate) {
    const nextDateMoment = moment(listing.firstAvailableDate.scheduledDate);
    return {
      year: nextDateMoment.format('YYYY'),
      month: nextDateMoment.format('MMM'),
      day: nextDateMoment.format('D'),
    };
  }

  return null;
};

export const EventInfoBox: FC<InfoBoxProps> = ({ listing }) => {
  const nextDateLabels = useMemo(() => getNextDateLabels(listing), [listing]);

  if (!nextDateLabels) {
    return null;
  }

  return (
    <InfoBox className="listing-carousel-card-details" asColumn>
      <Month>{nextDateLabels.month}</Month>
      <Day>{nextDateLabels.day}</Day>
    </InfoBox>
  );
};
