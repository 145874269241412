import { useRouteMatch } from 'react-router-dom';

enum ROUTES {
  HOME = 'HOME',
  HOME_RESULTS = 'HOME_RESULTS',
  PRODUCT_LANDING_PAGE = 'PRODUCT_LANDING_PAGE',
  PRODUCT_LANDING_PAGE_RESULTS = 'PRODUCT_LANDING_PAGE_RESULTS',
}

interface Route {
  id: ROUTES;
  path: string;
  getPath: (params?: Record<string, string | undefined>) => string;
  backRoute: ROUTES;
}

const HomeRoute: Route = {
  id: ROUTES.HOME,
  path: '/',
  getPath: () => '/',
  backRoute: ROUTES.HOME,
};

const HomeResultsRoute: Route = {
  id: ROUTES.HOME_RESULTS,
  path: '/search',
  getPath: () => '/search',
  backRoute: ROUTES.HOME,
};

const ProductLandingPage: Route = {
  id: ROUTES.PRODUCT_LANDING_PAGE,
  path: '/landing/:product',
  getPath: (params) => `/landing/${params?.product}`,
  backRoute: ROUTES.HOME,
};

const ProductLandingPageResults: Route = {
  id: ROUTES.PRODUCT_LANDING_PAGE_RESULTS,
  path: '/landing/:product/search',
  getPath: (params) => `/landing/${params?.product}/search`,
  backRoute: ROUTES.PRODUCT_LANDING_PAGE,
};

const ROUTES_MAP: Record<ROUTES, Route> = {
  [ROUTES.HOME]: HomeRoute,
  [ROUTES.HOME_RESULTS]: HomeResultsRoute,
  [ROUTES.PRODUCT_LANDING_PAGE]: ProductLandingPage,
  [ROUTES.PRODUCT_LANDING_PAGE_RESULTS]: ProductLandingPageResults,
};

const getBackRoutePath = (
  currentRoute: Route,
  params?: Record<string, string | undefined>,
): string => {
  const backRoute = ROUTES_MAP[currentRoute.backRoute];
  return backRoute.getPath(params ?? {});
};

export const getPreviousRoute = (
  match: ReturnType<typeof useRouteMatch>,
): string => {
  if (!match?.path) return '/';

  const currentRoute = Object.values(ROUTES_MAP).find(
    (route) => route.path === match.path,
  );

  return currentRoute
    ? getBackRoutePath(currentRoute, match.params ?? {})
    : '/';
};
