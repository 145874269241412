export default {
  unavailable: '사용 불가',
  person: '사람',
  people: '사람들',
  reserveNow: '지금 예약',
  selectTime: '시간 선택',
  select: '선택',
  reserve: '예약',
  time: '시간',
  tier: '등급',
  questions: '질문',
  addToCart: '장바구니에 추가',
  checkout: '결제',
  total: '합계',
  availability: '가용성',
  whatsIncluded: '포함된 항목',
  showMore: '더 보기',
  showLess: '덜 보기',
  session: '세션',
  viewDetails: '자세히 보기',
  findAvailability: '가용성 확인',
  loading: '로딩 중',
  capacity: '용량',
  noAvailableSessionsOnThisDate: '이 날짜에 사용할 수 있는 세션이 없습니다',
  noGroupAvailableOnThisDateAndTime:
    '이 날짜와 시간에 {{groupTitle}}을 사용할 수 없습니다',
  noCollectionFound: '컬렉션을 찾을 수 없습니다!',
  noResourceGroupFound: '리소스 그룹을 찾을 수 없습니다!',
  noData: '데이터 없음!',
  continue: '계속',
};
