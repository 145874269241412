import React from 'react';

const CalendarIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20 * scale}
      height={20 * scale}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.90911 2H4.71128V3.18035H2V18H18.0002V3.18035H15.2889V2H13.0911V3.18046H6.90911V2ZM15.2889 5.01417V3.83342H17.2813V6.92506H2.71899V3.83342H4.71128V5.01417H6.90911V3.83353H13.0911V5.01417H15.2889ZM2.71899 7.57813V17.3469H17.2813V7.57813H2.71899ZM5.43028 4.3611V2.65307H6.19011V4.3611H5.43028ZM13.8101 2.65307V4.3611H14.5699V2.65307H13.8101Z"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
    </svg>
  );
};

export default CalendarIcon;
