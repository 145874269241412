import { useEffect } from 'react';
import { fetchExperience } from 'actions/experience';

import { useAppState, useBrandId, useDispatch } from 'AppProvider';
import useExperienceId from 'hooks/use-experience-id';
import useSearchQueryParams from 'hooks/use-search-params';

const useFetchExperience = () => {
  const dispatch = useDispatch();
  const experienceId = useExperienceId();
  const brandId = useBrandId();
  const { searchParams } = useSearchQueryParams();

  const latest = String(searchParams?.latest).toLowerCase() === 'true';

  const {
    experience,
    error,
    fetchExperienceStatus,
    fetchExperienceResponseCode,
    fetchSettingsStatus,
    experienceSettings,
  } = useAppState((state) => state.experience);

  useEffect(() => {
    if (experienceId) {
      dispatch(fetchExperience(experienceId, { brandId, latest }));
    }
  }, []);

  return {
    experience,
    error,
    fetchExperienceStatus,
    responseStatus: fetchExperienceResponseCode,
    fetchSettingsStatus,
    experienceSettings,
  };
};

export default useFetchExperience;
