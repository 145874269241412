import React, { FC, useState } from 'react';
import { z } from 'zod';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useSWR from 'swr';
import { Event } from 'types/listings';
import { get } from 'utils/axios';
import { BookingMode } from 'types/payment';
import { useBrandId } from 'AppProvider';
import {
  ResourceGroupCollectionEventType,
  GetWaitlistEntryPublicEndpoint,
  PublicGetListingEndpoint,
  ListingVersion,
  SessionStatus,
} from '@kouto/types';
import useEventSessions from 'features/Events/hooks/useEventSessions';
import Spinner from 'components/Spinner/Spinner';

import { useCart } from 'hooks/useCart';
import { CartItemState } from 'types/cart';

type ParamsType = {
  eventId?: string;
  waitlistEntryId?: string;
};

const EventClaimTickets: FC = () => {
  const history = useHistory();
  const brandId = useBrandId();
  const { onAddToCart } = useCart();
  const [checkoutCalled, setCheckoutCalled] = useState(false);

  const goToError = () => {
    history.push({
      pathname: '/claim-tickets-error',
    });
  };

  // 1 - get params from url
  const matchEventClaim = useRouteMatch<ParamsType>(
    '/event/:eventId/claim/:waitlistEntryId',
  );
  const emptyParams: ParamsType = {};
  const { eventId, waitlistEntryId } = matchEventClaim?.params ?? emptyParams;

  // 2 - get waitlistEntry from api
  let waitlistEntryUrl: string | null = null;
  let waitlistEntry:
    | z.infer<typeof GetWaitlistEntryPublicEndpoint.responseSchema>
    | undefined;
  if (
    checkoutCalled ||
    !eventId ||
    !waitlistEntryId ||
    typeof brandId !== 'string'
  ) {
    if (!checkoutCalled) {
      goToError();
    }
  } else {
    waitlistEntryUrl = GetWaitlistEntryPublicEndpoint.url({
      brandId,
      waitlistEntryId,
    });
  }
  const {
    data: waitlistEntryData,
    isLoading: isLoadingWaitlistEntry,
    error: errorWaitlistEntry,
  } = useSWR(
    waitlistEntryUrl,
    get<{
      data: z.infer<typeof GetWaitlistEntryPublicEndpoint.responseSchema>;
    }>,
  );
  if (!isLoadingWaitlistEntry && waitlistEntryData) {
    waitlistEntry = waitlistEntryData?.data;
  }
  if (errorWaitlistEntry || (!waitlistEntry && !isLoadingWaitlistEntry)) {
    if (!checkoutCalled) {
      goToError();
    }
  }

  // 3 - get event from api
  let eventApiUrl: string | null = null;
  let event: Event | undefined;
  if (!checkoutCalled && waitlistEntry?.resourceGroupCollectionId) {
    eventApiUrl = PublicGetListingEndpoint.url({
      listingId: waitlistEntry.resourceGroupCollectionId,
      query: {
        version: ListingVersion.PUBLISHED,
      },
    });
  }
  const {
    data: eventData,
    isLoading: isLoadingEvent,
    error: eventError,
  } = useSWR(eventApiUrl, get<{ data: Event }>);
  if (!isLoadingEvent && eventData) {
    event = eventData?.data;
  }
  if (eventError || (eventApiUrl && !event && !isLoadingEvent)) {
    if (!checkoutCalled) {
      goToError();
    }
  }

  // 4 - get sessions, to get price
  const { sessions: eventSessions, isLoading: isLoadingEventSessions } =
    useEventSessions({
      eventId,
      from: waitlistEntry?.startDate,
      to: waitlistEntry?.startDate,
      waitlistEntryId,
    });
  const session = eventSessions.filter(
    (s) =>
      s.status !== SessionStatus.OVERLAPPED &&
      s.startDateTime ===
        `${waitlistEntry?.startDate}T${waitlistEntry?.startTime}:00` &&
      s.duration === waitlistEntry?.sessionDuration,
  )[0];
  const priceTier = session?.priceTiers.find(
    (pt) => pt.name === waitlistEntry?.participants[0].priceTierName,
  );
  if (
    eventId &&
    waitlistEntry?.startDate &&
    !priceTier &&
    !isLoadingEventSessions
  ) {
    if (!checkoutCalled) {
      goToError();
    }
  }

  // 5 - add to cart if all data are ready
  if (
    !event ||
    !waitlistEntry ||
    !event.resourceGroups?.[0]?.experiences?.[0]?.id ||
    !priceTier
  ) {
    return null;
  }

  const hasAddOns = event.addOns.length > 0;

  const cartData = {
    waitlistEntryId,
    state: hasAddOns ? CartItemState.PENDING : CartItemState.READY,
    participants: formatParticipants(
      waitlistEntry.participants,
      priceTier.price,
    ),
    notes: '',
    maxParticipantReached: '',
    bookingMode:
      event.eventType === ResourceGroupCollectionEventType.RESERVED_SEATING
        ? BookingMode.PRIVATE
        : BookingMode.SHARED,
    experienceId: event.resourceGroups[0].experiences[0].id,
    listingId: event.id,
    ...(event.eventType === ResourceGroupCollectionEventType.RESERVED_SEATING
      ? {
          groupId: event.resourceGroups[0].id,
          groupTitle: event.resourceGroups[0].title,
        }
      : {}),
    additionalCustomQuestionResponses: formatQuestions(
      waitlistEntry.purchaser.customQuestionAnswers,
    ),
    sessionDateTime: `${waitlistEntry.startDate}T${waitlistEntry.startTime}`,
    sessionDuration: moment
      .duration(waitlistEntry.sessionDuration, 'minutes')
      .toISOString(),
  };

  const purchaserParams = new URLSearchParams();
  if (waitlistEntry.purchaser.firstName) {
    purchaserParams.append('firstName', waitlistEntry.purchaser.firstName);
  }
  if (waitlistEntry.purchaser.lastName) {
    purchaserParams.append('lastName', waitlistEntry.purchaser.lastName);
  }
  if (waitlistEntry.purchaser.emailAddress) {
    purchaserParams.append(
      'emailAddress',
      waitlistEntry.purchaser.emailAddress,
    );
  }
  if (waitlistEntry.purchaser.phoneNumber) {
    purchaserParams.append('phoneNumber', waitlistEntry.purchaser.phoneNumber);
  }

  setCheckoutCalled(true);
  onAddToCart(
    cartData,
    hasAddOns ? '/addons/{cartItemId}' : '/checkout',
    purchaserParams.toString(),
  );

  return (
    <Spinner
      style={{
        minHeight: '75vh',
      }}
    />
  );
};

const formatParticipants = (
  participants: z.infer<
    typeof GetWaitlistEntryPublicEndpoint.responseSchema
  >['participants'],
  price: number,
) =>
  participants.map((p) => ({
    fullName: `${p.firstName} ${p.lastName}`,
    firstName: p.firstName,
    lastName: p.lastName,
    terms: false,
    price,
    priceTierName: p.priceTierName ?? '',
    emailAddress: p.emailAddress ?? undefined,
    phoneNumber: p.phoneNumber ?? undefined,
    ...(p.customQuestionAnswers?.length
      ? {
          customQuestionResponses: formatQuestions(p.customQuestionAnswers),
        }
      : {}),
  }));

const formatQuestions = (
  answers: z.infer<
    typeof GetWaitlistEntryPublicEndpoint.responseSchema
  >['participants'][number]['customQuestionAnswers'],
) => {
  return answers
    ? Object.fromEntries(
        answers.map((answer) => [
          answer.question.customQuestionId,
          answer.answer.map((a) => formatQuestionAnswer(a)),
        ]),
      )
    : {};
};

const formatQuestionAnswer = (
  answer: NonNullable<
    z.infer<
      typeof GetWaitlistEntryPublicEndpoint.responseSchema
    >['participants'][number]['customQuestionAnswers']
  >[number]['answer'][number],
) => {
  let value: string | boolean = false;
  if (Array.isArray(answer.value)) {
    value = answer.value[0];
  } else {
    value = answer.value || false;
  }
  if (!value) {
    value = false;
  }

  return {
    key: answer.key || null,
    value,
  };
};

export default EventClaimTickets;
