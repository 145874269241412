import React, { useEffect, useState, useRef, useMemo, ReactNode } from 'react';
import styled from 'styled-components';
import { Span } from '../theme/Typography/ResourceTypography';
import { Close } from '../Modal/Modal.styles';

export interface BottomDrawerProps {
  size?: 'full';
  open: boolean;
  heading: ReactNode;
  onClose: () => void;
  hideCloseIcon?: boolean;
}

const css = `
  body {
    max-height: 100vh;
    overflow: hidden;
  }
`;

const animationDuration = 250;

export const BottomDrawer: React.FC<
  React.PropsWithChildren<BottomDrawerProps>
> = ({ size, children, open, heading, onClose, hideCloseIcon }) => {
  const [shouldOpen, setShouldOpen] = useState(false);

  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = null;
    if (open) {
      setShouldOpen(open);
    } else {
      timeout.current = setTimeout(() => {
        setShouldOpen(false);
      }, animationDuration);
    }

    return () => {
      if (timeout.current) clearTimeout(timeout.current);

      timeout.current = null;
    };
  }, [open]);

  const className = useMemo(() => {
    const classArray = ['button-drawer-container'];
    if (open) {
      classArray.push('open');
    }

    if (!open && !shouldOpen) {
      classArray.push('closed');
    }

    return classArray.join(' ');
  }, [open]);

  return (
    <>
      <style>{open || shouldOpen ? css : null}</style>
      <Container
        data-should-open={Number(shouldOpen)}
        full={size === 'full'}
        className={className}
        onClick={onClose}
      >
        <ContentWrapper
          className="content-wrapper drawer-content-wrapper"
          role="region"
          tabIndex={0}
          onClick={(e) => e.stopPropagation()}
        >
          <DrawerHead className="drawer-header">
            <Span
              fontSize="20px"
              lineHeight="100%"
              className="bottom-drawer-heading-title"
            >
              {heading}
            </Span>

            {hideCloseIcon ? null : (
              <CloseIcon onClick={onClose} className="close-icon" />
            )}
          </DrawerHead>
          <div className="drawer-content">
            {open || shouldOpen ? children : null}
          </div>
        </ContentWrapper>
      </Container>
    </>
  );
};

const Container = styled.div<{ full?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:not(.closed) {
    z-index: +999;
    height: 100vh;
    width: 100vw;
  }

  &:before {
    transition: all ${animationDuration / 2}ms;
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
  }

  .content-wrapper {
    transition: all ${animationDuration}ms ease-in-out;
    transform: scaleY(0);
    transform-origin: bottom;
  }

  &.open {
    pointer-events: all;

    &:before {
      background: var(--way-palette-black-30);
    }

    .content-wrapper {
      max-height: ${(props) => (props.full ? '80vh' : '50vh')};
      transform: scaleY(1);

      > .drawer-content {
        opacity: 1;
      }
    }
  }
`;

const ContentWrapper = styled.div`
  max-height: 0;
  height: fit-content;
  width: 100%;
  z-index: 1;
  position: fixed;
  overflow: auto;
  bottom: 0;
  padding: 16px;
  border-radius: 4px 4px 0 0;
  background: var(--way-colors-coherentColorShades-100);
  box-shadow: var(--way-design-boxShadow-s);
  backdrop-filter: blur(40px);

  > .drawer-content {
    transition: all ${animationDuration / 4}ms ease-in-out;
    opacity: 0;
  }
`;

const DrawerHead = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 32px;
  width: 100%;
  margin-bottom: 12px;

  > .bottom-drawer-heading-title {
    display: grid;
    place-items: center;
  }

  > .close-icon {
    width: 30px;
    height: 30px;
    border: 0.5px solid var(--way-colors-borderColor);
    border-radius: 50%;
  }
`;

const CloseIcon = styled(Close)`
  background: var(--way-palette-white-100);
  border-radius: 50%;
  transition: transform 0.2s;
  flex-shrink: 0;
  &:hover {
    transform: scale(1.3);
  }
`;
