import React from 'react';

const InfoCircleIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20 * scale}
      height={20 * scale}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M15.3421 4L9.99997 9.38227L4.65781 4L4 4.66275L9.29751 10L4.00007 15.3373L4.65788 16L10 10.6177L15.3422 16L16 15.3373L10.7025 9.99996L15.9999 4.66275L15.3421 4Z"
        style={{
          fill: color,
          stroke: 'none',
        }}
      />
    </svg>
  );
};

export default InfoCircleIcon;
