import fetchApi from 'utils/fetch';
import * as types from 'types/experience';
import { getEmbedConfig } from 'features/EmbedConfig';
import { PublicGetListingsEndpoint } from '@kouto/types';
import * as helpers from './helpers';

export const resetCurrentExperience = () => ({
  type: types.RESET_EXPERIENCE,
});
/**
 * Fetch one experience
 */
const fetchExperienceRequest = ({ id }) => ({
  type: types.FETCH_REQUEST,
  payload: {
    id,
  },
});

const fetchExperienceSuccess = ({ experience }) => ({
  type: types.FETCH_SUCCESS,
  payload: {
    experience,
  },
});

export const fetchExperience = (slug, options) => async (dispatch) => {
  if (!options.brandId) {
    throw new Error('options.brandId not set or invalid');
  }
  // const defaultBrandId = helpers.getDefaultBrandId();
  dispatch(fetchExperienceRequest({ id: slug }));
  try {
    const { data } = await fetchApi
      .get(
        `v1/brands/${options.brandId}/experiences/${slug}?latest=${
          options.latest ?? false
        }`,
      )
      .json();

    return dispatch(fetchExperienceSuccess({ experience: data }));
  } catch (errorResponse) {
    return dispatch(helpers.errorAction(types.FETCH_FAILURE, errorResponse));
  }
};
/**
 * List/Search
 */
const searchExperiencesRequest = () => ({
  type: types.SEARCH_REQUEST,
});

const searchExperiencesSuccess = ({ experiences, append, pagination }) => ({
  type: types.SEARCH_SUCCESS,
  payload: {
    append,
    experiences,
    pagination,
  },
});

export const searchExperiences =
  (query, options, append = false) =>
  async (dispatch) => {
    if (!options.brandId) {
      throw new Error('options.brandId not set or invalid');
    }

    const embedConfig = getEmbedConfig();

    dispatch(searchExperiencesRequest());
    const searchOptions = {};
    searchOptions.searchParams = helpers.filterEmpty({
      ...query,
      categoryIds: embedConfig?.categoryId
        ? [embedConfig.categoryId]
        : query.categoryIds ?? [],
    });
    try {
      const listingsUrl = PublicGetListingsEndpoint.url({
        query: helpers.filterEmpty({
          isUnlisted: 'false',
          ...query,
          categoryIds: embedConfig?.categoryId
            ? [embedConfig.categoryId]
            : query.categoryIds ?? [],
        }),
      });

      const { items, meta } = await fetchApi
        .get(listingsUrl.slice(1, listingsUrl.length))
        .json();
      return dispatch(
        searchExperiencesSuccess({
          experiences: items.filter((item) => item.pictures.length > 0),
          pagination: meta,
          append,
        }),
      );
    } catch (errorResponse) {
      return dispatch(helpers.errorAction(types.SEARCH_FAILURE, errorResponse));
    }
  };

/**
 * Featured Experiences List
 */
const featuredExperiencesRequest = () => ({
  type: types.FEATURED_REQUEST,
});

const featuredExperiencesSuccess = ({ experiences }) => ({
  type: types.FEATURED_SUCCESS,
  payload: {
    experiences,
  },
});

export const featuredExperiences = (options, query) => async (dispatch) => {
  if (!options.brandId) {
    throw new Error('options.brandId not set or invalid');
  }
  dispatch(featuredExperiencesRequest());
  const embedConfig = getEmbedConfig();
  try {
    const { items } = await fetchApi
      .get(`v1/brands/${options.brandId}/experiences`, {
        searchParams: {
          ...query,
          categoryId: embedConfig.categoryId
            ? [embedConfig.categoryId]
            : query.categoryIds ?? [],
        },
      })
      .json();
    return dispatch(
      featuredExperiencesSuccess({
        experiences: items,
      }),
    );
  } catch (errorResponse) {
    return dispatch(helpers.errorAction(types.FEATURED_FAILURE, errorResponse));
  }
};

const getAvailableDatesRequest = ({ from, to }) => ({
  type: types.FETCH_AVAILABILITY_REQUEST,
  payload: {
    from,
    to,
  },
});

const getAvailableDatesSuccess = ({
  availabilities,
  fetchedUntilDate,
  shouldAppend,
}) => ({
  type: types.FETCH_AVAILABILITY_SUCCESS,
  shouldAppend,
  payload: {
    availabilities,
    fetchedUntilDate,
  },
});

export const getAvailableDates =
  (experience, { from, to, isPrivate, latest }, shouldAppend) =>
  async (dispatch) => {
    if (!experience.id) {
      throw new Error('experienceId is required');
    }
    dispatch(getAvailableDatesRequest({ from, to }));
    try {
      const searchOptions = { searchParams: {} };
      const endpointValue = experience?.isBookable
        ? 'dates'
        : 'dates-with-sessions';
      if (isPrivate) {
        searchOptions.searchParams.private = isPrivate;
      }
      if (latest) {
        searchOptions.searchParams.latest = latest;
      }

      const { items } = await fetchApi
        .get(
          `v2/experiences/${experience.id}/${endpointValue}/${from}/${to}`,
          searchOptions,
        )
        .json();
      return dispatch(
        getAvailableDatesSuccess({
          availabilities: items,
          fetchedUntilDate: to,
          shouldAppend,
        }),
      );
    } catch (errorResponse) {
      return dispatch(
        helpers.errorAction(types.FETCH_AVAILABILITY_FAILURE, errorResponse),
      );
    }
  };

export const resetFirstAvailableDate = () => ({
  type: types.RESET_FIRST_AVAILABLE_DATE,
});

const getFirstAvailableDateRequest = ({ from }) => ({
  type: types.FETCH_FIRST_AVAILABLE_DATE_REQUEST,
  payload: {
    from,
  },
});

const getFirstAvailableDateSuccess = ({ firstAvailableDate, sessions }) => ({
  type: types.FETCH_FIRST_AVAILABLE_DATE_SUCCESS,
  payload: {
    firstAvailableDate,
    sessions,
  },
});

export const getFirstAvailableDate =
  (experienceId, { from, isPrivate, latest }) =>
  async (dispatch) => {
    if (!experienceId) {
      throw new Error('experienceId is required');
    }
    dispatch(getFirstAvailableDateRequest({ from }));
    try {
      const searchOptions = {
        searchParams: {},
      };

      if (isPrivate) {
        searchOptions.searchParams.private = isPrivate;
      }

      if (from) {
        searchOptions.searchParams.from = from;
      }

      if (latest) {
        searchOptions.searchParams.latest = latest;
      }

      const {
        data: { scheduledDate, sessions },
      } = await fetchApi
        .get(
          `v2/experiences/${experienceId}/first-available-date`,
          searchOptions,
        )
        .json();
      return dispatch(
        getFirstAvailableDateSuccess({
          firstAvailableDate: scheduledDate,
          sessions,
        }),
      );
    } catch (errorResponse) {
      return dispatch(
        helpers.errorAction(
          types.FETCH_FIRST_AVAILABLE_DATE_FAILURE,
          errorResponse,
        ),
      );
    }
  };

const getAvailableSessionsRequest = ({ date }) => ({
  type: types.FETCH_SESSIONS_REQUEST,
  payload: {
    date,
  },
});

const getAvailableSessionsSuccess = ({
  date,
  sessions,
  cartExperiences,
  experienceId,
}) => ({
  type: types.FETCH_SESSIONS_SUCCESS,
  payload: {
    date,
    sessions,
    cartExperiences,
    experienceId,
  },
});

export const getAvailableSessions =
  (experienceId, date, query) => async (dispatch) => {
    if (!experienceId) {
      throw new Error('experienceId is required');
    }
    const cartExperiences = Object.values(query.cartExperienceList).filter(
      (c) => c.sessionDate === date,
    );
    dispatch(getAvailableSessionsRequest({ date }));
    try {
      const searchOptions = {
        ...(query?.isPrivate && { private: query.isPrivate }),
        searchParams: {},
      };

      if (query?.latest) {
        searchOptions.searchParams.latest = query.latest;
      }
      const { items } = await fetchApi
        .get(`v2/experiences/${experienceId}/sessions/${date}`, searchOptions)
        .json();
      return dispatch(
        getAvailableSessionsSuccess({
          date,
          sessions: items,
          cartExperiences,
          experienceId,
        }),
      );
    } catch (errorResponse) {
      return dispatch(
        helpers.errorAction(types.FETCH_SESSIONS_FAILURE, errorResponse),
      );
    }
  };

const getExperienceSettingsRequest = ({ experienceId }) => {
  return {
    type: types.FETCH_EXPERIENCE_SETTINGS_REQUEST,
    payload: {
      experienceId,
    },
  };
};

const getExperienceSettingsSuccess = ({ data }) => {
  return {
    type: types.FETCH_EXPERIENCE_SETTINGS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const getExperienceSettings =
  (brandId, experienceId) => async (dispatch) => {
    dispatch(getExperienceSettingsRequest({ experienceId }));
    try {
      const { data } = await fetchApi
        .get(`v1/brands/${brandId}/experiences/${experienceId}/settings`)
        .json();
      return dispatch(getExperienceSettingsSuccess({ data }));
    } catch (errorResponse) {
      return dispatch(
        helpers.errorAction(
          types.FETCH_EXPERIENCE_SETTINGS_FAILURE,
          errorResponse,
        ),
      );
    }
  };

const getSessionPriceTiersRequest = ({ startDateTimes }) => {
  return {
    type: types.FETCH_SESSION_PRICE_TIERS_REQUEST,
    payload: {
      startDateTimes,
    },
  };
};

const getSessionPriceTiersSuccess = ({ items }) => {
  return {
    type: types.FETCH_SESSION_PRICE_TIERS_SUCCESS,
    payload: {
      items,
    },
  };
};

export const getSessionPriceTiers =
  (experienceId, startDateTimes) => async (dispatch) => {
    dispatch(getSessionPriceTiersRequest({ startDateTimes }));
    const queryParams = startDateTimes
      .map((value, i) => `startDateTimes[${i}]=${value}`)
      .join('&');

    try {
      const { items } = await fetchApi
        .get(
          `v2/experiences/${experienceId}/sessions/price-tiers?${queryParams}`,
        )
        .json();

      return dispatch(getSessionPriceTiersSuccess({ items }));
    } catch (errorResponse) {
      return dispatch(
        helpers.errorAction(
          types.FETCH_SESSION_PRICE_TIERS_FAILURE,
          errorResponse,
        ),
      );
    }
  };
