import React, { createContext, useContext, useReducer } from 'react';
/* eslint-disable react/jsx-no-constructed-context-values */
import PropTypes from 'prop-types';
import rootReducer from 'reducers';
import useReducerLogger from 'use-reducer-logger';
import storeInitialState from 'store/initial-state';

export const AppContext = createContext();

export const useEnhancedReducer = (reducer, initialState) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const returnActionDispatch = (action) => {
    dispatch(action);
    return action;
  };
  const enhancedDispatch = (actionCreator) => {
    if (typeof actionCreator === 'object') {
      return returnActionDispatch(actionCreator);
    }
    return actionCreator(returnActionDispatch);
  };
  return [state, enhancedDispatch];
};

export const wrapReducer = (reducer) => {
  if (process.env.NODE_ENV === 'development') {
    return useReducerLogger(reducer);
  }
  return reducer;
};

export const AppContextProvider = ({ brandId, children }) => {
  const [state, dispatch] = useEnhancedReducer(
    wrapReducer(rootReducer),
    storeInitialState,
  );
  return (
    <AppContext.Provider value={{ state, dispatch, brandId }}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  brandId: PropTypes.string.isRequired,
};

export const useAppContext = () => {
  const contextValue = useContext(AppContext);
  return contextValue;
};

export const useBrandId = () => {
  const { brandId } = useContext(AppContext);
  return brandId;
};

export const useAppState = (stateSelector) => {
  const { state } = useContext(AppContext);
  if (stateSelector) {
    return stateSelector(state);
  }
  return state;
};

export const useDispatch = () => {
  const { dispatch } = useContext(AppContext);
  return dispatch;
};
