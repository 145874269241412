import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useCartItemId } from 'features/ShoppingCart/hooks';
import { useTranslation } from 'react-i18next';
import { useIsMobile, useIsTablet } from 'WindowDimensionProvider';
import useOneCartItemListing from 'hooks/useOneCartItemListing';
import { Listing } from 'types/listings';
import { useCart } from 'hooks/useCart';
import { CartItemState } from 'types/cart';
import useSearchQueryParams from 'hooks/use-search-params';
import { NavigationHeader } from 'components/NavigationHeader';
import { Flex, Sep } from 'components/common/styled/common-styled';
import { PageWithSidebar, Wrapper } from 'components/theme/Wrapper';
import { listingShouldGoThroughParticipantsPage } from 'utils/listings';
import { PrimaryButton, SecondaryButton } from 'components/theme/Button/Button';
import {
  ResourceHeading2,
  ResourceParagraph,
} from 'components/theme/Typography/ResourceTypography';
import ListingCartItemSummary from 'features/ShoppingCart/components/ListingCartItemSummary';
import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';
import {
  analyticsManager,
  AnalyticsProduct,
  getAnalyticsDataFromCartItem,
} from 'features/analytics';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import useCancellationPolicy from 'hooks/useCancellationPolicy';
import AddOnsList from './components/AddOnsList';
import AddOnsPageLoading from './components/AddOnsPageLoading';

const SelectAddOnsPage = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { onEditCart: editCart } = useCart();
  const { t: translate } = useTranslation();
  const { searchParams } = useSearchQueryParams();
  const cartItemId = useCartItemId();

  const { cartItem, listing, isLoading } = useOneCartItemListing(cartItemId);
  const [addOnsAmounts, setAddOnsAmounts] = useState<Record<string, number>>(
    {},
  );

  const { cancellationPolicy } = useCancellationPolicy(
    listing?.cancellationPolicyId,
  );

  const onEditCart: typeof editCart = (cartData, route, overrideSearch) => {
    if (cartData.state === CartItemState.READY) {
      const data = getAnalyticsDataFromCartItem({
        cartData,
        listing,
      });

      analyticsManager.sendEvent(
        route?.includes('checkout')
          ? ANALYTICS_EVENT.CLICK_PROCEED_TO_CHECKOUT_BUTTON
          : ANALYTICS_EVENT.CLICK_ADD_TO_CART_BUTTON,
        data,
      );
    }

    return editCart(cartData, route, overrideSearch);
  };

  const analyticsItems = useMemo(() => {
    const initialData = {
      ids: [] as string[],
      titles: [] as string[],
      categories: [] as string[],
      items: [] as AnalyticsProduct[],
    };

    return (
      listing?.addOns?.reduce((acc, addOn, index) => {
        acc.items.push({
          id: addOn.id,
          index,
          title: addOn?.label ?? '',
          category: listing.category?.name,
          priceTiers: [
            {
              name: '',
              price: addOn?.price,
            },
          ],
        });
        acc.ids.push(addOn?.id ?? '');
        acc.titles.push(addOn?.label ?? '');

        acc.categories.push(listing.category?.name ?? '');
        return acc;
      }, initialData) ?? initialData
    );
  }, [listing]);

  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_ADDONS,
    payload: {
      [ANALYTICS_PROPERTY.AllExperiences]: analyticsItems.titles.join(';'),
      [ANALYTICS_PROPERTY.AllCategories]:
        analyticsItems.categories.join(';') || '',
      [ANALYTICS_PROPERTY.ExperienceCount]:
        analyticsItems.items?.length?.toString(),
      [ANALYTICS_PROPERTY.Products]: JSON.stringify(analyticsItems.items) ?? [],
    },
    optionalPayload: {
      [ANALYTICS_PROPERTY.ListId]:
        cartItem?.groupId ?? cartItem?.experienceId ?? listing?.id,
      [ANALYTICS_PROPERTY.ListName]: 'AddOns',
    },
    isNotReady: !listing?.addOns?.length,
  });

  useEffect(() => {
    setAddOnsAmounts(
      (cartItem?.addOns || []).reduce((acc, addOn) => {
        acc[addOn.id] = addOn.selectedNumber;
        return acc;
      }, {} as Record<string, number>),
    );
  }, [cartItem]);

  const availableAddOns: Listing['addOns'] = [
    ...(listing?.addOns || []),
    ...((cartItem?.groupId &&
      listing?.resourceGroups?.find((rg) => rg.id === cartItem.groupId)
        ?.addOns) ||
      []),
  ];

  const cartItemDate = useMemo(() => {
    return moment(cartItem?.sessionDateTime.split('T')[0]).format(
      'ddd, MMMM Do',
    );
  }, [cartItem?.sessionDateTime]);

  const shouldGoToParticipantsPage = useMemo(() => {
    const { askPartiticpantsInfo, hasCustomQuestions } =
      listingShouldGoThroughParticipantsPage(
        listing,
        cartItem?.groupId,
        cartItem?.experienceId,
      );
    return askPartiticpantsInfo || hasCustomQuestions;
  }, [listing, cartItem]);

  if (
    ((!cartItem || !listing) && !isLoading) ||
    (listing && !availableAddOns.length)
  ) {
    return (
      <Wrapper>
        <LayoutWrapper>
          <NavigationHeader />
        </LayoutWrapper>
      </Wrapper>
    );
  }

  if (isLoading || !listing) {
    return <AddOnsPageLoading />;
  }

  const selectedAddOns = availableAddOns.flatMap((addOn) => {
    if (addOnsAmounts[addOn.id]) {
      return {
        id: addOn.id,
        label: addOn.label,
        selectedNumber: addOnsAmounts[addOn.id],
        price: addOn.price,
        taxes: addOn.taxes,
      };
    }
    return [];
  });

  const onAddToCart = () => {
    if (!cartItem) return;
    onEditCart(
      {
        ...cartItem,
        state: CartItemState.READY,
        addOns: selectedAddOns,
      },
      '/',
    );
  };

  const onCheckout = () => {
    if (!cartItem) return;

    const params = new URLSearchParams();
    Object.keys(searchParams).forEach((key) => {
      params.set(key, `${searchParams[key]}`);
    });

    onEditCart(
      {
        ...cartItem,
        state: CartItemState.READY,
        addOns: selectedAddOns,
      },
      '/checkout',
      params.toString(),
    );
  };

  const goToParticipantsPage = () => {
    if (!cartItem) return;
    const { askPartiticpantsInfo } = listingShouldGoThroughParticipantsPage(
      listing,
      cartItem.groupId,
      cartItem.experienceId,
    );
    const params = new URLSearchParams();
    params.set('skipParticipantsName', askPartiticpantsInfo ? 'false' : 'true');
    Object.keys(searchParams).forEach((key) => {
      params.set(key, `${searchParams[key]}`);
    });

    onEditCart(
      {
        ...cartItem,
        state: CartItemState.PENDING,
        addOns: selectedAddOns,
      },
      `/e/${cartItem.experienceId}/participants/${cartItem.cartItemId}`,
      params.toString(),
    );
  };

  return (
    <Wrapper>
      <LayoutWrapper>
        <NavigationHeader />
        <PageWithSidebar>
          <MainContent>
            <Flex direction="column" alignItem="flex-start" gap={6}>
              <ResourceHeading2 fontSize="28px" className="add-ons-page-title">
                {listing?.title}
              </ResourceHeading2>
              <ResourceParagraph
                className="current-date"
                fontSize="16px"
                opacity="0.7"
                fontWeight="400"
                lineHeight="20px"
              >
                {cartItemDate}
              </ResourceParagraph>
            </Flex>

            <Sep />

            <AddOnsWrapper>
              <AddOnsList
                addOns={availableAddOns}
                selectedPriceTiersCount={cartItem?.participants.length || 1}
                addOnsAmounts={addOnsAmounts}
                onAddOnsAmountsChange={setAddOnsAmounts}
                columns={isMobile || isTablet ? 1 : 2}
              />
            </AddOnsWrapper>

            <Sep />

            {shouldGoToParticipantsPage ? (
              <PrimaryButton
                big
                className="continue-button"
                onClick={goToParticipantsPage}
              >
                {translate('continue')}
              </PrimaryButton>
            ) : (
              <ButtonsWrapper>
                <SecondaryButton
                  big
                  onClick={onAddToCart}
                  className="add-to-cart-button"
                >
                  {translate('addToCart')}
                </SecondaryButton>

                <PrimaryButton
                  big
                  className="checkout-button"
                  onClick={onCheckout}
                >
                  {translate('checkout')}
                </PrimaryButton>
              </ButtonsWrapper>
            )}
          </MainContent>

          {cartItem && listing && (
            <ListingCartItemSummary
              cartItem={cartItem}
              listing={listing}
              overriddenAddOns={selectedAddOns}
              cancellationPolicy={cancellationPolicy}
            />
          )}
        </PageWithSidebar>
      </LayoutWrapper>
    </Wrapper>
  );
};

export default SelectAddOnsPage;

const LayoutWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  > div {
    flex-shrink: 0;
    flex-grow: 0;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  max-width: calc(100% - 350px - 72px);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 36px;

  @media (max-width: 768px) {
    max-width: unset;
  }
`;

const AddOnsWrapper = styled.div`
  width: 100%;
  max-height: calc(100vh - 400px);
  min-height: 270px;
  overflow-y: auto;

  @media (max-width: 768px) {
    max-height: unset;
    height: fit-content;
    overflow-y: unset;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  & button {
    width: 50%;
  }
`;
