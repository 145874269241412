import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import isEmpty from 'lodash/isEmpty';
import { useTotalCartAmountTracker } from './useTotalCartAmountTracker';
import useTrackCartParticipants from '../features/ShoppingCart/hooks/useTrackCartParticipants';
import { useCartItemId } from '../features/ShoppingCart/hooks';
import { useAppState, useBrandId, useDispatch } from '../AppProvider';
import { IParticipantConfig } from '../features/ShoppingCart/types';
import useSearchQueryParams from './use-search-params';
import { useToastContext } from '../ToastProvider';
import { useExperienceFilterParam } from './use-experience-filter-param';
import useFetchExperienceList from '../selectors/experienceList';
import { CartItemState, ICartExperience } from '../types/cart';
import useFetchExperience from '../selectors/experience';
import useCartExperienceSession from '../components/ExperienceView/hooks/useCartExperienceSession';
import useSelectedParticipants from '../selectors/participants';
import useParticipants from '../features/ShoppingCart/hooks/useParticipants';
import { formatDateForReq, formatUTCDate, uuidv4 } from '../utils';
import {
  addExperienceToCart,
  editCartExperience,
  removeExperienceFromCart,
} from '../actions/cart';
import { BookingMode } from '../types/payment';

import { getCartIneligibleError } from '../features/ShoppingCart/utils';
import {
  getAvailableSessions,
  getExperienceSettings,
} from '../actions/experience';
import { FETCH_SESSIONS_FAILURE } from '../types/experience';
import { ISessionPayload } from '../types/experience.types';
import { fetchBrandTerms } from '../actions/brand';
import { CustomQuestionResponse } from '../types/custom-questions';
import useCartItems from './useCartItems';

export interface RawCartData
  extends Omit<CartData, 'additionalCustomQuestionResponses'> {
  additionalCustomQuestionResponses: Record<string, [CustomQuestionResponse]>;
}

export interface CartData extends IParticipantConfig {
  state: CartItemState;
  sessionDate?: string;
  sessionTime?: string;
  duration?: string;
  experienceId?: string;
  listingId?: string;
  bookingMode?: BookingMode;
  waitlistEntryId?: string;
}

export const useCart = () => {
  useTotalCartAmountTracker();
  useTrackCartParticipants();

  const cartItemId = useCartItemId();
  const dispatch = useDispatch();
  const brandId = useBrandId();
  const history = useHistory();
  const { searchParams } = useSearchQueryParams();

  const { showToast } = useToastContext();

  const experienceFilterParam = useExperienceFilterParam();

  const { experienceList, isFetchingList } = useFetchExperienceList();
  const { cartItems } = useCartItems(true);

  const { experience, fetchExperienceStatus, fetchSettingsStatus } =
    useFetchExperience();

  const cartItem = useCartExperienceSession(
    {
      duration: searchParams.duration,
      startTime: searchParams.sessionTime,
    },
    searchParams.sessionDate,
    experience?.id,
  );

  const { filteredSelectedCustomers } = useSelectedParticipants();

  const { accessCode } = useAppState(
    (state: Record<string, unknown>) => state.accessCode,
  );
  const selectedParticipants = useParticipants(filteredSelectedCustomers);
  const [maxFieldCapacity, setMaxFieldCapacity] = useState(
    selectedParticipants.length,
  );

  const sessionDateTime = formatUTCDate(
    searchParams.sessionDate,
    searchParams.sessionTime,
  );

  const cartEligibilityParams = {
    addedItem: {
      sessionDateTime,
      experience,
      duration: searchParams.duration,
    },
    experienceList,
    cartExperienceItems: cartItems,
    ontoggle,
  };

  const participantsInfoMapper = (
    participants: ICartExperience['participants'],
  ) => {
    return participants.map((p) => {
      const splittedName = p.fullName?.split(' ');
      return {
        ...p,
        firstName: splittedName?.[0] || '',
        lastName: splittedName?.slice(1).join(' ') || '',
      };
    });
  };

  const onDispatchCartAction = async (
    cartData: Omit<ICartExperience, 'cartItemId'>,
  ) => {
    const { cartItemMetadata } = accessCode || {};
    const newCartItemId = uuidv4();

    await dispatch(
      addExperienceToCart({
        cartItemMetadata: {
          cartItemId: newCartItemId,
          ...cartData,
          bookingMode:
            searchParams.mode || cartData.bookingMode || BookingMode.SHARED,
          participants: participantsInfoMapper(cartData.participants),
          ...(cartItemMetadata?.experienceId === cartData.experienceId &&
            cartItemMetadata.sessionDateTime === cartData.sessionDateTime && {
              accessCode: accessCode.code,
            }),
        },
        cartItemId: newCartItemId,
        persistMeta: {
          brandId,
        },
      }),
    );

    return newCartItemId;
  };

  const onEditCart = async (
    cartData: ICartExperience,
    route?: string,
    overrideSearch?: string,
  ) => {
    await dispatch(
      editCartExperience({
        cartItemId: cartData.cartItemId,
        cartItemMetadata: {
          ...cartData,
          participants: participantsInfoMapper(cartData.participants),
        },
        persistMeta: {
          brandId,
        },
      }),
    );

    redirectIfNeeded(cartData.cartItemId, route, overrideSearch);
    showToastIfNeeded(cartData.state);
  };

  const onAddToCart = async (
    rawData: Omit<ICartExperience, 'cartItemId'>,
    route?: string,
    overrideSearch?: string,
  ) => {
    const questions: ICartExperience['additionalCustomQuestionResponses'] = {};
    if (!isEmpty(rawData.additionalCustomQuestionResponses)) {
      Object.keys(rawData.additionalCustomQuestionResponses).forEach((key) => {
        const id = key.replace('additionalCustomQuestionResponses.', '');
        questions[id] = rawData.additionalCustomQuestionResponses[key];
      });
    }
    const cartData = {
      ...rawData,
      additionalCustomQuestionResponses: questions,
    };

    const cartErrorMessage = getCartIneligibleError(
      cartEligibilityParams,
      fetchExperienceStatus,
    );
    if (
      cartErrorMessage &&
      !cartErrorMessage.includes('Payment method is not compatible')
    ) {
      showToast({
        title: 'Failure',
        message: cartErrorMessage,
        type: 'failure',
      });
      return false;
    }

    const newCartItemId = await onDispatchCartAction(cartData);
    if (!newCartItemId) {
      showToast({
        title: 'Failure',
        message: 'Unable to add to cart!',
        type: 'failure',
      });
      return false;
    }

    redirectIfNeeded(newCartItemId, route, overrideSearch);
    showToastIfNeeded(cartData.state);

    return newCartItemId;
  };

  const redirectIfNeeded = (
    newCartItemId: string,
    route?: string,
    overrideSearch?: string,
  ) => {
    let nextRoute = route;
    if (route && route.includes('{cartItemId}')) {
      nextRoute = route.replace('{cartItemId}', newCartItemId);
    }

    if (nextRoute) {
      history.push({
        pathname: nextRoute,
        search: overrideSearch || `?${experienceFilterParam}`,
      });
    }
  };

  const showToastIfNeeded = (cartItemState: ICartExperience['state']) => {
    // TODO if we're editing participants for an item already in the cart, for now we show 'Item added to cart' instead of ''Item updated'
    if (cartItemState === CartItemState.READY) {
      showToast({
        title: 'Success',
        message: 'Item added to cart',
      });
    }
  };

  const onDeleteFromCart = () => {
    if (cartItemId) {
      dispatch(
        removeExperienceFromCart({
          cartItemId,
          persistMeta: {
            brandId,
          },
        }),
      );
      history.push('/e');
      showToast({
        title: 'Success',
        message: 'Item removed from cart',
      });
    }
  };

  const fetchSessionCapacity = async () => {
    const { sessionDateTime, sessionDuration } = cartItems[cartItemId] || {};

    const { payload, type } = await dispatch(
      getAvailableSessions(experience?.id, formatDateForReq(sessionDateTime), {
        isPrivate: searchParams.mode === BookingMode.PRIVATE,
        cartExperienceList: cartItems,
      }),
    );

    if (type === FETCH_SESSIONS_FAILURE) {
      return;
    }

    const sessionCapacity = payload.sessions.find((sess: ISessionPayload) => {
      return (
        sess.startTime === moment(sessionDateTime).format('HH:mm:ss') &&
        sess.duration === sessionDuration
      );
    });

    setMaxFieldCapacity(sessionCapacity?.supportedParticipantsCount || 0);
  };

  useEffect(() => {
    if (!experience.loading && brandId) {
      dispatch(fetchBrandTerms(brandId));
      dispatch(getExperienceSettings(brandId, experience?.id));
    }
  }, [experience.id, experience.loading, brandId]);

  useEffect(() => {
    if (experience?.loading) {
      return;
    }
    if (cartItemId) {
      fetchSessionCapacity().then();
      return;
    }
    if (typeof searchParams?.supportedParticipantsCount !== 'undefined') {
      setMaxFieldCapacity(searchParams.supportedParticipantsCount || 0);
    }
  }, [cartItemId, experience]);

  return {
    onAddToCart,
    onEditCart,
    maxFieldCapacity,
    isFetchingList,
    fetchSettingsStatus,
    fetchExperienceStatus,
    onDeleteFromCart,
    setMaxFieldCapacity,
    experience,
    cartItem,
  };
};
