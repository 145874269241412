export default {
  // these should be unique as the values are used as keys
  profileImageOfHost: 'Imagem de perfil do anfitrião {{hostName}}',
  hostedBy: 'Hospedado por',
  experiences: 'experiências',
  experience: 'experiência',
  clear: 'Limpar',
  cancel: 'Cancelar',
  apply: 'Aplicar',
  more: 'Mais',
  experiencePhotoOfListing:
    'Foto da experiência {{photoNumber}} do anúncio {{listingTitle}}',
  noExperiencesFound: 'Nenhuma experiência encontrada',
  noExperiencesFoundFor: 'Nenhuma experiência encontrada para',
  discoverExperiences: 'Descubra experiências',
  clearFilters: 'Limpar filtros',
  buyTickets: 'Comprar ingressos',
  reserve: 'Reservar',
  activateProductCardTitle: 'Eventos',
  reserveProductCardTitle: 'Atividades',
  hostProductCardTitle: 'Experiências locais',
  mainLandingPageTitle: 'Todas as experiências',
  activateLandingPageTitle: 'Eventos',
  reserveLandingPageTitle: 'Atividades e comodidades',
  hostLandingPageTitle: 'Experiências locais',
  exclusive: 'Exclusivo',
  filterExperiences: 'Filtrar experiências',
};
