export default {
  reviewCompleteHeader: 'Rever e finalizar',
  participants: 'Participantes',
  enterParticipantInfoText:
    'Por favor preencha a informação de cada participante',
  purchaser: 'Comprador',
  firstName: 'Nome',
  lastName: 'Apelido',
  email: 'Email',
  optionalEmail: 'Email (opcional)',
  'phone:': 'Telefone',
  participantFieldPlaceholder: 'Adicionar participante novo ou já existente',
  reservationSummary: 'Resumo da encomenda',
  editParticipantsDetails: 'Editar detalhes do participante',
  participantDetails: 'Detalhes do participante',
  duplicatePurchaserText: 'Mesmo que o comprador',
  phone: 'Telefone',
  addParticipant: 'Adicional',
  participant: 'Participante',
  removeParticipant: 'Remover',
  experienceLimitReached:
    'Ops! Questa esperienza ha raggiunto il numero massimo di partecipanti',
  payment: 'Pagamento',
  duration: '',
  discountCode: 'Código de desconto',
  removeCode: 'Remover código',
  invalidCodeValidation: 'Código inválido',
  networkErrorDiscountCode: 'Algo correu mal do nosso lado',
  discount: 'Desconto',
  promo: 'Promo',
  promoCode: 'Código promocional',
  apply: 'Aplicar',
  total: 'Total',
  subTotal: 'Subtotal',
  includes: 'Inclui',
  and: 'e',
  addHostNotes: 'Adicionar notas',
  policyAggreementText:
    'Concordo com os termos de serviço e condições de privacidade',
  brandAggreementText: 'the terms and conditions of the"',
  termsAggreementValidation: 'Por favor aceite os termos e condições',
  firstNameValidation: 'Primeiro nome obrigatório',
  lastNameValidation: 'Apelido obrigatório',
  emailValidation: {
    emailRequiredText: 'Email obrigatório',
    invalidEmail: 'Email inválido',
    not_specified: 'Endereço de email inválido',
  },
  phoneValidation: {
    'Invalid Phone Number': 'Telefone inválido',
    not_specified: 'Inválido',
    phoneRequiredText: 'Número de telefone obrigatório',
  },
  participantFirstNameValidation: 'Nome do participante obrigatório',
  participantLastNameValidation: 'Apelido do participante obrigatório',
  complete: 'Complete',
  '404Message': 'Não encontrado',
  waiting: 'Aguarde',
  complimentary: 'Gratuito',
  confirmationHeader: 'A tua experiência está reservada!',
  confirmationText: 'Um email de confirmação foi enviado com todos os detalhes',
  viewAll: 'Ver todas as experiências',
  paymentMethod: 'Método de pagamento',
  creditCard: 'Cartão de crédito',
  roomCharge: 'Debitar ao quarto',
  memberCharge: 'Número de membro',
  roomOrReservationNumber: 'Número de quarto ou Número de confirmação',
  roomNumber: 'Número de quarto',
  roomNumberLegacy: 'Número de quarto ou Número de confirmação de reserva',
  memberNumber: 'Número de membro',
  kicc: 'Pagamento rápido',
  chargeValidation: 'Número de quarto e apelido obrigatório',
  memberNumberValidation: 'Insira número de membro',
  invalidRoomNumber: 'Número de quarto inválido',
  invalidRoomNumberLegacy: 'Número de quarto ou código de reserva inválidos',
  invalidMemberNumber: 'Número de membro inválido',
  invalidPhoneNumber: 'Número de telefone inválido',
  guestNameIsRequired: 'Nome de hóspede obrigatório',
  roomNumberIsRequired: 'Numero de quarto obrigatório',
  roomNumberIsRequiredLegacy:
    'Número de quarto ou código de reserva obrigatórios',
  roomOrReservationNumberIsRequired:
    'Número de quarto ou número de confirmação obrigatórios',
  cardNumberIncomplete: 'Número de cartão incompleto',
  lastNameValidationError: 'Apelido obrigatório',
  isReqd: 'obrigatório',
  reservationCode: 'Némero de reserva/confirmação',
  nonHotelGuest: 'Cliente externo',
  onPropertyGuest: 'Hóspede no hotel',
  preArrivalGuest: 'Hóspede a chegar',
  cardNumber: 'Número de cartão',
  pmsValidationErrorMessage: '"Apelido',
  pmsDateValidationErrorMessage:
    'Ups! A data da experiência não coincide entre as datas da sua chegada e partida',
  fullName: 'Nome e apelido',
  checkout: 'Saída',
  editParticipants: 'Editar participantes',
  private: 'Privado',
  browseAllExperieces: 'Ver todas as experiências',
  payWithCreditCard: 'Pagar com cartão de crédito',
  payWithRoomCharge: 'Pagar com débito ao quarto',
  payWithMemberCharge: 'Pagar com débito de membro',
  payWith: 'Pagar com',
  bookBulkErrorByCard:
    'Houve um problema ao confirmar sua reserva. Seu cartão não foi cobrado.',
  bookBulkErrorByRoomCharge: 'Apelido e número do quarto inválidos.',
  bookBulkErrorByMemberCharge: 'Apelido e número de membro inválidos.',
  cancellationPolicy: 'Política de cancelamento',
  reviewCancellation: 'Rever cancelamento',
  selectAReservationToCancel: 'Selecione uma reserva para cancelar',
  confirmCancellation: 'Confirmar cancelamento',
  cancellable: 'Cancelável',
  cancelled: 'Cancelado',
  deadlinePassed: 'Prazo ultrapassado',
  noCancellations: 'Cancelamentos não disponíveis',
  youPaid: 'Pagou',
  refund: 'Reembolso',
  yourRefund: 'Seu reembolso',
  yourRefundWillBeProcessedToTheOriginalPayment:
    'Seu reembolso será processado para o pagamento original',
  thisReservationCanNoLongerBeCancelledPleaseEmail:
    'Esta reserva não pode mais ser cancelada. Por favor, envie um email para {{brandEmail}}',
  reservationCancelled: 'Reserva cancelada',
  thisReservationCannotBeCancelled: 'Esta reserva não pode ser cancelada',
  thisReservationHasAlreadyBeenCancelled: 'Esta reserva já foi cancelada',
  theHotel: 'o Hotel',
  cancelling: 'A cancelar',
  previouslyRefunded: 'Reembolsado anteriormente',
  remainingBalance: 'Saldo restante',
};
