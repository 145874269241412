import React, { FC, useCallback, useEffect, useState } from 'react';
import { ListingPictureKind } from '@kouto/types';
import usePrevious from 'hooks/usePrevious';
import { useIsMobile } from 'WindowDimensionProvider';
import MinimalVideoPlayer from 'components/MinimalVideoPlayer/MinimalVideoPlayer';
import { listingMediaIsVideo } from 'utils/listings';
import { FormattedListingMedia } from 'types/listings';
import MinimalCarousel from './MinimalCarousel';
import { Image } from './styled';

type Props = {
  medias: (FormattedListingMedia & {
    alt?: string;
  })[];
};

const AUTOPLAY_TIMEOUT = 6000; // ms

const MediaCarousel: FC<Props> = ({ medias }) => {
  const isMobile = useIsMobile();
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  const previousSelectedTabIndex = usePrevious(selectedMediaIndex);
  const [timeoutId, setTimeoutId] = useState<number | null>(null);
  const mediaCount = medias?.length || 0;

  const goToNextSlide = useCallback(() => {
    if (mediaCount > 1) {
      setSelectedMediaIndex((selectedMediaIndex + 1) % mediaCount);
    }
  }, [selectedMediaIndex, mediaCount]);

  useEffect(() => {
    if (mediaCount > 1 && previousSelectedTabIndex !== selectedMediaIndex) {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
      if (medias[selectedMediaIndex]?.type === 'image') {
        setTimeoutId(window.setTimeout(goToNextSlide, AUTOPLAY_TIMEOUT));
      }
    }
  }, [
    medias,
    selectedMediaIndex,
    previousSelectedTabIndex,
    mediaCount,
    timeoutId,
    goToNextSlide,
  ]);

  return (
    <MinimalCarousel
      selectedSlideIndex={selectedMediaIndex}
      fit="cover"
      onSlideChange={setSelectedMediaIndex}
    >
      {(medias ?? []).map((media, index) => {
        if (listingMediaIsVideo(media)) {
          return (
            <MinimalVideoPlayer
              key={media.id}
              fit="cover"
              videoUrl={isMobile ? media.urlMobile : media.urlDesktop}
              coverUrl={isMobile ? media.previewMobile : media.previewDesktop}
              loop={mediaCount === 1}
              play={index === selectedMediaIndex}
              onVideoEnd={goToNextSlide}
            />
          );
        }
        return (
          <Image
            key={media.id}
            fit={media.kind === ListingPictureKind.COVER ? 'cover' : 'contain'}
            src={isMobile ? media.urlMobile : media.urlDesktop}
            alt={media.alt}
          />
        );
      })}
    </MinimalCarousel>
  );
};

export default MediaCarousel;
