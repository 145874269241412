import React, { PropsWithChildren } from 'react';
import { useAppState } from 'AppProvider';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';
import { STATUS_PENDING } from 'types/app';
import NotFound from 'components/NotFound/NotFound';
import { Wrapper } from 'components/theme/Wrapper';

const FeatureFlagWrapper = ({
  children,
  featureKey,
}: PropsWithChildren<{ featureKey: string }>) => {
  const { brand } = useAppState();
  const isFeatureEnabled = useBrandToggleFeature(featureKey);
  const isFetchingSettings = brand.fetchSettingsStatus === STATUS_PENDING;
  if (isFetchingSettings) {
    return (
      <div style={{ marginTop: '50px' }}>
        <Wrapper>
          {' '}
          <SkeletonLine style={{ height: 500, width: 600 }} translucent />
        </Wrapper>
      </div>
    );
  }
  return isFeatureEnabled ? children : <NotFound />;
};

export default FeatureFlagWrapper;
