import React, { useEffect } from 'react';
import { IToastConfig, useToastContext } from 'ToastProvider';
import { Heading6 } from 'components/theme/Typography/Typography';
import IconX from 'assets/icon-x';
import * as Styled from './styles';

interface IToastItemProps extends IToastConfig {
  current: number;
}

const Toast = () => {
  const { toastList } = useToastContext();
  return toastList.map((toast, i) => (
    <ToastItem key={toast.id} {...toast} current={i + 1} />
  ));
};

const ToastItem = ({
  id,
  position,
  title,
  type,
  message,
  current,
  duration,
}: IToastItemProps) => {
  const { deleteToast } = useToastContext();

  useEffect(() => {
    const timerId = setTimeout(() => {
      deleteToast(id);
    }, duration);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <Styled.NotificationContainer
      key={id}
      className={`notification-container__${id} ${position}`}
      current={current}
    >
      <Styled.Notification className={position}>
        <Styled.NotificationButton
          type="button"
          onClick={() => deleteToast(id)}
        >
          <IconX color="var(--way-palette-black-70)" />
        </Styled.NotificationButton>
        <Styled.NotificationContent>
          <Styled.NotificationTextHeader>
            <Styled.NotificationImage isSuccess={type === 'success'}>
              {type === 'success' ? (
                <Styled.StyledSuccessIcon />
              ) : (
                <Styled.StyledFailIcon />
              )}
            </Styled.NotificationImage>
            <Heading6>{title}</Heading6>
          </Styled.NotificationTextHeader>
          {message && <Heading6 className="message">{message}</Heading6>}
        </Styled.NotificationContent>
      </Styled.Notification>
    </Styled.NotificationContainer>
  );
};

export default Toast;
