import React, { ComponentProps, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getSessionStartEndTime } from 'utils/formatDuration';
import { ResourceSessionList } from 'components/ResourceSessionSelector/ResourceSessionList';
import { SelectTriggerButton } from './SelectTriggerButton';
import { BottomDrawer } from './BottomDrawer';

interface Props extends ComponentProps<typeof ResourceSessionList> {}

export const BottomSheetSessionSelector: React.FC<Props> = ({
  onSessionClick,
  sessions,
  selectedSession,
}) => {
  const [open, setOpen] = useState(false);
  const { t: translate } = useTranslation();

  const onSessionSelect = (session: Props['sessions'][number]) => {
    setOpen(false);
    onSessionClick?.(session);
  };

  const data = selectedSession
    ? getSessionStartEndTime(selectedSession, 'h:mm A')
    : undefined;

  const defaultLabel = sessions.length
    ? translate('selectTime')
    : translate('unavailable');

  return (
    <>
      <SelectTriggerButton
        disabled={!sessions.length}
        onClick={() => setOpen(true)}
        aria-label={translate('selectSession')}
      >
        {data ? (
          <SessionTimeSpan>
            {data?.startTime} - {data?.endTime}
          </SessionTimeSpan>
        ) : (
          defaultLabel
        )}
      </SelectTriggerButton>

      <BottomDrawer
        open={open}
        heading="Session"
        onClose={() => setOpen(false)}
      >
        <ResourceSessionList
          onSessionClick={onSessionSelect}
          sessions={sessions}
          selectedSession={selectedSession}
        />
      </BottomDrawer>
    </>
  );
};

const SessionTimeSpan = styled.span`
  @media screen and (max-width: 395px) {
    font-size: 12px;
  }
`;
