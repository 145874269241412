import { JoinWaitlistEndpoint } from '@kouto/types';
import fetchApi from 'utils/fetch';

import {
  WaitlistActionTypes,
  IWaitlistAddExperienceAction,
  IWaitlistAddItemAction,
  IWaitlistCleanAction,
  IWaitlistFetchExperienceAction,
  IWaitlistFetchExperienceErrorAction,
} from 'types/waitlist';
import { JoinWaitlistRequest } from 'features/ShoppingCart/types';

export const addExperienceToWaitlist = (
  payload: IWaitlistAddExperienceAction['payload'],
): IWaitlistAddExperienceAction => ({
  type: WaitlistActionTypes.ADD_EXPERIENCE_TO_WAITLIST,
  payload,
});

export const addItemToWaitlist = (
  payload: IWaitlistAddItemAction['payload'],
): IWaitlistAddItemAction => ({
  type: WaitlistActionTypes.ADD_ITEM_TO_WAITLIST,
  payload,
});

export const cleanWaitlist = (
  payload: IWaitlistCleanAction['payload'],
): IWaitlistCleanAction => ({
  type: WaitlistActionTypes.CLEAN_WAITLIST,
  payload,
});

export const fetchWaitlistExperience = (
  payload: IWaitlistFetchExperienceAction['payload'],
): IWaitlistFetchExperienceAction => ({
  type: WaitlistActionTypes.FETCH_WAITLIST_EXPERIENCE,
  payload,
});

export const fetchWaitlistExperienceError = (
  payload: IWaitlistFetchExperienceErrorAction['payload'],
): IWaitlistFetchExperienceErrorAction => ({
  type: WaitlistActionTypes.FETCH_WAITLIST_EXPERIENCE_ERROR,
  payload,
});

export const joinWaitlistAction = async (
  brandId: string,
  waitlistId: string,
  body: JoinWaitlistRequest,
): Promise<boolean> => {
  const joinWaitlistUrl = JoinWaitlistEndpoint.url({
    brandId,
    waitlistId,
  }).substring(1);

  try {
    await fetchApi.post(joinWaitlistUrl, {
      json: body,
    });
    return true;
  } catch (e) {
    // todo: handle error
    return false;
  }
};
