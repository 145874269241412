import React, { FC } from 'react';
import {
  useLocation,
  Redirect,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import { Wrapper } from 'components/theme/Wrapper';
import { CollectionLayout } from 'components/layouts/CollectionLayout';
import { useIsTablet } from 'WindowDimensionProvider';
import useResourceBookingSession from './hooks/useResourceBookingSession';
import { CollectionPage, ResourcePage } from './pages';
import { TwoColumnMapContainer } from './components';
import { NonMappedResourceGroupPage } from './pages/ResourceGroupPage/NonMappedResourceGroupPage';
import { MappedResourceGroupPage } from './pages/ResourceGroupPage/MappedResourceGroupPage';

export const ReserveCollection: FC = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const isTabletView = useIsTablet();
  const { isLoading, cleanedUrl, collection } = useResourceBookingSession();

  if (
    !isLoading &&
    cleanedUrl &&
    `${location.pathname}${location.search}` !== cleanedUrl &&
    !location.pathname.includes('/addons')
  ) {
    const search = cleanedUrl.split('?')[1];
    return (
      <Redirect
        to={{
          pathname: cleanedUrl.split('?')[0],
          ...(search ? { search: `?${search}` } : {}),
        }}
      />
    );
  }

  return (
    <Wrapper>
      <CollectionLayout>
        <TwoColumnMapContainer>
          {!collection?.map ? (
            <Switch>
              <Route
                exact={!isTabletView}
                path={match.path}
                component={CollectionPage}
              />
              {!isTabletView && (
                <Route
                  exact
                  path={`${match.path}/group/:groupId`}
                  component={NonMappedResourceGroupPage}
                />
              )}
            </Switch>
          ) : (
            <Switch>
              <Route exact path={match.path} component={CollectionPage} />
              <Route
                exact
                path={`${match.path}/group/:groupId`}
                component={MappedResourceGroupPage}
              />
              <Route
                exact
                path={`${match.path}/group/:groupId/resource/:resourceId`}
                component={ResourcePage}
              />
            </Switch>
          )}
        </TwoColumnMapContainer>
      </CollectionLayout>
    </Wrapper>
  );
};
