/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useMemo, useState } from 'react';

interface ContextType {
  focusedResources: Set<string>;
  focusResources: (resourceIds: string[]) => void;
  unfocusResources: (resourceIds: string[]) => void;
  selectedResource: string | null;
  selectResource: (resourceId: string) => void;
  unselectResource: () => void;
}

const ResourceMapContext = React.createContext<ContextType>({
  focusedResources: new Set<string>(),
  focusResources: (_resourceIds: string[]) => {},
  unfocusResources: (_resourceIds: string[]) => {},
  selectedResource: null,
  selectResource: (_resourceId: string) => {},
  unselectResource: () => {},
});

ResourceMapContext.displayName = 'ResourceMapContext';

export const useResourceMapContext = () => useContext(ResourceMapContext);

export const ResourceMapContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [focusedResources, setFocusedResources] = useState(new Set<string>());
  const [selectedResource, setSelectedResource] = useState<string | null>(null);

  const value = useMemo(
    () => ({
      focusedResources,
      focusResources: (ids: string[]) => {
        setFocusedResources(new Set(ids));
      },
      unfocusResources: (ids: string[]) => {
        ids.forEach((id) => focusedResources.delete(id));
        setFocusedResources(new Set(focusedResources));
      },
      selectedResource,
      selectResource: (id: string) => {
        setSelectedResource(id);
      },
      unselectResource: () => {
        setSelectedResource(null);
      },
    }),
    [
      focusedResources,
      setFocusedResources,
      selectedResource,
      setSelectedResource,
    ],
  );

  return (
    <ResourceMapContext.Provider value={value}>
      {children}
    </ResourceMapContext.Provider>
  );
};
