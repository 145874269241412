import React, { FC } from 'react';
import styled from 'styled-components';
import { CancellationBooking } from '../useGuestCancellation';
import BookingCancellationCard from './BookingCancellationCard';

type Props = {
  bookings: CancellationBooking[];
  selectedBookingId?: string;
  selectBookingId: (bookingId: string) => void;
};

const SelectReservationStep: FC<Props> = ({
  bookings,
  selectedBookingId,
  selectBookingId,
}) => {
  return (
    <BookingsCancellationList>
      {bookings.map((booking) => (
        <BookingCancellationCard
          key={booking.bookingId}
          booking={booking}
          selected={booking.bookingId === selectedBookingId}
          selectBookingId={selectBookingId}
        />
      ))}
    </BookingsCancellationList>
  );
};

export default SelectReservationStep;

const BookingsCancellationList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 32px;
`;
