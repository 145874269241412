export default {
  date: '날짜',
  chooseExpDate: '체험 날짜 선택',
  filterExpDate: '날짜별 필터링',
  experienceType: '종류',
  experienceVibe: '바이브',
  filterExperienceType: '체험 유형별 필터링',
  filterExperienceVibe: '체험 분위기로 필터링',
  search: '검색',
  viewCalendar: '전체 캘린더 보기',
  back: '뒤로',
  previous: '이전으로',
  next: '다음',
  noExperiences: '체험 없음',
};
