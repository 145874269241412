import { HashRouter, BrowserRouter, MemoryRouter } from 'react-router-dom';
import { EmbedConfig } from 'features/EmbedConfig';
import {
  createHashHistory,
  createBrowserHistory,
  createMemoryHistory,
  HashType,
} from 'history';
import { RouterTypes } from './types';

export const getRouterFromType = (routerType: string) => {
  switch (routerType) {
    case RouterTypes.HASH:
      return HashRouter;
    case RouterTypes.BROWSER:
      return BrowserRouter;
    case RouterTypes.MEMORY:
      return MemoryRouter;
    default:
      return HashRouter;
  }
};

const getHashType = (hashType: string): HashType => {
  if (
    hashType === 'slash' ||
    hashType === 'noslash' ||
    hashType === 'hashbang'
  ) {
    return hashType;
  }

  return 'hashbang';
};

export const getRouterOptions = (embedConfig: EmbedConfig) => {
  switch (embedConfig.routerType) {
    case 'hash':
      return {
        hashType: getHashType(embedConfig.hashType),
        basename: embedConfig.basePath,
      };
    case 'browser':
      return {
        basename: embedConfig.basePath,
        hashType: getHashType('noslash'),
      };
    case 'memory':
      return {};
    default:
      return { hashType: getHashType('hashbang') };
  }
};

export const getHistory = (embedConfig: EmbedConfig) => {
  const config = getRouterOptions(embedConfig);

  switch (embedConfig.routerType) {
    case 'hash':
      return createHashHistory(config);
    case 'browser':
      return createBrowserHistory(config);
    case 'memory':
      return createMemoryHistory({});
    default:
      return createHashHistory(config);
  }
};
