import React from 'react';
import { P1 } from 'components/theme/Typography';
import { useAppState } from 'AppProvider';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import {
  TYPE_RECURRING,
  BOOKING_AVAILABILITY_MODE_NON_RESTRICTED,
} from 'types/experience';
import { useTranslation } from 'react-i18next';

const PartySize = () => {
  const { experience } = useAppState((state) => state.experience);
  const { t: translate } = useTranslation();

  const maxPartySize = experience?.partySize;
  const isExperiencePrivate = isEqual(
    experience?.bookingAvailabilityMode,
    BOOKING_AVAILABILITY_MODE_NON_RESTRICTED,
  );

  const getPartySlots = () => {
    if (maxPartySize > 1)
      return translate('maxPartySizes', { maxGroupSize: maxPartySize });
    return translate('maxPartySize', { maxGroupSize: maxPartySize });
  };

  if (!experience?.partySize) return null;

  return (
    <PartySizeContainer
      isRecurring={isEqual(experience?.type, TYPE_RECURRING)}
      isPrivate={isExperiencePrivate}
    >
      {getPartySlots()}
    </PartySizeContainer>
  );
};

const PartySizeContainer = styled(P1)`
  margin: ${({ isRecurring }) =>
    isRecurring ? '0px !important' : '16px 0 0 !important'};
  padding-bottom: ${({ isPrivate }) => (isPrivate ? '16px' : '0px')};
`;

export default PartySize;
