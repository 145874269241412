export default {
  date: 'Datum',
  chooseExpDate: 'Vyberte datum',
  filterExpDate: 'Filtrovat podle data',
  experienceType: 'Typ',
  filterExperienceType: 'Filtrovat podle typu',
  experienceVibe: 'Atmosféra',
  filterExperienceVibe: 'Filtrovat podle atmosféry',
  search: 'Hledat',
  viewCalendar: 'Kalendář',
  back: 'Zpět',
  previous: 'Předchozí',
  next: 'Další',
  noExperiences: 'Žádné zážitky',
  cancelChanges: 'Zrušit změny',
  saveChanges: 'Uložit změny',
  removeFromReservation: 'Odebrat z rezervace',
  addToCart: 'Přidat do košíku',
  proceedToCheckout: 'Pokračovat k pokladně',
};
