import { Collection } from 'types/listings';
import React from 'react';
import ResponsiveImage from 'components/common/ResponsiveImage/ResponsiveImage';
import { Flex } from 'components/common/styled/common-styled';
import { ResourceParagraph } from 'components/theme/Typography/ResourceTypography';
import styled from 'styled-components';
import { TruncatedText, TruncationMode } from 'components/TruncatedText';
import { CollectionPageNonMappedHeaderSkeleton } from '../pages/Skeleton';

interface Props {
  collection: Collection;
  isLoading?: boolean;
}

export const CollectionHero: React.FC<Props> = ({ collection, isLoading }) => {
  if (isLoading) {
    return <CollectionPageNonMappedHeaderSkeleton />;
  }
  return (
    <Flex direction="column" gap={24} alignItem="flex-start">
      <HeroImageWrapper>
        {collection.coverPicture && (
          <ResponsiveImage
            viewType="large"
            uriObject={collection.coverPicture.uri}
            defaultSize="1080w"
            CustomRenderer={Renderer}
            alt={collection.title}
          />
        )}
      </HeroImageWrapper>
      <Flex direction="column" gap={12} alignItem="flex-start">
        <CollectionDetails>
          <ResourceParagraph
            className="collection-details"
            mobileFontSize="12px"
          >
            <CollectionTitle>{collection.title}</CollectionTitle>
            <TruncatedText
              text={collection.headline ?? ''}
              isRichText
              maxLines={2}
              mode={TruncationMode.INLINE}
            />
          </ResourceParagraph>
        </CollectionDetails>
      </Flex>
    </Flex>
  );
};

const HeroImageWrapper = styled.div`
  margin-bottom: 0;
  border-radius: 0;
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  max-height: 367px;
  align-items: center;
  justify-content: center;

  > picture {
    width: 100%;
  }
`;

const Renderer = styled.img`
  object-fit: cover;
  border-radius: 0;
  object-position: center;
  width: 100%;
`;

const CollectionDetails = styled.div`
  > p > p {
    ~ div {
      margin-top: -24px;
    }
  }
`;

export const CollectionTitle = styled.span<{
  allowMultipleLines?: boolean;
}>`
  font-size: 28px;
  line-height: 40px;
  font-weight: 700;
  white-space: ${({ allowMultipleLines }) =>
    allowMultipleLines ? 'normal' : 'nowrap'};
  text-overflow: ellipsis;
  overflow: hidden;
`;
