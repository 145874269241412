import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ReadMoreButton from 'components/common/ReadMoreButton';

import { getShortDescription } from 'utils';

const BlockDescription = ({
  description,
  showReadMore,
  shortDescriptionLength,
}) => {
  const [isMoreShown, setIsMoreShown] = useState(false);

  const paragraphSplitter = (text) => {
    return text?.split(/\r?\n/).map((line, index) => {
      return (
        <Paragraph
          className="block-description__paragraph"
          key={`block-${index}`}
        >
          {line}
        </Paragraph>
      );
    });
  };

  return (
    /* eslint react/no-array-index-key: "off" */
    <>
      {isMoreShown || !showReadMore
        ? paragraphSplitter(description)
        : paragraphSplitter(
            getShortDescription(description, shortDescriptionLength),
          )}

      {description?.length >= shortDescriptionLength && showReadMore && (
        <ReadMoreButton onClick={() => setIsMoreShown(!isMoreShown)}>
          {isMoreShown ? 'Read Less' : 'Read More'}
        </ReadMoreButton>
      )}
    </>
  );
};

BlockDescription.propTypes = {
  description: PropTypes.string,
  showReadMore: PropTypes.bool,
  shortDescriptionLength: PropTypes.number,
};

BlockDescription.defaultProps = {
  description: '',
  showReadMore: false,
  shortDescriptionLength: 200,
};

export default BlockDescription;

const Paragraph = styled.div`
  font-size: 14px;
  line-height: 32px;
  margin-top: 0;
  white-space: pre-wrap;
  min-height: 10px;
  margin-bottom: 2.5px;
`;
