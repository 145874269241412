import React, { useState, useCallback, useEffect } from 'react';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'WindowDimensionProvider';
import {
  AVAILABLE_BRAND_FEATURES as BrandFeature,
  BookingMode,
  ANALYTICS_EVENT,
} from '@kouto/types';
import { CartItemState } from 'types/cart';
import { ResourceDetailContainer } from 'components/Resource/ResourceDetailContainer';
import BottomSheetResourcePage from 'components/Resource/BottomSheetResourcePage';
import { useResourceMapContext } from 'features/Reserve/contexts/ResourceMapContext';
import moment from 'moment';
import { useCart } from 'hooks/useCart';
import { listingShouldGoThroughParticipantsPage } from 'utils/listings';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import { useTierParticipants } from 'hooks/use-selected-participants';
import { useDispatch } from 'AppProvider';
import { validateAccessCode } from 'actions/access-code';
import { formatUTCDate, TIME_FORMAT } from 'utils';
import { VALIDATE_ACCESS_CODE_FAILURE } from 'types/access-code';
import {
  analyticsManager,
  getAnalyticsDataFromCartItem,
} from 'features/analytics';
import useResourceBookingSession from '../hooks/useResourceBookingSession';
import { ResourcePageSkeleton } from './Skeleton';

export const ResourcePage: React.FC = () => {
  const {
    collection,
    collectionId,
    resourceGroup,
    resource,
    date,
    time,
    latest,
    selectedSession,
    isLoading,
    setResourceBookingSessionParams,
  } = useResourceBookingSession();
  const isMobileView = useIsMobile();
  const { focusResources } = useResourceMapContext();
  const [, setSelectedTierCustomers] = useTierParticipants();
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();
  const [accessCodeError, setAccessCodeError] = useState('');
  const [isCodeValidated, setIsAccessCodeValidated] = useState(false);

  const isAccessCodeVerified = resourceGroup?.isExclusive
    ? isCodeValidated
    : true;

  const [tierId, setTierId] = useState(selectedSession?.priceTiers?.[0].id);
  const { onAddToCart } = useCart();
  const isCartFeatureEnabled = useBrandToggleFeature(
    BrandFeature.SHOPPING_CART,
  );

  useEffect(() => {
    focusResources([]);
  }, []);

  const deselectResource = useCallback(() => {
    setResourceBookingSessionParams({
      resourceId: undefined,
    });
  }, [setResourceBookingSessionParams]);

  useEffect(() => {
    if (!tierId && selectedSession?.priceTiers) {
      setTierId(selectedSession.priceTiers?.[0].id);
    }
  }, [tierId, selectedSession]);

  const handleSessionChange = useCallback(
    (time: string, duration: number) => {
      setResourceBookingSessionParams({
        time,
        duration,
      });
    },
    [setResourceBookingSessionParams],
  );

  if (isLoading) {
    return <ResourcePageSkeleton />;
  }

  if (!collection || !resourceGroup || !resource || !selectedSession) {
    return <>{translate('noData')}</>;
  }

  const syncToCart = async (
    route = '',
    state = CartItemState.READY,
    overrideSearch = '',
  ) => {
    if (!tierId) {
      return;
    }

    const priceTier = selectedSession?.priceTiers?.find((t) => t.id === tierId);

    if (!priceTier) {
      return;
    }

    const participants = [
      {
        fullName: 'Resource Purchaser',
        firstName: 'Resource',
        lastName: 'Purchaser',
        terms: false,
        price: priceTier.price,
        priceTierName: priceTier.name || '',
      },
    ];

    setSelectedTierCustomers({
      currentExperience: resource.id,
      selectedParticipants: [{ ...priceTier, selectedNumber: 1 }],
    });

    const cartData = {
      groupId: resourceGroup.id,
      participants,
      notes: '',
      state,
      bookingMode: BookingMode.SHARED,
      maxParticipantReached: '',
      listingId: collectionId,
      experienceId: resource?.id,
      additionalCustomQuestionResponses: {},
      sessionDateTime: `${date}T${
        selectedSession?.startDateTime
          ? moment(selectedSession.startDateTime).format(TIME_FORMAT)
          : undefined
      }`,
      sessionDuration: selectedSession?.duration
        ? moment.duration(selectedSession?.duration, 'minutes').toISOString()
        : '',
    };

    if (state === CartItemState.READY) {
      const data = getAnalyticsDataFromCartItem({
        cartData,
        resourceGroup,
        collection,
      });

      analyticsManager.sendEvent(
        route
          ? ANALYTICS_EVENT.CLICK_PROCEED_TO_CHECKOUT_BUTTON
          : ANALYTICS_EVENT.CLICK_ADD_TO_CART_BUTTON,
        data,
      );
    }
    await onAddToCart(cartData, route, overrideSearch);
  };

  const handleAddToCart = async () => {
    await syncToCart();
    setResourceBookingSessionParams({
      resourceId: undefined,
    });
  };

  const handleOnCheckout = async () => {
    await syncToCart('/checkout');
  };

  const { hasAddOns, askPartiticpantsInfo, hasCustomQuestions } =
    listingShouldGoThroughParticipantsPage(
      collection,
      resourceGroup.id,
      resource.id,
    );

  const withAccessCodeValidation =
    (onSuccess: CallableFunction) => async (code: string) => {
      if (!resourceGroup.isExclusive) {
        onSuccess();
        return;
      }

      if (isEmpty(code)) {
        setAccessCodeError('Access code is required');
        return;
      }
      const response = await dispatch(
        validateAccessCode({
          code,
          brandId: collection?.brandId,
          experienceId: resource.id,
          sessionDateTime: formatUTCDate(date, time),
          isCartFeatureEnabled,
        }),
      );
      if (response.type === VALIDATE_ACCESS_CODE_FAILURE) {
        setAccessCodeError('Invalid code');
        return;
      }
      onSuccess();
    };

  const onContinue =
    hasAddOns || hasCustomQuestions || askPartiticpantsInfo
      ? async () => {
          if (hasAddOns) {
            await syncToCart('/addons/{cartItemId}', CartItemState.PENDING);
          } else {
            const params = new URLSearchParams();
            params.set(
              'skipParticipantsName',
              askPartiticpantsInfo ? 'false' : 'true',
            );
            if (date) {
              params.set('date', date);
            }
            if (latest) {
              params.set('latest', 'true');
            }
            await syncToCart(
              `/e/${resource.id}/${
                isCartFeatureEnabled ? 'participants' : 'booking'
              }/{cartItemId}`,
              CartItemState.PENDING,
              params.toString(),
            );
          }
        }
      : undefined;

  if (collection.map) {
    return isMobileView ? (
      <BottomSheetResourcePage
        open={!!resource}
        isLoading={isLoading}
        onClose={() => deselectResource()}
        heading={resource.title}
        size="full"
        priceTiers={selectedSession?.priceTiers || []}
        selectedPriceTierId={tierId}
        onTierSelect={setTierId}
        selectedSession={selectedSession}
        onAddToCart={handleAddToCart}
        onCheckout={handleOnCheckout}
        onContinue={onContinue}
        accessCodeVerified={isAccessCodeVerified}
        accessCodeError={accessCodeError}
        onValidateAccessCode={withAccessCodeValidation(() => {
          setIsAccessCodeValidated(true);
        })}
      />
    ) : (
      <ResourceDetailContainer
        onAddToCart={withAccessCodeValidation(handleAddToCart)}
        onCheckout={withAccessCodeValidation(handleOnCheckout)}
        onSessionSelect={handleSessionChange}
        selectedPriceTierId={tierId}
        onTierSelect={setTierId}
        isExclusive={resourceGroup?.isExclusive}
        onContinue={onContinue && withAccessCodeValidation(onContinue)}
        accessCodeError={accessCodeError}
      />
    );
  }

  return null;
};
