import { ANALYTICS_PROPERTY } from '@kouto/types';
import useTotalCartAmount from 'selectors/cart';
import { useEffect } from 'react';
import { analyticsManager } from 'features/analytics';
import useCartItemsListings from 'hooks/useCartItemsListings';

export function useTotalCartAmountTracker() {
  const { cartItems, listings } = useCartItemsListings();
  const { cartTotal, totalDiscountAmount, nonIncludedTaxes } =
    useTotalCartAmount(cartItems, listings);

  useEffect(() => {
    analyticsManager.setAppData({
      [ANALYTICS_PROPERTY.TotalCartAmount]: `${cartTotal.toFixed(2)}`,
      [ANALYTICS_PROPERTY.TotalTaxAmount]: `${nonIncludedTaxes
        .reduce((sum, tax) => sum + tax.amountFee, 0)
        .toFixed(2)}`,
    });
  }, [cartTotal, totalDiscountAmount, nonIncludedTaxes]);
}
