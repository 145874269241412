import {
  ANALYTICS_EVENT,
  IAnalyticsConfiguration,
  type IAnalyticsIntegrationResponse,
} from '@kouto/types/src/api/integration/analytics';
import {
  AnalyticsClient,
  type IAnalyticsDataPayload,
} from 'features/analytics/types/analytics';
import { debug } from 'utils/debug';
import { IntegrationName } from '@kouto/types';
import { Ga4EventsFormatter } from './events.formatter';

type Ga4Config = Partial<Omit<IAnalyticsConfiguration, 'initializeSdk'>> & {
  enabled?: boolean;
};

export class Ga4Client extends AnalyticsClient {
  private readonly config: Ga4Config;

  readonly provider = IntegrationName.GA4_ANALYTICS;

  constructor(response: IAnalyticsIntegrationResponse) {
    super();
    this.config = response.config ?? {};
  }

  async init() {
    await this.runWhenAvailable();

    this.ready = true;

    this.events.forEach((event) =>
      this.sendAnalyticsEvent(event.eventName, event.payload),
    );
  }

  hasEvent() {
    return !!this.config?.enabled;
  }

  // eslint-disable-next-line class-methods-use-this
  isAvailable(): boolean {
    return !!window.dataLayer;
  }

  private sendAnalyticsEvent(
    eventName: ANALYTICS_EVENT,
    payload: IAnalyticsDataPayload,
  ) {
    if (!this.hasEvent()) {
      return;
    }

    const isView = eventName.startsWith('view_');

    const eventProperties = Ga4EventsFormatter(eventName, payload);

    if (!eventProperties) {
      return;
    }

    delete eventProperties?.products;

    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push(eventProperties);

    if (isView) {
      debug('log', this.provider, {
        type: 'View Event triggered',
        eventProperties,
        eventConfig: this.config,
      });
      return;
    }

    debug('log', this.provider, {
      type: 'Link Event triggered',
      eventProperties,
      eventConfig: this.config,
    });
  }

  sendEvent(eventName: ANALYTICS_EVENT, payload: IAnalyticsDataPayload): void {
    if (this.config && !this.hasEvent()) {
      return;
    }
    if (!this.ready) {
      this.events.push({
        eventName,
        payload,
      });
      return;
    }

    this.sendAnalyticsEvent(eventName, payload);
  }
}

declare global {
  interface Window {
    dataLayer?: object[];
  }
}
