import { ANALYTICS_EVENT, ANALYTICS_PROPERTY } from '@kouto/types';
import {
  AnalyticsProduct,
  type IAnalyticsDataPayload,
} from 'features/analytics/types/analytics';

type DataPayload = Omit<IAnalyticsDataPayload, 'products'> & {
  products: AnalyticsProduct[];
};

type MapperFunction = (payload: DataPayload) => {
  event: string;
  ecommerce: {
    value?: number;
    tax?: number;
    currency?: string;
    coupon?: string;
    items?: unknown[];
  };
};

function getNumericValue(value?: string | number) {
  const num = Number(`${value}`);
  if (Number.isNaN(num)) {
    return undefined;
  }

  return num;
}

function filterOutEmptyValues<T extends object>(obj: T): Partial<T> {
  return Object.entries(obj)
    .filter(([, v]) => v || v === 0)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

// Utility function to map product data to GA4 event item format
const mapProductsToItems = (
  payload: DataPayload,
  additionalProps = {} as Partial<
    Record<
      'index' | 'item_list_name' | 'item_isBookable' | 'coupon',
      string | number
    >
  >,
) => {
  const { products } = payload;

  if (!products?.length) {
    return [];
  }

  return products.flatMap((product) => {
    const productData = {
      item_id: product.id,
      item_name: product.title,
      item_category: product.category,
      item_brand: payload.brandName,
      discount: getNumericValue(product.discount),
      item_list_id: product.itemListId,
      item_list_name: product.itemListName,
      coupon: product.coupon,
      ...additionalProps,
    };
    if (!product.priceTiers?.length) {
      return filterOutEmptyValues(productData);
    }
    return product?.priceTiers?.map?.((priceTier, index) => {
      const data = {
        price: getNumericValue(priceTier.price),
        item_variant: priceTier.name,
        quantity: getNumericValue(priceTier.selectedNumber),
        index: getNumericValue(product.index ?? additionalProps.index),
        ...productData,
      };

      if (index > 0) {
        delete data.discount;
        delete data.coupon;
      }

      return filterOutEmptyValues(data);
    });
  });
};

// Mapper function for viewItemListEvent
const viewItemListEvent: MapperFunction = (payload) => {
  const items = mapProductsToItems(payload);

  return {
    event: 'view_item_list',
    ecommerce: {
      item_list_id: payload[ANALYTICS_PROPERTY.ListId],
      item_list_name: payload[ANALYTICS_PROPERTY.ListName] ?? 'All Experiences',
      items,
    },
  };
};

// Mapper function for selectItemEvent
const selectItemEvent: MapperFunction = (payload) => {
  const items = mapProductsToItems(payload, {
    index: getNumericValue(payload[ANALYTICS_PROPERTY.ExperienceOrder]),
    item_isBookable: payload.isBookable,
  });

  return {
    event: 'select_item',
    ecommerce: {
      items,
    },
  };
};

// Mapper function for viewItemEvent
const viewItemEvent: MapperFunction = (payload) => {
  const items = mapProductsToItems(payload, {
    index: getNumericValue(payload[ANALYTICS_PROPERTY.ExperienceOrder]),
    item_isBookable: payload.isBookable,
  });

  return {
    event: 'view_item',
    ecommerce: {
      currency: payload.currencyCode,
      items,
    },
  };
};

// Mapper function for addToCartEvent
const addToCartEvent: MapperFunction = (payload) => {
  const items = mapProductsToItems(payload, {
    index: getNumericValue(payload[ANALYTICS_PROPERTY.ExperienceOrder]),
  });

  return {
    event: 'add_to_cart',
    ecommerce: {
      item_list_id: payload[ANALYTICS_PROPERTY.ListId],
      item_list_name: payload[ANALYTICS_PROPERTY.ListName],
      tax: getNumericValue(payload[ANALYTICS_PROPERTY.TotalTaxAmount]),
      currency: payload.currencyCode,
      value: getNumericValue(payload[ANALYTICS_PROPERTY.CurrentCartAmount]),
      coupon: payload[ANALYTICS_PROPERTY.DiscountCode],
      items,
    },
  };
};

// Mapper function for beginCheckoutEvent
const beginCheckoutEvent: MapperFunction = (payload) => {
  const items = mapProductsToItems(payload, {
    index: getNumericValue(payload[ANALYTICS_PROPERTY.ExperienceOrder]),
  });

  return {
    event: 'begin_checkout',
    ecommerce: {
      currency: payload.currencyCode,
      tax: getNumericValue(payload[ANALYTICS_PROPERTY.TotalTaxAmount]),
      coupon: payload[ANALYTICS_PROPERTY.DiscountCode],
      value: getNumericValue(payload[ANALYTICS_PROPERTY.CurrentCartAmount]),
      items,
    },
  };
};

// Mapper function for viewCartEvent
const viewCartEvent: MapperFunction = (payload) => {
  const items = mapProductsToItems(payload);

  return {
    event: 'view_cart',
    ecommerce: {
      currency: payload[ANALYTICS_PROPERTY.CurrencyCode],
      tax: getNumericValue(payload[ANALYTICS_PROPERTY.TotalTaxAmount]),
      value: getNumericValue(payload[ANALYTICS_PROPERTY.TotalCartAmount]),
      items,
    },
  };
};

// Mapper function for addPaymentInfoEvent
const addPaymentInfoEvent: MapperFunction = (payload) => {
  const items = mapProductsToItems(payload);

  return {
    event: 'add_payment_info',
    ecommerce: {
      currency: payload[ANALYTICS_PROPERTY.CurrencyCode],
      tax: getNumericValue(payload[ANALYTICS_PROPERTY.TotalTaxAmount]),
      value: getNumericValue(payload[ANALYTICS_PROPERTY.TotalCartAmount]),
      payment_type: payload[ANALYTICS_PROPERTY.PaymentMethod],
      items,
    },
  };
};

// Mapper function for purchaseEvent
const purchaseEvent: MapperFunction = (payload) => {
  const items = mapProductsToItems(payload);

  return {
    event: 'purchase',
    ecommerce: {
      currency: payload.currencyCode,
      value: getNumericValue(payload[ANALYTICS_PROPERTY.TotalCartAmount]),
      tax: getNumericValue(payload[ANALYTICS_PROPERTY.TotalTaxAmount]),
      transaction_id: payload[ANALYTICS_PROPERTY.CartConfirmationCode],
      items,
    },
  };
};

const eventMapper: Partial<Record<ANALYTICS_EVENT, MapperFunction>> = {
  [ANALYTICS_EVENT.VIEW_HOME]: viewItemListEvent,
  [ANALYTICS_EVENT.VIEW_COLLECTION]: viewItemListEvent,
  [ANALYTICS_EVENT.VIEW_ADDONS]: viewItemListEvent,
  [ANALYTICS_EVENT.VIEW_EVENTS]: viewItemListEvent,
  [ANALYTICS_EVENT.VIEW_HOSTS]: viewItemListEvent,
  [ANALYTICS_EVENT.VIEW_ACTIVITIES]: viewItemListEvent,
  [ANALYTICS_EVENT.CLICK_EXPERIENCE]: selectItemEvent,
  [ANALYTICS_EVENT.VIEW_EXPERIENCE_DETAIL]: viewItemEvent,
  [ANALYTICS_EVENT.CLICK_ADD_TO_CART_BUTTON]: addToCartEvent,
  [ANALYTICS_EVENT.CLICK_PROCEED_TO_CHECKOUT_BUTTON]: beginCheckoutEvent,
  [ANALYTICS_EVENT.VIEW_CHECKOUT]: viewCartEvent,
  [ANALYTICS_EVENT.CLICK_COMPLETE_PAYMENT_BUTTON]: addPaymentInfoEvent,
  [ANALYTICS_EVENT.VIEW_BOOKING_CONFIRMATION]: purchaseEvent,
};

export function Ga4EventsFormatter(
  event: ANALYTICS_EVENT,
  payload: IAnalyticsDataPayload,
) {
  try {
    const products = (
      payload.products ? JSON.parse(payload.products) : []
    ) as AnalyticsProduct[];

    const data = { ...payload };
    delete data.products;

    const mappedEventPayload = eventMapper[event]?.({ ...data, products });
    if (mappedEventPayload?.ecommerce) {
      mappedEventPayload.ecommerce = filterOutEmptyValues(
        mappedEventPayload.ecommerce,
      );

      if (
        mappedEventPayload.ecommerce.value &&
        mappedEventPayload.ecommerce.tax
      ) {
        mappedEventPayload.ecommerce.value -=
          mappedEventPayload.ecommerce.tax ?? 0;
      }
    }
    return { ...data, ...mappedEventPayload };
  } catch (_e) {
    return undefined;
  }
}
