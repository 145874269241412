import moment from 'moment';
import { Session } from 'types/listings';

export function formatDuration(durationInMinutes: number) {
  const duration = moment.duration(durationInMinutes, 'minutes');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  let result = '';

  if (hours > 0) {
    result += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  }

  if (minutes > 0) {
    result = result.replace('hour', 'hr');
    if (result.length > 0) {
      result += ' ';
    }
    result += `${minutes} min`;
  }

  return result;
}

export function getSessionStartEndTime(
  session: Pick<Session, 'startDateTime' | 'duration'>,
  format = 'h:mma',
) {
  const startTime = moment(session.startDateTime);
  const endTime = startTime.clone().add(session.duration, 'minutes');
  return {
    startTime: startTime.format(format).replace(':00', ''),
    endTime: endTime.format(format).replace(':00', ''),
  };
}

export const convertIntervalToMinutes = (duration: string) => {
  return moment.duration(duration).asMinutes();
};
