import React from 'react';

const PlayIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  ...props
}) => {
  return (
    <svg
      width={20 * scale}
      height={20 * scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6319 17.5V2.5L5.8338 6.89498H2.5V13.105H5.8338L10.6319 17.5ZM6.0989 7.61288L9.93485 4.0992V15.9008L6.0989 12.3871H3.19702V7.61288H6.0989Z"
        fill={color}
        fillOpacity="0.8"
      />
      <path
        d="M17.0071 7.35319L14.9302 9.49237L12.8532 7.35319L12.3603 7.86082L14.4373 10L12.3603 12.1392L12.8532 12.6468L14.9302 10.5076L17.0071 12.6468L17.5 12.1392L15.423 10L17.5 7.86082L17.0071 7.35319Z"
        fill={color}
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default PlayIcon;
