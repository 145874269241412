import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReserveButton } from 'components/CollapsibleResource/styles';
import { BottomDrawer } from 'components/BottomDrawer/BottomDrawer';

type Props = {
  onClose: () => void;
};

const BottomSheetCategorySelector: FC<PropsWithChildren<Props>> = ({
  children,
  onClose,
}) => {
  const { t: translate } = useTranslation();

  return (
    <BottomDrawer
      size="full"
      open
      heading={translate('filterExperiences')}
      onClose={onClose}
    >
      <CategoriesList>{children}</CategoriesList>
      <ReserveButton onClick={onClose}>{translate('apply')}</ReserveButton>
    </BottomDrawer>
  );
};

const CategoriesList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export default BottomSheetCategorySelector;
