import React from 'react';

const CirclePlusIcon = ({
  color = 'var(--way-colors-svgColor)',
  scale = 1,
  className = '',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24 * scale}
      height={24 * scale}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`feather feather-plus-circle ${className}`}
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        style={{
          fill: 'none',
          stroke: color,
        }}
      />
      <line
        x1="12"
        y1="8"
        x2="12"
        y2="16"
        style={{
          fill: 'none',
          stroke: color,
        }}
      />
      <line
        x1="8"
        y1="12"
        x2="16"
        y2="12"
        style={{
          fill: 'none',
          stroke: color,
        }}
      />
    </svg>
  );
};

export default CirclePlusIcon;
