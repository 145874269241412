import React, { useMemo } from 'react';
import { PriceTier } from '@kouto/types';
import { RadioGroup } from 'components/RadioGroup';
import { currencyFormat, getCurrencyFromCode } from 'utils';
import styled from 'styled-components';

interface Props {
  priceTiers: PriceTier[];
  currency: string;
  value?: string;
  onChange: (tier: string) => void;
}

export const PriceTierRadioSelector: React.FC<Props> = ({
  priceTiers,
  currency,
  value,
  onChange,
}) => {
  const options = useMemo(
    () =>
      priceTiers.map((p) => ({
        value: p.id,
        label: (
          <Label>
            <span>{p.name}</span>
            <span>
              {p.price === 0
                ? 'Complimentary'
                : currencyFormat(getCurrencyFromCode(currency))(p.price)}
            </span>
          </Label>
        ),
      })),
    [priceTiers, currency],
  );

  if (!value) {
    return null;
  }

  return (
    <RadioGroup selectedValue={value} options={options} onChange={onChange} />
  );
};

const Label = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > * {
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;

    &:not(:first-child) {
      color: var(--way-colors-contrastColorShades-60);
    }

    &:first-child {
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
    }
  }
`;
