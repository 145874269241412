import { useState, useEffect } from 'react';
import useSearchParams from 'hooks/use-search-params';
import { ValueType } from 'react-select';
import { FilterOption, IFilterState } from './types';
import { loadFilterOptions } from './helpers';

const mapIds = (
  ids: string | string[] | undefined,
  options: FilterOption[],
): FilterOption[] | null => {
  const idsArray = Array.isArray(ids) ? ids : [ids];
  const initialValues = (idsArray ?? []).reduce(
    (filteredOptions: FilterOption[], id) => {
      if (!id) return filteredOptions;
      const option = options.find(({ value }) => value === id);

      if (!option) return filteredOptions;

      return [...(filteredOptions ?? []), { value: id, label: option.label }];
    },
    [],
  );

  return initialValues.length > 0 ? initialValues : null;
};

const getInitialValues = ({
  key,
  rawOptions,
  useQueryParams,
  initialValues = [],
  searchParams,
}: Omit<IFilterState, 'isMulti'> & {
  searchParams: Record<string, unknown>;
}) => {
  if (initialValues.length || !useQueryParams) {
    return loadFilterOptions(initialValues);
  }

  return (
    mapIds(
      searchParams[key] as string | string[],
      loadFilterOptions(rawOptions),
    ) ?? []
  );
};

export const useFilterState = ({
  key,
  rawOptions,
  useQueryParams = true,
  initialValues = [],
  onChange,
  isMulti,
}: IFilterState) => {
  const { searchParams, replaceParams } = useSearchParams();
  const options = loadFilterOptions(rawOptions);

  const initialState = getInitialValues({
    key,
    rawOptions,
    useQueryParams,
    initialValues,
    searchParams,
  });

  const [values, setValues] = useState<FilterOption[]>([]);

  useEffect(() => {
    if (initialState?.length && values.length === 0) {
      setValues(initialState);
    }
  }, [initialState]);

  const onValuesChange = (
    selectedValues: ValueType<FilterOption, typeof isMulti>,
  ) => {
    const selectedValuesArray = Array.isArray(selectedValues)
      ? selectedValues
      : [selectedValues];

    const sanitizedValues =
      selectedValuesArray?.filter((v) => v !== null) ?? [];

    if (useQueryParams) {
      replaceParams({
        ...searchParams,
        [key]: sanitizedValues.map(({ value }) => value),
      });
    }

    setValues(sanitizedValues);
    onChange?.(sanitizedValues);
  };

  return {
    values,
    onValuesChange,
    options,
  };
};
